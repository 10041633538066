import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip as TooltipChart,
  ResponsiveContainer,
} from "recharts";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";

import LegendItem from "../legend-item";
import CustomTooltip from "../custom-tooltip";
import { WEEK, MONTH, QUARTER } from "../account-data";
import { cn } from "utils/classNames";
import { extractIcons } from "utils/extractIcons";

const TransactionalProduct = () => {
  const { transactionalProduct } = useSelector((store) => store.metrics);

  const [period, setPeriod] = useState(WEEK);
  const [chartData, setChartData] = useState([]);

  const buttons = [{ text: WEEK }, { text: MONTH }, { text: QUARTER }];

  useEffect(() => {
    const findIconColor = (idTransaction) => {
      const icon = extractIcons.find(
        (icon) => parseInt(idTransaction) === icon.idTransaction
      );

      return icon ? icon.color : null;
    };

    if (transactionalProduct.length > 0) {
      const dataArr = transactionalProduct.map((product) => ({
        name: product.descricao,
        value:
          period === WEEK
            ? product.valorSemana
            : period === MONTH
            ? product.valorMes
            : product.valorTrimestre,
        color: findIconColor(product.idTransacao),
        qtde:
          period === WEEK
            ? product.quantidadeSemana
            : period === MONTH
            ? product.qauntidadeMes
            : product.quantidadeTrimestre,
      }));
      setChartData(dataArr.filter((item) => item.value > 0));
    }
  }, [transactionalProduct, period]);

  return (
    <div className="w-full bg-white border rounded-lg col-span-4 lg:col-span-2 flex flex-col">
      <div className="flex flex-row flex-wrap items-center justify-between gap-6 p-6">
        <div className="flex flex-row items-center gap-1">
          <h2 className="text-xl font-semibold">Transacional por produto</h2>
          <QuestionMarkCircleIcon
            className="h-5 w-5 cursor-help"
            data-tooltip-id="transactional-product-explanation"
          />
          <Tooltip id="transactional-product-explanation">
            <div className="text-center text-sm normal-case">
              Confira os valores transacionados
              <br />e as quantidades de transações
              <br />
              realizadas por produto e por período.
            </div>
          </Tooltip>
        </div>
        <div className="flex flex-row items-center gap-3">
          {buttons.map((button) => (
            <button
              key={button.text}
              onClick={() => setPeriod(button.text)}
              className={cn(
                "bg-gray-200 px-5 py-1 text-gray-800 rounded-full text-sm w-28 text-center uppercase font-medium",
                period === button.text
                  ? "bg-indigo-600 text-white"
                  : "hover:bg-indigo-400 hover:text-white"
              )}
            >
              {button.text}
            </button>
          ))}
        </div>
      </div>
      {chartData.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 items-center justify-between h-auto md:h-80">
          <ResponsiveContainer width={"100%"} height={250}>
            <PieChart>
              <Pie
                data={chartData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                paddingAngle={1}
                innerRadius={70}
                outerRadius={120}
                minAngle={2}
              >
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <TooltipChart content={<CustomTooltip />} />
            </PieChart>
          </ResponsiveContainer>
          <div className="flex flex-col gap-3 h-auto p-6 md:h-64 overflow-y-auto custom-scroll-track">
            {chartData.map((data) => (
              <LegendItem
                key={data.color}
                name={data.name}
                value={data.value}
                qtde={data.qtde}
                color={data.color}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center grow text-gray-500 text-lg h-auto md:h-80">
          Sem transações no período
        </div>
      )}
    </div>
  );
};

export default TransactionalProduct;
