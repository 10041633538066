import { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import ConfirmationModal from "components/modal-confirmation";
import classNames from "utils/classNames";
import Modal from "components/modal";
import TextArea from "components/textarea";
import { convertCurrencyBRLToString } from "utils/mask";
import { isResponseSuccess } from "utils/request";
import { renderDocument, formatDate } from "utils/mask";
import {
  fetchPendingRequests,
  approve,
  reject,
} from "reducers/limits/limitsThunk";

const PendingList = () => {
  const dispatch = useDispatch();
  const { pendingRequests } = useSelector((store) => store.limits);

  const [groupedData, setGroupedData] = useState([]);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isReasonModalOpen, setReasonModalOpen] = useState(false);
  const [item, setItem] = useState(null);
  const [reason, setReason] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (!pendingRequests.length) {
      dispatch(fetchPendingRequests());
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const pendingRequestsArray = Object.values(
      pendingRequests.reduce(
        (acc, { idClient, nome, documento, nomeBanco, ...rest }) => {
          if (!acc[idClient]) {
            acc[idClient] = {
              idClient,
              nome,
              documento,
              nomeBanco,
              list: [],
            };
          }
          acc[idClient].list.push(rest);
          return acc;
        },
        {}
      )
    );

    setGroupedData(pendingRequestsArray);
  }, [pendingRequests]);

  const openModalHandler = (id, type) => {
    setItem({ id, type });
    setReasonModalOpen(true);
  };

  const confirmModalHandler = () => {
    if (!reason) {
      setError("Motivo de cancelamento deve ser informado!");
      return;
    }

    setReasonModalOpen(false);
    setIsConfirmationModalOpen(true);
  };

  const handleSubmit = async () => {
    const payload = {
      idLimitIncrease: item.id,
      motivo: reason,
    };

    let response;
    if (item?.type === "approve") {
      response = await dispatch(approve(payload));
    } else {
      response = await dispatch(reject(payload));
    }

    if (isResponseSuccess(response)) {
      closeModalHandler();
    }
  };

  const closeModalHandler = () => {
    setReasonModalOpen(false);
    setIsConfirmationModalOpen(false);
    setItem(null);
    setReason("");
    setError("");
  };

  const handleNavigation = (id, document) => {
    let type = 1;
    document.length === 14 ? (type = 2) : (type = 1);

    return `/contas/detalhes/${id}?type=${type}`;
  };

  return (
    <>
      <ConfirmationModal
        title={`${
          item?.type === "approve" ? "Aprovar " : "Recusar "
        } solicitação de limite`}
        description={`Tem certeza que deseja 
          ${
            item?.type === "approve" ? "aprovar " : "recusar "
          } esta solicitação de aumento de limite`}
        confirmButtonLabel={
          item?.type === "approve"
            ? "Aprovar solicitação"
            : "Recusar solicitação"
        }
        onConfirm={handleSubmit}
        cancelButtonLabel={"Cancelar"}
        onCancel={closeModalHandler}
        isModalOpen={isConfirmationModalOpen}
      />
      <Modal
        confirmButtonLabel="Continuar"
        onConfirm={confirmModalHandler}
        cancelButtonLabel={"Voltar"}
        onCancel={closeModalHandler}
        isModalOpen={isReasonModalOpen}
      >
        <div className="text-left">
          <TextArea
            id={"reason"}
            name={"reason"}
            label={"Motivo"}
            value={reason || ""}
            onChange={(evt) => setReason(evt.target.value)}
            maxLength={500}
            error={error}
            errorMessage={error}
          />
        </div>
      </Modal>
      <div className="mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:mx-0 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className=" px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Transação
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
              >
                Data solicitação
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Tipo limite
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Limite atual
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Novo limite
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 table-cell"
              ></th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {groupedData?.length > 0 ? (
              groupedData.map((client) => (
                <Fragment key={client.idClient}>
                  <tr className="border-t border-gray-200">
                    <th
                      colSpan={7}
                      scope="colgroup"
                      className="bg-primary-bank-1 px-4 py-2 text-left text-sm font-semibold text-white sm:px-6 bg-opacity-90"
                    >
                      <div className="flex flex-row items-center justify-between w-full gap-3">
                        <div>
                          <p>{client.nome}</p>
                          <p>{renderDocument(client.documento)}</p>
                        </div>
                        <div className="text-right">
                          <p>{client.nomeBanco}</p>
                          <Link
                            className="underline text-right"
                            to={handleNavigation(
                              client.idClient,
                              client.documento
                            )}
                            target="_blank"
                          >
                            ID Client: {client.idClient}
                          </Link>
                        </div>
                      </div>
                    </th>
                  </tr>
                  {client?.list?.map((limit, transactionIdx) => (
                    <tr
                      key={limit.id}
                      className={classNames(
                        transactionIdx === 0
                          ? "border-gray-300"
                          : "border-gray-200",
                        "border-t align-middle"
                      )}
                    >
                      <td className="w-80 whitespace-normal py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {limit.transacao}
                        <dl className="font-normal">
                          <dt className="sr-only md:hidden">Nome</dt>
                          <dd className="text-gray-500 md:hidden">
                            Data solicitação:{" "}
                            {formatDate(limit.dataSolicitacao)}
                          </dd>
                          <dt className="sr-only sm:hidden">Tipo de limite</dt>
                          <dd className="text-gray-500 sm:hidden">
                            Limite {limit.limiteNoturno ? "noturno" : "diário"}
                          </dd>
                          <dt className="sr-only lg:hidden">Valor atual</dt>
                          <dd className="text-gray-500 lg:hidden">
                            Valor atual: R${" "}
                            {convertCurrencyBRLToString(limit.limiteAtual)}
                          </dd>
                          <dt className="sr-only lg:hidden">Novo limite</dt>
                          <dd className="text-gray-500 lg:hidden">
                            Novo limite: R$ R${" "}
                            {convertCurrencyBRLToString(limit.novoLimite)}
                          </dd>
                        </dl>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden md:table-cell">
                        {formatDate(limit.dataSolicitacao)}
                      </td>
                      <td className="whitespace-normal px-3 py-4 text-sm text-gray-500 truncate hidden sm:table-cell">
                        {limit.limiteNoturno ? "Noturno" : "Diário"}
                      </td>
                      <td className="whitespace-normal px-3 py-4 text-sm text-gray-500 hidden lg:table-cell">
                        R$ {convertCurrencyBRLToString(limit.limiteAtual)}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden lg:table-cell">
                        R$ {convertCurrencyBRLToString(limit.novoLimite)}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 font-medium sm:pr-6">
                        <div className="flex flex-col">
                          <button
                            className="text-indigo-600 hover:text-indigo-500 text-right"
                            onClick={() =>
                              openModalHandler(limit.id, "approve")
                            }
                          >
                            Aprovar
                          </button>
                          <button
                            className="text-red-600 hover:text-red-500 text-right"
                            onClick={() => openModalHandler(limit.id, "reject")}
                          >
                            Rejeitar
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </Fragment>
              ))
            ) : (
              <tr>
                <td
                  colSpan={7}
                  className="text-sm sm:text-base text-gray-500 text-center py-5"
                >
                  Não há dados para exibir
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PendingList;
