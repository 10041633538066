import React, { forwardRef } from "react";

const Button = forwardRef(
  (
    {
      type,
      label,
      secondary = false,
      danger = false,
      onClick,
      className,
      disabled = false,
    },
    ref
  ) => {
    return (
      <button
        type={type}
        onClick={onClick}
        disabled={disabled}
        ref={ref}
        className={`${
          secondary
            ? "text-gray-700 bg-white hover:bg-gray-50 border-gray-300"
            : "text-white bg-indigo-600 hover:bg-indigo-700 border-transparent"
        } ${
          danger
            ? "bg-red-600 hover:bg-red-700 focus:ring-red-500"
            : "focus:ring-indigo-500"
        }
        inline-flex whitespace-nowrap justify-center py-2 px-4 border shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-indigo-300 ${className}`}
      >
        {label}
      </button>
    );
  }
);

export default Button;
