import { useState, useReducer, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { TrashIcon } from "@heroicons/react/24/solid";
import Layout from "components/layout";
import Button from "components/button";
import Modal from "components/modal";
import Input from "components/input";
import Select from "components/select";
import TextArea from "components/textarea";
import DatePicker from "components/date-picker";
import Pagination from "components/pagination";
import timezone from "moment-timezone";
import {
  cnpjMask,
  cpfMask,
  formatWithTimezone,
  numberMask,
  phoneMask,
} from "utils/mask";
import {
  setAddToRestrictiveList,
  setDeleteFromRestrictiveList,
} from "reducers/onboarding/onboardingSlice";
import {
  fetchAllRestrictiveList,
  fetchBanks,
  fetchRestrictiveListTypes,
  postRestrictiveList,
  deleteRestrictiveList,
} from "reducers/onboarding/onboardingThunk";
import { checkRole } from "utils/isRoleAllowed";
import { USER_ADMIN, ADMIN_ECOSYSTEM } from "utils/const";
import Tooltip from "components/tooltip";

const initialValues = {
  currentPage: 1,
  dateFilter: {
    startDate: timezone().subtract(30, "d").format("YYYY-MM-DD"),
    endDate: timezone().format("YYYY-MM-DD"),
  },
  itemsPerPage: 30
};

const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
const SET_DATE_FILTER = "SET_DATE_FILTER";
const SET_ITEMS_PER_PAGE = "SET_ITEMS_PER_PAGE";

const reducer = (state, action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE: 
      return {...state, currentPage: action.payload};
    case SET_DATE_FILTER: 
      return {...state, dateFilter: action.payload, currentPage: 1};
    case SET_ITEMS_PER_PAGE:
      return {...state, itemsPerPage: action.payload, currentPage: 1};
    default:
      return state;
  }
};

const RestrictiveList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [payload, setPayload] = useState({});
  const [payloadErrors, setPayloadErrors] = useState({});
  const [document, setDocument] = useState("");
  const [error, setError] = useState("");
  const [reasonToRemove, setReasonToRemove] = useState("");
  const [reasonToRemoveError, setReasonToRemoveError] = useState("");
  const [idRestrictiveListOnboarding, setIdRestrictiveListOnboarding] = useState("");
  const [idCustomerBank, setIdCustomerBank] = useState("");
  const { restrictiveList, banks, restrictiveListType, addToRestrictiveList, deleteFromRestrictiveList } = useSelector(
    (store) => store.onboarding
  );
  const { role } = useSelector((store) => store.user);
  const [ state, localDispatch ] = useReducer(reducer, initialValues);
  const dispatch = useDispatch();

  const fetchRestrictiveListHandler = useCallback(() => {
    dispatch(
      fetchAllRestrictiveList({
        dataInicio: state.dateFilter.startDate,
        dataFim: state.dateFilter.endDate,
        paginaAtual: state.currentPage,
        document: numberMask(document),
        itemsPerPage: state.itemsPerPage,
      })
    );

    // eslint-disable-next-line
  }, [dispatch, state]);
  
  useEffect(() => {
    fetchRestrictiveListHandler();

  }, [fetchRestrictiveListHandler]);

  useEffect(() => {
    dispatch(fetchBanks());
    dispatch(fetchRestrictiveListTypes());

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(addToRestrictiveList) {
      setIsModalOpen(false);
      setPayload({})
      fetchRestrictiveListHandler();
    }

    return () => {
      dispatch(setAddToRestrictiveList(false));
    };

    // eslint-disable-next-line
  }, [addToRestrictiveList]);

  useEffect(() => {
    if(deleteFromRestrictiveList) {
      setIsRemoveModalOpen(false);
      fetchRestrictiveListHandler();
    }

    return () => {
      dispatch(setDeleteFromRestrictiveList(false));
    };

    // eslint-disable-next-line
  }, [deleteFromRestrictiveList]);

  const handleChangeDate = (newValue) => {
    const startDate = timezone(newValue.startDate, "YYYY-MM-DD");
    const endDate = timezone(newValue.endDate, "YYYY-MM-DD");

    localDispatch({
      type: SET_DATE_FILTER,
      payload: {
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
      },
    });
  };

  const handleChangeDocument = (evt) => {
    const { value } = evt.target;

    if (!value.length) {
      setDocument("");
    } else if (numberMask(value).length <= 11) {
      setDocument(cpfMask(value));
    } else {
      setDocument(cnpjMask(value));
    }
  };

  const handleFilter = () => {
    if (document.length) {
      if (
        numberMask(document).length !== 11 &&
        numberMask(document).length !== 14
      ) {
        setError("Tamanho inválido");
        return;
      }
    }

    setError("");

    changeCurrentPage();
  };

  const handleNextPage = () => {
    localDispatch({ type: SET_CURRENT_PAGE, payload: state.currentPage + 1 });
  };

  const handlePreviousPage = () => {
    localDispatch({ type: SET_CURRENT_PAGE, payload: state.currentPage - 1 });
  };

  const changeCurrentPage = (page = 1) => {
    localDispatch({ type: SET_CURRENT_PAGE, payload: page });
  };

  const changeItemsPerPageHandler = (e) => {
    localDispatch({
      type: SET_ITEMS_PER_PAGE,
      payload: parseInt(e.target.value),
    });
  };

  const renderBankName = (idBank) => {
    const bankArr = banks.filter((bank) => bank.id === idBank);
    if (bankArr.length) return bankArr[0].name;

    return idBank;
  };

  const renderDocument = (document) => {
    const newDocument = numberMask(document);

    if (newDocument.length === 14) {
      return cnpjMask(newDocument);
    } else if (newDocument.length === 11) {
      return cpfMask(newDocument);
    }
  };

  const renderRestrictiveListValue = (type, value) => {
    if (!value) return "";

    if (type === 1 || type === 2) {
      return renderDocument(value);
    }

    if (type === 3) {
      return phoneMask(value);
    }

    return value;
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setPayload({});
    setPayloadErrors({});
  };

  const handleChange = (evt) => {
    const { name, value } = evt.target;

    let newValue = value;

    if (name === "valor") {
      if (payload.idRestrictiveListType === "1" || payload.idRestrictiveListType === "2") {
        if (numberMask(value) && numberMask(value).length <= 11) {
          newValue = cpfMask(value);
        } else {
          newValue = cnpjMask(value);
        }
      } else if (payload.idRestrictiveListType === "3") {
        newValue = phoneMask(value);
      } else {
        newValue = value;
      }
    }

    setPayload((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const validateRequest = (values) => {
    const errors = {};
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&"*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    if (!values.idRestrictiveListType) {
      errors.idRestrictiveListType = "Tipo deve ser informado!";
    }

    if (!values.valor) {
      errors.valor = "Item para inclusão deve ser informado!";
    } else if (
      values.idRestrictiveListType === "1" ||
      values.idRestrictiveListType === "2"
    ) {
      if (
        numberMask(values.valor).length !== 11 &&
        numberMask(values.valor).length !== 14
      ) {
        errors.valor = "Informe um CPF ou CNPJ válido!";
      }
    } else if (values.idRestrictiveListType === "3") {
      if (numberMask(values.valor).length !== 11) {
        errors.valor = "Celular deve ter 11 dígitos!";
      }
    } else if (values.idRestrictiveListType === "4") {
      if (!emailRegex.test(values.valor)) {
        errors.valor = "Email em formato inválido!";
      }
    }

    if (process.env.REACT_APP_MODE === "CORE" && !values.idCustomerBank) {
      errors.idCustomerBank = "Banco deve ser informado!";
    }

    if (!values.razaoInclusao) {
      errors.razaoInclusao = "Motivo de inclusão deve ser informado!";
    }

    setPayloadErrors(errors);

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = () => {
    if (validateRequest(payload)) {

      let payloadRequest = {
        idCustomerBank: parseInt(payload.idCustomerBank),
        idBlacklistType: payload.idRestrictiveListType,
        razaoInclusao: payload.razaoInclusao,
        valor: payload.idRestrictiveListType !== "4"
          ? numberMask(payload.valor)
          : payload.valor,
      }
      dispatch(
        postRestrictiveList(payloadRequest)
      );
      handleCancel();
    }
  };

  const handleOpenRemoveModal = (id, idBank) => {
    setIdRestrictiveListOnboarding(id);
    setIdCustomerBank(idBank);
    setIsRemoveModalOpen(true);
  };

  const handleRemoveFromList = () => {
    if (!reasonToRemove) {
      setReasonToRemoveError("Motivo deve ser informado!");
      return;
    }

    dispatch(
      deleteRestrictiveList({
        idRestrictiveListOnboarding,
        payload: { razaoExclusao: reasonToRemove, idCustomerBank },
      })
    );
    handleCancelRemove();
  };

  const handleCancelRemove = () => {
    setIsRemoveModalOpen(false);
    setReasonToRemove("");
    setReasonToRemoveError("");
    setIdRestrictiveListOnboarding("");
  };

  return (
    <>
      <Modal
        confirmButtonLabel={"Remover"}
        onConfirm={handleRemoveFromList}
        cancelButtonLabel={"Cancelar"}
        onCancel={handleCancelRemove}
        isModalOpen={isRemoveModalOpen}
      >
        <div className="mt-5 text-left flex flex-col gap-5">
          <div>
            <TextArea
              label={"Motivo"}
              id={"razaoExclusao"}
              name={"razaoExclusao"}
              placeholder={"Descreva o motivo para excluir da lista"}
              value={reasonToRemove || ""}
              maxLength={500}
              onChange={(evt) => setReasonToRemove(evt.target.value)}
              error={reasonToRemoveError}
              errorMessage={reasonToRemoveError}
            />
          </div>
        </div>
      </Modal>
      <Modal
        confirmButtonLabel={"Cadastrar"}
        onConfirm={handleSubmit}
        cancelButtonLabel={"Cancelar"}
        onCancel={handleCancel}
        isModalOpen={isModalOpen}
      >
        <div className="mt-5 text-left flex flex-col gap-5">
          <div>
            <Select
              label={"Tipo"}
              id={"idRestrictiveListType"}
              name={"idRestrictiveListType"}
              value={payload.idRestrictiveListType || ""}
              onChange={handleChange}
              options={restrictiveListType}
              placeholder={"Selecione o tipo"}
              error={payloadErrors.idRestrictiveListType}
              errorMessage={payloadErrors.idRestrictiveListType}
            />
          </div>
          <div>
            <Input
              label={"Valor"}
              type={"text"}
              name={"valor"}
              id={"valor"}
              value={payload.valor || ""}
              onChange={handleChange}
              error={payloadErrors.valor}
              errorMessage={payloadErrors.valor}
              disabled={!payload.idRestrictiveListType}
              maxLength={payload.idRestrictiveListType === "3" ? "15" : "100"}
            />
          </div>
          {process.env.REACT_APP_MODE === "CORE" && (
            <div>
              <Select
                label={"Banco"}
                id={"idCustomerBank"}
                name={"idCustomerBank"}
                value={payload.idCustomerBank || ""}
                onChange={handleChange}
                options={banks}
                placeholder={"Selecione o banco"}
                error={payloadErrors.idCustomerBank}
                errorMessage={payloadErrors.idCustomerBank}
              />
            </div>
          )}
          <div>
            <TextArea
              label={"Motivo"}
              id={"razaoInclusao"}
              name={"razaoInclusao"}
              placeholder={"Descreva o motivo da inclusão na lista restritiva"}
              value={payload.razaoInclusao || ""}
              maxLength={500}
              onChange={handleChange}
              error={payloadErrors.razaoInclusao}
              errorMessage={payloadErrors.razaoInclusao}
            />
          </div>
        </div>
      </Modal>
      <Layout title={"Lista Restritiva"}>
        <div className="relative">
          <div className="flex flex-col md:flex-row gap-3 items-start justify-between">
            <div className="flex flex-col w-full sm:flex-row gap-3 items-start">
              <div className="sm:max-w-[184px] w-full">
                <DatePicker 
                  dateFilter={state.dateFilter}
                  handleChangeDate={handleChangeDate}
                />
              </div>
              <div className="w-full md:max-w-xs">
                <Input
                  type={"text"}
                  name={"document"}
                  id={"document"} 
                  placeholder={"Informe o CPF ou CNPJ"}
                  onChange={handleChangeDocument}
                  value={document}
                  error={error}
                  errorMessage={error}
                />
              </div>
              <Button
                type={"submit"}
                label={"Filtrar"}
                onClick={handleFilter}
                className={"w-full sm:w-auto"}
              />
            </div>
            {checkRole(role, [USER_ADMIN, ADMIN_ECOSYSTEM]) && (
              <Button
                type={"button"}
                label={"Adicionar Documento"}
                onClick={() => setIsModalOpen(true)}
                className={"w-full sm:w-auto"}
              />
            )}
          </div>
          <div className="mt-4 sm:mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:mx-0 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Chave
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden lg:table-cell"
                  >
                    Inclusão
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden md:table-cell"
                  >
                    Exclusão
                  </th>
                  {process.env.REACT_APP_MODE === "CORE" && (
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden xl:table-cell"
                    >
                      Banco
                    </th>
                  )}
                  {checkRole(role, [USER_ADMIN, ADMIN_ECOSYSTEM]) && (
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Remover</span>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {restrictiveList.itens.length > 0 ? (
                  restrictiveList.itens.map((item) => (
                    <tr key={item.idRestrictiveListOnboarding}>
                      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm sm:w-auto sm:max-w-none sm:pl-6 text-gray-900 font-medium lg:text-gray-500 lg:font-normal">
                        <span className="text-gray-900 font-medium block">
                          {item.nomeTipoRestrictiveList}
                        </span>
                        {renderRestrictiveListValue(item.idRestrictiveListType, item.valor)}
                        <dl className="font-normal">
                          {process.env.REACT_APP_MODE === "CORE" && (
                            <>
                              <dt className="sr-only xl:hidden">Banco</dt>
                              <dd className="mt-1 text-gray-500 xl:hidden">
                                {renderBankName(parseInt(item.idCustomerBank))}
                              </dd>
                            </>
                          )}
                          <dt className="sr-only lg:hidden">Dados inclusão</dt>
                          <dd className="mt-3 text-gray-500 lg:hidden">
                            <span className="block mb-1">
                              Incluído por: {item.incluidoPorNome}
                            </span>
                            <span className="block mb-1">
                              Data inclusão:{" "}
                              {formatWithTimezone(item.incluidoEm)}
                            </span>
                            <span className="block mb-1">
                              Motivo inclusão: {item.razaoInclusao}
                            </span>
                          </dd>
                          {item.excluidoPorNome && (
                            <>
                              <dt className="sr-only md:hidden">
                                Dados exclusão
                              </dt>
                              <dd className="mt-3 text-gray-500 md:hidden">
                                <span className="block mb-1">
                                  Excluído por: {item.excluidoPorNome}
                                </span>
                                <span className="block mb-1">
                                  Data exclusão:{" "}
                                  {formatWithTimezone(item.excluidoEm)}
                                </span>
                                <span className="block mb-1">
                                  Motivo exclusão: {item.razaoExclusao}
                                </span>
                              </dd>
                            </>
                          )}
                        </dl>
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500 hidden lg:table-cell">
                        <span className="block whitespace-nowrap">
                          Responsável: {item.incluidoPorNome}
                        </span>
                        <span className="block whitespace-nowrap">
                          Data: {formatWithTimezone(item.incluidoEm)}
                        </span>
                        <span className="block">
                          Motivo: {item.razaoInclusao}
                        </span>
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500 hidden md:table-cell">
                        {item.excluidoPorNome && (
                          <>
                            {" "}
                            <span className="block whitespace-nowrap">
                              Responsável: {item.excluidoPorNome}
                            </span>
                            <span className="block whitespace-nowrap">
                              Data: {formatWithTimezone(item.excluidoEm)}
                            </span>
                            <span className="block">
                              Motivo: {item.razaoExclusao}
                            </span>
                          </>
                        )}
                      </td>
                      {process.env.REACT_APP_MODE === "CORE" && (
                        <td className="px-3 py-4 text-sm text-gray-500 hidden xl:table-cell whitespace-nowrap">
                          {renderBankName(parseInt(item.idCustomerBank))}
                        </td>
                      )}
                      {checkRole(role, [USER_ADMIN, ADMIN_ECOSYSTEM]) && (
                        <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          {!item.excluidoPorNome && (
                            <Tooltip text={"Excluir"}>
                              <TrashIcon
                              className="h-5 w-5 text-red-600 cursor-pointer hover:scale-125"
                              onClick={() => 
                                  handleOpenRemoveModal(
                                    item.idBlacklistOnboarding,
                                    item.idCustomerBank
                                  )
                                }
                              >
                                <span className="sr-only">, {item.valor}</span>
                              </TrashIcon>
                            </Tooltip>
                          )}
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={
                        process.env.REACT_APP_MODE === "CORE" &&
                        checkRole(role, [USER_ADMIN, ADMIN_ECOSYSTEM])
                          ? 5
                          : 4
                      }
                      className="text-sm sm:text-base text-gray-500 text-center py-5"
                    >
                      Não há dados para exibir
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Pagination
              total={restrictiveList.total}
              pageItens={restrictiveList?.itens?.length}
              pageCount={Math.ceil(restrictiveList.total / state.itemsPerPage)}
              currentPage={state.currentPage}
              onNextPage={handleNextPage}
              onPreviousPage={handlePreviousPage}
              onClick={changeCurrentPage}
              itemsPerPage={state.itemsPerPage}
              onChangeItemsPerPage={changeItemsPerPageHandler}
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default RestrictiveList;
