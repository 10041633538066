import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Layout from "components/layout";
import NewAccountPf from "./pf";
import NewAccountPj from "./pj";
import { fetchBanks } from "reducers/onboarding/onboardingThunk";

export default function NewAccount() {
  const zipcode = useSelector((store) => store.zipcode);
  const { banks } = useSelector((store) => store.onboarding);
  const dispatch = useDispatch();
  const [tipoConta, setTipoConta] = useState("PF");

  useEffect(() => {
    dispatch(fetchBanks());

    // eslint-disable-next-line
  }, []);

  return (
    <Layout title="Abrir nova conta">
      <div className="relative">
        <select
          name="tipoConta"
          id="tipoConta"
          value={tipoConta}
          onChange={(e) => setTipoConta(e.target.value)}
          className="absolute right-0 block py-2 px-3 pr-10 border border-gray-300 bg-gray-50 text-gray-500 rounded-md border-r-1 focus:ring-indigo-500 focus:border-indigo-500 text-sm -mt-14"
        >
          <option value="PJ">Pessoa Jurídica</option>
          <option value="PF">Pessoa Física</option>
        </select>
        {tipoConta === "PF" && <NewAccountPf banks={banks} zipcode={zipcode} />}
        {tipoConta === "PJ" && <NewAccountPj banks={banks} zipcode={zipcode} />}
      </div>
    </Layout>
  );
}
