import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  IdentificationIcon,
  BuildingStorefrontIcon,
  EnvelopeIcon,
  PhoneIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";

import CopyToClipboard from "components/copy-to-clipboard";
import { cpfMask, cnpjMask, phoneMask } from "utils/mask";

export default function PageHeader() {
  const { client, status } = useSelector((store) => store.client);
  const [copied, setCopied] = useState({ isActive: false, field: "" });
  const [accountType, setAccountType] = useState();
  const [loadingImg, setLoadingImg] = useState(true);
  const [imgSrc, setImgSrc] = useState(null);
  const isOpenByApp = client.onboardingManualBy === 0;
  const accountIsActive = status.idStatus === (1 || 2 || 3);
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  const accountTypeQuery = parseInt(query.get("type"));

  let document = "";
  accountType === 1
    ? (document = cpfMask(client.cpf))
    : (document = cnpjMask(client.cnpj));

  let phoneNumber = phoneMask(`${client.codigoArea}${client.numeroTelefone}`);

  useEffect(() => {
    setAccountType(accountTypeQuery);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (client?.urlImagemGrande) {
      setImgSrc(client.urlImagemGrande);
      setLoadingImg(true);
    }

    // eslint-disable-next-line
  }, [client]);

  const handleImageError = () => {
    setImgSrc(null);
    setLoadingImg(false);
  };

  const ProfilePicture = () => {
    return (
      imgSrc && (
        <div className="flex justify-center align-middle max-w-[70px] max-h-[70px] mr-2 lg:mr-3 lg:max-w-[100px] lg:max-h-[100px] p-1 rounded-full ring-2 ring-gray-300 dark:ring-gray-500">
          <img
            className="w-full h-full rounded-full object-cover"
            src={imgSrc}
            onError={handleImageError}
            alt="Foto de perfil do usuário"
          />
        </div>
      )
    );
  };

  return (
    <div className="flex items-center">
      <div className="hidden lg:block">{!loadingImg && <ProfilePicture />}</div>
      <div className="w-full">
        <div className="flex items-center">
          <div className="lg:hidden">{!loadingImg && <ProfilePicture />}</div>

          <div className="w-full flex flex-col items-start lg:flex-row gap-1 lg:items-center justify-between">
            <h1 className="text-xl font-bold leading-tight text-gray-900">
              {client?.nomeFantasia || client?.razaoSocial || client?.nome}
            </h1>
            <div className="flex flex-wrap gap-1">
              <span
                className={`inline-flex items-center w-fit rounded-full px-3 py-0.5 text-sm font-medium ${
                  accountIsActive
                    ? "bg-blue-100 text-blue-800"
                    : "bg-red-100 text-red-800"
                }`}
              >
                {status.descricao}
              </span>
              <span
                className={`inline-flex items-center w-fit rounded-full px-3 py-0.5 text-sm font-medium ${
                  isOpenByApp
                    ? "bg-blue-100 text-blue-800"
                    : "bg-red-100 text-red-800"
                }`}
              >
                {isOpenByApp
                  ? "Conta aberta pelo app"
                  : "Conta aberta manualmente"}
              </span>
            </div>
          </div>
        </div>
        <p className="block min-w-max items-center mt-2 mb-2 lg:mt-0 text-xs text-gray-400">
          Clique para copiar:
        </p>
        <div className="flex flex-col gap-3 lg:flex-row lg:justify-between lg:items-center w-full">
          <div className="flex flex-col md:flex-row md:space-x-6">
            <div className="inline-flex min-w-max items-center mt-3 md:mt-0 text-sm text-gray-500">
              {copied.field === "document" ? (
                <CheckCircleIcon className="flex-shrink text-green-500 h-[18px] w-[18px] mr-2" />
              ) : accountType === 1 ? (
                <IdentificationIcon className="flex-shrink h-5 w-5 mr-1.5" />
              ) : (
                <BuildingStorefrontIcon className="flex-shrink h-5 w-5 mr-1.5" />
              )}
              <CopyToClipboard
                value={document}
                onComplete={() => {
                  setCopied({
                    isActive: true,
                    field: "document",
                  });
                }}
                onReset={() => {
                  setCopied({
                    isActive: false,
                    field: "",
                  });
                }}
              >
                {document}
              </CopyToClipboard>
            </div>
            <div className="inline-flex min-w-max items-center mt-3 md:mt-0 text-sm text-gray-500 hover:text-gray-600">
              {copied.field === "email" ? (
                <CheckCircleIcon className="flex-shrink text-green-500 h-[18px] w-[18px] mr-1.5" />
              ) : (
                <EnvelopeIcon className="flex-shrink h-[18px] w-[18px] mr-1.5" />
              )}
              <CopyToClipboard
                value={client.email}
                onComplete={() => {
                  setCopied({
                    isActive: true,
                    field: "email",
                  });
                }}
                onReset={() => {
                  setCopied({
                    isActive: false,
                    field: "",
                  });
                }}
              >
                {client.email}
              </CopyToClipboard>
            </div>
            <div className="inline-flex min-w-max items-center mt-3 md:mt-0 text-sm text-gray-500 hover:text-gray-600">
              {copied.field === "phoneNumber" ? (
                <CheckCircleIcon className="flex-shrink text-green-500 h-[18px] w-[18px] mr-1" />
              ) : (
                <PhoneIcon className="flex-shrink h-4 w-4 mr-1.5" />
              )}
              <CopyToClipboard
                value={phoneNumber}
                onComplete={() => {
                  setCopied({
                    isActive: true,
                    field: "phoneNumber",
                  });
                }}
                onReset={() => {
                  setCopied({
                    isActive: false,
                    field: "",
                  });
                }}
              >
                {phoneNumber}
              </CopyToClipboard>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-3 text-sm my-3 md:flex-row md:space-x-6">
          <p className="text-gray-500">ID Cliente: {client.idClient}</p>
          <p className="text-gray-500">ID Conta: {client.idAccount}</p>
        </div>
      </div>
    </div>
  );
}
