import { createAsyncThunk } from "@reduxjs/toolkit";

import { setNotification } from "reducers/notification/notificationSlice";
import { setIsLoading } from "reducers/loading/isLoadingSlice";
import {
  getBaasBalance,
  getBanksBalance,
  getBaasBalanceByMonth,
  getBanksBalanceByMonth,
  getBaasBalanceByDay,
  getBanksBalanceByDay,
  getBaasExtract,
  getBanksExtract,
  getClientExtract,
  downloadCSVBaas,
  downloadCSVBanks,
  downloadCSVClient,
} from "services/financial";

export const fetchBaasBalance = createAsyncThunk(
  "financial/fetchBaasBalance",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getBaasBalance();

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchBanksBalance = createAsyncThunk(
  "financial/fetchBanksBalance",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getBanksBalance();

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchBaasBalanceByMonth = createAsyncThunk(
  "financial/fetchBaasBalanceByMonth",
  async ({ dataInicio, dataFim, currentPage, itemsPerPage }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getBaasBalanceByMonth(
        dataInicio,
        dataFim,
        currentPage,
        itemsPerPage
      );

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchBanksBalanceByMonth = createAsyncThunk(
  "financial/fetchBanksBalanceByMonth",
  async (
    { idCustomerBank, dataInicio, dataFim, currentPage, itemsPerPage },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getBanksBalanceByMonth(
        idCustomerBank,
        dataInicio,
        dataFim,
        currentPage,
        itemsPerPage
      );

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchBaasBalanceByDay = createAsyncThunk(
  "financial/fetchBaasBalanceByDay",
  async ({ dataInicio, dataFim, currentPage, itemsPerPage }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getBaasBalanceByDay(
        dataInicio,
        dataFim,
        currentPage,
        itemsPerPage
      );

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchBanksBalanceByDay = createAsyncThunk(
  "financial/fetchBanksBalanceByDay",
  async (
    { idCustomerBank, dataInicio, dataFim, currentPage, itemsPerPage },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getBanksBalanceByDay(
        idCustomerBank,
        dataInicio,
        dataFim,
        currentPage,
        itemsPerPage
      );

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchBaasExtract = createAsyncThunk(
  "financial/fetchBaasExtract",
  async (
    { dataInicio, dataFim, currentPage, itemsPerPage, creditoDebito, tipo },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getBaasExtract(
        dataInicio,
        dataFim,
        currentPage,
        itemsPerPage,
        creditoDebito,
        tipo
      );

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchBanksExtract = createAsyncThunk(
  "financial/fetchBanksExtract",
  async (
    {
      idCustomerBank,
      dataInicio,
      dataFim,
      currentPage,
      itemsPerPage,
      creditoDebito,
      tipo,
    },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getBanksExtract(
        idCustomerBank,
        dataInicio,
        dataFim,
        currentPage,
        itemsPerPage,
        creditoDebito,
        tipo
      );

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue({ transacoes: [], total: 0 });
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchClientExtract = createAsyncThunk(
  "financial/fetchClientExtract",
  async (
    {
      idClient,
      dataInicio,
      dataFim,
      currentPage,
      itemsPerPage,
      creditoDebito,
      tipo,
    },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getClientExtract(
        idClient,
        dataInicio,
        dataFim,
        currentPage,
        itemsPerPage,
        creditoDebito,
        tipo
      );

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue({ transacoes: [], total: 0 });
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchBaasCsv = createAsyncThunk(
  "financial/fetchBaasCsv",
  async (params, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const { dataInicio, dataFim } = params;

      const response = await downloadCSVBaas(dataInicio, dataFim);

      return response.data;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchBanksCsv = createAsyncThunk(
  "financial/fetchBanksCsv",
  async (params, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const { idCustomerBank, dataInicio, dataFim } = params;

      const response = await downloadCSVBanks(
        idCustomerBank,
        dataInicio,
        dataFim
      );

      return response.data;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchClientCsv = createAsyncThunk(
  "financial/fetchClientCsv",
  async (params, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const { idClient, dataInicio, dataFim, creditoDebito, tipo } = params;

      const response = await downloadCSVClient(
        idClient,
        dataInicio,
        dataFim,
        creditoDebito,
        tipo
      );

      return response.data;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);
