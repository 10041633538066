import axiosClient from "./apiClient";

export function getBaasBalance() {
  return axiosClient.get(`${process.env.REACT_APP_API_DASH}/saldo/baas`);
}

export function getBanksBalance() {
  return axiosClient.get(`${process.env.REACT_APP_API_DASH}/saldo/banks`);
}

export function getBaasBalanceByMonth(
  dataInicio,
  dataFim,
  currentPage,
  itemsPerPage
) {
  const params = new URLSearchParams();

  dataInicio && params.append("dataInicio", dataInicio);
  dataFim && params.append("dataFinal", dataFim);
  currentPage && params.append("pagina", currentPage);
  itemsPerPage && params.append("itensPorPagina", itemsPerPage);

  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/saldo/baas/months`,
    {
      params,
    }
  );
}

export function getBanksBalanceByMonth(
  idBank,
  dataInicio,
  dataFim,
  currentPage,
  itemsPerPage
) {
  const params = new URLSearchParams();

  dataInicio && params.append("dataInicio", dataInicio);
  dataFim && params.append("dataFinal", dataFim);
  currentPage && params.append("pagina", currentPage);
  itemsPerPage && params.append("itensPorPagina", itemsPerPage);

  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/saldo/bank/${idBank}/months`,
    {
      params,
    }
  );
}

export function getBaasBalanceByDay(
  dataInicio,
  dataFim,
  currentPage,
  itemsPerPage
) {
  const params = new URLSearchParams();

  dataInicio && params.append("dataInicio", dataInicio);
  dataFim && params.append("dataFinal", dataFim);
  currentPage && params.append("pagina", currentPage);
  itemsPerPage && params.append("itensPorPagina", itemsPerPage);

  return axiosClient.get(`${process.env.REACT_APP_API_DASH}/saldo/baas/days`, {
    params,
  });
}

export function getBanksBalanceByDay(
  idBank,
  dataInicio,
  dataFim,
  currentPage,
  itemsPerPage
) {
  const params = new URLSearchParams();

  dataInicio && params.append("dataInicio", dataInicio);
  dataFim && params.append("dataFinal", dataFim);
  currentPage && params.append("pagina", currentPage);
  itemsPerPage && params.append("itensPorPagina", itemsPerPage);

  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/saldo/bank/${idBank}/days`,
    {
      params,
    }
  );
}

export function getBaasExtract(
  dataInicio,
  dataFim,
  currentPage,
  itemsPerPage,
  creditoDebito,
  tipo
) {
  const params = new URLSearchParams();

  dataInicio && params.append("dataInicio", dataInicio);
  dataFim && params.append("dataFinal", dataFim);
  currentPage && params.append("pagina", currentPage);
  itemsPerPage && params.append("itensPorPagina", itemsPerPage);
  creditoDebito && params.append("creditoDebito", creditoDebito);
  tipo && params.append("tipo", tipo);

  return axiosClient.get(`${process.env.REACT_APP_API_DASH}/extrato/baas`, {
    params,
  });
}

export function getBanksExtract(
  idCustomerBank,
  dataInicio,
  dataFim,
  currentPage,
  itemsPerPage,
  creditoDebito,
  tipo
) {
  const params = new URLSearchParams();

  idCustomerBank && params.append("idCustomerBank", idCustomerBank);
  dataInicio && params.append("dataInicio", dataInicio);
  dataFim && params.append("dataFinal", dataFim);
  currentPage && params.append("pagina", currentPage);
  itemsPerPage && params.append("itensPorPagina", itemsPerPage);
  creditoDebito && params.append("creditoDebito", creditoDebito);
  tipo && params.append("tipo", tipo);

  return axiosClient.get(`${process.env.REACT_APP_API_DASH}/extrato/bank`, {
    params,
  });
}

export function getClientExtract(
  idClient,
  dataInicio,
  dataFim,
  currentPage,
  itemsPerPage,
  creditoDebito,
  tipo
) {
  const params = new URLSearchParams();

  idClient && params.append("idClient", idClient);
  dataInicio && params.append("dataInicio", dataInicio);
  dataFim && params.append("dataFinal", dataFim);
  currentPage && params.append("pagina", currentPage);
  itemsPerPage && params.append("itensPorPagina", itemsPerPage);
  creditoDebito && params.append("creditoDebito", creditoDebito);
  tipo && params.append("tipo", tipo);

  return axiosClient.get(`${process.env.REACT_APP_API_DASH}/extrato/client`, {
    params,
  });
}

export function downloadCSVBaas(dataInicio, dataFim, creditoDebito, tipo) {
  const params = new URLSearchParams();

  dataInicio && params.append("dataInicio", dataInicio);
  dataFim && params.append("dataFinal", dataFim);
  creditoDebito && params.append("creditoDebito", creditoDebito);
  tipo && params.append("tipo", tipo);

  return axiosClient.get(`${process.env.REACT_APP_API_DASH}/extrato/baas/csv`, {
    params,
  });
}

export function downloadCSVBanks(
  idCustomerBank,
  dataInicio,
  dataFim,
  creditoDebito,
  tipo
) {
  const params = new URLSearchParams();

  idCustomerBank && params.append("idCustomerBank", idCustomerBank);
  dataInicio && params.append("dataInicio", dataInicio);
  dataFim && params.append("dataFinal", dataFim);
  creditoDebito && params.append("creditoDebito", creditoDebito);
  tipo && params.append("tipo", tipo);

  return axiosClient.get(`${process.env.REACT_APP_API_DASH}/extrato/bank/csv`, {
    params,
  });
}

export function downloadCSVClient(
  idClient,
  dataInicio,
  dataFim,
  creditoDebito,
  tipo
) {
  const params = new URLSearchParams();

  idClient && params.append("idClient", idClient);
  dataInicio && params.append("dataInicio", dataInicio);
  dataFim && params.append("dataFinal", dataFim);
  creditoDebito && params.append("creditoDebito", creditoDebito);
  tipo && params.append("tipo", tipo);

  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/extrato/client/csv`,
    {
      params,
    }
  );
}
