import timezone from "moment-timezone";
import moment from "moment";

export const cnpjMask = (value) => {
  if (value)
    return value
      .replace(/\D+/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d)/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
};

export const cpfMask = (value) => {
  if (value)
    return value
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
};

export const cepMask = (value) => {
  if (value)
    return value
      .replace(/\D/g, "")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{3})\d+?$/, "$1");
};

export const phoneMask = (value) => {
  if (value)
    return value
      .replace(/\D/g, "")
      .replace(/^(\d{2})(\d)/g, "($1) $2")
      .replace(/(\d)(\d{4})$/, "$1-$2");
};

export const numberMask = (value) => {
  if (value) return value.replace(/\D/g, "");
};

export const dateMask = (value) => {
  if (value) {
    let v = value.replace(/\D/g, "").slice(0, 8);
    if (v.length >= 5) {
      return `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`;
    } else if (v.length >= 3) {
      return `${v.slice(0, 2)}/${v.slice(2)}`;
    }
    return v;
  }
};

export const timeMask = (value) => {
  if (value) {
    let v = value.replace(/\D/g, "").slice(0, 4);
    if (v.length >= 3) return `${v.slice(0, 2)}:${v.slice(2, 4)}`;
    return v;
  }
};

export const convertCurrencyBRLToFloat = (value) => {
  if (!value) value = "0.00";

  return parseFloat(
    value
      .replace(/[^0-9,.]/g, "")
      .replace(/[.]/g, "")
      .replace(",", ".")
  );
};

export const convertCurrencyBRLToString = (value) => {
  if (!value) value = "0,00";

  const currencyFractionDigits = new Intl.NumberFormat("pt-br", {
    style: "currency",
    currency: "BRL",
  }).resolvedOptions().maximumFractionDigits;

  return value.toLocaleString("pt-br", {
    maximumFractionDigits: currencyFractionDigits,
    minimumFractionDigits: 2,
  });
};

export const formatCurrency = (inputValue) => {
  const DECIMAL_SIZE = -2;
  const onlyDigits = inputValue
    .split("")
    .filter((s) => /\d/.test(s))
    .join("")
    .padStart(3, "0");

  const digitsFloat =
    onlyDigits.slice(0, DECIMAL_SIZE) + "." + onlyDigits.slice(DECIMAL_SIZE);

  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  })
    .format(digitsFloat)
    .replace("R$", "")
    .trim();
};

export const currencyMask = (value) => {
  if (convertCurrencyBRLToFloat(value)) {
    return value
      .replace(/\D/g, "")
      .replace(/(\d)(\d{2})$/, "$1,$2")
      .replace(/(?=(\d{3})+(\D))\B/g, ".");
  }
};

export const formatDate = (date, mask) => {
  return timezone.utc(date).format(!mask ? "DD/MM/YYYY HH:mm:ss" : mask);
};

export const formatWithTimezone = (date) => {
  return timezone
    .utc(date)
    .tz("America/Sao_Paulo")
    .format("DD/MM/YYYY HH:mm:ss");
};

export const renderDocument = (document) => {
  if (!document) return "";
  if (document.length === 14) {
    return cnpjMask(document);
  } else if (document.length === 11) {
    return cpfMask(document);
  } else {
    return document;
  }
};

export const getUTCTime = (time) => {
  return moment(time, "HH:mm:ss").format("HH:mm");
};
