import { createAsyncThunk } from "@reduxjs/toolkit";

import { getCep } from "services/cep";
import { setNotification } from "reducers/notification/notificationSlice";
import { setIsLoading } from "reducers/loading/isLoadingSlice";

export const fetchZipcode = createAsyncThunk(
  "user/fetchZipcode",
  async (zipcode, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getCep(zipcode);
      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(zipcode);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);
