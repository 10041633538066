import { createAsyncThunk } from "@reduxjs/toolkit";

import { setNotification } from "reducers/notification/notificationSlice";
import { setIsLoading } from "reducers/loading/isLoadingSlice";
import {
  getAccountLimits,
  updateAccountLimit,
  getBankPixLimit,
  getPendingRequests,
  approveLimitRequest,
  rejectLimitRequest,
  getHistoryRequests,
} from "services/limits";

export const listClientLimits = createAsyncThunk(
  "limits/listClientLimits",
  async (idClient, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      let response = await getAccountLimits(idClient);

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const newAccountLimit = createAsyncThunk(
  "limits/newAccountLimit",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      await updateAccountLimit(payload.idClient, payload);

      thunkAPI.dispatch(
        setNotification({
          type: "success",
          title: "Limite Cadastrado",
          message: "O novo limite foi cadastrado com sucesso!",
        })
      );

      thunkAPI.dispatch(listClientLimits(payload.idClient));
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchBankPixLimit = createAsyncThunk(
  "limits/fetchBankPixLimit",
  async (idCustomerBank, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getBankPixLimit(idCustomerBank);

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchPendingRequests = createAsyncThunk(
  "limits/fetchPendingRequests",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getPendingRequests();

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const approve = createAsyncThunk(
  "limits/approve",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      await approveLimitRequest(payload);

      thunkAPI.dispatch(fetchPendingRequests());

      thunkAPI.dispatch(
        setNotification({
          type: "success",
          title: "Solicitação aprovada!",
          message:
            "Você aprovou a solicitação de aumento de limite com sucesso.",
        })
      );
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const reject = createAsyncThunk(
  "limits/reject",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      await rejectLimitRequest(payload);

      thunkAPI.dispatch(fetchPendingRequests());

      thunkAPI.dispatch(
        setNotification({
          type: "success",
          title: "Solicitação recusada!",
          message:
            "Você recusou a solicitação de aumento de limite com sucesso.",
        })
      );
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchHistoryRequests = createAsyncThunk(
  "onboarding/fetchHistoryRequests",
  async (
    {
      dataInicio,
      dataFim,
      documento,
      paginaAtual,
      itemsPerPage,
      idCustomerBank,
    },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getHistoryRequests(
        dataInicio,
        dataFim,
        documento,
        paginaAtual,
        itemsPerPage,
        idCustomerBank
      );

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue([]);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);
