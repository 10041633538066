import React from "react";

export default function TextArea({
  type,
  name,
  id,
  value,
  label,
  rows = 3,
  disabled = false,
  required = false,
  error = false,
  errorMessage,
  maxLength,
  onChange,
  placeholder,
}) {
  return (
    <>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <textarea
          type={type}
          name={name}
          id={id}
          value={value}
          onChange={onChange}
          disabled={disabled}
          required={required}
          maxLength={maxLength}
          placeholder={placeholder}
          className={`${
            error
              ? "border-red-300 focus:ring-red-500 focus:border-red-500 text-red-900 pr-10"
              : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 pr-2"
          }
              block w-full mt-1 outline-0 text-sm rounded-md disabled:cursor-not-allowed disabled:bg-gray-100 resize-none`}
          aria-describedby={`${name}-error`}
          rows={rows}
        />
        {error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 fill-red-600"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        )}
      </div>
      {error && (
        <p className="mt-2 mb-0.5 text-sm text-red-600" id={`${name}-error`}>
          {errorMessage}
        </p>
      )}
    </>
  );
}
