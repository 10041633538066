import NightTime from "./night-time";
import BankLimit from "./bank-limit";

const Limits = () => {
  return (
    <>
      <NightTime />
      <BankLimit />
    </>
  );
};

export default Limits;
