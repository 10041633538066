import { createSlice } from "@reduxjs/toolkit";

import { registerPos, fethPosList } from "./posThunk";

const initialState = {
  posList: [],
  postPos: { success: false, error: false },
};

const posSlice = createSlice({
  name: "pos",
  initialState,
  reducers: {
    setPostPos: (state, action) => {
      return { ...state, postPos: action.payload };
    },
  },
  extraReducers: {
    [registerPos.pending]: (state) => {
      return { ...state, postPos: { success: false, error: false } };
    },
    [registerPos.fulfilled]: (state) => {
      return { ...state, postPos: { success: true, error: false } };
    },
    [registerPos.rejected]: (state) => {
      return { ...state, postPos: { success: false, error: true } };
    },
    [fethPosList.pending]: (state) => {
      return { ...state, posList: [] };
    },
    [fethPosList.fulfilled]: (state, action) => {
      return { ...state, posList: action.payload };
    },
    [fethPosList.rejected]: (state) => {
      return { ...state, posList: [] };
    },
  },
});

export const { setPostPos } = posSlice.actions;

export default posSlice.reducer;
