import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import SlideOver from "components/slide-over";
import Input from "components/input";
import Button from "components/button";
import ConfirmationModal from "components/modal-confirmation";
import Modal from "components/modal";
import Select from "components/select";
import { convertCurrencyBRLToString } from "utils/mask";
import { USER_ADMIN, ADMIN_ECOSYSTEM } from "utils/const";
import { checkRole } from "utils/isRoleAllowed";
import {
  fetchClientAvailableGroups,
  removeClientFeeGroup,
  patchClientFeeGroup,
  fetchClientFees,
} from "reducers/fees/feesThunk";

const Fees = () => {
  const dispatch = useDispatch();

  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [feeDetail, setFeeDetail] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [feeGroupsList, setFeeGroupsList] = useState([]);
  const [idFeeGroup, setIdFeeGroup] = useState("");
  const [error, setError] = useState("");
  const { role } = useSelector((store) => store.user);
  const { clientFees, clientAvailableGroups } = useSelector(
    (store) => store.fees
  );
  const { idClient } = useParams();

  useEffect(() => {
    dispatch(fetchClientFees(idClient));
    dispatch(fetchClientAvailableGroups(idClient));
  }, [idClient, dispatch]);

  useEffect(() => {
    setFeeGroupsList(
      clientAvailableGroups.map((feeGroup) => ({
        id: feeGroup.idFeeGroup,
        name: feeGroup.nomeGrupoTarifa,
      }))
    );
  }, [clientAvailableGroups]);

  const renderChargedValue = (feeItem) => {
    const isPercentual = feeItem.percentual;
    const symbol = isPercentual ? "%" : "R$";

    if (isPercentual) {
      return (
        <div className="flex flex-col">
          <span>
            {convertCurrencyBRLToString(feeItem.percentual)} {symbol}
          </span>
          {feeItem.valorMaximoParaCobrancaPorPercentual && (
            <span className="text-xs leading-5 text-gray-500">
              valor máximo R${" "}
              {convertCurrencyBRLToString(
                feeItem.valorMaximoParaCobrancaPorPercentual
              )}
            </span>
          )}
        </div>
      );
    } else {
      return `${symbol} ${convertCurrencyBRLToString(feeItem.valor)}`;
    }
  };

  const openDetailsHandler = (item) => {
    setFeeDetail(item);
    setIsDetailsOpen(true);
  };

  const closeDetailsHandler = () => {
    setIsDetailsOpen(false);
  };

  const deleteFeeGroupHandler = () => {
    dispatch(removeClientFeeGroup(idClient));
    setIsConfirmationModalOpen(false);
  };

  const registerFeeGroupHandler = () => {
    if (!idFeeGroup) {
      setError("Grupo de tarifas deve ser informado!");
      return;
    }

    dispatch(
      patchClientFeeGroup({ idClient, idFeeGroup: parseInt(idFeeGroup) })
    );
    closeModalHandler();
  };

  const closeModalHandler = () => {
    setIsModalOpen(false);
    setIdFeeGroup("");
    setError("");
  };

  const checkClientGroupFee = () => {
    if (!clientFees.length) return " ";

    const groupFee = clientAvailableGroups.find(
      (group) => group.idFeeGroup === clientFees[0].idFeeGroup
    );

    if (groupFee) return groupFee.nomeGrupoTarifa;

    return " ";
  };

  return (
    <>
      <div className="relative">
        <SlideOver
          isOpen={isDetailsOpen}
          onClose={closeDetailsHandler}
          title={feeDetail.nome}
        >
          <dl className="space-y-8 sm:space-y-6">
            <div>
              <Input
                label={"Tipo de cobrança"}
                value={feeDetail.percentual ? "Percentual" : "Valor fixo"}
                disabled
                type={"text"}
              />
            </div>
            <div>
              <Input
                label={"Valor mínimo para cobrança"}
                value={`R$ ${convertCurrencyBRLToString(
                  feeDetail.valorMinimoParaCobranca
                )}`}
                disabled
                type={"text"}
              />
            </div>
            <div>
              <Input
                label={"Quantidade mínima mensal para cobrança"}
                value={feeDetail.quantidadeMinimaMensalParaCobranca}
                disabled
                type={"text"}
              />
            </div>
            <div>
              <Input
                label={"Quantidade máxima mensal para cobrança"}
                value={feeDetail.quantidadeMaximaMensalParaCobranca}
                disabled
                type={"text"}
              />
            </div>
            <div>
              <Input
                label={"Dias de atraso para tarifas periódicas"}
                value={feeDetail.quantidadeDiasAtrasoTarifaPeriodica}
                disabled
                type={"text"}
              />
            </div>
          </dl>
        </SlideOver>

        <ConfirmationModal
          title={"Deseja prosseguir?"}
          description={
            "Caso confirme, o cliente será isentado em todas as transações."
          }
          confirmButtonLabel={"Continuar"}
          onConfirm={deleteFeeGroupHandler}
          cancelButtonLabel={"Cancelar"}
          onCancel={() => setIsConfirmationModalOpen(false)}
          isModalOpen={isConfirmationModalOpen}
        />

        <Modal
          title={
            clientFees.length > 0
              ? "Mudar grupo de tarifas"
              : "Cadastrar grupo de tarifas"
          }
          description={
            "Caso a lista esteja vazia, o banco em que o cliente está cadastrado não possui nenhum grupo de tarifas cadastrado."
          }
          confirmButtonLabel={"Salvar"}
          onConfirm={registerFeeGroupHandler}
          cancelButtonLabel={"Cancelar"}
          onCancel={closeModalHandler}
          isModalOpen={isModalOpen}
        >
          <div className="text-left mt-5">
            <div>
              <Select
                id={"idFeeGroup"}
                name={"idFeeGroup"}
                label={"Grupo de tarifas:"}
                placeholder={"Selecione o grupo de tarifas"}
                value={idFeeGroup}
                onChange={(e) => setIdFeeGroup(e.target.value)}
                options={feeGroupsList}
                error={error}
                errorMessage={error}
              />
            </div>
          </div>
        </Modal>
      </div>

      <div className="flex items-center mb-5 justify-between">
        <span className="text-lg font-semibold flex flex-row items-center">
          {checkClientGroupFee()}
        </span>
        {checkRole(role, [USER_ADMIN, ADMIN_ECOSYSTEM]) && (
          <div className="flex flex-wrap gap-3">
            <Button
              type={"button"}
              label={
                clientFees.length > 0
                  ? "Mudar grupo de tarifas"
                  : "Cadastrar tarifas"
              }
              className="w-full sm:w-auto"
              onClick={() => setIsModalOpen(true)}
            />
            {clientFees.length > 0 && (
              <Button
                type={"button"}
                label={"Remover tarifas"}
                className="w-full sm:w-auto"
                onClick={() => setIsConfirmationModalOpen(true)}
                danger
              />
            )}
          </div>
        )}
      </div>
      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Nome
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
              >
                Tipo cobrança
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Valor cobrado
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Detalhes</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {clientFees.length > 0 ? (
              clientFees.map((fee) => (
                <tr key={fee.idFee}>
                  <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                    {fee.nome}
                    <dl className="font-normal">
                      <dt className="sr-only md:hidden">Tipo de cobrança</dt>
                      <dd className="mt-1 text-gray-500 md:hidden">
                        {fee.percentual
                          ? "Cobrança por percentual"
                          : "Cobrança por valor fixo"}
                      </dd>
                      <dt className="sr-only sm:hidden">Valor cobrado</dt>
                      <dd className="mt-3 text-gray-500 sm:hidden">
                        {renderChargedValue(fee)}
                      </dd>
                    </dl>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                    {fee.percentual ? "Percentual" : "Valor fixo"}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {renderChargedValue(fee)}
                  </td>
                  <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                    <button
                      className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                      onClick={() => openDetailsHandler(fee)}
                    >
                      Detalhes
                      <span className="sr-only">, {fee.descricao}</span>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={5}
                  className="text-sm sm:text-base text-gray-500 text-center py-5"
                >
                  Não há dados para exibir
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Fees;
