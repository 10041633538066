import { createAsyncThunk } from "@reduxjs/toolkit";

import { setIsLoading } from "reducers/loading/isLoadingSlice";
import { setNotification } from "reducers/notification/notificationSlice";
import { numberMask } from "utils/mask";
import {
  getAccounts,
  chargeBack,
  getChargeBackId,
  getCancelAccounts,
  cancelAccount,
  undoCancel,
  addToCancelling,
  createPreTransaction,
  getPendingPreTransactions,
  deletePreTransaction,
  patchPreTransaction,
  getAccountStatus,
} from "services/accounts";

export const fetchAccounts = createAsyncThunk(
  "accounts/fetchAccounts",
  async (filter, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));
      const response = await getAccounts(
        filter.name,
        numberMask(filter.document),
        filter.email,
        filter.idAccount,
        filter.idClient,
        filter.idBank,
        numberMask(filter.phoneNumber),
        filter.accountType,
        filter.itemsPerPage,
        filter.page,
        filter.idStatus
      );

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchAccountStatus = createAsyncThunk(
  "accounts/fetchAccountStatus",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));
      const response = await getAccountStatus();

      return response.data.success
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const chargeBackTransaction = createAsyncThunk(
  "accounts/chargeBackTransaction",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await chargeBack(payload);

      thunkAPI.dispatch(
        setNotification({
          type: "success",
          title: "Estorno realizado",
          message: "O estorno foi realizado com sucesso!",
        })
      );

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchChargeBackId = createAsyncThunk(
  "accounts/fetchChargeBackId",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getChargeBackId();

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchCancelAccounts = createAsyncThunk(
  "accounts/fetchCancelAccounts",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getCancelAccounts();

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue([]);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const addCancel = createAsyncThunk(
  "accounts/addCancel",
  async ({ idClient, idBank }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      await addToCancelling(idClient, idBank);

      thunkAPI.dispatch(
        setNotification({
          type: "success",
          title: "Conta adicionada",
          message: "A conta foi adicionada à fila com sucesso!",
        })
      );
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const accountCancel = createAsyncThunk(
  "accounts/accountCancel",
  async ({ idClient, RazaoCancelamento }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      await cancelAccount(idClient, { RazaoCancelamento });

      thunkAPI.dispatch(fetchCancelAccounts());

      thunkAPI.dispatch(
        setNotification({
          type: "success",
          title: "Conta cancelada",
          message: "A conta foi cancelada com sucesso!",
        })
      );
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const deleteCancelRequest = createAsyncThunk(
  "accounts/deleteCancelRequest",
  async (idClient, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      await undoCancel(idClient);

      thunkAPI.dispatch(fetchCancelAccounts());

      thunkAPI.dispatch(
        setNotification({
          type: "success",
          title: "Solicitação removida",
          message: "A solicitação de cancelamento foi removida com sucesso!",
        })
      );
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const postPreTransaction = createAsyncThunk(
  "accounts/postPreTransaction",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await createPreTransaction(payload);

      thunkAPI.dispatch(
        setNotification({
          type: "success",
          title: "Solicitação enviada",
          message: "A solicitação foi enviada para o Admin!",
        })
      );

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchPendingPreTransactions = createAsyncThunk(
  "accounts/fetchPendingPreTransactions",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getPendingPreTransactions();

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue([]);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const deletePreTransactionRequest = createAsyncThunk(
  "accounts/deletePreTransactionRequest",
  async (idAccountPreTransaction, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      await deletePreTransaction(idAccountPreTransaction);

      thunkAPI.dispatch(fetchPendingPreTransactions());

      thunkAPI.dispatch(
        setNotification({
          type: "success",
          title: "Transação removida",
          message: "A transação foi removida com sucesso!",
        })
      );
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const approvePreTransaction = createAsyncThunk(
  "accounts/approvePreTransaction",
  async (idAccountPreTransaction, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      await patchPreTransaction(idAccountPreTransaction);

      thunkAPI.dispatch(fetchPendingPreTransactions());

      thunkAPI.dispatch(
        setNotification({
          type: "success",
          title: "Transação criada",
          message:
            "A transação foi criada e já está disponível na conta do cliente!",
        })
      );
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);
