import { convertCurrencyBRLToString } from "utils/mask";

const LegendItem = ({ name, value, color, qtde }) => {
  return (
    <div className="flex flex-row items-center gap-3">
      <div style={{ backgroundColor: color }} className="h-5 w-5 rounded" />
      <div className="flex flex-col gap-0">
        <span className="text-gray-900 font-medium">{name}</span>
        <span className="text-gray-500">
          <span className="text-sm">
            R$ {convertCurrencyBRLToString(value)}
          </span>
          <span className="text-xs"> ({qtde} transações)</span>
        </span>
      </div>
    </div>
  );
};

export default LegendItem;
