export const TED_DESCRIPTION = "Transferência TED";
export const DEPOSIT_DESCRIPTION = "Depósito via Boleto";
export const PIX_DESCRIPTION = "PIX";
export const PIX_DEVOLUTION_DESCRIPTION = "Estorno PIX";
export const PAYMENT_DESCRIPTION = "Pagamento de conta";
export const INTERNAL_TRANSFER_DESCRIPTION = "Transferência entre contas";

export const getDescription = (id, desc) => {
  switch (id) {
    case 1:
    case 2:
      return TED_DESCRIPTION;
    case 3:
      return DEPOSIT_DESCRIPTION;
    case 7:
    case 8:
      return PIX_DESCRIPTION;
    case 12:
      return PIX_DEVOLUTION_DESCRIPTION;
    case 23:
      return PAYMENT_DESCRIPTION;
    case 24:
      return INTERNAL_TRANSFER_DESCRIPTION;
    default:
      return desc;
  }
};
