import React from "react";

export default function Input({
  type,
  name,
  id,
  value,
  label,
  disabled = false,
  required = false,
  error = false,
  errorMessage,
  maxLength,
  onChange,
  onBlur,
  autoComplete,
  placeholder,
  prefix,
  hint,
  onKeyUp,
}) {
  return (
    <>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className={`relative rounded-md shadow-sm ${label && "mt-1"}`}>
        {prefix && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span className="sm:text-sm">{prefix}</span>
          </div>
        )}
        <input
          type={type}
          name={name}
          id={id}
          value={value}
          onChange={onChange}
          disabled={disabled}
          required={required}
          maxLength={maxLength}
          onBlur={onBlur}
          placeholder={placeholder}
          className={`${
            error
              ? "border-red-300 focus:ring-red-500 focus:border-red-500 text-red-900 pr-10"
              : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 pr-2"
          }
              ${prefix ? "pl-8" : ""}
              block w-full outline-0 text-sm rounded-md disabled:cursor-not-allowed disabled:bg-gray-100`}
          aria-describedby={`${name}-error`}
          autoComplete={autoComplete}
          onKeyUp={onKeyUp}
        />
        {error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 fill-red-600"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        )}
      </div>
      {error ? (
        <p className="mb-0.5 mt-2 text-sm text-red-600" id={`${name}-error`}>
          {errorMessage}
        </p>
      ) : hint ? (
        <p className="mt-1 text-xs text-gray-500" id={`${name}-description`}>
          {hint}
        </p>
      ) : null}
    </>
  );
}
