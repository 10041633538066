import { createSlice } from "@reduxjs/toolkit";

import {
  postPfAccount,
  postPjAccount,
  fetchBanks,
  fetchAllPermissiveList,
  fetchRestrictiveListTypes,
  postPermissiveList,
  deletePermissiveList,
  fetchOnboardingList,
  fetchAllRestrictiveList,
  postRestrictiveList,
  deleteRestrictiveList,
} from "./onboardingThunk";

const initialState = {
  banks: [],
  permissiveList: { itens: [], total: 0 },
  restrictiveList: { itens: [], total: 0 },
  restrictiveListType: [],
  isPosting: false,
  error: false,
  addToPermissiveList: false,
  deleteFromPermissiveList: false,
  addToRestrictiveList: false,
  deleteFromRestrictiveList: false,
  onboardingList: { onboarding: [], total: 0 },
};

export const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    setAddToPermissiveList: (state, action) => {
      return { ...state, addToPermissiveList: action.payload };
    },
    setDeleteFromPermissiveList: (state, action) => {
      return { ...state, deleteFromPermissiveList: action.payload };
    },
    setAddToRestrictiveList: (state, action) => {
      return { ...state, addToRestrictiveList: action.payload };
    },
    setDeleteFromRestrictiveList: (state, action) => {
      return { ...state, deleteFromRestrictiveList: action.payload };
    },
  },
  extraReducers: {
    [postPfAccount.pending]: (state) => {
      return { ...state, isPosting: true, error: false };
    },
    [postPfAccount.fulfilled]: (state) => {
      return { ...state, isPosting: false, error: false };
    },
    [postPfAccount.rejected]: (state) => {
      return { ...state, isPosting: false, error: true };
    },
    [postPjAccount.pending]: (state) => {
      return { ...state, isPosting: true, error: false };
    },
    [postPjAccount.fulfilled]: (state) => {
      return { ...state, isPosting: false, error: false };
    },
    [postPjAccount.rejected]: (state) => {
      return { ...state, isPosting: false, error: true };
    },
    [fetchBanks.fulfilled]: (state, action) => {
      const banksArray = action.payload.map((bank) => ({
        id: bank.idCustomerBank,
        name: bank.nome,
      }));
      return { ...state, banks: banksArray };
    },
    [fetchAllPermissiveList.fulfilled]: (state, action) => {
      return { ...state, permissiveList: action.payload };
    },
    [fetchAllRestrictiveList.fulfilled]: (state, action) => {
      return { ...state, restrictiveList: action.payload };
    },
    [fetchRestrictiveListTypes.fulfilled]: (state, action) => {
      const typesArray = action.payload.map((type) => ({
        id: type.idBlacklistType,
        name: type.nomeTipoBlacklist,
      }));

      return { ...state, restrictiveListType: typesArray };
    },
    [postPermissiveList.pending]: (state) => {
      return { ...state, isPosting: true, error: false };
    },
    [postPermissiveList.fulfilled]: (state) => {
      return {
        ...state,
        addToPermissiveList: true,
        isPosting: false,
        error: false,
      };
    },
    [postPermissiveList.rejected]: (state) => {
      return {
        ...state,
        addToPermissiveList: false,
        isPosting: false,
        error: true,
      };
    },
    [deletePermissiveList.pending]: (state) => {
      return { ...state, isPosting: true, error: false };
    },
    [deletePermissiveList.fulfilled]: (state) => {
      return {
        ...state,
        deleteFromPermissiveList: true,
        isPosting: false,
        error: false,
      };
    },
    [deletePermissiveList.rejected]: (state) => {
      return {
        ...state,
        deleteFromPermissiveList: false,
        isPosting: false,
        error: true,
      };
    },
    [postRestrictiveList.pending]: (state) => {
      return { ...state, isPosting: true, error: false };
    },
    [postRestrictiveList.fulfilled]: (state) => {
      return {
        ...state,
        addToRestrictiveList: true,
        isPosting: false,
        error: false,
      };
    },
    [postRestrictiveList.rejected]: (state) => {
      return {
        ...state,
        addToRestrictiveList: false,
        isPosting: false,
        error: true,
      };
    },
    [deleteRestrictiveList.pending]: (state) => {
      return { ...state, isPosting: true, error: false };
    },
    [deleteRestrictiveList.fulfilled]: (state) => {
      return {
        ...state,
        deleteFromRestrictiveList: true,
        isPosting: false,
        error: false,
      };
    },
    [deleteRestrictiveList.rejected]: (state) => {
      return {
        ...state,
        deleteFromRestrictiveList: false,
        isPosting: false,
        error: true,
      };
    },
    [fetchOnboardingList.fulfilled]: (state, action) => {
      return { ...state, onboardingList: action.payload };
    },
    [fetchOnboardingList.rejected]: (state) => {
      return { ...state, onboardingList: { onboarding: [], total: 0 } };
    },
  },
});

export const {
  setAddToPermissiveList,
  setDeleteFromPermissiveList,
  setAddToRestrictiveList,
  setDeleteFromRestrictiveList,
} = onboardingSlice.actions;

export default onboardingSlice.reducer;
