import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { isEqual } from "lodash";

import Button from "components/button";
import Input from "components/input";
import Select from "components/select";
import { cpfMask, phoneMask, dateMask, numberMask } from "utils/mask";
import { ufs } from "utils/ufs";
import { setEditingForm } from "reducers/client/clientSlice";
import { updateClient } from "reducers/client/clientThunk";
import { checkRole } from "utils/isRoleAllowed";
import { USER_ADMIN, USER_OPERACOES, ADMIN_ECOSYSTEM } from "utils/const";

export default function PartnerData() {
  const dispatch = useDispatch();
  const { client, isUpdating, editingForm } = useSelector(
    (store) => store.client
  );
  const { role } = useSelector((store) => store.user);
  const [partnerData, setPartnerData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isEditingMode, setIsEditingMode] = useState(false);

  useEffect(() => {
    !isUpdating && setIsEditingMode(false);
  }, [isUpdating]);

  useEffect(() => {
    setPartnerData({
      nomeSocio: client.nomeSocio,
      numeroTelefoneSocio: `${client.codigoAreaSocio}${client.numeroTelefoneSocio}`,
      dataNascimentoSocio: moment(client.dataNascimentoSocio).format(
        "DD/MM/YYYY"
      ),
      nacionalidade: client.nacionalidade,
      rgSocio: client.rgSocio,
      estadoRGSocio: client.estadoRGSocio && client.estadoRGSocio.trim(),
      emissorRGSocio: client.emissorRGSocio,
      dataEmissaoRG: moment(client.dataEmissaoRG).format("DD/MM/YYYY"),
      nomeMaeSocio: client.nomeMaeSocio,
      pepSocio: client.pepSocio ? "2" : "1",
      dataInicioPEPSocio: moment(client.dataInicioPEPSocio).format(
        "DD/MM/YYYY"
      ),
    });
  }, [client, isEditingMode]);

  const changePartnerData = (event) => {
    const { name, value } = event.target;

    setPartnerData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const validateForm = (values) => {
    const errors = {};

    if (!values.nomeSocio) {
      errors.nomeSocio = "Nome do sócio deve ser informado!";
    }

    if (!values.dataNascimentoSocio) {
      errors.dataNascimentoSocio = "Data de nascimento deve ser informada!";
    } else if (values.dataNascimentoSocio.length < 10) {
      errors.dataNascimentoSocio = "Data de nascimento inválida!";
    } else if (!moment(values.dataNascimentoSocio, "DD/MM/YYYY").isValid()) {
      errors.dataNascimentoSocio = "Data de nascimento inválida!";
    }

    if (!values.numeroTelefoneSocio) {
      errors.numeroTelefoneSocio = "Celular deve ser informado!";
    } else if (numberMask(values.numeroTelefoneSocio).length !== 11) {
      errors.numeroTelefoneSocio = "Celular deve ter 11 dígitos!";
    }

    if (!values.nacionalidade) {
      errors.nacionalidade = "Nacionalidade deve ser informada!";
    }

    if (!values.nomeMaeSocio) {
      errors.nomeMaeSocio = "Nome da mãe deve ser informado!";
    }

    if (!values.rgSocio) {
      errors.rgSocio = "RG deve ser informado!";
    }

    if (!values.estadoRGSocio) {
      errors.estadoRGSocio = "Estado de emissão do RG deve ser informado!";
    }

    if (!values.emissorRGSocio) {
      errors.emissorRGSocio = "Orgão emissor do RG deve ser informado!";
    }

    if (!values.dataEmissaoRG) {
      errors.dataEmissaoRG = "Data de emissão do RG deve ser informada!";
    } else if (values.dataEmissaoRG.length < 10) {
      errors.dataEmissaoRG = "Data inválida!";
    } else if (!moment(values.dataEmissaoRG, "DD/MM/YYYY").isValid()) {
      errors.dataEmissaoRG = "Data inválida!";
    }

    if (!values.nacionalidade) {
      errors.nacionalidade = "Nacionalidade deve ser informada!";
    }

    if (!values.pepSocio) {
      errors.pepSocio = "Pessoa Exposta Politicamente deve ser informada!";
    }

    if (values.pepSocio === "2") {
      if (!values.dataInicioPEPSocio) {
        errors.dataInicioPEPSocio = "Data de início deve ser informada!";
      } else if (values.dataInicioPEPSocio.length < 10) {
        errors.dataInicioPEPSocio = "Data de início inválida!";
      } else if (!moment(values.dataInicioPEPSocio, "DD/MM/YYYY").isValid()) {
        errors.dataInicioPEPSocio = "Data de início inválida!";
      }
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const comparePartnerData = () => {
    const clientStore = {
      nomeSocio: client.nomeSocio,
      numeroTelefoneSocio: `${client.codigoAreaSocio}${client.numeroTelefoneSocio}`,
      dataNascimentoSocio: moment(client.dataNascimentoSocio).format(
        "DD/MM/YYYY"
      ),
      nacionalidade: client.nacionalidade,
      rgSocio: client.rgSocio,
      estadoRGSocio: client.estadoRGSocio,
      emissorRGSocio: client.emissorRGSocio,
      dataEmissaoRG: moment(client.dataEmissaoRG).format("DD/MM/YYYY"),
      nomeMaeSocio: client.nomeMaeSocio,
      pepSocio: client.pepSocio ? "2" : "1",
      dataInicioPEPSocio: moment(client.dataInicioPEPSocio).format(
        "DD/MM/YYYY"
      ),
    };

    return isEqual(clientStore, {
      ...partnerData,
      numeroTelefoneSocio: numberMask(partnerData.numeroTelefoneSocio),
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateForm({ ...partnerData }) && !isUpdating) {
      dispatch(
        updateClient({
          ...client,
          ...partnerData,
          accountType: 2,
          codigoAreaSocio: numberMask(partnerData.numeroTelefoneSocio).substr(
            0,
            2
          ),
          numeroTelefoneSocio: numberMask(
            partnerData.numeroTelefoneSocio
          ).substr(2),
          dataNascimentoSocio: partnerData.dataNascimentoSocio,
          dataEmissaoRG: partnerData.dataEmissaoRG,
          pepSocio:
            !partnerData.pepSocio || partnerData.pepSocio === "1"
              ? false
              : true,
          dataInicioPEPSocio: partnerData.dataInicioPEPSocio
            ? partnerData.dataInicioPEPSocio
            : "1900-01-01",
        })
      );
      dispatch(setEditingForm(""));
    }
  };

  const handleEdit = () => {
    dispatch(setEditingForm("partner-data"));
    setIsEditingMode(true);
  };

  const handleCancel = () => {
    dispatch(setEditingForm(""));
    setIsEditingMode(false);
    setFormErrors({});
  };

  return (
    <form action="#" method="POST" onSubmit={handleSubmit}>
      <div className="shadow sm:overflow-hidden sm:rounded-md">
        <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Dados do sócio
            </h3>
          </div>

          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"Nome"}
                type={"text"}
                name={"nomeSocio"}
                id={"nomeSocio"}
                value={partnerData.nomeSocio || ""}
                onChange={changePartnerData}
                error={formErrors.nomeSocio}
                errorMessage={formErrors.nomeSocio}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"CPF"}
                type={"text"}
                name={"cpfSocio"}
                id={"cpfSocio"}
                value={cpfMask(client.cpfSocio) || ""}
                onChange={() => {}}
                disabled={
                  checkRole(role, [USER_ADMIN, ADMIN_ECOSYSTEM])
                    ? !isEditingMode
                    : true
                }
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"Celular"}
                type={"text"}
                name={"numeroTelefoneSocio"}
                id={"numeroTelefoneSocio"}
                maxLength={15}
                value={phoneMask(partnerData.numeroTelefoneSocio) || ""}
                onChange={changePartnerData}
                error={formErrors.numeroTelefoneSocio}
                errorMessage={formErrors.numeroTelefoneSocio}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"Data de nascimento"}
                type={"text"}
                name={"dataNascimentoSocio"}
                id={"dataNascimentoSocio"}
                value={dateMask(partnerData.dataNascimentoSocio) || ""}
                onChange={changePartnerData}
                error={formErrors.dataNascimentoSocio}
                errorMessage={formErrors.dataNascimentoSocio}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"RG"}
                type={"text"}
                name={"rgSocio"}
                id={"rgSocio"}
                value={partnerData.rgSocio || ""}
                onChange={changePartnerData}
                error={formErrors.rgSocio}
                errorMessage={formErrors.rgSocio}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Select
                label={"Estado de emissão RG"}
                id={"estadoRGSocio"}
                name={"estadoRGSocio"}
                value={partnerData.estadoRGSocio || ""}
                placeholder={"Selecione o Estado"}
                options={ufs}
                onChange={changePartnerData}
                error={formErrors.estadoRGSocio}
                errorMessage={formErrors.estadoRGSocio}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"Orgão emissor RG"}
                type={"text"}
                name={"emissorRGSocio"}
                id={"emissorRGSocio"}
                value={partnerData.emissorRGSocio || ""}
                onChange={changePartnerData}
                error={formErrors.emissorRGSocio}
                errorMessage={formErrors.emissorRGSocio}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"Data de emissão RG"}
                type={"text"}
                name={"dataEmissaoRG"}
                id={"dataEmissaoRG"}
                value={dateMask(partnerData.dataEmissaoRG) || ""}
                onChange={changePartnerData}
                error={formErrors.dataEmissaoRG}
                errorMessage={formErrors.dataEmissaoRG}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"Nacionalidade"}
                type={"text"}
                name={"nacionalidade"}
                id={"nacionalidade"}
                value={partnerData.nacionalidade || ""}
                onChange={changePartnerData}
                error={formErrors.nacionalidade}
                errorMessage={formErrors.nacionalidade}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"Nome completo da mãe"}
                type={"text"}
                name={"nomeMaeSocio"}
                id={"nomeMaeSocio"}
                value={partnerData.nomeMaeSocio || ""}
                onChange={changePartnerData}
                error={formErrors.nomeMaeSocio}
                errorMessage={formErrors.nomeMaeSocio}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Select
                label={"Pessoa Politicamente Exposta (PPE)"}
                id={"pepSocio"}
                name={"pepSocio"}
                placeholder={"Selecione uma Opção"}
                value={partnerData.pepSocio || ""}
                error={formErrors.pepSocio}
                errorMessage={formErrors.pepSocio}
                options={[
                  { id: 1, name: "Não" },
                  { id: 2, name: "Sim" },
                ]}
                onChange={changePartnerData}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"Início PPE"}
                type={"text"}
                name={"dataInicioPEPSocio"}
                id={"dataInicioPEPSocio"}
                value={
                  (partnerData.pepSocio === "2" &&
                    dateMask(partnerData.dataInicioPEPSocio)) ||
                  ""
                }
                onChange={changePartnerData}
                disabled={partnerData.pepSocio === "1" || !isEditingMode}
                error={formErrors.dataInicioPEPSocio}
                errorMessage={formErrors.dataInicioPEPSocio}
              />
            </div>
          </div>
        </div>
        {checkRole(role, [USER_ADMIN, USER_OPERACOES, ADMIN_ECOSYSTEM]) && (
          <div className="bg-gray-50 px-4 py-3 space-x-3 text-right sm:px-6">
            {!isEditingMode && (
              <Button
                type={"button"}
                label={"Editar"}
                onClick={handleEdit}
                disabled={editingForm && editingForm !== "partner-data"}
              />
            )}
            {isEditingMode && (
              <>
                <Button
                  type={"submit"}
                  label={"Salvar"}
                  onClick={handleSubmit}
                  disabled={comparePartnerData()}
                />
                <Button
                  type={"button"}
                  secondary
                  label={"Cancelar"}
                  onClick={handleCancel}
                />
              </>
            )}
          </div>
        )}
      </div>
    </form>
  );
}
