import axiosClient from "./apiClient";

export function getBankList() {
  return axiosClient.get(`${process.env.REACT_APP_API_DASH}/bank`);
}

export function getBankLimits(idCustomerBank) {
  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/bank/${idCustomerBank}/limits`
  );
}

export function getRendeMais(year, month) {
  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/rendemais/calc/${year}/${month}`
  );
}

export function updateNightTime(idCustomerBank, payload) {
  return axiosClient.post(
    `${process.env.REACT_APP_API_DASH}/bank/${idCustomerBank}/nighttime`,
    payload,
    {
      headers: { "Content-Type": "application/json;charset=utf-8" },
    }
  );
}

export function updateBankLimit(idCustomerBank, payload) {
  return axiosClient.post(
    `${process.env.REACT_APP_API_DASH}/bank/${idCustomerBank}/limit`,
    payload,
    {
      headers: { "Content-Type": "application/json;charset=utf-8" },
    }
  );
}

export function updateOnboardingTime(idCustomerBank, payload) {
  return axiosClient.post(
    `${process.env.REACT_APP_API_DASH}/bank/${idCustomerBank}/onboarding`,
    payload,
    {
      headers: { "Content-Type": "application/json;charset=utf-8" },
    }
  );
}
