import { useSelector } from "react-redux";

export default function CreationDetails() {
  const { client } = useSelector((store) => store.client);
  return (
    <div className="shadow overflow-hidden mt-5 sm:rounded-md">
      <div className="px-6 py-4 sm:py-5 bg-white border-b border-gray-200">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Justificativa
        </h3>
      </div>
      <div className="px-4 py-7 bg-white sm:p-7">
        <div className="grid grid-cols-6 gap-10">
          <div className="col-span-6">
            <p className="text-base font-medium leading-6 text-gray-900">
              Conta aberta por:
            </p>
            <span className="text-sm text-gray-700">{`${client.onboardingManualByName}`}</span>
          </div>
          <div className="col-span-6">
            <p className="text-base font-medium leading-6 text-gray-900">
              Motivo de abertura:
            </p>
            <span className="text-sm text-gray-700">{`${client.onboardingManualReason}`}</span>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 h-[62px] px-4 space-x-3 py-3 sm:px-6"></div>
    </div>
  );
}
