export default function BadgeCelcoin({ celcoinStatus }) {
  const messages = [
    "CONTA OK / KYC OK",
    "Conta interna",
    "Ecosistema inativo",
    "Conta balde",
    "Conta inativa",
    "Cadastro não permitido para menor de idade.",
    "Conta automação",
  ];

  const checkAccountStatus = () => {
    return messages.includes(celcoinStatus);
  };

  if (process.env.REACT_APP_MODE === "CLIENT") return;

  return (
    <div className="flex items-center gap-1">
      <span className="text-sm text-gray-500">Status celcoin</span>
      <span
        className={`inline-flex items-center w-fit rounded-full px-3 py-0.5 mx-1 md:my-1 text-xs font-medium bg-blue-100  ${
          checkAccountStatus()
            ? "bg-blue-100 text-blue-800"
            : "bg-red-100 text-red-800"
        }`}
      >
        {celcoinStatus}
      </span>
    </div>
  );
}
