import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  Tooltip as TooltipChart,
  ResponsiveContainer,
} from "recharts";

import CustomTooltip from "../custom-tooltip";
import { getMonthName } from "utils/months";

const Rentability = () => {
  const { rentability } = useSelector((store) => store.metrics);
  const [barData, setBarData] = useState([]);

  useEffect(() => {
    if (rentability.length > 0) {
      const tempArr = rentability.map((month) => ({
        ...month,
        mes: getMonthName(month.mes),
      }));

      setBarData(tempArr);
    } else {
      setBarData([]);
    }
  }, [rentability]);

  return (
    <div className="col-span-4 lg:col-span-2 bg-white border rounded-lg p-6 flex flex-col">
      <div className="flex flex-row items-center gap-1">
        <h2 className="text-xl font-semibold">Comparativo de rentabilidade</h2>
        <QuestionMarkCircleIcon
          className="h-5 w-5 cursor-help shrink-0"
          data-tooltip-id="rentability-explanation"
        />
        <Tooltip id="rentability-explanation">
          <div className="text-center text-sm normal-case">
            Acompanhe a rentabilidade bruta dos últimos 12 meses. Os valores
            <br />
            totais apresentados só contemplam custos ou ganhos relacionados à
            <br />
            tarifas cobradas.
          </div>
        </Tooltip>
      </div>
      {barData.length > 0 ? (
        <div className="mt-10 h-80">
          <ResponsiveContainer width="100%" height={300}>
            <BarChart width={500} height={300} data={barData}>
              <XAxis dataKey="mes" />
              <YAxis scale="log" domain={[1, "auto"]} />
              <TooltipChart content={<CustomTooltip />} />
              <Bar
                dataKey="valor"
                fill="#4f46e5"
                activeBar={<Rectangle stroke="blue" />}
                maxBarSize={50}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <div className="mt-10 flex items-center justify-center grow text-gray-500 text-lg h-80">
          Sem transações no período
        </div>
      )}
    </div>
  );
};

export default Rentability;
