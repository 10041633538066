import axiosClient from "./apiClient";

export function getPixSent(idClient, idAccountTransaction) {
  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/voucher/pix/enviado/${idClient}/${idAccountTransaction}`
  );
}

export function getPixReceivid(idClient, idAccountTransaction) {
  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/voucher/pix/recebido/${idClient}/${idAccountTransaction}`
  );
}

export function getPixReturned(idClient, idAccountTransaction) {
  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/voucher/pix/retornado/${idClient}/${idAccountTransaction}`
  );
}

export function getPayment(idClient, idAccountTransaction) {
  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/voucher/pagamento/${idClient}/${idAccountTransaction}`
  );
}

export function internalTransferSent(idClient, idAccountTransaction) {
  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/voucher/transferencia/interna/enviada/${idClient}/${idAccountTransaction}`
  );
}

export function internalTransferReceivid(idClient, idAccountTransaction) {
  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/voucher/transferencia/interna/recebida/${idClient}/${idAccountTransaction}`
  );
}

export function getVoucherPdf(idClient, idTransaction) {
  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/voucher/pdf/${idClient}/${idTransaction}`, 
    {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/pdf',
        'Accept': 'application/pdf'
      }
    }
  );
}
