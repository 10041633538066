import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";

import Modal from "components/modal";
import Input from "components/input";
import { updateTimeOnboarding } from "reducers/bank/bankThunk";
import { isResponseSuccess } from "utils/request";
import { timeMask } from "utils/mask";

const ModalSetTime = ({ open, setOpen, item }) => {
  const dispatch = useDispatch();

  const [payload, setPayload] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (open && item?.idBanco) {
      setPayload(item);
    }
  }, [item, open]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setPayload((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEnter = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    if (validate()) {
      const response = await dispatch(
        updateTimeOnboarding({
          ...payload,
          horarioInicioOnboarding: moment(
            payload.horarioInicioOnboarding,
            "HH:mm"
          ).format("HH:mm:ss"),
          horarioFinalOnboarding: moment(
            payload.horarioFinalOnboarding,
            "HH:mm"
          ).format("HH:mm:ss"),
          idCustomerBank: payload.idBanco,
        })
      );

      if (isResponseSuccess(response)) {
        handleCloseModal();
      }
    }
  };

  const validate = () => {
    const errors = {};

    if (!payload?.horarioInicioOnboarding) {
      errors.horarioInicioOnboarding = "Campo obrigatório!";
    } else if (!moment(payload?.horarioInicioOnboarding, "HH:mm").isValid()) {
      errors.horarioInicioOnboarding = "Formato inválido!";
    } else if (payload?.horarioInicioOnboarding?.length < 5) {
      errors.horarioInicioOnboarding = "Tamanho inválido!";
    } else if (
      payload?.horarioInicioOnboarding >= payload?.horarioFinalOnboarding
    ) {
      errors.horarioFinalOnboarding =
        "Horário inicial deve ser inferior ao final!";
    }

    if (!payload?.horarioFinalOnboarding) {
      errors.horarioFinalOnboarding = "Campo obrigatório!";
    } else if (!moment(payload?.horarioFinalOnboarding, "HH:mm").isValid()) {
      errors.horarioFinalOnboarding = "Formato inválido!";
    } else if (payload?.horarioFinalOnboarding?.length < 5) {
      errors.horarioFinalOnboarding = "Tamanho inválido!";
    }

    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
    setErrors({});
  };

  return (
    <Modal
      title={"Alterar horário Onboarding"}
      confirmButtonLabel={"Alterar horário"}
      onConfirm={handleSubmit}
      cancelButtonLabel={"Cancelar"}
      onCancel={handleCloseModal}
      isModalOpen={open}
    >
      <div className="text-left space-y-5" onKeyUp={handleEnter}>
        <div className="mt-5">
          <label className="block text-sm font-medium text-gray-700">
            Banco
          </label>
          <p className="block w-full mt-1 text-sm font-medium">
            {item?.descricao}
          </p>
        </div>
        <div>
          <Input
            label={"Horário inicial"}
            type={"text"}
            name={"horarioInicioOnboarding"}
            id={"horarioInicioOnboarding"}
            value={timeMask(payload?.horarioInicioOnboarding) || ""}
            onChange={handleChange}
            error={errors.horarioInicioOnboarding}
            errorMessage={errors.horarioInicioOnboarding}
          />
        </div>
        <div>
          <Input
            label={"Horário final"}
            type={"text"}
            name={"horarioFinalOnboarding"}
            id={"horarioFinalOnboarding"}
            value={timeMask(payload?.horarioFinalOnboarding) || ""}
            onChange={handleChange}
            error={errors.horarioFinalOnboarding}
            errorMessage={errors.horarioFinalOnboarding}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModalSetTime;
