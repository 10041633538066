import { createAsyncThunk } from "@reduxjs/toolkit";

import { setNotification } from "reducers/notification/notificationSlice";
import { setIsLoading } from "reducers/loading/isLoadingSlice";
import {
  getMetrics,
  getTransactionalProduct,
  getRentability,
} from "services/metrics";

export const fetchMetrics = createAsyncThunk(
  "metrics/fetchMetrics",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      let response = await getMetrics();

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue([]);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchTransactionalProducts = createAsyncThunk(
  "metrics/fetchTransactionalProducts",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      let response = await getTransactionalProduct();

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue([]);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchRentability = createAsyncThunk(
  "metrics/fetchRentability",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      let response = await getRentability();

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue([]);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);
