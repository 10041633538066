export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    authority:
      "https://login.microsoftonline.com/951e2141-2417-4449-9ee3-a20483c2e3c6",
    redirectUri:
      process.env.NODE_ENV === "development"
        ? "http://localhost:3000/"
        : process.env.REACT_APP_AZURE_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ["api://BaaSDashboard/fullAccess"],
};
