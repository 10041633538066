import React from "react";

const Tooltip = ({ text, children }) => {
  return (
    <div className="relative group inline-block">
      {children}
      <div className="hidden group-hover:block absolute left-2/4 transform -translate-x-1/2 -top-12">
        <div className="bg-gray-700 text-white text-xs text-center rounded-md p-2 relative">
          <div className="absolute w-3 h-3 bg-gray-700 rotate-45 -bottom-[4px] left-1/2 transform -translate-x-1/2"></div>
          {text}
        </div>
      </div>
    </div>
  );
};

export default Tooltip;
