import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EyeIcon } from "@heroicons/react/24/solid";

import Layout from "components/layout";
import JsonModal from "components/modal-json";
import { fetchApplicationErrors } from "reducers/client/clientThunk";
import { formatDate } from "utils/mask";

export default function Errors() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { applicationErrors } = useSelector((store) => store.client);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [jsonRequest, setJsonRequest] = useState(null);

  useEffect(() => {
    dispatch(fetchApplicationErrors());
  }, [dispatch]);

  const openModalHandler = (request) => {
    setIsModalOpen(true);
    request.param1.value = JSON.parse(request?.param1?.value);
    setJsonRequest(request);
  };

  const closeModalHandler = () => {
    setIsModalOpen(false);
    setTimeout(() => setJsonRequest(null), 500);
  };

  const navigationHandler = (id, type) => {
    navigate(`/contas/detalhes/${id}?type=${type}`);
  };

  return (
    <Layout title={"Últimos erros"}>
      <JsonModal
        isModalOpen={isModalOpen}
        onCloseModal={closeModalHandler}
        json={jsonRequest}
      />
      <p className="text-sm text-gray-500">
        Esta página lista os erros das últimas 24 horas
      </p>
      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:mx-0 md:rounded-lg mt-6">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 whitespace-nowrap"
              >
                ID Client
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
              >
                Data / Hora
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Endpoint
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Erro
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              ></th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {applicationErrors?.errors?.length > 0 ? (
              applicationErrors.errors.map((error) => (
                <tr key={error?.idAccountAPILog}>
                  <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm text-gray-500 sm:w-auto sm:max-w-none sm:pl-6 whitespace-nowrap">
                    <button
                      onClick={() =>
                        navigationHandler(error?.idClient, error?.tipo)
                      }
                      className="text-indigo-600 hover:text-indigo-500 text-left font-medium"
                    >
                      {error?.idClient}
                      <span className="sr-only">,{error?.idClient}</span>
                    </button>
                    <dl className="font-normal lg:hidden">
                      <dt className="sr-only md:hidden">Data / Hora</dt>
                      <dd className="mt-1 truncate text-gray-500 md:hidden">
                        {formatDate(error?.dateStart)}
                      </dd>
                      <dt className="sr-only lg:hidden">Endpoint</dt>
                      <dd className="mt-1 truncate text-gray-500 lg:hidden flex items-center">
                        <button
                          className="mr-3"
                          onClick={() =>
                            openModalHandler(JSON.parse(error?.request))
                          }
                        >
                          {<EyeIcon className="h-5 w-5" />}
                        </button>
                        {error?.endpoint}
                      </dd>
                      <dt className="sr-only sm:hidden">Mensagem de erro</dt>
                      <dd className="mt-1 text-gray-500 sm:hidden whitespace-normal">
                        {error?.response}
                      </dd>
                    </dl>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell whitespace-nowrap">
                    {formatDate(error?.dateStart)}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell whitespace-nowrap">
                    {error?.endpoint}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {error?.response}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                    <button
                      onClick={() =>
                        openModalHandler(JSON.parse(error?.request))
                      }
                    >
                      {<EyeIcon className="h-5 w-5" />}
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={5}
                  className="text-sm sm:text-base text-gray-500 text-center py-5"
                >
                  Não há dados para exibir
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Layout>
  );
}
