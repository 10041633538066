import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Modal from "components/modal";
import Input from "components/input";
import Select from "components/select";
import { newAccountLimit } from "reducers/limits/limitsThunk";
import { fetchTransactionTypes } from "reducers/fees/feesThunk";
import { isResponseSuccess } from "utils/request";
import {
  convertCurrencyBRLToFloat,
  convertCurrencyBRLToString,
  formatCurrency,
} from "utils/mask";

const NewLimitModal = ({ update, setUpdate, open, setOpen, item, setItem }) => {
  const dispatch = useDispatch();
  const { idClient } = useParams();

  const { transactionTypes } = useSelector((store) => store.fees);
  const [payload, setPayload] = useState({});
  const [payloadErrors, setPayloadErrors] = useState({});
  const [selectOptions, setSelectOptions] = useState([]);

  useEffect(() => {
    !transactionTypes.length && dispatch(fetchTransactionTypes());

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSelectOptions(
      transactionTypes.map((transaction) => ({
        id: transaction.idTransaction,
        name: transaction.descricao,
      }))
    );
  }, [transactionTypes]);

  useEffect(() => {
    if (open && item?.idTransaction) {
      setPayload({
        ...item,
        valorMaximo: convertCurrencyBRLToString(item.valorMaximo),
        valorMaximoNoturno: convertCurrencyBRLToString(item.valorMaximoNoturno),
      });
    }
  }, [item, open]);

  const handleSubmit = async () => {
    if (validatePayload(payload)) {
      const response = await dispatch(
        newAccountLimit({
          ...payload,
          Quantidade: parseInt(payload.quantidade) || 0,
          valorMaximo: convertCurrencyBRLToFloat(payload.valorMaximo),
          valorMaximoNoturno: convertCurrencyBRLToFloat(
            payload.valorMaximoNoturno
          ),
          idClient: idClient,
          idLimitType: 1,
        })
      );
      if (isResponseSuccess(response)) {
        handleCloseModal();
      }
    }
  };

  const validatePayload = (values) => {
    const errors = {};

    if (!values.idTransaction) {
      errors.idTransaction = "Selecione o serviço!";
    }

    setPayloadErrors(errors);

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleEnter = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      handleSubmit();
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
    setUpdate(false);
    setPayload({});
    setPayloadErrors({});
    setItem({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setPayload((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeValue = (e) => {
    const { name, value } = e.target;

    setPayload((prevState) => ({
      ...prevState,
      [name]: formatCurrency(value),
    }));
  };

  return (
    <Modal
      title={update ? "Alteração de limite" : "Novo limite"}
      description={
        update
          ? "Certifique-se que os valores estão corretos antes de salvar a alteração."
          : "Caso o serviço cadastrado já exista, ele será atualizado com os novos valores."
      }
      confirmButtonLabel={update ? "Alterar limite" : "Cadastrar limite"}
      onConfirm={handleSubmit}
      cancelButtonLabel={"Cancelar"}
      onCancel={handleCloseModal}
      isModalOpen={open}
    >
      <div className="text-left space-y-5" onKeyUp={handleEnter}>
        <div className="mt-5">
          {update ? (
            <>
              <label className="block text-sm font-medium text-gray-700">
                Serviço
              </label>
              <p className="block w-full mt-1 text-sm font-medium">
                {payload.descricaoTransacao}
              </p>
            </>
          ) : (
            <Select
              label={"Serviço"}
              id={"idTransaction"}
              name={"idTransaction"}
              value={payload?.idTransaction || ""}
              placeholder={"Selecione o serviço"}
              options={selectOptions}
              onChange={handleChange}
              error={payloadErrors.idTransaction}
              errorMessage={payloadErrors.idTransaction}
            />
          )}
        </div>
        <div>
          <Input
            label={"Quantidade"}
            type={"text"}
            name={"quantidade"}
            id={"quantidade"}
            placeholder={"0"}
            value={payload.quantidade}
            onChange={handleChange}
            hint={"Caso esteja zerado, as transações serão bloqueadas."}
          />
        </div>
        <div>
          <Input
            label={"Limite diurno"}
            type={"text"}
            name={"valorMaximo"}
            id={"valorMaximo"}
            placeholder={"0,00"}
            value={payload.valorMaximo || ""}
            onChange={handleChangeValue}
            prefix={"R$"}
            hint={"Caso esteja zerado, as transações serão bloqueadas."}
          />
        </div>
        <div>
          <Input
            label={"Limite noturno"}
            type={"text"}
            name={"valorMaximoNoturno"}
            id={"valorMaximoNoturno"}
            placeholder={"0,00"}
            value={payload.valorMaximoNoturno || ""}
            onChange={handleChangeValue}
            prefix={"R$"}
            hint={"Caso esteja zerado, as transações serão bloqueadas."}
          />
        </div>
      </div>
    </Modal>
  );
};

export default NewLimitModal;
