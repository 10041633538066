export default function CopyToClipboard({
  children,
  value,
  onComplete,
  onReset,
}) {
  function onCopy() {
    navigator.clipboard.writeText(value);
    if (typeof onComplete === "function") {
      onComplete();
    }
    if (typeof onReset === "function") {
      setTimeout(onReset, 2000);
    }
  }

  return <button onClick={onCopy}>{children}</button>;
}
