import { createSlice } from "@reduxjs/toolkit";

import { postEmailBase } from "./emailBaseThunk";

const initialState = {
  success: false,
  error: false,
};

export const emailBaseSlice = createSlice({
  name: "emailer",
  initialState,
  extraReducers: {
    [postEmailBase.pending]: (state) => {
      return { ...state, error: false, success: false };
    },
    [postEmailBase.rejected]: (state) => {
      return { ...state, error: true, success: false };
    },
    [postEmailBase.fulfilled]: (state) => {
      return { ...state, error: false, success: true };
    },
  },
});

export default emailBaseSlice.reducer;
