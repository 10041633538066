import axiosClient from "./apiClient";

export function emailBase(idCustomerBank, idTemplate, receiver) {
  const params = new URLSearchParams();
  receiver && params.append("receiver", receiver);

  return axiosClient.post(
    `${process.env.REACT_APP_API_DASH}/bank/${idCustomerBank}/emailbulk/${idTemplate}`,
    null,
    { params }
  );
}
