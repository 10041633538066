import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import Button from "components/button";
import NewLimitModal from "./components/new-limit-modal";
import { listClientLimits } from "reducers/limits/limitsThunk";
import { convertCurrencyBRLToString } from "utils/mask";

export default function Limits() {
  const dispatch = useDispatch();
  const { idClient } = useParams();

  const { clientLimits } = useSelector((store) => store.limits);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [item, setItem] = useState();
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    dispatch(listClientLimits(idClient));
  }, [dispatch, idClient]);

  const handleEditTransaction = (updatedItem) => {
    setItem(updatedItem);
    setIsUpdate(true);
    setIsModalOpen(true);
  };

  const handleNewTransaction = () => {
    setIsUpdate(false);
    setIsModalOpen(true);
  };

  return (
    <>
      <div className="relative">
        <NewLimitModal
          update={isUpdate}
          setUpdate={setIsUpdate}
          open={isModalOpen}
          setOpen={setIsModalOpen}
          item={item}
          setItem={setItem}
        />
      </div>
      {process.env.REACT_APP_MODE === "CORE" && (
        <div className="flex flex-row-reverse">
          <Button
            type={"button"}
            label={"Novo limite"}
            className="w-full sm:w-auto"
            onClick={handleNewTransaction}
          />
        </div>
      )}
      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:mx-0 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Descrição
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
              >
                Quantidade
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Valor
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Valor Noturno
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Valor Atual
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Editar</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {clientLimits.length > 0 ? (
              clientLimits.map((limit) => (
                <tr key={limit.idTransaction}>
                  <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                    {limit.descricaoTransacao}
                    <dl className="font-normal lg:hidden">
                      <dt className="sr-only">Tipo</dt>
                      <dd className="mt-1 truncate text-gray-700">
                        {limit.descricaoTipoLimite}
                      </dd>
                      <dt className="sr-only md:hidden">Quantidade</dt>
                      <dd className="mt-1 truncate text-gray-500 md:hidden">
                        Quantidade: {limit.quantidade}
                      </dd>
                      <dt className="sr-only sm:hidden">Valor diurno</dt>
                      <dd className="mt-1 truncate text-gray-500 sm:hidden">
                        Valor: R${" "}
                        {convertCurrencyBRLToString(limit.valorMaximo)}
                      </dd>
                      <dt className="sr-only sm:hidden">Valor noturno</dt>
                      <dd className="mt-1 truncate text-gray-500 sm:hidden">
                        Valor noturno: R${" "}
                        {convertCurrencyBRLToString(limit.valorMaximoNoturno)}
                      </dd>
                      <dt className="sr-only sm:hidden">Valor atual</dt>
                      <dd className="mt-1 truncate text-gray-500 sm:hidden">
                        Valor atual: R${" "}
                        {convertCurrencyBRLToString(limit.valorAtual)}
                      </dd>
                    </dl>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                    {limit.quantidade}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    R$ {convertCurrencyBRLToString(limit.valorMaximo)}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    R$ {convertCurrencyBRLToString(limit.valorMaximoNoturno)}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    R$ {convertCurrencyBRLToString(limit.valorAtual)}
                  </td>
                  <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                    <span
                      className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                      onClick={() => handleEditTransaction(limit)}
                    >
                      Alterar
                      <span className="sr-only">
                        , {limit.descricaoTransacao}
                      </span>
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={5}
                  className="text-sm sm:text-base text-gray-500 text-center py-5"
                >
                  Não há dados para exibir
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
