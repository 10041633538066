import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { fetchLoginHistory } from "reducers/client/clientThunk";
import { formatDate } from "utils/mask";

export default function LoginHistory() {
  const dispatch = useDispatch();
  const { client, loginHistory } = useSelector((store) => store.client);

  useEffect(() => {
    dispatch(fetchLoginHistory(client.idClient));
  }, [dispatch, client.idClient]);

  const getYearLastLogin = () => {
    const date = formatDate(loginHistory.lastLogin);
    const yearDate = moment(date, "DD/MM/YYYY HH:mm:ss").year();

    if (yearDate === 1900) {
      return "Cliente não logou na conta";
    } else {
      return date;
    }
  };

  return (
    <>
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
          <dt className="truncate text-sm font-medium text-gray-500">
            Último login
          </dt>
          <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">
            {getYearLastLogin()}
          </dd>
        </div>
        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
          <dt className="truncate text-sm font-medium text-gray-500">
            Quantidade de logins
          </dt>
          <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">
            {loginHistory.qtdLogins}
          </dd>
        </div>
      </div>
      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:mx-0 md:rounded-lg mt-8">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Histórico de logins
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {loginHistory?.logins?.length > 0 ? (
              loginHistory.logins.map((login) => (
                <tr key={login}>
                  <td className="py-4 pl-4 pr-3 text-sm text-gray-500 sm:w-auto sm:max-w-none sm:pl-6">
                    {formatDate(login)}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={1}
                  className="text-sm sm:text-base text-gray-500 text-center py-5"
                >
                  Não há dados para exibir
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
