import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";

import { setNotification } from "reducers/notification/notificationSlice";
import { setIsLoading } from "reducers/loading/isLoadingSlice";
import {
  getPfAccountDetails,
  getPjAccountDetails,
  updatePjAccount,
  updatePfAccount,
  getBalanceByMonth,
  getClientStatusFull,
  block,
  unblock,
  getLoginHistory,
  getClientErrors,
  getApplicationErrors,
} from "services/accounts";

// Função auxiliar para tratar a data antes de enviar para a API //
const formatDate = (date) => {
  let formatedDate = "";

  moment(date, "DD/MM/YYYY", true).isValid()
    ? (formatedDate = moment(date, "DD/MM/YYYY").format("YYYY-MM-DD"))
    : (formatedDate = moment(date).format("YYYY-MM-DD"));

  if (!date || formatedDate < "1900-01-01") {
    return "1900-01-01";
  } else {
    return formatedDate;
  }
};

export const fetchClient = createAsyncThunk(
  "client/fetchClient",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      let response = "";
      if (payload.accountType === 1) {
        response = await getPfAccountDetails(payload.idClient);
      } else if (payload.accountType === 2) {
        response = await getPjAccountDetails(payload.idClient);
      }

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const updateClient = createAsyncThunk(
  "client/updateClient",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      let response = "";

      if (payload.accountType === 1) {
        response = await updatePfAccount(payload.idClient, {
          ...payload,
          dataNascimento: formatDate(payload.dataNascimento),
          dataEmissaoRG: formatDate(payload.dataEmissaoRG),
          dataInicioPEP: formatDate(payload.dataInicioPEP),
        });
      } else if (payload.accountType === 2) {
        response = await updatePjAccount(payload.idClient, {
          ...payload,
          dataAbertura: formatDate(payload.dataAbertura),
          dataEmissaoRG: formatDate(payload.dataEmissaoRG),
          dataNascimentoSocio: formatDate(payload.dataNascimentoSocio),
          dataInicioPEPSocio: formatDate(payload.dataInicioPEPSocio),
        });
      }

      thunkAPI.dispatch(
        setNotification({
          type: "success",
          title: "Conta atualizada",
          message: "Conta atualizada com sucesso!",
        })
      );

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchBalanceByMonth = createAsyncThunk(
  "client/fetchBalanceByMonth",
  async (
    { idClient, dataInicio, dataFim, currentPage, itemsPerPage },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getBalanceByMonth(
        idClient,
        dataInicio,
        dataFim,
        currentPage,
        itemsPerPage
      );

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchClientStatusFull = createAsyncThunk(
  "client/fetchClientStatusFull",
  async (idClient, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getClientStatusFull(idClient);

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const blockAccount = createAsyncThunk(
  "client/blockAccount",
  async ({ idStatus, idClient }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const accountActive = idStatus === (1 || 2 || 3);

      if (accountActive) {
        await block(idClient);
      } else {
        await unblock(idClient);
      }

      thunkAPI.dispatch(fetchClientStatusFull(idClient));

      thunkAPI.dispatch(
        setNotification({
          type: "success",
          title: `Conta ${accountActive ? "bloqueada" : "ativada"}`,
          message: `Conta ${
            accountActive ? "bloqueada" : "ativada"
          } com sucesso!`,
        })
      );
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchLoginHistory = createAsyncThunk(
  "client/fetchLoginHistory",
  async (idClient, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getLoginHistory(idClient);

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue();
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchClientErrors = createAsyncThunk(
  "client/fetchClientErrors",
  async (
    { idClient, dataInicio, dataFim, currentPage, itemsPerPage },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getClientErrors(
        idClient,
        dataInicio,
        dataFim,
        currentPage,
        itemsPerPage
      );

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchApplicationErrors = createAsyncThunk(
  "client/fetchApplicationErrors",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getApplicationErrors();

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);
