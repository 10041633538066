import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";

import { checkRole } from "utils/isRoleAllowed";
import { fetchPendingPreTransactions } from "reducers/accounts/accountsThunk";
import { fetchPendingRequests } from "reducers/limits/limitsThunk";
import {
  PRE_TRANSACTION_APROVER,
  USER_ADMIN,
  ADMIN_ECOSYSTEM,
  LIMIT_REQUEST_APPROVER,
} from "utils/const";
import {
  deleteUserNotification,
  setUserNotification,
} from "reducers/azure-account/azureAccountSlice";

export default function Loader() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { preTransactionList } = useSelector((store) => store.accounts);
  const { pendingRequests } = useSelector((store) => store.limits);
  const { role } = useSelector((store) => store.user);

  useEffect(() => {
    if (preTransactionList.length > 0) {
      dispatch(
        setUserNotification({
          id: PRE_TRANSACTION_APROVER,
          description: "Nova transação criada",
          action: () => navigate("/financeiro/transacoes"),
        })
      );
    } else {
      dispatch(deleteUserNotification(PRE_TRANSACTION_APROVER));
    }
  }, [preTransactionList, dispatch, navigate]);

  useEffect(() => {
    if (
      checkRole(role, [PRE_TRANSACTION_APROVER, USER_ADMIN]) &&
      process.env.REACT_APP_MODE === "CORE"
    ) {
      dispatch(fetchPendingPreTransactions());
    }
  }, [role, dispatch]);

  useEffect(() => {
    if (pendingRequests.length > 0) {
      dispatch(
        setUserNotification({
          id: LIMIT_REQUEST_APPROVER,
          description: "Aumento de limite",
          action: () => navigate("/contas/limites"),
        })
      );
    } else {
      dispatch(deleteUserNotification(LIMIT_REQUEST_APPROVER));
    }
  }, [pendingRequests, dispatch, navigate]);

  useEffect(() => {
    if (checkRole(role, [ADMIN_ECOSYSTEM, USER_ADMIN])) {
      dispatch(fetchPendingRequests());
    }
  }, [role, dispatch]);

  return null;
}
