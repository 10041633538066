import { useEffect, useState, useCallback, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import Datepicker from "react-tailwindcss-datepicker";

import Pagination from "components/pagination";
import Tooltip from "components/tooltip";
import {
  fetchBaasBalanceByDay,
  fetchBanksBalanceByDay,
} from "reducers/financial/financialThunk";

const initialState = {
  currentPage: 1,
  dateFilter: {
    startDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  },
  itemsPerPage: 30,
};

const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
const SET_DATE_FILTER = "SET_DATE_FILTER";
const SET_ITEMS_PER_PAGE = "SET_ITEMS_PER_PAGE";

const reducer = (state, action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return { ...state, currentPage: action.payload };
    case SET_DATE_FILTER:
      return { ...state, dateFilter: action.payload, currentPage: 1 };
    case SET_ITEMS_PER_PAGE:
      return { ...state, itemsPerPage: action.payload, currentPage: 1 };
    default:
      return state;
  }
};

const DailyTable = () => {
  const { idBank } = useParams();
  const { baasBalanceDay, bankBalanceDay } = useSelector(
    (store) => store.financial
  );
  const [data, setData] = useState(
    isNaN(idBank) ? baasBalanceDay : bankBalanceDay
  );
  const dispatch = useDispatch();
  const isLoading = useSelector((store) => store.isLoading);
  const [state, reducerDispatch] = useReducer(reducer, initialState);

  const fetchBalanceHandler = useCallback(() => {
    if (isNaN(idBank)) {
      dispatch(
        fetchBaasBalanceByDay({
          dataInicio: state.dateFilter.startDate,
          dataFim: state.dateFilter.endDate,
          currentPage: state.currentPage,
          itemsPerPage: state.itemsPerPage,
        })
      );
    } else {
      dispatch(
        fetchBanksBalanceByDay({
          dataInicio: state.dateFilter.startDate,
          dataFim: state.dateFilter.endDate,
          idCustomerBank: idBank,
          currentPage: state.currentPage,
          itemsPerPage: state.itemsPerPage,
        })
      );
    }
  }, [dispatch, state, idBank]);

  useEffect(() => {
    fetchBalanceHandler();
  }, [fetchBalanceHandler]);

  useEffect(() => {
    isNaN(idBank) ? setData(baasBalanceDay) : setData(bankBalanceDay);

    // eslint-disable-next-line
  }, [baasBalanceDay, bankBalanceDay]);

  const renderDateColumn = (dia, mes, ano) => {
    return `${dia.toString().padStart(2, "0")}/${mes
      .toString()
      .padStart(2, "0")}/${ano}`;
  };

  const handleChangeDate = (newValue) => {
    const startDate = moment(newValue.startDate, "YYYY-MM-DD");
    const endDate = moment(newValue.endDate, "YYYY-MM-DD");

    reducerDispatch({
      type: SET_DATE_FILTER,
      payload: {
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
      },
    });
  };

  const handleNextPage = () => {
    reducerDispatch({ type: SET_CURRENT_PAGE, payload: state.currentPage + 1 });
  };

  const handlePreviousPage = () => {
    reducerDispatch({ type: SET_CURRENT_PAGE, payload: state.currentPage - 1 });
  };

  const changeCurrentPage = (page = 1) => {
    reducerDispatch({ type: SET_CURRENT_PAGE, payload: page });
  };

  const changeItemsPerPageHandler = (e) => {
    reducerDispatch({
      type: SET_ITEMS_PER_PAGE,
      payload: parseInt(e.target.value),
    });
  };

  return (
    <>
      <div className="flex flex-col sm:flex-row items-start gap-3">
        <div className="flex flex-row items-center w-full sm:w-auto">
          <Datepicker
            configs={{
              shortcuts: {
                today: "Hoje",
                yesterday: "Ontem",
                past: (period) => `Últimos ${period} dias`,
                currentMonth: "Este mês",
                pastMonth: "Último mês",
              },
            }}
            i18n={"pt"}
            placeholder={"Selecione o intervalo"}
            separator={"-"}
            displayFormat={"DD/MM/YYYY"}
            toggleClassName="hidden"
            readOnly={true}
            showShortcuts={true}
            value={state.dateFilter}
            onChange={handleChangeDate}
            primaryColor={"indigo"}
            popoverDirection={"down"}
            inputClassName="w-full sm:w-48 text-center relative transition-all duration-300 py-2.5 px-2 border-gray-300 rounded-lg tracking-wide font-light text-sm placeholder-gray-400 bg-white focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-indigo-500 focus:ring-indigo-500/20"
          />
          <Tooltip text={"Sincronizar saldo"}>
            <ArrowPathIcon
              className={`ml-3 h-5 w-5 cursor-pointer ${
                isLoading && "animate-spin"
              }`}
              onClick={fetchBalanceHandler}
              aria-hidden="true"
            />
          </Tooltip>
        </div>
      </div>
      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:mx-0 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Período
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Saldo inicial
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
              >
                Entrada
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
              >
                Saída
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Bloqueado
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Saldo final
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {data?.balance?.length > 0 ? (
              data.balance.map((balance) => (
                <tr key={uuidv4()}>
                  <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                    {renderDateColumn(balance.dia, balance.mes, balance.ano)}
                    <dl className="font-normal lg:hidden">
                      <dt className="sr-only">Saldo inicial</dt>
                      <dd className="mt-1 truncate text-gray-700">
                        Saldo inicial{" "}
                        {balance.saldoInicial.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </dd>
                      <dt className="sr-only md:hidden">Entrada</dt>
                      <dd className="mt-1 truncate text-gray-700 md:hidden">
                        Entrada{" "}
                        {balance.entrada.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </dd>
                      <dt className="sr-only md:hidden">Saída</dt>
                      <dd className="mt-1 truncate text-gray-700 md:hidden">
                        Saída{" "}
                        {balance.saida.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </dd>
                      <dt className="sr-only sm:hidden">Bloqueado</dt>
                      <dd className="mt-1 truncate text-gray-700 sm:hidden">
                        Bloqueado{" "}
                        {balance.bloqueado.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </dd>
                      <dt className="sr-only sm:hidden">Saldo final</dt>
                      <dd className="mt-1 truncate text-gray-700 sm:hidden">
                        Saldo final{" "}
                        {balance.saldoFinal.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </dd>
                    </dl>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                    {balance.saldoInicial.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                    {balance.entrada.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                    {balance.saida.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {balance.bloqueado.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {balance.saldoFinal.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={6}
                  className="text-sm sm:text-base text-gray-500 text-center py-5"
                >
                  Não há dados para exibir
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination
          total={data.total}
          pageItens={state.itemsPerPage}
          pageCount={Math.ceil(data.total / state.itemsPerPage)}
          currentPage={state.currentPage}
          onNextPage={handleNextPage}
          onPreviousPage={handlePreviousPage}
          onClick={changeCurrentPage}
          itemsPerPage={state.itemsPerPage}
          onChangeItemsPerPage={changeItemsPerPageHandler}
        />
      </div>
    </>
  );
};

export default DailyTable;
