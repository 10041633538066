import { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import Layout from "components/layout";
import Button from "components/button";
import FeeDetails from "./details";
import Select from "components/select";
import NewFee from "./new-fee";
import ConfirmationModal from "components/modal-confirmation";
import classNames from "utils/classNames";
import { checkRole } from "utils/isRoleAllowed";
import { USER_ADMIN, ADMIN_ECOSYSTEM } from "utils/const";
import { fetchBankList } from "reducers/bank/bankThunk";
import { convertCurrencyBRLToString } from "utils/mask";
import {
  fetchGroupItems,
  fetchGroupsFees,
  fetchPeriodicFees,
  fetchFees,
  removeFeeFromGroup,
} from "reducers/fees/feesThunk";

const Fees = () => {
  const [idCustomerBank, setIdCustomerBank] = useState(1);
  const [feeDetail, setFeeDetail] = useState({});
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [bankList, setBankList] = useState([]);
  const [payload, setPayload] = useState({});
  const { groupItemsList, groupsFeesList, feesList, periodicFeesList } =
    useSelector((store) => store.fees);
  const { banks } = useSelector((store) => store.bank);
  const { role, id_Customer_Bank } = useSelector((store) => store.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!groupsFeesList.tarifaGrupos.length) dispatch(fetchGroupsFees(1));
    if (!feesList.tarifas.length) dispatch(fetchFees(1));
    if (!periodicFeesList.tarifas.length) dispatch(fetchPeriodicFees(1));
    if (!banks.length) dispatch(fetchBankList());

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(
      fetchGroupItems(
        process.env.REACT_APP_MODE === "CORE"
          ? idCustomerBank
          : id_Customer_Bank
      )
    );

    // eslint-disable-next-line
  }, [idCustomerBank, id_Customer_Bank]);

  useEffect(() => {
    setBankList(
      banks.map((bank) => ({ id: bank.idBanco, name: bank.descricao }))
    );
  }, [banks]);

  const renderChargedValue = (feeItem) => {
    const isPercentual = feeItem.percentual;
    const symbol = isPercentual ? "%" : "R$";

    if (isPercentual) {
      return (
        <div className="flex flex-col">
          <span>
            {convertCurrencyBRLToString(feeItem.percentual)} {symbol}
          </span>
          {feeItem.valorMaximoParaCobrancaPorPercentual && (
            <span className="text-xs leading-5 text-gray-500">
              valor máximo R${" "}
              {convertCurrencyBRLToString(
                feeItem.valorMaximoParaCobrancaPorPercentual
              )}
            </span>
          )}
        </div>
      );
    } else {
      return `${symbol} ${convertCurrencyBRLToString(feeItem.valor)}`;
    }
  };

  const removeFeeHandler = () => {
    dispatch(removeFeeFromGroup({ ...payload, idCustomerBank }));
    closeConfirmationModalHandler();
  };

  const openDetailsHandler = (item) => {
    setFeeDetail(item);
    setIsDetailsOpen(true);
  };

  const closeDetailsHandler = () => {
    setIsDetailsOpen(false);
  };

  const openConfirmationModalHandler = (idFeeGroupFee, idfee, isPeriodica) => {
    setIsConfirmationModalOpen(true);
    setPayload({ idFeeGroupFee, idfee, isPeriodica });
  };

  const closeConfirmationModalHandler = () => {
    setIsConfirmationModalOpen(false);
    setPayload({});
  };

  return (
    <Layout title="Tarifas">
      <div className="relative">
        <NewFee
          isModalOpen={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          idCustomerBank={
            process.env.REACT_APP_MODE === "CORE"
              ? idCustomerBank
              : id_Customer_Bank
          }
        />

        <FeeDetails
          isDetailsOpen={isDetailsOpen}
          feeDetail={feeDetail}
          onClose={closeDetailsHandler}
          idCustomerBank={
            process.env.REACT_APP_MODE === "CORE"
              ? idCustomerBank
              : id_Customer_Bank
          }
        />

        <ConfirmationModal
          title={"Deseja remover a tarifa?"}
          description={
            "Sua ação irá remover a tarifa do grupo de cobrança permanentemente. Deseja prosseguir?"
          }
          confirmButtonLabel={"Continuar"}
          onConfirm={removeFeeHandler}
          cancelButtonLabel={"Cancelar"}
          onCancel={closeConfirmationModalHandler}
          isModalOpen={isConfirmationModalOpen}
        />

        <div className="relative md:absolute flex gap-3 right-0 mt-0 md:-mt-16">
          {process.env.REACT_APP_MODE === "CORE" && (
            <Select
              id={"idCustomerBank"}
              name={"idCustomerBank"}
              value={idCustomerBank || ""}
              placeholder={"Selecione o banco desejado"}
              options={bankList}
              onChange={(e) => setIdCustomerBank(e.target.value)}
            />
          )}
          {checkRole(role, [USER_ADMIN, ADMIN_ECOSYSTEM]) && (
            <Button
              className="mt-1"
              label={"Cadastrar Nova Tarifa"}
              onClick={() => setIsModalOpen(true)}
            />
          )}
        </div>

        <div className="mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:mx-0 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Nome
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Tipo tarifa
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Tipo cobrança
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Valor cobrado
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Detalhes</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {groupItemsList.tarifaGrupoTarifas.length > 0 ? (
                groupItemsList.tarifaGrupoTarifas.map((feeGroup) => (
                  <Fragment key={feeGroup.nomeGrupoTarifa}>
                    <tr className="border-t border-gray-200">
                      <th
                        colSpan={5}
                        scope="colgroup"
                        className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                      >
                        <div className="flex items-center justify-between">
                          <div>{feeGroup.nomeGrupoTarifa}</div>
                        </div>
                      </th>
                    </tr>
                    {feeGroup.tarifas.map((fee, index) => (
                      <tr
                        key={fee.nome}
                        className={classNames(
                          index === 0 ? "border-gray-300" : "border-gray-200",
                          "border-t align-middle h-20"
                        )}
                      >
                        <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                          {fee.nome}
                        </td>
                        <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                          {fee.isPeriodica ? "Periódica" : "Transacional"}
                        </td>
                        <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                          {fee.percentual ? "Percentual" : "Valor fixo"}
                        </td>
                        <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                          {renderChargedValue(fee)}
                        </td>
                        <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <div className="flex flex-col">
                            <button
                              className="text-indigo-600 hover:text-indigo-900 text-left"
                              onClick={() => openDetailsHandler(fee)}
                            >
                              Detalhes
                              <span className="sr-only">, {fee.descricao}</span>
                            </button>
                            <button
                              onClick={() =>
                                openConfirmationModalHandler(
                                  fee.idFeeGroupFee,
                                  fee.idFee,
                                  fee.isPeriodica
                                )
                              }
                              className="text-red-600 hover:text-red-500 text-left"
                            >
                              Remover tarifa
                              <span className="sr-only">, {fee.descricao}</span>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </Fragment>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={5}
                    className="text-sm sm:text-base text-gray-500 text-center py-5"
                  >
                    Não há dados para exibir
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default Fees;
