import { useEffect, useState } from "react";

const useWindowResize = (breakPointWidth) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [collapsed, setCollapsed] = useState(
    window.innerWidth < breakPointWidth
  );

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);

      if (newWidth < breakPointWidth) {
        setCollapsed(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [breakPointWidth]);

  return { width, collapsed, setCollapsed };
};

export default useWindowResize;
