import React from "react";
import { useNavigate } from "react-router-dom";

import Button from "components/button";

export default function NotFound() {
  const navigate = useNavigate();

  function handleNavigation() {
    navigate("/");
  }

  return (
    <div className="h-full w-full fixed bg-white">
      <div className="flex justify-center items-center h-full">
        <div className="flex flex-col sm:flex-row -mt-40 gap-5 sm:gap-0 px-3 sm:px-0">
          <h2 className="font-bold text-4xl md:text-5xl text-indigo-600 sm:pr-8 sm:border-r border-gray-300">
            <span className="sr-only">Error</span>404
          </h2>
          <div className="flex flex-col text-left sm:pl-8">
            <div className="space-y-1 md:space-y-2">
              <p className="text-2xl sm:text-3xl md:text-5xl font-semibold">Página não encontrada.</p>
              <p className="text-sm text-gray-500">Verifique se a URL digitada está correta e tente novamente.</p>
            </div>
            <div className="pt-4 sm:pt-7">
              <Button
                label={"Início"}
                type={"button"}
                onClick={handleNavigation}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}