import { useSelector } from "react-redux";

import PersonalData from "./personal-data";
import PersonalAddress from "./personal-address";
import CreationDetails from "../common/creation-details";

export default function ClientePfForm() {
  const { client } = useSelector((store) => store.client);

  return (
    <>
      <PersonalData />
      <PersonalAddress />
      {client.onboardingManualBy !== 0 && <CreationDetails />}
    </>
  );
}
