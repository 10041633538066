import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Favicon from "react-favicon";

import Router from "routes";
import Login from "features/login";
import Loading from "components/loading";
import Notification from "components/notification";
import Loader from "features/loader";
import Sidebar from "components/sidebar";
import { banks } from "utils/banks";
import "./App.css";

function App() {
  const isLoading = useSelector((store) => store.isLoading);
  const [favicon, setFavicon] = useState("");

  const idCustomerBank = parseInt(process.env.REACT_APP_ID_BANK) || 1;

  useEffect(() => {
    setFavicon(`/favicons/${idCustomerBank}/favicon.ico`);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const name = getBankName();

    document.title = `${name} - Dashboard`;

    // eslint-disable-next-line
  }, []);

  function getBankName() {
    const bank = banks.find((item) => item.idCustomerBank === idCustomerBank);
    return bank.name;
  }

  return (
    <>
      {favicon && <Favicon url={favicon} />}
      <Notification></Notification>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Loader />
        {isLoading && <Loading />}
        <Sidebar>
          <div className="flex flex-col grow w-full">
            <Router />
          </div>
        </Sidebar>
      </AuthenticatedTemplate>
    </>
  );
}

export default App;
