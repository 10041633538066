import { createSlice } from "@reduxjs/toolkit";

import {
  fetchClient,
  updateClient,
  fetchBalanceByMonth,
  fetchClientStatusFull,
  blockAccount,
  fetchLoginHistory,
  fetchClientErrors,
  fetchApplicationErrors,
} from "./clientThunk";

const initialState = {
  client: {},
  isUpdating: false,
  balance: { balance: [], total: 0 },
  errors: { errors: [], total: 0 },
  editingForm: "",
  status: { idStatus: "", descricao: "" },
  loginHistory: { lastLogin: "", qtdLogins: 0, logins: [] },
  applicationErrors: [],
};

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    setEditingForm: (state, action) => {
      return { ...state, editingForm: action.payload };
    },
  },
  extraReducers: {
    [fetchClient.fulfilled]: (state, action) => {
      return { ...state, client: { ...action.payload } };
    },
    [updateClient.pending]: (state) => {
      return { ...state, isUpdating: true };
    },
    [updateClient.rejected]: (state) => {
      return { ...state, isUpdating: false };
    },
    [updateClient.fulfilled]: (state, action) => {
      return { ...state, client: { ...action.payload }, isUpdating: false };
    },
    [fetchBalanceByMonth.pending]: (state) => {
      return { ...state, balance: { balance: [], total: 0 } };
    },
    [fetchBalanceByMonth.fulfilled]: (state, action) => {
      return { ...state, balance: action.payload };
    },
    [blockAccount.pending]: (state) => {
      return { ...state, status: { ...state.status, isBlocking: true } };
    },
    [blockAccount.fulfilled]: (state, action) => {
      return {
        ...state,
        status: { ...state.status, active: action.payload, isBlocking: false },
      };
    },
    [blockAccount.rejected]: (state) => {
      return {
        ...state,
        status: { ...state.status, isBlocking: false },
      };
    },
    [fetchClientStatusFull.fulfilled]: (state, action) => {
      return { ...state, status: action.payload };
    },
    [fetchLoginHistory.rejected]: (state) => {
      return {
        ...state,
        loginHistory: { lastLogin: "", qtdLogins: 0, logins: [] },
      };
    },
    [fetchLoginHistory.fulfilled]: (state, action) => {
      return { ...state, loginHistory: action.payload };
    },
    [fetchClientErrors.pending]: (state) => {
      return { ...state, errors: { errors: [], total: 0 } };
    },
    [fetchClientErrors.fulfilled]: (state, action) => {
      return { ...state, errors: action.payload };
    },
    [fetchApplicationErrors.fulfilled]: (state, action) => {
      return { ...state, applicationErrors: action.payload };
    },
  },
});

export const { setEditingForm } = clientSlice.actions;

export default clientSlice.reducer;
