import axiosClient from "./apiClient";

export function getTransactionTypes() {
  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/fees/transactionTypes`
  );
}

export function getPeriodTypes() {
  return axiosClient.get(`${process.env.REACT_APP_API_DASH}/fees/periodTypes`);
}

export function getFees(currentPage) {
  const params = new URLSearchParams();

  currentPage && params.append("pageNumber", currentPage);
  params.append("rowsPerPage", 30);

  return axiosClient.get(`${process.env.REACT_APP_API_DASH}/fees/fees`, {
    params,
  });
}

export function deleteFee(idFee) {
  return axiosClient.delete(
    `${process.env.REACT_APP_API_DASH}/fees/fees/${idFee}`
  );
}

export function postFee(payload) {
  return axiosClient.post(
    `${process.env.REACT_APP_API_DASH}/fees/fees`,
    payload,
    {
      headers: { "Content-Type": "application/json;charset=utf-8" },
    }
  );
}

export function updateFee(idFee, payload) {
  return axiosClient.put(
    `${process.env.REACT_APP_API_DASH}/fees/fees/${idFee}`,
    payload,
    {
      headers: { "Content-Type": "application/json;charset=utf-8" },
    }
  );
}

export function getPeriodicFees(currentPage) {
  const params = new URLSearchParams();

  currentPage && params.append("pageNumber", currentPage);
  params.append("rowsPerPage", 30);

  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/fees/feesperiodic`,
    {
      params,
    }
  );
}

export function deletePeriodicFee(idPeriodicFee) {
  return axiosClient.delete(
    `${process.env.REACT_APP_API_DASH}/fees/feesperiodic/${idPeriodicFee}`
  );
}

export function postPeriodicFee(payload) {
  return axiosClient.post(
    `${process.env.REACT_APP_API_DASH}/fees/feesperiodic`,
    payload,
    {
      headers: { "Content-Type": "application/json;charset=utf-8" },
    }
  );
}

export function updatePeriodicFee(idFeePeriodic, payload) {
  return axiosClient.put(
    `${process.env.REACT_APP_API_DASH}/fees/feesperiodic/${idFeePeriodic}`,
    payload,
    {
      headers: { "Content-Type": "application/json;charset=utf-8" },
    }
  );
}

export function getGroupsFees(currentPage) {
  const params = new URLSearchParams();

  currentPage && params.append("pageNumber", currentPage);
  params.append("rowsPerPage", 30);

  return axiosClient.get(`${process.env.REACT_APP_API_DASH}/fees/feegroups`, {
    params,
  });
}

export function deleteGroupFee(idFeeGroup) {
  return axiosClient.delete(
    `${process.env.REACT_APP_API_DASH}/fees/feegroups/${idFeeGroup}`
  );
}

export function postGroupFee(payload) {
  return axiosClient.post(
    `${process.env.REACT_APP_API_DASH}/fees/feegroups`,
    payload,
    {
      headers: { "Content-Type": "application/json;charset=utf-8" },
    }
  );
}

export function updateGroupFee(idFeeGroup, payload) {
  return axiosClient.put(
    `${process.env.REACT_APP_API_DASH}/fees/feegroups/${idFeeGroup}`,
    payload,
    {
      headers: { "Content-Type": "application/json;charset=utf-8" },
    }
  );
}

export function getGroupsItems(idCustomerBank) {
  const params = new URLSearchParams();

  idCustomerBank && params.append("idCustomerBank", idCustomerBank);

  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/fees/feegroupfees`,
    {
      params,
    }
  );
}

export function postGroupItems(payload) {
  return axiosClient.post(
    `${process.env.REACT_APP_API_DASH}/fees/feegroupfees`,
    payload,
    {
      headers: { "Content-Type": "application/json;charset=utf-8" },
    }
  );
}

export function patchFeeToGroupItems(idFeeGroupFee, idFee) {
  return axiosClient.patch(
    `${process.env.REACT_APP_API_DASH}/fees/feegroupfees/${idFeeGroupFee}/fee/${idFee}`
  );
}

export function patchPeriodFeeToGroupItems(idFeeGroupFee, idFee) {
  return axiosClient.patch(
    `${process.env.REACT_APP_API_DASH}/fees/feegroupfees/${idFeeGroupFee}/feeperiodic/${idFee}`
  );
}

export function updateFeeToGroupItems(idFeeGroupFee, payload) {
  return axiosClient.put(
    `${process.env.REACT_APP_API_DASH}/fees/feegroupfees/${idFeeGroupFee}`,
    payload,
    {
      headers: { "Content-Type": "application/json;charset=utf-8" },
    }
  );
}

export function getClientFees(idClient) {
  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/fees/fees/client/${idClient}`
  );
}

export function getClientAvailableGroups(idClient) {
  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/fees/feegroups/client/${idClient}`
  );
}

export function deleteClientFeeGroup(idClient) {
  return axiosClient.delete(
    `${process.env.REACT_APP_API_DASH}/client/${idClient}/feegroup`
  );
}

export function updateClientFeeGroup(idClient, payload) {
  return axiosClient.patch(
    `${process.env.REACT_APP_API_DASH}/client/${idClient}/feegroup`,
    payload,
    {
      headers: { "Content-Type": "application/json;charset=utf-8" },
    }
  );
}

export function deleteFeeFromGroup(idFeeGroupFee, idfee) {
  return axiosClient.delete(
    `${process.env.REACT_APP_API_DASH}/fees/feegroupfees/${idFeeGroupFee}/fee/${idfee}`
  );
}

export function deletePeriodicFeeFromGroup(idFeeGroupFee, idFeePeriodic) {
  return axiosClient.delete(
    `${process.env.REACT_APP_API_DASH}/fees/feegroupfees/${idFeeGroupFee}/feeperiodic/${idFeePeriodic}`
  );
}
