import axiosClient from "./apiClient";

export function newPfAccount(payload) {
  return axiosClient.post(
    `${process.env.REACT_APP_API_ONBOARDING_CELCOIN}/api/OnboardingPessoaFisicaManual`,
    payload,
    {
      headers: { "Content-Type": "application/json;charset=utf-8" },
    }
  );
}

export function newPjAccount(payload) {
  return axiosClient.post(
    `${process.env.REACT_APP_API_ONBOARDING_CELCOIN}/api/OnboardingPessoaJuridicaManual`,
    payload,
    {
      headers: { "Content-Type": "application/json;charset=utf-8" },
    }
  );
}

export function getBanksList() {
  return axiosClient.get(`${process.env.REACT_APP_API_DASH}/bank/onboarding`, {
    headers: { "Content-Type": "application/json;charset=utf-8" },
  });
}

export function addPermissiveList(payload) {
  return axiosClient.post(
    `${process.env.REACT_APP_API_DASH}/whitelist`,
    payload,
    {
      headers: { "Content-Type": "application/json;charset=utf-8" },
    }
  );
}

export function removeFromPermissiveList(idOnboardingPermissiveList) {
  return axiosClient.delete(
    `${process.env.REACT_APP_API_DASH}/whitelist/${idOnboardingPermissiveList}`
  );
}

export function getAllPermissiveList(
  dataInicio,
  dataFim,
  document,
  paginaAtual,
  itemsPerPage
) {
  const params = new URLSearchParams();

  dataInicio && params.append("dataInicio", dataInicio);
  dataFim && params.append("dataFinal", dataFim);
  document && params.append("documento", document);
  paginaAtual && params.append("pagina", paginaAtual);
  itemsPerPage && params.append("itensPorPagina", itemsPerPage);

  return axiosClient.get(`${process.env.REACT_APP_API_DASH}/whitelist`, {
    headers: { "Content-Type": "application/json;charset=utf-8" },
    params: params,
  });
}

export function getAllRestrictiveList(
  dataInicio,
  dataFim,
  document,
  paginaAtual,
  itemsPerPage
) {
  const params = new URLSearchParams();

  dataInicio && params.append("dataInicio", dataInicio);
  dataFim && params.append("dataFinal", dataFim);
  document && params.append("valor", document);
  paginaAtual && params.append("pagina", paginaAtual);
  itemsPerPage && params.append("itensPorPagina", itemsPerPage);

  return axiosClient.get(`${process.env.REACT_APP_API_DASH}/blacklist`, {
    headers: { "Content-Type": "application/json;charset=utf-8" },
    params: params,
  });
}

export function getRestrictiveListType() {
  return axiosClient.get(`${process.env.REACT_APP_API_DASH}/blacklist/types`, {
    headers: { "Content-Type": "application/json;charset=utf-8" },
  });
}

export function addRestrictiveList(payload) {
  return axiosClient.post(
    `${process.env.REACT_APP_API_DASH}/blacklist`,
    payload,
    {
      headers: { "Content-Type": "application/json;charset=utf-8" },
    }
  );
}

export function removeFromRestrictiveList(
  idRestrictiveListOnboarding,
  payload
) {
  return axiosClient.delete(
    `${process.env.REACT_APP_API_DASH}/blacklist/${idRestrictiveListOnboarding}`,
    {
      data: payload,
      headers: { "Content-Type": "application/json;charset=utf-8" },
    }
  );
}

export function getOnboardingList(
  dataInicio,
  dataFim,
  document,
  paginaAtual,
  itemsPerPage,
  idCustomerBank
) {
  const params = new URLSearchParams();

  dataInicio && params.append("dataInicio", dataInicio);
  dataFim && params.append("dataFinal", dataFim);
  document && params.append("documento", document);
  paginaAtual && params.append("pagina", paginaAtual);
  itemsPerPage && params.append("itensPorPagina", itemsPerPage);
  idCustomerBank && params.append("idCustomerBank", idCustomerBank);

  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/status/onboarding`,
    {
      params,
    }
  );
}

export function createCelcoinPf(idClient) {
  return axiosClient.post(
    `${process.env.REACT_APP_API_ONBOARDING}/celcoin/pf/${idClient}`,
    null,
    {
      headers: { "Content-Type": "application/json;charset=utf-8" },
    }
  );
}

export function createCelcoinPj(idClient) {
  return axiosClient.post(
    `${process.env.REACT_APP_API_ONBOARDING}/celcoin/pj/${idClient}`,
    null,
    {
      headers: { "Content-Type": "application/json;charset=utf-8" },
    }
  );
}
