import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BuildingLibraryIcon } from "@heroicons/react/24/outline";

import { logos } from "utils/logos";
import { updateTimeOnboarding } from "reducers/bank/bankThunk";
import Toggle from "components/toggle";
import ConfirmationModal from "components/modal-confirmation";

const getBankLogo = (idBank) => {
  const logo = logos.filter((logo) => logo.idCustomerBank === idBank);

  if (logo.length) {
    return (
      <div className="rounded-md">
        <div className="h-9 w-9 flex items-center">{logo[0].svg}</div>
      </div>
    );
  } else {
    return (
      <div className="rounded-md bg-indigo-500 p-3">
        <BuildingLibraryIcon
          className="h-4 w-4 text-white"
          aria-hidden="true"
        />
      </div>
    );
  }
};

const OnboardingAvailability = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idCustomerBank, setIdCustomerBank] = useState("");
  const [item, setItem] = useState({});
  const [availabilityChanged, setAvailabilityChanged] = useState();
  const [availabilityValue, setAvailabilityValue] = useState();
  const { banks, success } = useSelector((store) => store.bank);
  const dispatch = useDispatch();

  useEffect(() => {
    if (success) {
      setIsModalOpen(false);
    }
  }, [success]);

  const handleOpenModal = (idBanco, fieldName, enabled, itemChanged) => {
    setIdCustomerBank(idBanco);
    setAvailabilityChanged(fieldName);
    setAvailabilityValue(enabled);
    setItem(itemChanged);
    setIsModalOpen(true);
  };

  const handleSubmit = () => {
    dispatch(
      updateTimeOnboarding({
        idCustomerBank,
        ...item,
        [availabilityChanged]: availabilityValue,
      })
    );
  };

  return (
    <div className="relative">
      <ConfirmationModal
        title={"Alterar disponibilidade"}
        description={"Tem certeza que deseja alterar a disponibilidade?"}
        confirmButtonLabel={"Confirmar"}
        onConfirm={handleSubmit}
        cancelButtonLabel={"Cancelar"}
        onCancel={() => setIsModalOpen(false)}
        type={"danger"}
        isModalOpen={isModalOpen}
      />
      <div className="shadow sm:overflow-hidden sm:rounded-md">
        <div className="bg-white ">
          <div className="py-6 px-4 sm:p-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Disponibilidade Onboarding
            </h3>
          </div>
          <div>
            <ul className="divide-y divide-gray-200">
              {banks.map((bank) => (
                <li key={bank.idBanco}>
                  <span className="block">
                    <div className="flex items-center px-4 py-4 sm:px-6">
                      <div className="flex min-w-0 flex-1 items-center">
                        {getBankLogo(bank.idBanco)}
                        <div className="min-w-0 flex-1 pl-4 md:grid md:grid-cols-3 md:gap-4">
                          <div className="flex  flex-col justify-center">
                            <p className="truncate text-sm font-medium text-indigo-600">
                              {bank.descricao}
                            </p>
                            <div className="md:hidden space-y-3 mt-4">
                              <Toggle
                                enabled={bank.fimDeSemanaOnboarding}
                                label={"Fim de semana"}
                                onChange={() =>
                                  handleOpenModal(
                                    bank.idBanco,
                                    "fimDeSemanaOnboarding",
                                    !bank.fimDeSemanaOnboarding,
                                    bank
                                  )
                                }
                              />
                              <Toggle
                                enabled={bank.feriadoOnboarding}
                                label={"Feriado"}
                                onChange={() =>
                                  handleOpenModal(
                                    bank.idBanco,
                                    "feriadoOnboarding",
                                    !bank.feriadoOnboarding,
                                    bank
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="hidden md:block">
                            <Toggle
                              enabled={bank.fimDeSemanaOnboarding}
                              label={"Fim de semana"}
                              onChange={() =>
                                handleOpenModal(
                                  bank.idBanco,
                                  "fimDeSemanaOnboarding",
                                  !bank.fimDeSemanaOnboarding,
                                  bank
                                )
                              }
                            />
                          </div>
                          <div className="hidden md:block">
                            <Toggle
                              enabled={bank.feriadoOnboarding}
                              label={"Feriado"}
                              onChange={() =>
                                handleOpenModal(
                                  bank.idBanco,
                                  "feriadoOnboarding",
                                  !bank.feriadoOnboarding,
                                  bank
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingAvailability;
