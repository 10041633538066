import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { get } from "lodash";

import Button from "components/button";
import {
  formatDate,
  convertCurrencyBRLToString,
  renderDocument,
} from "utils/mask";
import { getDescription } from "utils/transactionType";
import { banks } from "utils/banks";
import { fetchVoucherPdf } from "reducers/voucher/voucherThunk";

export default function ModalVoucher({
  isModalOpen,
  onOpenChange,
  transactional,
}) {
  const [logo, setLogo] = useState();
  const { voucher } = useSelector((store) => store.voucher);
  const { idClient, idCustomerBank, idTransaction, idAccountTransaction } =
    transactional;
  const dispatch = useDispatch();

  useEffect(() => {
    if (idCustomerBank)
      banks.forEach((bank) => {
        if (bank.idCustomerBank === parseInt(idCustomerBank)) {
          setLogo(bank.svg);
        }
      });
  }, [idCustomerBank]);

  const openChangeHandler = () => {
    onOpenChange(false);
  };

  const downloadVoucherPdfHandler = async () => {
    const response = await dispatch(
      fetchVoucherPdf({ idClient, idAccountTransaction })
    );

    const reqStatus = get(response, "meta.requestStatus", "");
    if (reqStatus === "fulfilled") {
      const url = window.URL.createObjectURL(
        new Blob([response.payload], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `comprovante-${idClient}-${idAccountTransaction}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }
  };

  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={openChangeHandler}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-30 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-1 w-full sm:max-w-md sm:p-6">
                {(!voucher ||
                  (voucher && formatDate(voucher.data, "yyyy") === "0001")) && (
                  <div className="bg-white rounded-lg px-6 py-12">
                    <p className="text-center text-xl">
                      Transação não encontrada
                    </p>
                  </div>
                )}
                {voucher && formatDate(voucher.data, "yyyy") !== "0001" && (
                  <>
                    <div className="bg-white space-y-1 px-6 print:shadow-none print:rounded-none">
                      <header className="flex items-center justify-between">
                        <div className="flex justify-end max-w-[120px] sm:max-w-[130px] h-8">
                          {logo}
                        </div>
                        <div className="text-right flex flex-col">
                          <span className="text-lg font-semibold">
                            Comprovante
                          </span>
                          <span className="text-sm text-black text-opacity-60">
                            {formatDate(voucher.data)}
                          </span>
                          <div className="h-9 flex flex-row-reverse relative">
                            <span className="text-white py-1 px-10 rounded bg-success block absolute text-sm font-semibold">
                              Efetuada
                            </span>
                          </div>
                        </div>
                      </header>
                      <h1 className="text-lg font-semibold">
                        Dados da transação
                      </h1>
                      <hr className="border-dashed" />
                      <section className="space-y-3">
                        <h2
                          className={`text-primary-bank-${idCustomerBank} font-semibold text-base`}
                        >
                          Sobre a transação
                        </h2>
                        <div>
                          <h3 className="font-medium text-base">
                            Tipo de transação
                          </h3>
                          <span className="text-sm block font-semibold">
                            {getDescription(parseInt(idTransaction))}
                          </span>
                        </div>
                        {voucher.data && (
                          <div>
                            <h3 className="font-medium text-base">
                              Data | Hora da transação
                            </h3>
                            <span className="text-sm block">
                              {formatDate(voucher.data)}
                            </span>
                          </div>
                        )}
                        {voucher.valor && (
                          <div>
                            <h3 className="font-medium text-base">
                              Valor da transação
                            </h3>
                            <span className="text-sm block font-semibold">
                              R$ {convertCurrencyBRLToString(voucher.valor)}
                            </span>
                          </div>
                        )}
                        {voucher.valorTarifa > 0 && (
                          <div className="print:hidden">
                            <h3 className="font-medium text-base">
                              Valor da tarifa
                            </h3>
                            <span className="text-sm block font-semibold">
                              R${" "}
                              {convertCurrencyBRLToString(voucher.valorTarifa)}
                            </span>
                          </div>
                        )}
                        {voucher.codigoBarra && (
                          <div>
                            <h3 className="font-medium text-base">
                              Código de barra
                            </h3>
                            <span className="text-sm flex items-center gap-2">
                              {voucher.codigoBarra}
                            </span>
                          </div>
                        )}
                        {voucher.hash && (
                          <div>
                            <h3 className="font-medium text-base">
                              ID da transação
                            </h3>
                            <span className="text-sm flex items-center gap-2">
                              {voucher.hash}
                            </span>
                          </div>
                        )}
                        <div>
                          <h3 className="font-medium text-base">
                            Código da transação
                          </h3>
                          <span className="text-sm flex items-center gap-2">
                            {idAccountTransaction}
                          </span>
                        </div>
                      </section>
                      <hr className="border-dashed" />
                      <section className="space-y-3">
                        <h2
                          className={`text-primary-bank-${idCustomerBank} font-semibold text-base`}
                        >
                          Quem pagou
                        </h2>
                        {voucher.nomePagador && (
                          <div>
                            <h3 className="font-medium text-base">Nome</h3>
                            <span className="text-sm block">
                              {voucher.nomePagador}
                            </span>
                          </div>
                        )}
                        {voucher.documentoPagador && (
                          <div>
                            <h3 className="font-medium text-base">CPF/CNPJ</h3>
                            <span className="text-sm block">
                              {renderDocument(voucher.documentoPagador)}
                            </span>
                          </div>
                        )}
                        {(voucher.agenciaPagador || voucher.contaPagador) && (
                          <div className="flex gap-5">
                            {voucher.contaPagador && (
                              <div>
                                <h3 className="font-medium text-base">Conta</h3>
                                <span className="text-sm block">
                                  {voucher.contaPagador}
                                </span>
                              </div>
                            )}
                            {voucher.agenciaPagador && (
                              <div>
                                <h3 className="font-medium text-base">
                                  Agência
                                </h3>
                                <span className="text-sm block">
                                  {voucher.agenciaPagador}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                        {voucher.bancoPagador &&
                          voucher.bancoPagador !== " " && (
                            <div>
                              <h3 className="font-medium text-base">
                                Instituição
                              </h3>
                              <span className="text-sm block">
                                {voucher.bancoPagador}
                              </span>
                            </div>
                          )}
                      </section>
                      {voucher.nomeRecebedor && (
                        <>
                          <hr className="border-dashed" />
                          <section className="space-y-3">
                            <h2
                              className={`text-primary-bank-${idCustomerBank} font-semibold text-base`}
                            >
                              Quem recebeu
                            </h2>
                            {voucher.nomeRecebedor && (
                              <div>
                                <h3 className="font-medium text-base">Nome</h3>
                                <span className="text-sm block">
                                  {voucher.nomeRecebedor}
                                </span>
                              </div>
                            )}
                            {voucher.documentoRecebedor && (
                              <div>
                                <h3 className="font-medium text-base">
                                  CPF/CNPJ
                                </h3>
                                <span className="text-sm block">
                                  {renderDocument(voucher.documentoRecebedor)}
                                </span>
                              </div>
                            )}
                            {(voucher.agenciaRecebedor ||
                              voucher.contaRecebedor) && (
                              <div className="flex gap-5">
                                {voucher.contaRecebedor && (
                                  <div>
                                    <h3 className="font-medium text-base">
                                      Conta
                                    </h3>
                                    <span className="text-sm block">
                                      {voucher.contaRecebedor}
                                    </span>
                                  </div>
                                )}
                                {voucher.agenciaRecebedor && (
                                  <div>
                                    <h3 className="font-medium text-base">
                                      Agência
                                    </h3>
                                    <span className="text-sm block">
                                      {voucher.agenciaRecebedor}
                                    </span>
                                  </div>
                                )}
                              </div>
                            )}
                            {voucher.bancoRecebedor && (
                              <div>
                                <h3 className="font-medium text-base">
                                  Instituição
                                </h3>
                                <span className="text-sm block">
                                  {voucher.bancoRecebedor}
                                </span>
                              </div>
                            )}
                          </section>
                        </>
                      )}
                    </div>
                    <div className="mt-5 sm:mt-6 flex flex-col sm:grid sm:grid-flow-row-dense sm:grid-cols-2 gap-3">
                      <Button
                        label="Download"
                        onClick={downloadVoucherPdfHandler}
                      />
                      <Button
                        label="Fechar"
                        secondary
                        onClick={openChangeHandler}
                      />
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
