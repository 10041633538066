import { useEffect, useReducer, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Datepicker from "react-tailwindcss-datepicker";
import moment from "moment";

import Layout from "components/layout";
import Pagination from "components/pagination";
import { fetchLogs } from "reducers/admin/adminThunk";
import { formatDate, renderDocument } from "utils/mask";

const initialState = {
  currentPage: 1,
  dateFilter: {
    startDate: moment().subtract(30, "d").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  },
  itemsPerPage: 30,
};

const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
const SET_DATE_FILTER = "SET_DATE_FILTER";
const SET_ITEMS_PER_PAGE = "SET_ITEMS_PER_PAGE";

const reducer = (state, action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return { ...state, currentPage: action.payload };
    case SET_DATE_FILTER:
      return { ...state, dateFilter: action.payload, currentPage: 1 };
    case SET_ITEMS_PER_PAGE:
      return { ...state, itemsPerPage: action.payload, currentPage: 1 };
    default:
      return state;
  }
};

export default function Logs() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { logs } = useSelector((store) => store.admin);

  const [state, reducerDispatch] = useReducer(reducer, initialState);

  const fetchSystemLogs = useCallback(() => {
    dispatch(
      fetchLogs({
        dataInicio: state.dateFilter.startDate,
        dataFinal: state.dateFilter.endDate,
        pagina: state.currentPage,
        itensPorPagina: state.itemsPerPage,
      })
    );
  }, [state, dispatch]);

  useEffect(() => {
    fetchSystemLogs();
  }, [fetchSystemLogs]);

  const handleChangeDate = (newValue) => {
    const startDate = moment(newValue.startDate, "YYYY-MM-DD");
    const endDate = moment(newValue.endDate, "YYYY-MM-DD");

    reducerDispatch({
      type: SET_DATE_FILTER,
      payload: {
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
      },
    });
  };

  const handleNextPage = () => {
    reducerDispatch({ type: SET_CURRENT_PAGE, payload: state.currentPage + 1 });
  };

  const handlePreviousPage = () => {
    reducerDispatch({ type: SET_CURRENT_PAGE, payload: state.currentPage - 1 });
  };

  const changeCurrentPage = (page = 1) => {
    reducerDispatch({ type: SET_CURRENT_PAGE, payload: page });
  };

  const changeItemsPerPageHandler = (e) => {
    reducerDispatch({
      type: SET_ITEMS_PER_PAGE,
      payload: parseInt(e.target.value),
    });
  };

  const navigationHandler = (id, document) => {
    let type;
    document.length === 11 ? (type = 1) : (type = 2);

    navigate(`/contas/detalhes/${id}?type=${type}`);
  };

  return (
    <Layout title={"Logs"}>
      <Datepicker
        configs={{
          shortcuts: {
            today: "Hoje",
            yesterday: "Ontem",
            past: (period) => `Últimos ${period} dias`,
            currentMonth: "Este mês",
            pastMonth: "Último mês",
          },
        }}
        i18n={"pt"}
        placeholder={"Selecione o intervalo"}
        separator={"-"}
        displayFormat={"DD/MM/YYYY"}
        toggleClassName="hidden"
        readOnly={true}
        showShortcuts={true}
        value={state.dateFilter}
        onChange={handleChangeDate}
        primaryColor={"indigo"}
        popoverDirection={"down"}
        inputClassName="w-full sm:w-48 text-center relative transition-all duration-300 py-2.5 px-2 border-gray-300 rounded-lg tracking-wide font-light text-sm placeholder-gray-400 bg-white focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-indigo-500 focus:ring-indigo-500/20"
      />
      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:mx-0 md:rounded-lg mt-6">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                ID User
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Usuário
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Data / Hora
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
              >
                Endpoint
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {logs?.itens?.length > 0 ? (
              logs.itens.map((log) => (
                <tr key={log.idDashboardAPILog}>
                  <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                    <button
                      onClick={() =>
                        navigationHandler(log?.idClient, log?.documento)
                      }
                      className="text-indigo-600 hover:text-indigo-500 text-left font-medium"
                    >
                      {log.idClient}
                      <span className="sr-only">,{log?.idClient}</span>
                    </button>

                    <dl className="font-normal lg:hidden">
                      <dt className="sr-only">Usuário</dt>
                      <dd className="mt-1 truncate text-gray-700 flex flex-col">
                        <span className="text-gray-900 font-medium block">
                          {log.nome}
                        </span>
                        <span>{renderDocument(log.documento)}</span>
                      </dd>
                      <dt className="sr-only">Saldo inicial</dt>
                      <dd className="mt-1 truncate text-gray-700">
                        Data / Hora - {formatDate(log.dateStart)}
                      </dd>
                      <dt className="sr-only md:hidden">Endpoint</dt>
                      <dd className="mt-1 truncate text-gray-700 md:hidden">
                        {log.descricao}
                      </dd>
                    </dl>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                    <span className="text-gray-900 font-medium block">
                      {log.nome}
                    </span>
                    <span>{renderDocument(log.documento)}</span>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                    {formatDate(log.dateStart)}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                    {log.descricao}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={4}
                  className="text-sm sm:text-base text-gray-500 text-center py-5"
                >
                  Não há dados para exibir
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination
          total={logs.total}
          pageItens={state.itemsPerPage}
          pageCount={Math.ceil(logs.total / state.itemsPerPage)}
          currentPage={state.currentPage}
          onNextPage={handleNextPage}
          onPreviousPage={handlePreviousPage}
          onClick={changeCurrentPage}
          itemsPerPage={state.itemsPerPage}
          onChangeItemsPerPage={changeItemsPerPageHandler}
        />
      </div>
    </Layout>
  );
}
