import ClientePfForm from "./cliente-pf";
import ClientePjForm from "./cliente-pj";
import Transactions from "./transactions";
import Fees from "./fees";
import Limits from "./limits";
import PosList from "./pos";
import UserConciliation from "./conciliation";
import LoginHistory from "./login-history";
import ClientErrors from "./client-errors";

import { USER_FINANCEIRO, USER_ADMIN, ADMIN_ECOSYSTEM } from "utils/const";

const getNavigationMenu = (accountType) => {
  return [
    {
      id: 1,
      name: "Cliente",
      current: true,
      active: true,
      protected: false,
      roles: [],
      component: accountType === 1 ? ClientePfForm : ClientePjForm,
    },
    {
      id: 6,
      name: "Conciliação",
      current: false,
      active: true,
      protected: true,
      roles: [USER_FINANCEIRO, USER_ADMIN, ADMIN_ECOSYSTEM],
      component: UserConciliation,
    },
    {
      id: 3,
      name: "Transações",
      current: false,
      active: true,
      protected: true,
      roles: [USER_FINANCEIRO, USER_ADMIN, ADMIN_ECOSYSTEM],
      component: Transactions,
    },
    {
      id: 4,
      name: "Tarifas",
      current: false,
      active: true,
      protected: false,
      roles: [],
      component: Fees,
    },
    {
      id: 5,
      name: "Limites",
      current: false,
      active: true,
      protected: true,
      roles: [USER_ADMIN, ADMIN_ECOSYSTEM],
      component: Limits,
    },
    {
      id: 7,
      name: "POS",
      current: false,
      active: process.env.REACT_APP_MODE === "CORE" ? true : false,
      protected: true,
      roles: [USER_ADMIN, ADMIN_ECOSYSTEM],
      component: PosList,
    },
    {
      id: 8,
      name: "Histórico de Login",
      current: false,
      active: true,
      protected: false,
      roles: [],
      component: LoginHistory,
    },
    {
      id: 9,
      name: "Erros",
      current: false,
      active: process.env.REACT_APP_MODE === "CORE" ? true : false,
      protected: false,
      roles: [],
      component: ClientErrors,
    },
  ];
};
export default getNavigationMenu;
