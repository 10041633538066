import { createSlice } from "@reduxjs/toolkit";

import {
  fetchPixSent,
  fetchPixReceivid,
  fetchPixReturned,
  fetchPayment,
  fetchInternalTransferSent,
  fetchInternalTransferReceivid
} from "./voucherThunk";

const initialState = {
  success: false,
  error: false,
  voucher: null
};

export const voucherSlice = createSlice({
  name: "emailer",
  initialState,
  reducers: {
    setVoucher: (state, action) => {
      return { ...state, voucher: action.payload };
    },
  },
  extraReducers: {
    [fetchPixSent.pending]: (state) => {
      return { ...state, error: false, success: false, voucher: null };
    },
    [fetchPixSent.rejected]: (state) => {
      return { ...state, error: true, success: false, voucher: null };
    },
    [fetchPixSent.fulfilled]: (state, action) => {
      return { ...state, error: false, success: true, voucher: action.payload };
    },
    [fetchPixReceivid.pending]: (state) => {
      return { ...state, error: false, success: false, voucher: null };
    },
    [fetchPixReceivid.rejected]: (state) => {
      return { ...state, error: true, success: false, voucher: null };
    },
    [fetchPixReceivid.fulfilled]: (state, action) => {
      return { ...state, error: false, success: true, voucher: action.payload };
    },
    [fetchPixReturned.pending]: (state) => {
      return { ...state, error: false, success: false, voucher: null };
    },
    [fetchPixReturned.rejected]: (state) => {
      return { ...state, error: true, success: false, voucher: null };
    },
    [fetchPixReturned.fulfilled]: (state, action) => {
      return { ...state, error: false, success: true, voucher: action.payload };
    },
    [fetchPayment.pending]: (state) => {
      return { ...state, error: false, success: false, voucher: null };
    },
    [fetchPayment.rejected]: (state) => {
      return { ...state, error: true, success: false, voucher: null };
    },
    [fetchPayment.fulfilled]: (state, action) => {
      return { ...state, error: false, success: true, voucher: action.payload };
    },
    [fetchInternalTransferSent.pending]: (state) => {
      return { ...state, error: false, success: false, voucher: null };
    },
    [fetchInternalTransferSent.rejected]: (state) => {
      return { ...state, error: true, success: false, voucher: null };
    },
    [fetchInternalTransferSent.fulfilled]: (state, action) => {
      return { ...state, error: false, success: true, voucher: action.payload };
    },
    [fetchInternalTransferReceivid.pending]: (state) => {
      return { ...state, error: false, success: false, voucher: null };
    },
    [fetchInternalTransferReceivid.rejected]: (state) => {
      return { ...state, error: true, success: false, voucher: null };
    },
    [fetchInternalTransferReceivid.fulfilled]: (state, action) => {
      return { ...state, error: false, success: true, voucher: action.payload };
    },
  },
});

export const { setVoucher } = voucherSlice.actions;

export default voucherSlice.reducer;
