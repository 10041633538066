import axiosClient from "./apiClient";

export function getMetrics() {
  return axiosClient.get(`${process.env.REACT_APP_API_DASH}/metricas/bank`);
}

export function getRentability() {
  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/metricas/bank/rentabilidade`
  );
}

export function getTransactionalProduct() {
  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/metricas/bank/transacoes/produto`
  );
}
