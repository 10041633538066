import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import Layout from "components/layout";
import Entrada from "assets/entrada.svg";
import Saida from "assets/saida.svg";
import Hold from "assets/hold.svg";
import ConfirmationModal from "components/modal-confirmation";
import { getDescription } from "utils/transactionType";
import {
  deletePreTransactionRequest,
  approvePreTransaction,
} from "reducers/accounts/accountsThunk";
import {
  formatWithTimezone,
  convertCurrencyBRLToString,
  renderDocument,
} from "utils/mask";

export default function PendingTransactions() {
  const dispatch = useDispatch();
  const { preTransactionList } = useSelector((store) => store.accounts);

  const [idAccountPreTransaction, setIdAccountPreTransaction] = useState(false);
  const [approveRequestModal, setApproveRequestModal] = useState(false);
  const [deleteRequestModal, setDeleteRequestModal] = useState(false);

  const navigationHandler = (id, document) => {
    let type = 1;
    document.length === 14 ? (type = 2) : (type = 1);

    return `/contas/detalhes/${id}?type=${type}`;
  };

  const openApproveRequestModal = (id) => {
    setIdAccountPreTransaction(id);
    setApproveRequestModal(true);
  };

  const approveRequestHandler = () => {
    dispatch(approvePreTransaction(idAccountPreTransaction));
    closeApproveRequestModal();
  };

  const closeApproveRequestModal = () => {
    setApproveRequestModal(false);
  };

  const openDeleteRequestModal = (id) => {
    setIdAccountPreTransaction(id);
    setDeleteRequestModal(true);
  };

  const deleteRequestHandler = () => {
    dispatch(deletePreTransactionRequest(idAccountPreTransaction));
    closeDeleteModalHandler();
  };

  const closeDeleteModalHandler = () => {
    setDeleteRequestModal(false);
  };

  return (
    <Layout title={"Transações pendentes"}>
      <ConfirmationModal
        title={"Deseja excluir a solicitação?"}
        description={"Sua ação não poderá ser desfeita. Deseja prosseguir?"}
        confirmButtonLabel={"Continuar"}
        onConfirm={deleteRequestHandler}
        cancelButtonLabel={"Cancelar"}
        onCancel={closeDeleteModalHandler}
        isModalOpen={deleteRequestModal}
      />
      <ConfirmationModal
        title={"Deseja aprovar a solicitação?"}
        description={"Sua ação não poderá ser desfeita. Deseja prosseguir?"}
        confirmButtonLabel={"Continuar"}
        onConfirm={approveRequestHandler}
        cancelButtonLabel={"Cancelar"}
        onCancel={closeApproveRequestModal}
        isModalOpen={approveRequestModal}
      />
      <div className="mt-4 sm:mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:mx-0 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:table-cell whitespace-nowrap"
              >
                Tipo transação
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Cliente
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Banco
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell whitespace-nowrap"
              >
                Data solicitação
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Valor
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Detalhes</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {preTransactionList && preTransactionList.length > 0 ? (
              preTransactionList.map((item) => (
                <tr key={item.idAccountPreTransaction}>
                  <td className="hidden pl-4 pr-3 py-4 sm:pl-6 text-sm text-gray-500 lg:table-cell">
                    <div className="flex flex-row shrink-0 items-center gap-1">
                      {getDescription(item.idTransaction, item.tipoTransacao)}
                      <img
                        className="h-4 w-auto"
                        src={
                          item.tipoEntrada === "C"
                            ? Entrada
                            : item.tipoEntrada === "D"
                            ? Saida
                            : Hold
                        }
                        alt="Entrada/Saida"
                      />
                    </div>
                  </td>
                  <td className="w-full max-w-0 py-4 px-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none">
                    <span>{item.nomeClient}</span>
                    <dl className="font-normal text-gray-500">
                      <dt className="sr-only">Documento</dt>
                      <dd className="mt-1 truncate">
                        <Link
                          className="text-indigo-600 hover:text-indigo-500 text-left font-medium"
                          to={navigationHandler(item.idClient, item.documentoClient)}
                          target="_blank"
                        >
                          {renderDocument(item.documentoClient)}
                        </Link>
                      </dd>
                      <dt className="sr-only lg:hidden">Banco</dt>
                      <dd className="mt-1 truncate lg:hidden">
                        {item.nomeBanco}
                      </dd>
                      <dt className="sr-only lg:hidden">Banco</dt>
                      <dd className="mt-1 truncate lg:hidden">
                        <div className="flex flex-row shrink-0 items-center gap-1">
                          {getDescription(
                            item.idTransaction,
                            item.tipoTransacao
                          )}
                          <img
                            className="h-4 w-auto"
                            src={
                              item.tipoEntrada === "C"
                                ? Entrada
                                : item.tipoEntrada === "D"
                                ? Saida
                                : Hold
                            }
                            alt="Entrada/Saida"
                          />
                        </div>
                      </dd>
                      <dt className="sr-only md:hidden">Data Solicitação</dt>
                      <dd className="mt-1 truncate md:hidden">
                        Solicitado em: {formatWithTimezone(item.data)}
                      </dd>
                      <dt className="sr-only sm:hidden">Valor da transação</dt>
                      <dd className="mt-1 truncate sm:hidden">
                        Valor: R$ {convertCurrencyBRLToString(item.valor)}
                      </dd>
                    </dl>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell whitespace-nowrap">
                    {item.nomeBanco}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell whitespace-nowrap">
                    {formatWithTimezone(item.data)}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell whitespace-nowrap">
                    R$ {convertCurrencyBRLToString(item.valor)}
                  </td>
                  <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 whitespace-nowrap">
                    <div className="flex flex-col justify-start">
                      <button
                        onClick={() =>
                          openApproveRequestModal(item.idAccountPreTransaction)
                        }
                        className="text-indigo-600 hover:text-indigo-500 text-left"
                      >
                        Aprovar
                        <span className="sr-only">, {item.idClient}</span>
                      </button>
                      <button
                        onClick={() =>
                          openDeleteRequestModal(item.idAccountPreTransaction)
                        }
                        className={"text-red-600 hover:text-red-500 text-left"}
                      >
                        Excluir
                        <span className="sr-only">, {item.idClient}</span>
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={6}
                  className="text-sm sm:text-base text-gray-500 text-center py-5"
                >
                  Não há dados para exibir
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Layout>
  );
}
