import React from "react";

import SvgIcon from "components/svg-icon";
import Pix from "assets/icons/pix.svg";
import InternalTransfer from "assets/icons/internal_transfer.svg";
import Fallback from "assets/icons/fallback.svg";
import BillingPayment from "assets/icons/billing_payment.svg";
import Boleto from "assets/icons/boleto.svg";
import Card from "assets/icons/card.svg";
import CellCredit from "assets/icons/cell_credit.svg";
import Fee from "assets/icons/fee.svg";
import GatewayBlock from "assets/icons/gateway_block.svg";
import Gateway from "assets/icons/gateway.svg";
import ServiceCredit from "assets/icons/service_credit.svg";
import Transfer from "assets/icons/transfer.svg";
import Revenue from "assets/icons/revenue.svg";

export const extractIcons = [
  {
    idTransaction: 1,
    icon: <SvgIcon icon={Transfer} alt={"Transferência enviada"} />,
    color: "#FF5733", // Laranja
  },
  {
    idTransaction: 2,
    icon: <SvgIcon icon={Transfer} alt={"Transferência recebida"} />,
    color: "#33FF57", // Verde claro
  },
  {
    idTransaction: 3,
    icon: <SvgIcon icon={Boleto} alt={"Boleto gerado"} />,
    color: "#3357FF", // Azul escuro
  },
  {
    idTransaction: 4,
    icon: <SvgIcon icon={Boleto} alt={"Boleto compensado"} />,
    color: "#FF33A1", // Rosa
  },
  {
    idTransaction: 5,
    icon: <SvgIcon icon={CellCredit} alt={"Recarga celular"} />,
    color: "#FFBD33", // Amarelo
  },
  {
    idTransaction: 6,
    icon: <SvgIcon icon={ServiceCredit} alt={"Recarga serviços digitais"} />,
    color: "#33FFBD", // Verde-água
  },
  {
    idTransaction: 7,
    icon: <SvgIcon icon={Pix} alt={"Pix enviado"} />,
    color: "#BD33FF", // Roxo
  },
  {
    idTransaction: 8,
    icon: <SvgIcon icon={Pix} alt={"Pix recebido"} />,
    color: "#33A1FF", // Azul claro
  },
  {
    idTransaction: 9,
    icon: <SvgIcon icon={Fee} alt={"Manutenção da conta"} />,
    color: "#FF3333", // Vermelho
  },
  {
    idTransaction: 10,
    icon: <SvgIcon icon={Fee} alt={"Manutenção da conta PJ"} />,
    color: "#33FF33", // Verde
  },
  {
    idTransaction: 11,
    icon: <SvgIcon icon={Transfer} alt={"Estorno transferência"} />,
    color: "#5733FF", // Azul profundo
  },
  {
    idTransaction: 12,
    icon: <SvgIcon icon={Pix} alt={"Pix devolvido"} />,
    color: "#FF33FF", // Magenta
  },
  {
    idTransaction: 13,
    icon: <SvgIcon icon={Pix} alt={"Pix PJ enviado"} />,
    color: "#FFA533", // Laranja claro
  },
  {
    idTransaction: 14,
    icon: <SvgIcon icon={Pix} alt={"Pix PJ recebido"} />,
    color: "#33FFA5", // Verde-menta
  },
  {
    idTransaction: 15,
    icon: <SvgIcon icon={GatewayBlock} alt={"Atualizar bloqueio Gateway"} />,
    color: "#57FF33", // Verde fluorescente
  },
  {
    idTransaction: 16,
    icon: <SvgIcon icon={GatewayBlock} alt={"Cancelar pagamento Gateway"} />,
    color: "#FF33BD", // Rosa chiclete
  },
  {
    idTransaction: 17,
    icon: <SvgIcon icon={Gateway} alt={"Capturar pagamento Gateway"} />,
    color: "#337AFF", // Azul forte
  },
  {
    idTransaction: 18,
    icon: <SvgIcon icon={Gateway} alt={"Criar cofre Gateway"} />,
    color: "#FF5733", // Vermelho queimado
  },
  {
    idTransaction: 19,
    icon: <SvgIcon icon={Gateway} alt={"Desbloquear pagamento Gateway"} />,
    color: "#33FF77", // Verde esmeralda
  },
  {
    idTransaction: 20,
    icon: <SvgIcon icon={Gateway} alt={"Iniciar pagamento Gateway"} />,
    color: "#BDFF33", // Amarelo esverdeado
  },
  {
    idTransaction: 21,
    icon: <SvgIcon icon={Gateway} alt={"Info pagamento Gateway"} />,
    color: "#5733BD", // Roxo escuro
  },
  {
    idTransaction: 22,
    icon: <SvgIcon icon={Gateway} alt={"Tokenizar cartão Gateway"} />,
    color: "#FF7733", // Laranja médio
  },
  {
    idTransaction: 23,
    icon: <SvgIcon icon={BillingPayment} alt={"Pagamento de conta"} />,
    color: "#77FF33", // Verde vivo
  },
  {
    idTransaction: 24,
    icon: <SvgIcon icon={InternalTransfer} alt={"Transferência Interna"} />,
    color: "#FF33DC", // Rosa vibrante
  },
  {
    idTransaction: 25,
    icon: <SvgIcon icon={Fee} alt={"Recebimento tarifa"} />,
    color: "#337BFF", // Azul oceano
  },
  {
    idTransaction: 26,
    icon: <SvgIcon icon={Fee} alt={"Cobrança de tarifa"} />,
    color: "#33FFD1", // Verde água claro
  },
  {
    idTransaction: 27,
    icon: <SvgIcon icon={Card} alt={"Cartão débito"} />,
    color: "#FF333F", // Vermelho intenso
  },
  {
    idTransaction: 28,
    icon: <SvgIcon icon={Card} alt={"Estorno cartão débito"} />,
    color: "#33A7FF", // Azul céu
  },
  {
    idTransaction: 29,
    icon: <SvgIcon icon={Card} alt={"Recebimento cartão crédito - físico"} />,
    color: "#FFEB33", // Amarelo intenso
  },
  {
    idTransaction: 30,
    icon: <SvgIcon icon={Card} alt={"Recebimento cartão débito - físico"} />,
    color: "#33FF77", // Verde intenso
  },
  {
    idTransaction: 31,
    icon: <SvgIcon icon={Card} alt={"Recebimento cartão crédito - virtual"} />,
    color: "#B3FF33", // Amarelo limão
  },
  {
    idTransaction: 32,
    icon: <SvgIcon icon={Card} alt={"Recebimento cartão débito - virtual"} />,
    color: "#33B7FF", // Azul piscina
  },
  {
    idTransaction: 33,
    icon: <SvgIcon icon={Card} alt={"Desconto taxa de franquia"} />,
    color: "#FF9933", // Laranja queimado
  },
  {
    idTransaction: 34,
    icon: <SvgIcon icon={Card} alt={"Cartão crédito"} />,
    color: "#D433FF", // Roxo vibrante
  },
  {
    idTransaction: 35,
    icon: <SvgIcon icon={Card} alt={"Estorno cartão crédito"} />,
    color: "#33D4FF", // Azul vibrante
  },
  {
    idTransaction: 36,
    icon: <SvgIcon icon={Revenue} alt={"Receita de antecipação"} />,
    color: "#FF338F", // Rosa magenta
  },
  {
    idTransaction: 37,
    icon: <SvgIcon icon={Fee} alt={"Estorno recebimento tarifa"} />,
    color: "#FF333F", // Vermelho carmim
  },
  {
    idTransaction: 38,
    icon: <SvgIcon icon={Fee} alt={"Estorno cobrança tarifa"} />,
    color: "#33FF8F", // Verde elétrico
  },
  {
    idTransaction: 39,
    icon: <SvgIcon icon={Fee} alt={"Estorno multa contratual"} />,
    color: "#3377FF", // Azul cobalto
  },
  {
    idTransaction: 40,
    icon: <SvgIcon icon={Fee} alt={"Multa contratual"} />,
    color: "#FF3377", // Vermelho rosado
  },
  {
    idTransaction: 41,
    icon: <SvgIcon icon={Revenue} alt={"Rendimentos"} />,
    color: "#FFEB33", // Amarelo vivo
  },
  {
    idTransaction: 42,
    icon: <SvgIcon icon={BillingPayment} alt={"Estorno pagamento de conta"} />,
    color: "#33FFA3", // Verde limão
  },
];

const renderTransactionIcon = (idTransaction, optionalProps = {}) => {
  const icon = extractIcons.find(
    (icon) => icon.idTransaction === idTransaction
  );

  if (icon) {
    return React.cloneElement(icon.icon, optionalProps);
  }

  return <SvgIcon icon={Fallback} alt={"Transação"} {...optionalProps} />;
};

export default renderTransactionIcon;
