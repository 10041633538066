export const tipoEmpresaArr = [
  {
    id: "MEI",
    name: "MEI",
  },
  {
    id: "EIRELI",
    name: "EIRELI",
  },
  {
    id: "Empresário Individual",
    name: "Empresário Individual",
  },
  {
    id: "Sociedade Empresária Limitada",
    name: "Sociedade Empresária Limitada",
  },
  {
    id: "Sociedade Simples",
    name: "Sociedade Simples",
  },
  {
    id: "Sociedade Anônima",
    name: "Sociedade Anônima",
  },
  {
    id: "Sociedade Limitada Unipessoal",
    name: "Sociedade Limitada Unipessoal",
  },
];

export const naturezaLegalArr = [
  {
    id: "101-5",
    name: "101-5 - Órgão Público do Poder Executivo Federal",
  },
  {
    id: "102-3",
    name: "102-3 - Órgão Público do Poder Executivo Estadual ou do Distrito Federal",
  },
  {
    id: "103-1",
    name: "103-1 - Órgão Público do Poder Executivo Municipal",
  },
  {
    id: "104-0",
    name: "104-0 - Órgão Público do Poder Legislativo Federal",
  },
  {
    id: "105-8",
    name: "105-8 - Órgão Público do Poder Legislativo Estadual ou do Distrito Federal",
  },
  {
    id: "106-6",
    name: "106-6 - Órgão Público do Poder Legislativo Municipal",
  },
  {
    id: "107-4",
    name: "107-4 - Órgão Público do Poder Judiciário Federal ",
  },
  {
    id: "108-2",
    name: "108-2 - Órgão Público do Poder Judiciário Estadual",
  },
  {
    id: "110-4",
    name: "110-4 - Autarquia Federal",
  },
  {
    id: "111-2",
    name: "111-2 - Autarquia Estadual ou do Distrito Federal",
  },
  {
    id: "112-0",
    name: "112-0 - Autarquia Municipal ",
  },
  {
    id: "113-9",
    name: "113-9 - Fundação Pública de Direito Público Federal",
  },
  {
    id: "114-7",
    name: "114-7 - Fundação Pública de Direito Público Estadual ou do Distrito Federal",
  },
  {
    id: "115-5",
    name: "115-5 - Fundação Pública de Direito Público Municipal",
  },
  {
    id: "116-3",
    name: "116-3 - Órgão Público Autônomo Federal",
  },
  {
    id: "117-1",
    name: "117-1 - Órgão Público Autônomo Estadual ou do Distrito Federal",
  },
  {
    id: "118-0",
    name: "118-0 - Órgão Público Autônomo Municipal",
  },
  {
    id: "119-8",
    name: "119-8 - Comissão Polinacional",
  },
  {
    id: "121-0",
    name: "121-0 - Consórcio Público de Direito Público (Associação Pública)",
  },
  {
    id: "122-8",
    name: "122-8 - Consórcio Público de Direito Privado",
  },
  {
    id: "123-6",
    name: "123-6 - Estado ou Distrito Federal",
  },
  {
    id: "124-4",
    name: "124-4 - Município",
  },
  {
    id: "125-2",
    name: "125-2 - Fundação Pública de Direito Privado Federal",
  },
  {
    id: "126-0",
    name: "126-0 - Fundação Pública de Direito Privado Estadual ou do Distrito Federal",
  },
  {
    id: "127-9",
    name: "127-9 - Fundação Pública de Direito Privado Municipal",
  },
  {
    id: "128-7",
    name: "128-7 - Fundo Público da Administração Indireta Federal",
  },
  {
    id: "129-5",
    name: "129-5 - Fundo Público da Administração Indireta Estadual ou do Distrito Federal",
  },
  {
    id: "130-9",
    name: "130-9 - Fundo Público da Administração Indireta Municipal",
  },
  {
    id: "131-7",
    name: "131-7 - Fundo Público da Administração Direta Federal",
  },
  {
    id: "132-5",
    name: "132-5 - Fundo Público da Administração Direta Estadual ou do Distrito Federal",
  },
  {
    id: "133-3",
    name: "133-3 - Fundo Público da Administração Direta Municipal",
  },
  {
    id: "134-1",
    name: "134-1 - União",
  },
  {
    id: "201-1",
    name: "201-1 - Empresa Pública",
  },
  {
    id: "203-8",
    name: "203-8 - Sociedade de Economia Mista",
  },
  {
    id: "204-6",
    name: "204-6 - Sociedade Anônima Aberta",
  },
  {
    id: "205-4",
    name: "205-4 - Sociedade Anônima Fechada",
  },
  {
    id: "206-2",
    name: "206-2 - Sociedade Empresária Limitada",
  },
  {
    id: "207-0",
    name: "207-0 - Sociedade Empresária em Nome Coletivo",
  },
  {
    id: "208-9",
    name: "208-9 - Sociedade Empresária em Comandita Simples",
  },
  {
    id: "209-7",
    name: "209-7 - Sociedade Empresária em Comandita por Ações",
  },
  {
    id: "212-7",
    name: "212-7 - Sociedade em Conta de Participação",
  },
  {
    id: "213-5",
    name: "213-5 - Empresário (Individual)",
  },
  {
    id: "214-3",
    name: "214-3 - Cooperativa",
  },
  {
    id: "215-1",
    name: "215-1 - Consórcio de Sociedades",
  },
  {
    id: "216-0",
    name: "216-0 - Grupo de Sociedades",
  },
  {
    id: "217-8",
    name: "217-8 - Estabelecimento, no Brasil, de Sociedade Estrangeira",
  },
  {
    id: "219-4",
    name: "219-4 - Estabelecimento, no Brasil, de Empresa Binacional Argentino-Brasileira",
  },
  {
    id: "221-6",
    name: "221-6 - Empresa Domiciliada no Exterior",
  },
  {
    id: "222-4",
    name: "222-4 - Clube/Fundo de Investimento",
  },
  {
    id: "223-2",
    name: "223-2 - Sociedade Simples Pura",
  },
  {
    id: "224-0",
    name: "224-0 - Sociedade Simples Limitada",
  },
  {
    id: "225-9",
    name: "225-9 - Sociedade Simples em Nome Coletivo",
  },
  {
    id: "226-7",
    name: "226-7 - Sociedade Simples em Comandita Simples",
  },
  {
    id: "227-5",
    name: "227-5 - Empresa Binacional",
  },
  {
    id: "228-3",
    name: "228-3 - Consórcio de Empregadores",
  },
  {
    id: "229-1",
    name: "229-1 - Consórcio Simples",
  },
  {
    id: "230-5",
    name: "230-5 - Empresa Individual de Responsabilidade Limitada (de Natureza Empresária)",
  },
  {
    id: "231-3",
    name: "231-3 - Empresa Individual de Responsabilidade Limitada (de Natureza Simples)",
  },
  {
    id: "232-1",
    name: "232-1 - Sociedade Unipessoal de Advogados",
  },
  {
    id: "233-0",
    name: "233-0 - Cooperativas de Consumo",
  },
  {
    id: "234-8",
    name: "234-8 - Empresa Simples de Inovação - Inova Simples",
  },
  {
    id: "235-6",
    name: "235-6 - Investidor Não Residente",
  },
  {
    id: "303-4",
    name: "303-4 - Serviço Notarial e Registral (Cartório)",
  },
  {
    id: "306-9",
    name: "306-9 - Fundação Privada",
  },
  {
    id: "307-7",
    name: "307-7 - Serviço Social Autônomo",
  },
  {
    id: "308-5",
    name: "308-5 - Condomínio Edilício",
  },
  {
    id: "310-7",
    name: "310-7 - Comissão de Conciliação Prévia",
  },
  {
    id: "311-5",
    name: "311-5 - Entidade de Mediação e Arbitragem",
  },
  {
    id: "313-1",
    name: "313-1 - Entidade Sindical",
  },
  {
    id: "320-4",
    name: "320-4 - Estabelecimento, no Brasil, de Fundação ou Associação Estrangeiras",
  },
  {
    id: "321-2",
    name: "321-2 - Fundação ou Associação Domiciliada no Exterior",
  },
  {
    id: "322-0",
    name: "322-0 - Organização Religiosa ",
  },
  {
    id: "323-9",
    name: "323-9 - Comunidade Indígena ",
  },
  {
    id: "324-7",
    name: "324-7 - Fundo Privado ",
  },
  {
    id: "325-5",
    name: "325-5 - Órgão de Direção Nacional de Partido Político",
  },
  {
    id: "326-3",
    name: "326-3 - Órgão de Direção Regional de Partido Político",
  },
  {
    id: "327-1",
    name: "327-1 - Órgão de Direção Local de Partido Político",
  },
  {
    id: "328-0",
    name: "328-0 - Comitê Financeiro de Partido Político",
  },
  {
    id: "329-8",
    name: "329-8 - Frente Plebiscitária ou Referendária",
  },
  {
    id: "330-1",
    name: "330-1 - Organização Social (OS)",
  },
  {
    id: "331-0",
    name: "331-0 - Demais Condomínios",
  },
  {
    id: "332-8",
    name: "332-8 - Plano de Benefícios de Previdência Complementar Fechada",
  },
  {
    id: "399-9",
    name: "399-9 - Associação Privada",
  },
  {
    id: "401-4",
    name: "401-4 - Empresa Individual Imobiliária",
  },
  {
    id: "402-2",
    name: "402-2 - Segurado Especial",
  },
  {
    id: "408-1",
    name: "408-1 - Contribuinte individual",
  },
  {
    id: "409-0",
    name: "409-0 - Candidato a Cargo Político Eletivo",
  },
  {
    id: "411-1",
    name: "411-1 - Leiloeiro ",
  },
  {
    id: "412-0",
    name: "412-0 - Produtor Rural (Pessoa Física)",
  },
  {
    id: "501-0",
    name: "501-0 - Organização Internacional",
  },
  {
    id: "502-9",
    name: "502-9 - Representação Diplomática Estrangeira",
  },
  {
    id: "503-7",
    name: "503-7 - Outras Instituições Extraterritoriais",
  },
];
