import { createAsyncThunk } from "@reduxjs/toolkit";

import { setNotification } from "reducers/notification/notificationSlice";
import { setIsLoading } from "reducers/loading/isLoadingSlice";
import {
  getTransactionTypes,
  getPeriodTypes,
  getFees,
  getPeriodicFees,
  deleteFee,
  postFee,
  updateFee,
  deletePeriodicFee,
  postPeriodicFee,
  updatePeriodicFee,
  getGroupsFees,
  deleteGroupFee,
  postGroupFee,
  updateGroupFee,
  getGroupsItems,
  getClientFees,
  postGroupItems,
  patchFeeToGroupItems,
  patchPeriodFeeToGroupItems,
  updateFeeToGroupItems,
  getClientAvailableGroups,
  deleteClientFeeGroup,
  updateClientFeeGroup,
  deleteFeeFromGroup,
  deletePeriodicFeeFromGroup,
} from "services/fees";

export const fetchTransactionTypes = createAsyncThunk(
  "fees/fetchTransactionTypes",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getTransactionTypes();

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue([]);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchPeriodTypes = createAsyncThunk(
  "fees/fetchPeriodTypes",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getPeriodTypes();

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue([]);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchFees = createAsyncThunk(
  "fees/fetchFees",
  async (currentPage, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getFees(currentPage);

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue({ tarifas: [], total: 0 });
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const excludeFee = createAsyncThunk(
  "fees/excludeFee",
  async (idFee, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await deleteFee(idFee);

      thunkAPI.dispatch(fetchFees(1));

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const createFee = createAsyncThunk(
  "fees/createFee",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await postFee(payload);

      thunkAPI.dispatch(fetchFees(1));

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const editFee = createAsyncThunk(
  "fees/editFee",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await updateFee(payload.idFee, payload.data);

      thunkAPI.dispatch(fetchFees(1));

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchPeriodicFees = createAsyncThunk(
  "fees/fetchPeriodicFees",
  async (currentPage, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getPeriodicFees(currentPage);

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue({ tarifas: [], total: 0 });
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const excludePeriodicFee = createAsyncThunk(
  "fees/excludePeriodicFee",
  async (idFee, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await deletePeriodicFee(idFee);

      thunkAPI.dispatch(fetchPeriodicFees(1));

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const createPeriodicFee = createAsyncThunk(
  "fees/createPeriodicFee",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await postPeriodicFee(payload);

      thunkAPI.dispatch(fetchPeriodicFees(1));

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const editPeriodicFee = createAsyncThunk(
  "fees/editPeriodicFee",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await updatePeriodicFee(
        payload.idPeriodicFee,
        payload.data
      );

      thunkAPI.dispatch(fetchPeriodicFees(1));

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchGroupsFees = createAsyncThunk(
  "fees/fetchGroupsFees",
  async (currentPage, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getGroupsFees(currentPage);

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue({ tarifaGrupos: [], total: 0 });
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const excludeGroupFee = createAsyncThunk(
  "fees/excludeGroupFee",
  async (idFeeGroup, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      await deleteGroupFee(idFeeGroup);

      thunkAPI.dispatch(fetchGroupsFees(1));
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const createGroupFee = createAsyncThunk(
  "fees/createGroupFee",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await postGroupFee(payload);

      thunkAPI.dispatch(fetchGroupsFees(1));

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const editGroupFee = createAsyncThunk(
  "fees/editGroupFee",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await updateGroupFee(payload.idGroupFee, payload.data);

      thunkAPI.dispatch(fetchGroupsFees(1));

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchGroupItems = createAsyncThunk(
  "fees/fetchGroupItems",
  async (idCustomerBank, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getGroupsItems(idCustomerBank);

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue({ tarifaGrupoTarifas: [], total: 0 });
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchClientFees = createAsyncThunk(
  "fees/fetchClientFees",
  async (idClient, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getClientFees(idClient);

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const createGroupItems = createAsyncThunk(
  "fees/createGroupItems",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await postGroupItems(payload);

      const idGroupItem = response.data.success.idFeeGroupFee;

      if (payload.feeType === "periodica") {
        await patchPeriodFeeToGroupItems(idGroupItem, payload.idFee);
      } else {
        await patchFeeToGroupItems(idGroupItem, payload.idFee);
      }

      thunkAPI.dispatch(fetchGroupItems(payload.idCustomerBank));

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const editGroupItems = createAsyncThunk(
  "fees/editGroupItems",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await updateFeeToGroupItems(payload.idFeeGroupFee, payload.data);

      thunkAPI.dispatch(fetchGroupItems(payload.idCustomerBank));

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchClientAvailableGroups = createAsyncThunk(
  "fees/fetchClientAvailableGroups",
  async (idClient, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getClientAvailableGroups(idClient);

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue([]);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const removeClientFeeGroup = createAsyncThunk(
  "fees/removeClientFeeGroup",
  async (idClient, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      await deleteClientFeeGroup(idClient);

      thunkAPI.dispatch(fetchClientFees(idClient));
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const patchClientFeeGroup = createAsyncThunk(
  "fees/patchClientFeeGroup",
  async ({ idClient, idFeeGroup }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      await updateClientFeeGroup(idClient, { idFeeGroup });

      thunkAPI.dispatch(fetchClientFees(idClient));
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const removeFeeFromGroup = createAsyncThunk(
  "fees/removeFeeFromGroup",
  async ({ idFeeGroupFee, idfee, idCustomerBank, isPeriodica }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      if (isPeriodica) {
        await deletePeriodicFeeFromGroup(idFeeGroupFee, idfee);
      } else {
        await deleteFeeFromGroup(idFeeGroupFee, idfee);
      }

      thunkAPI.dispatch(fetchGroupItems(idCustomerBank));
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);
