import { createSlice } from "@reduxjs/toolkit";

import {
  listClientLimits,
  newAccountLimit,
  fetchBankPixLimit,
  fetchPendingRequests,
  fetchHistoryRequests,
} from "./limitsThunk";

const initialState = {
  clientLimits: [],
  error: false,
  success: false,
  bankPixLimit: null,
  pendingRequests: [],
  historyList: [],
};

const limitsSlice = createSlice({
  name: "limits",
  initialState,
  extraReducers: {
    [listClientLimits.pending]: (state) => {
      return { ...state, clientLimits: [] };
    },
    [listClientLimits.fulfilled]: (state, action) => {
      return { ...state, clientLimits: action.payload };
    },
    [listClientLimits.rejected]: (state) => {
      return { ...state, clientLimits: [] };
    },
    [newAccountLimit.pending]: (state) => {
      return { ...state, error: false, success: false };
    },
    [newAccountLimit.fulfilled]: (state) => {
      return { ...state, error: false, success: true };
    },
    [newAccountLimit.rejected]: (state) => {
      return { ...state, error: true, success: false };
    },
    [fetchBankPixLimit.pending]: (state) => {
      return { ...state, bankPixLimit: null };
    },
    [fetchBankPixLimit.fulfilled]: (state, action) => {
      return { ...state, bankPixLimit: action.payload };
    },
    [fetchBankPixLimit.rejected]: (state) => {
      return { ...state, bankPixLimit: null };
    },
    [fetchPendingRequests.fulfilled]: (state, action) => {
      return { ...state, pendingRequests: action.payload };
    },
    [fetchHistoryRequests.fulfilled]: (state, action) => {
      return { ...state, historyList: action.payload };
    },
  },
});

export default limitsSlice.reducer;
