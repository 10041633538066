import timezone from "moment-timezone";

export const initialState = {
  currentPage: 1,
  dateFilter: {
    startDate: timezone().subtract(30, "d").format("YYYY-MM-DD"),
    endDate: timezone().format("YYYY-MM-DD"),
  },
  itemsPerPage: 30,
  idCustomerBank: "0",
};

export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_DATE_FILTER = "SET_DATE_FILTER";
export const SET_ITEMS_PER_PAGE = "SET_ITEMS_PER_PAGE";
export const SET_ID_CUSTOMER_BANK = "SET_ID_CUSTOMER_BANK";

export const reducer = (state, action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return { ...state, currentPage: action.payload };
    case SET_DATE_FILTER:
      return { ...state, dateFilter: action.payload, currentPage: 1 };
    case SET_ITEMS_PER_PAGE:
      return { ...state, itemsPerPage: action.payload, currentPage: 1 };
    case SET_ID_CUSTOMER_BANK:
      return { ...state, idCustomerBank: action.payload, currentPage: 1 };
    default:
      return state;
  }
};
