import { createAsyncThunk } from "@reduxjs/toolkit";

import { getUserInfo } from "services/user";
import { setNotification } from "reducers/notification/notificationSlice";

export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async (_, thunkAPI) => {
    try {
      const response = await getUserInfo();
      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    }
  }
);
