import Breadcrumb from "components/Breadcrumbs";
import classNames from "utils/classNames";

export default function Layout({ children, title, subTitle, breadcrumbPages }) {
  return (
    <>
      {process.env.REACT_APP_ENV === "QA" && (
        <div className="p-2 text-center text-sm bg-white border-b fixed w-full">
          <p className="text-red-600 font-medium">
            Você está usando o ambiente de QA! As mudanças aplicadas neste
            ambiente não serão refletidas no ambiente de produção!
          </p>
        </div>
      )}
      <div
        className={classNames(
          "py-10 w-full mx-auto px-4 sm:px-6 lg:px-8 print:p-0 print:m-0 print:bg-white",
          process.env.REACT_APP_ENV === "QA" && "mt-9"
        )}
      >
        {breadcrumbPages && (
          <div className="mb-6">
            <Breadcrumb pages={breadcrumbPages} />
          </div>
        )}
        <header className="print:hidden">
          <div>
            {title && (
              <h1 className="text-xl font-bold leading-tight text-gray-900">
                {title}
              </h1>
            )}
            {subTitle && (
              <div className="mt-2 text-sm text-gray-500">{subTitle}</div>
            )}
          </div>
        </header>
        <main>
          <div className="mt-4 sm:mt-6 print:m-0">{children}</div>
        </main>
      </div>
    </>
  );
}
