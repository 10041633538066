import { createSlice } from "@reduxjs/toolkit";

import {
  fetchTransactionTypes,
  fetchPeriodTypes,
  fetchFees,
  fetchPeriodicFees,
  fetchGroupsFees,
  fetchGroupItems,
  fetchClientFees,
  fetchClientAvailableGroups,
} from "./feesThunk";

const initialState = {
  clientFees: [],
  transactionTypes: [],
  periodTypes: [],
  feesList: { tarifas: [], total: 0 },
  periodicFeesList: { tarifas: [], total: 0 },
  groupsFeesList: { tarifaGrupos: [], total: 0 },
  groupItemsList: { tarifaGrupoTarifas: [], total: 0 },
  clientAvailableGroups: [],
};

const feesSlice = createSlice({
  name: "fees",
  initialState,
  extraReducers: {
    [fetchTransactionTypes.fulfilled]: (state, action) => {
      return { ...state, transactionTypes: action.payload };
    },
    [fetchTransactionTypes.rejected]: (state, action) => {
      return { ...state, transactionTypes: action.payload };
    },
    [fetchPeriodTypes.fulfilled]: (state, action) => {
      return { ...state, periodTypes: action.payload };
    },
    [fetchPeriodTypes.rejected]: (state, action) => {
      return { ...state, periodTypes: action.payload };
    },
    [fetchFees.fulfilled]: (state, action) => {
      return { ...state, feesList: action.payload };
    },
    [fetchFees.rejected]: (state, action) => {
      return { ...state, feesList: action.payload };
    },
    [fetchPeriodicFees.fulfilled]: (state, action) => {
      return { ...state, periodicFeesList: action.payload };
    },
    [fetchPeriodicFees.rejected]: (state, action) => {
      return { ...state, periodicFeesList: action.payload };
    },
    [fetchGroupsFees.fulfilled]: (state, action) => {
      return { ...state, groupsFeesList: action.payload };
    },
    [fetchGroupsFees.rejected]: (state, action) => {
      return { ...state, groupsFeesList: action.payload };
    },
    [fetchGroupItems.fulfilled]: (state, action) => {
      return { ...state, groupItemsList: action.payload };
    },
    [fetchGroupItems.rejected]: (state, action) => {
      return { ...state, groupItemsList: action.payload };
    },
    [fetchClientFees.pending]: (state) => {
      return { ...state, clientFees: [] };
    },
    [fetchClientFees.rejected]: (state) => {
      return { ...state, clientFees: [] };
    },
    [fetchClientFees.fulfilled]: (state, action) => {
      return { ...state, clientFees: action.payload };
    },
    [fetchClientAvailableGroups.pending]: (state) => {
      return { ...state, clientAvailableGroups: [] };
    },
    [fetchClientAvailableGroups.rejected]: (state) => {
      return { ...state, clientAvailableGroups: [] };
    },
    [fetchClientAvailableGroups.fulfilled]: (state, action) => {
      return { ...state, clientAvailableGroups: action.payload };
    },
  },
});

export default feesSlice.reducer;
