import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Modal from "components/modal";
import Select from "components/select";
import Input from "components/input";
import {
  convertCurrencyBRLToFloat,
  formatCurrency,
} from "utils/mask";
import { createGroupItems } from "reducers/fees/feesThunk";

const NewFee = ({ isModalOpen, onCancel, idCustomerBank }) => {
  const [payload, setPayload] = useState({});
  const [payloadError, setPayloadErros] = useState({});
  const [groupFeesOption, setGroupFeesOption] = useState([]);
  const [feesOption, setFeesOption] = useState([]);
  const [periodicFeesOption, setPeriodicFeesOption] = useState([]);
  const [valueFormatted, setValueFormatted] = useState();
  const [minValueFormatted, setMinValueFormatted] = useState("");
  const [percentualMaxFormatted, setPercentualMaxFormatted] = useState("");
  const { groupsFeesList, feesList, periodicFeesList } = useSelector(
    (store) => store.fees
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setGroupFeesOption(
      groupsFeesList.tarifaGrupos
        .filter(
          (groupFee) => groupFee.idCustomerBank === parseInt(idCustomerBank)
        )
        .map((transaction) => ({
          id: transaction.idFeeGroup,
          name: transaction.nomeGrupoTarifa,
        }))
    );
  }, [groupsFeesList, idCustomerBank]);

  useEffect(() => {
    setFeesOption(
      feesList.tarifas.map((transaction) => ({
        id: transaction.idFee,
        name: transaction.nome,
      }))
    );
  }, [feesList]);

  useEffect(() => {
    setPeriodicFeesOption(
      periodicFeesList.tarifas.map((transaction) => ({
        id: transaction.idFeePeriodic,
        name: transaction.nome,
      }))
    );
  }, [periodicFeesList]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setPayload((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const submitFeeHandler = () => {
    if (checkPayload(payload)) {
      dispatch(
        createGroupItems({
          ...payload,
          idCustomerBank: parseInt(idCustomerBank),
          idFeeGroup: parseInt(payload.idFeeGroup),
          idFee: parseInt(payload.idFee),
          quantidadeMinimaMensalParaCobranca:
            parseInt(payload.quantidadeMinimaMensalParaCobranca) || 0,
          quantidadeMaximaMensalParaCobranca:
            parseInt(payload.quantidadeMaximaMensalParaCobranca) || 0,
          quantidadeDiasAtrasoTarifaPeriodica:
            payload.feeType === "periodica"
              ? parseInt(payload.quantidadeDiasAtrasoTarifaPeriodica) || 0
              : 0,
          valorMinimoParaCobranca: convertCurrencyBRLToFloat(minValueFormatted),
          valor:
            payload.feeChargeType === "currency"
              ? convertCurrencyBRLToFloat(valueFormatted)
              : 0,
          percentual:
            payload.feeChargeType === "percentual"
              ? convertCurrencyBRLToFloat(valueFormatted)
              : 0,
          valorMaximoParaCobrancaPorPercentual:
            payload.feeChargeType === "percentual"
              ? convertCurrencyBRLToFloat(percentualMaxFormatted)
              : 0,
        })
      );

      cleanUpPayload();
    }
  };

  const cancelRequesHandler = () => {
    cleanUpPayload();
  };

  const cleanUpPayload = () => {
    setPayload({});
    setPayloadErros({});
    setValueFormatted("");
    setMinValueFormatted("");
    setPercentualMaxFormatted("");
    onCancel();
  };

  function checkPayload(values) {
    const errors = {};

    if (!values.idFeeGroup) {
      errors.idFeeGroup = "Grupo de tarifas deve ser informado!";
    }

    if (!values.feeType) {
      errors.feeType = "Tipo de tarifa deve ser informado!";
    }

    if (!values.idFee) {
      errors.idFee = "Tarifa deve ser informada!";
    }

    if (!values.feeChargeType) {
      errors.feeChargeType = "Tipo de cobrança deve ser informado!";
    }

    if (!valueFormatted || valueFormatted === "0,00") {
      errors.valor = "Valor da tarifa deve ser informado!";
    }

    if (values.feeChargeType === "percentual" && !percentualMaxFormatted) {
      errors.valorMaximoParaCobrancaPorPercentual =
        "Valor máximo deve ser informado!";
    }

    setPayloadErros(errors);

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <Modal
      confirmButtonLabel={"Cadastrar"}
      onConfirm={submitFeeHandler}
      cancelButtonLabel={"Cancelar"}
      onCancel={cancelRequesHandler}
      isModalOpen={isModalOpen}
    >
      <div className="mt-5 text-left flex flex-col gap-5">
        <div>
          <Select
            label={"Grupo de tarifas"}
            id={"idFeeGroup"}
            name={"idFeeGroup"}
            value={payload.idFeeGroup || ""}
            placeholder={"Selecione o grupo de tarifas"}
            options={groupFeesOption}
            onChange={handleChange}
            error={payloadError.idFeeGroup}
            errorMessage={payloadError.idFeeGroup}
          />
        </div>
        <div>
          <Select
            label={"Tipo de tarifa"}
            id={"feeType"}
            name={"feeType"}
            value={payload.feeType || ""}
            placeholder={"Selecione o tipo de tarifa"}
            options={[
              { id: "transacional", name: "Transacional" },
              { id: "periodica", name: "Periódica" },
            ]}
            onChange={handleChange}
            error={payloadError.feeType}
            errorMessage={payloadError.feeType}
          />
        </div>
        {payload.feeType === "periodica" && (
          <div>
            <Input
              label={"Dias de atraso para cobrança"}
              type={"number"}
              name={"quantidadeDiasAtrasoTarifaPeriodica"}
              id={"quantidadeDiasAtrasoTarifaPeriodica"}
              value={payload.quantidadeDiasAtrasoTarifaPeriodica || ""}
              onChange={handleChange}
              placeholder={"Não preencher caso seja 0"}
            />
          </div>
        )}
        <div>
          <Select
            label={"Tarifa"}
            id={"idFee"}
            name={"idFee"}
            value={payload.idFee || ""}
            placeholder={"Selecione a tarifa desejada"}
            options={
              payload.feeType === "transacional"
                ? feesOption
                : periodicFeesOption
            }
            onChange={handleChange}
            error={payloadError.idFee}
            errorMessage={payloadError.idFee}
          />
        </div>
        <div>
          <Select
            label={"Tipo de cobrança"}
            id={"feeChargeType"}
            name={"feeChargeType"}
            value={payload.feeChargeType || ""}
            placeholder={"Selecione o tipo de cobrança"}
            options={[
              { id: "percentual", name: "Percentual" },
              { id: "currency", name: "Valor fixo" },
            ]}
            onChange={handleChange}
            error={payloadError.feeChargeType}
            errorMessage={payloadError.feeChargeType}
          />
        </div>
        <div>
          <Input
            label={"Valor"}
            type={"text"}
            name={"valor"}
            id={"valor"}
            prefix={payload.feeChargeType === "percentual" ? "%" : "R$"}
            placeholder={"0,00"}
            value={valueFormatted || ""}
            onChange={(e) => setValueFormatted(formatCurrency(e.target.value))}
            error={payloadError.valor}
            errorMessage={payloadError.valor}
          />
        </div>
        {payload.feeChargeType === "percentual" && (
          <div>
            <Input
              label={"Valor máximo de cobrança"}
              type={"text"}
              name={"valorMaximoParaCobrancaPorPercentual"}
              id={"valorMaximoParaCobrancaPorPercentual"}
              prefix={"R$"}
              placeholder={"0,00"}
              value={percentualMaxFormatted || ""}
              onChange={(e) =>
                setPercentualMaxFormatted(formatCurrency(e.target.value))
              }
              error={payloadError.valorMaximoParaCobrancaPorPercentual}
              errorMessage={payloadError.valorMaximoParaCobrancaPorPercentual}
            />
          </div>
        )}
        <div>
          <Input
            label={"Valor mínimo para cobrança"}
            type={"text"}
            name={"valorMinimoParaCobranca"}
            id={"valorMinimoParaCobranca"}
            prefix={"R$"}
            placeholder={"Não preencher caso seja 0,00"}
            value={minValueFormatted || ""}
            onChange={(e) => setMinValueFormatted(formatCurrency(e.target.value))}
          />
        </div>
        <div>
          <Input
            label={"Quantidade mínima"}
            type={"number"}
            name={"quantidadeMinimaMensalParaCobranca"}
            id={"quantidadeMinimaMensalParaCobranca"}
            value={payload.quantidadeMinimaMensalParaCobranca || ""}
            onChange={handleChange}
            placeholder={"Não preencher caso seja 0"}
            hint={"Quantidade mínima mensal para a cobrança"}
          />
        </div>
        <div>
          <Input
            label={"Quantidade máxima"}
            type={"number"}
            name={"quantidadeMaximaMensalParaCobranca"}
            id={"quantidadeMaximaMensalParaCobranca"}
            value={payload.quantidadeMaximaMensalParaCobranca || ""}
            onChange={handleChange}
            placeholder={"Não preencher caso seja 0"}
            hint={"Quantidade máxima mensal para a cobrança"}
          />
        </div>
      </div>
    </Modal>
  );
};

export default NewFee;
