import axiosClient from "./apiClient";

export function getAccounts(
  name,
  document,
  email,
  idAccount,
  idClient,
  idBank,
  phoneNumber,
  type,
  itensPagina,
  paginaAtual,
  idStatus,
) {
  const params = new URLSearchParams();
  name && params.append("name", name);
  document && params.append("document", document);
  email && params.append("email", email);
  idAccount && params.append("id_Account", idAccount);
  idClient && params.append("id_Client", idClient);
  idBank && params.append("id_Customer_Bank", idBank);
  phoneNumber && params.append("phone", phoneNumber);
  type && params.append("type", type);
  itensPagina && params.append("itensPagina", itensPagina);
  paginaAtual && params.append("paginaAtual", paginaAtual);
  idStatus && params.append("id_status", idStatus);

  return axiosClient.get(`${process.env.REACT_APP_API_DASH}/account`, {
    params,
  });
}

export function getAccountStatus() {
  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/account/status`
  );
}

export function getPfAccountDetails(idAccount) {
  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/client/pf/${idAccount}`
  );
}

export function getPjAccountDetails(idAccount) {
  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/client/pj/${idAccount}`
  );
}

export function updatePjAccount(idClient, payload) {
  return axiosClient.post(
    `${process.env.REACT_APP_API_DASH}/client/pj/${idClient}`,
    payload,
    {
      headers: { "Content-Type": "application/json;charset=utf-8" },
    }
  );
}

export function updatePfAccount(idClient, payload) {
  return axiosClient.post(
    `${process.env.REACT_APP_API_DASH}/client/pf/${idClient}`,
    payload,
    {
      headers: { "Content-Type": "application/json;charset=utf-8" },
    }
  );
}

export function getBalanceByMonth(
  idClient,
  dataInicio,
  dataFim,
  currentPage,
  itemsPerPage
) {
  const params = new URLSearchParams();

  dataInicio && params.append("dataInicio", dataInicio);
  dataFim && params.append("dataFinal", dataFim);
  currentPage && params.append("pagina", currentPage);
  itemsPerPage && params.append("itensPorPagina", itemsPerPage);

  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/saldo/client/${idClient}/months`,
    {
      params,
    }
  );
}

export function getClientStatusFull(idClient) {
  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/client/status/${idClient}/full`
  );
}

export function block(idClient) {
  return axiosClient.post(
    `${process.env.REACT_APP_API_DASH}/client/block/${idClient}`
  );
}

export function unblock(idClient) {
  return axiosClient.post(
    `${process.env.REACT_APP_API_DASH}/client/unblock/${idClient}`
  );
}

export function cancelAccount(idClient, body) {
  return axiosClient.post(
    `${process.env.REACT_APP_API_DASH}/account/${idClient}/cancel`,
    body,
    {
      headers: { "Content-Type": "application/json;charset=utf-8" },
    }
  );
}

export function addToCancelling(idClient, idBank) {
  return axiosClient.post(
    `${process.env.REACT_APP_API_DASH}/account/${idClient}/addCancel/${idBank}`
  );
}

export function chargeBack(payload) {
  return axiosClient.post(
    `${process.env.REACT_APP_API_DASH}/Account/chargeback`,
    payload,
    {
      headers: { "Content-Type": "application/json;charset=utf-8" },
    }
  );
}

export function getChargeBackId() {
  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/account/transactionsChargeback`
  );
}

export function getCancelAccounts() {
  return axiosClient.get(`${process.env.REACT_APP_API_DASH}/account/cancel`);
}

export function undoCancel(idClient) {
  return axiosClient.post(
    `${process.env.REACT_APP_API_DASH}/account/${idClient}/undocancel`
  );
}

export function getLoginHistory(idClient) {
  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/client/logins/${idClient}`
  );
}

export function getClientErrors(
  idClient,
  dataInicio,
  dataFim,
  currentPage,
  itemsPerPage
) {
  const params = new URLSearchParams();

  dataInicio && params.append("dataInicio", dataInicio);
  dataFim && params.append("dataFinal", dataFim);
  currentPage && params.append("pagina", currentPage);
  itemsPerPage && params.append("itensPorPagina", itemsPerPage);

  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/client/logerrors/${idClient}`,
    {
      params,
    }
  );
}

export function getApplicationErrors() {
  return axiosClient.get(`${process.env.REACT_APP_API_DASH}/client/logerrors`);
}

export function createPreTransaction(body) {
  return axiosClient.post(
    `${process.env.REACT_APP_API_DASH}/account/pretransaction`,
    body,
    {
      headers: { "Content-Type": "application/json;charset=utf-8" },
    }
  );
}

export function getPendingPreTransactions() {
  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/account/pretransaction/notapproved`
  );
}

export function deletePreTransaction(idAccountPreTransaction) {
  return axiosClient.delete(
    `${process.env.REACT_APP_API_DASH}/account/pretransaction/${idAccountPreTransaction}`
  );
}

export function patchPreTransaction(idAccountPreTransaction) {
  return axiosClient.patch(
    `${process.env.REACT_APP_API_DASH}/account/pretransaction/${idAccountPreTransaction}`
  );
}
