import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Select from "components/select";
import Button from "components/button";
import NewLimitModal from "./components/new-limit-modal";
import { fetchBankLimits } from "reducers/bank/bankThunk";
import { convertCurrencyBRLToString } from "utils/mask";

const BankLimit = () => {
  const dispatch = useDispatch();

  const { banks, bankLimits } = useSelector((store) => store.bank);
  const { id_Customer_Bank } = useSelector((store) => store.user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [item, setItem] = useState();
  const [isUpdate, setIsUpdate] = useState(false);
  const [idCustomerBank, setIdCustomerBank] = useState(1);
  const [bankList, setBankList] = useState([]);

  useEffect(() => {
    setBankList(
      banks.map((bank) => ({ id: bank.idBanco, name: bank.descricao }))
    );
  }, [banks]);

  useEffect(() => {
    dispatch(
      fetchBankLimits(
        process.env.REACT_APP_MODE === "CORE"
          ? idCustomerBank
          : id_Customer_Bank
      )
    );
  }, [idCustomerBank, id_Customer_Bank, dispatch]);

  const handleEditTransaction = (updatedItem) => {
    setItem(updatedItem);
    setIsUpdate(true);
    setIsModalOpen(true);
  };

  const handleNewTransaction = () => {
    setIsUpdate(false);
    setIsModalOpen(true);
  };

  return (
    <div className="relative">
      <NewLimitModal
        update={isUpdate}
        setUpdate={setIsUpdate}
        open={isModalOpen}
        setOpen={setIsModalOpen}
        item={item}
        setItem={setItem}
        idCustomerBank={idCustomerBank}
      />
      <div className="shadow sm:overflow-hidden sm:rounded-md">
        <div className="bg-white py-6 px-4 sm:p-6">
          <div className="flex items-center justify-between w-full">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Limites
            </h3>
            {process.env.REACT_APP_MODE === "CORE" && (
              <div className="flex items-center gap-3">
                <div className="min-w-[200px]">
                  <Select
                    id={"idCustomerBank"}
                    name={"idCustomerBank"}
                    value={idCustomerBank || ""}
                    placeholder={"Selecione o banco"}
                    options={bankList}
                    onChange={(e) => setIdCustomerBank(e.target.value)}
                  />
                </div>
                <Button
                  type={"button"}
                  label={"Novo limite"}
                  className="w-full sm:w-auto"
                  onClick={handleNewTransaction}
                />
              </div>
            )}
          </div>
          <div className="mt-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Descrição
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                  >
                    Qtde
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Valor
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Valor Noturno
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Alterar</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {bankLimits.length > 0 ? (
                  bankLimits.map((limit) => (
                    <tr key={limit.idTransaction}>
                      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none">
                        {limit.descricaoTransacao}
                        <dl className="font-normal lg:hidden">
                          <dt className="sr-only md:hidden">Quantidade</dt>
                          <dd className="mt-1 truncate text-gray-500 md:hidden">
                            Quantidade: {limit.quantidade}
                          </dd>
                          <dt className="sr-only sm:hidden">Valor diurno</dt>
                          <dd className="mt-1 truncate text-gray-500 sm:hidden">
                            Valor: R${" "}
                            {convertCurrencyBRLToString(limit.valorMaximo)}
                          </dd>
                          <dt className="sr-only sm:hidden">Valor noturno</dt>
                          <dd className="mt-1 truncate text-gray-500 sm:hidden">
                            Valor noturno:{" "}
                            {convertCurrencyBRLToString(
                              limit.valorMaximoNoturno
                            )}
                          </dd>
                        </dl>
                      </td>
                      <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                        {limit.quantidade}
                      </td>
                      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                        R$ {convertCurrencyBRLToString(limit.valorMaximo)}
                      </td>
                      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                        R${" "}
                        {convertCurrencyBRLToString(limit.valorMaximoNoturno)}
                      </td>
                      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        <span
                          className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                          onClick={() => handleEditTransaction(limit)}
                        >
                          Alterar
                          <span className="sr-only">
                            , {limit.descricaoTransacao}
                          </span>
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={5}
                      className="text-sm sm:text-base text-gray-500 text-center py-5"
                    >
                      Não há dados para exibir
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankLimit;
