import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import Layout from "components/layout";
import Tab from "components/tab";
import MonthTable from "./balance- monthly";
import DailyTable from "./balance-daily";
import ExtractTable from "./extract";
import { fetchBanks } from "reducers/onboarding/onboardingThunk";
import { fetchBankPixLimit } from "reducers/limits/limitsThunk";
import { convertCurrencyBRLToString } from "utils/mask";

export default function BankDetails() {
  const [titleDisplay, setTitleDisplay] = useState();
  const [tabs, setTabs] = useState([
    { id: 1, name: "Mensal", current: true },
    { id: 2, name: "Diário", current: false },
    { id: 3, name: "Extrato", current: false },
  ]);
  const { banks } = useSelector((store) => store.onboarding);
  const { bankPixLimit } = useSelector((store) => store.limits);
  const { idBank } = useParams();
  const dispatch = useDispatch();
  const breadcrumbPages = [
    { name: "Conciliação", href: `/financeiro/conciliacao` },
    { name: "Detalhes" },
  ];

  useEffect(() => {
    dispatch(fetchBanks());

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    !isNaN(idBank) && dispatch(fetchBankPixLimit(idBank));

    // eslint-disable-next-line
  }, [idBank]);

  useEffect(() => {
    if (isNaN(idBank)) {
      setTitleDisplay("BaaS2u");
    } else {
      banks.forEach((bank) => {
        if (bank.id === parseInt(idBank)) {
          setTitleDisplay(bank.name);
        }
      });
    }

    // eslint-disable-next-line
  }, [banks]);

  const handleTabNavigation = (evt) => {
    const { value: id } = evt.target;
    setTabs(
      tabs.map((tab) =>
        tab.id === parseInt(id)
          ? { ...tab, current: true }
          : { ...tab, current: false }
      )
    );
  };

  return (
    <Layout
      title={titleDisplay ? titleDisplay : "Detalhes"}
      breadcrumbPages={breadcrumbPages}
    >
      {!isNaN(idBank) && bankPixLimit?.valorLimiteRealtime > 0 && (
        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 mb-6 sm:mb-2">
          <dt className="truncate text-sm font-medium text-gray-500">
            Limite diário disponível para PIX
          </dt>
          <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">
            R$ {convertCurrencyBRLToString(bankPixLimit?.valorLimiteRealtime)}
          </dd>
        </div>
      )}
      <Tab tabs={tabs} onChangeTab={handleTabNavigation} />
      <div className="space-y-5">
        {tabs[0].current && <MonthTable />}
        {tabs[1].current && <DailyTable />}
        {tabs[2].current && <ExtractTable />}
      </div>
    </Layout>
  );
}
