import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  homeAccountId: "",
  localAccountId: "",
  username: "",
  notification: [],
};

export const azureAccountSlice = createSlice({
  name: "azureAccount",
  initialState,
  reducers: {
    setAzureAccount: (state, action) => {
      return { ...state, ...action.payload };
    },
    setUserNotification: (state, action) => {
      if (!state.notification.find((n) => n.id === action.payload.id))
        return {
          ...state,
          notification: [...state.notification, action.payload],
        };
    },
    deleteUserNotification: (state, action) => {
      const notifications = state.notification.filter(
        (n) => n.id !== action.payload
      );

      return {
        ...state,
        notification: notifications,
      };
    },
  },
});

export const { setAzureAccount, setUserNotification, deleteUserNotification } =
  azureAccountSlice.actions;

export default azureAccountSlice.reducer;
