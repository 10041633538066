export const initialState = {
  apiFilter: {
    name: "",
    document: "",
    email: "",
    idAccount: "",
    idClient: "",
    phoneNumber: "",
  },
  page: 1,
  itemsPerPage: 30,
  accountType: "",
  idBank: "",
  filterType: "name",
  canSendRequest: false,
  idStatus: ""
};

export const SET_API_FILTER = "SET_API_FILTER";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_ITEMS_PER_PAGE = "SET_ITEMS_PER_PAGE";
export const SET_ACCOUNT_TYPE = "SET_ACCOUNT_TYPE";
export const SET_ID_BANK = "SET_ID_BANK";
export const SET_FILTER_TYPE = "SET_FILTER_TYPE";
export const RESET_FILTER_TYPE = "RESET_FILTER_TYPE";
export const SET_CAN_SEND_REQUEST = "SET_CAN_SEND_REQUEST";
export const SET_STATUS_FILTER = "SET_STATUS_FILTER";

export const reducer = (state, action) => {
  switch (action.type) {
    case SET_API_FILTER:
      return { ...state, apiFilter: action.payload };
    case SET_CURRENT_PAGE:
      return { ...state, page: action.payload };
    case SET_ITEMS_PER_PAGE:
      return { ...state, itemsPerPage: action.payload, page: 1 };
    case SET_ACCOUNT_TYPE:
      return { ...state, accountType: action.payload };
    case SET_ID_BANK:
      return { ...state, idBank: action.payload };
    case SET_FILTER_TYPE:
      return {
        ...state,
        filterType: action.payload,
        apiFilter: initialState.apiFilter,
      };
    case RESET_FILTER_TYPE:
      return {
        ...state,
        filterType: "name",
        canSendRequest: true,
        apiFilter: initialState.apiFilter,
      };
    case SET_CAN_SEND_REQUEST:
      return { ...state, canSendRequest: action.payload };
    case SET_STATUS_FILTER:
      return { ...state, idStatus: action.payload};
    default:
      return state;
  }
};
