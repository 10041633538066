import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";

import Modal from "components/modal";
import Input from "components/input";
import { updateTime } from "reducers/bank/bankThunk";
import { isResponseSuccess } from "utils/request";
import { timeMask } from "utils/mask";

const ModalSetTime = ({ open, setOpen, item }) => {
  const dispatch = useDispatch();

  const [payload, setPayload] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (open && item?.idBanco) {
      setPayload(item);
    }
  }, [item, open]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setPayload((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEnter = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    if (validate()) {
      const response = await dispatch(
        updateTime({
          ...payload,
          horarioInicioNoturno: moment(
            payload.horarioInicioNoturno,
            "HH:mm"
          ).format("HH:mm:ss"),
          horarioFinalNoturno: moment(
            payload.horarioFinalNoturno,
            "HH:mm"
          ).format("HH:mm:ss"),
          idCustomerBank: payload.idBanco,
        })
      );

      if (isResponseSuccess(response)) {
        handleCloseModal();
      }
    }
  };

  const validate = () => {
    const errors = {};

    if (!payload?.horarioInicioNoturno) {
      errors.horarioInicioNoturno = "Campo obrigatório!";
    } else if (!moment(payload?.horarioInicioNoturno, "HH:mm").isValid()) {
      errors.horarioInicioNoturno = "Formato inválido!";
    } else if (payload?.horarioInicioNoturno.length < 5) {
      errors.horarioInicioNoturno = "Tamanho inválido!";
    }

    if (!payload?.horarioFinalNoturno) {
      errors.horarioFinalNoturno = "Campo obrigatório!";
    } else if (!moment(payload?.horarioFinalNoturno, "HH:mm").isValid()) {
      errors.horarioFinalNoturno = "Formato inválido!";
    } else if (payload?.horarioFinalNoturno.length < 5) {
      errors.horarioFinalNoturno = "Tamanho inválido!";
    } else if (payload?.horarioFinalNoturno >= payload?.horarioInicioNoturno) {
      errors.horarioFinalNoturno =
        "Horário final deve ser inferior ao inicial!";
    }

    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
    setErrors({});
  };

  return (
    <Modal
      title={"Alterar horário noturno"}
      confirmButtonLabel={"Alterar horário"}
      onConfirm={handleSubmit}
      cancelButtonLabel={"Cancelar"}
      onCancel={handleCloseModal}
      isModalOpen={open}
    >
      <div className="text-left space-y-5" onKeyUp={handleEnter}>
        <div className="mt-5">
          <label className="block text-sm font-medium text-gray-700">
            Banco
          </label>
          <p className="block w-full mt-1 text-sm font-medium">
            {item?.descricao}
          </p>
        </div>
        <div>
          <Input
            label={"Horário inicial"}
            type={"text"}
            name={"horarioInicioNoturno"}
            id={"horarioInicioNoturno"}
            value={timeMask(payload?.horarioInicioNoturno) || ""}
            onChange={handleChange}
            error={errors.horarioInicioNoturno}
            errorMessage={errors.horarioInicioNoturno}
          />
        </div>
        <div>
          <Input
            label={"Horário final"}
            type={"text"}
            name={"horarioFinalNoturno"}
            id={"horarioFinalNoturno"}
            value={timeMask(payload?.horarioFinalNoturno) || ""}
            onChange={handleChange}
            error={errors.horarioFinalNoturno}
            errorMessage={errors.horarioFinalNoturno}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModalSetTime;
