import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEqual } from "lodash";

import Input from "components/input";
import Button from "components/button";
import Select from "components/select";
import { cepMask, numberMask } from "utils/mask";
import { ufs } from "utils/ufs";
import { setEditingForm } from "reducers/client/clientSlice";
import { updateClient } from "reducers/client/clientThunk";
import { fetchZipcode } from "reducers/zipcode/zipcodeThunk";
import { checkRole } from "utils/isRoleAllowed";
import { USER_ADMIN, USER_OPERACOES, ADMIN_ECOSYSTEM } from "utils/const";

export default function PartnerAddres() {
  const dispatch = useDispatch();
  const { client, isUpdating, editingForm } = useSelector(
    (store) => store.client
  );
  const zipcode = useSelector((store) => store.zipcode);
  const { role } = useSelector((store) => store.user);
  const [partnerAddress, setPartnerAddress] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [canUpdateCep, setCanUpdateCep] = useState(false);
  const [isEditingMode, setIsEditingMode] = useState(false);

  useEffect(() => {
    !isUpdating && setIsEditingMode(false);
  }, [isUpdating]);

  useEffect(() => {
    if (canUpdateCep && zipcode.localidade) {
      setPartnerAddress((prevState) => ({
        ...prevState,
        enderecoSocio: zipcode.logradouro,
        enderecoBairroSocio: zipcode.bairro,
        enderecoCidadeSocio: zipcode.localidade,
        enderecoEstadoSocio: zipcode.uf,
        enderecoCEPSocio: zipcode.cep,
      }));
      setCanUpdateCep(false);
    }

    // eslint-disable-next-line
  }, [zipcode]);

  useEffect(() => {
    setPartnerAddress({
      enderecoCEPSocio: client.enderecoCEPSocio,
      enderecoSocio: client.enderecoSocio,
      enderecoNumeroSocio: client.enderecoNumeroSocio,
      enderecoBairroSocio: client.enderecoBairroSocio,
      enderecoComplementoSocio: client.enderecoComplementoSocio,
      enderecoEstadoSocio: client.enderecoEstadoSocio,
      enderecoCidadeSocio: client.enderecoCidadeSocio,
    });
  }, [client, isEditingMode]);

  const changePartnerAddress = (event) => {
    const { name, value } = event.target;

    setPartnerAddress((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const validateForm = (values) => {
    const errors = {};

    if (!values.enderecoCEPSocio) {
      errors.enderecoCEPSocio = "CEP deve ser informado!";
    } else if (numberMask(values.enderecoCEPSocio).length !== 8) {
      errors.enderecoCEPSocio = "CEP deve ter 8 dígitos!";
    }

    if (!values.enderecoSocio) {
      errors.enderecoSocio = "Rua deve ser informada!";
    }

    if (!values.enderecoNumeroSocio) {
      errors.enderecoNumeroSocio = "Número deve ser informado!";
    }

    if (!values.enderecoBairroSocio) {
      errors.enderecoBairroSocio = "Bairro deve ser informado!";
    }

    if (!values.enderecoEstadoSocio) {
      errors.enderecoEstadoSocio = "Estado deve ser informado!";
    }

    if (!values.enderecoCidadeSocio) {
      errors.enderecoCidadeSocio = "Cidade deve ser informada!";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const comparePartnerAddress = () => {
    const clientStore = {
      enderecoCEPSocio: client.enderecoCEPSocio,
      enderecoSocio: client.enderecoSocio,
      enderecoNumeroSocio: client.enderecoNumeroSocio,
      enderecoBairroSocio: client.enderecoBairroSocio,
      enderecoComplementoSocio: client.enderecoComplementoSocio,
      enderecoEstadoSocio: client.enderecoEstadoSocio,
      enderecoCidadeSocio: client.enderecoCidadeSocio,
    };

    return isEqual(clientStore, {
      ...partnerAddress,
      enderecoCEPSocio: numberMask(partnerAddress.enderecoCEPSocio),
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateForm({ ...partnerAddress }) && !isUpdating) {
      dispatch(
        updateClient({
          ...client,
          ...partnerAddress,
          accountType: 2,
          enderecoCEPSocio: numberMask(partnerAddress.enderecoCEPSocio),
        })
      );
      dispatch(setEditingForm(""));
    }
  };

  const handleEdit = () => {
    dispatch(setEditingForm("partner-address"));
    setIsEditingMode(true);
  };

  const handleCancel = () => {
    dispatch(setEditingForm(""));
    setIsEditingMode(false);
    setFormErrors({});
  };

  const handleBlur = (event) => {
    const { value } = event.target;
    const formZipcode = numberMask(value);

    if (formZipcode && formZipcode.length === 8) {
      setCanUpdateCep(true);
      dispatch(fetchZipcode(formZipcode));
    }
  };

  return (
    <form action="#" method="POST" onSubmit={handleSubmit}>
      <div className="shadow sm:overflow-hidden sm:rounded-md">
        <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Endereço do sócio
            </h3>
          </div>

          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"CEP"}
                type={"text"}
                name={"enderecoCEPSocio"}
                id={"enderecoCEPSocio"}
                value={cepMask(partnerAddress.enderecoCEPSocio) || ""}
                onChange={changePartnerAddress}
                onBlur={handleBlur}
                error={formErrors.enderecoCEPSocio}
                errorMessage={formErrors.enderecoCEPSocio}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6">
              <Input
                label={"Rua"}
                type={"text"}
                name={"enderecoSocio"}
                id={"enderecoSocio"}
                value={partnerAddress.enderecoSocio || ""}
                onChange={changePartnerAddress}
                error={formErrors.enderecoSocio}
                errorMessage={formErrors.enderecoSocio}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-2">
              <Input
                label={"Número"}
                type={"text"}
                name={"enderecoNumeroSocio"}
                id={"enderecoNumeroSocio"}
                value={partnerAddress.enderecoNumeroSocio || ""}
                onChange={changePartnerAddress}
                error={formErrors.enderecoNumeroSocio}
                errorMessage={formErrors.enderecoNumeroSocio}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-2">
              <Input
                label={"Bairro"}
                type={"text"}
                name={"enderecoBairroSocio"}
                id={"enderecoBairroSocio"}
                value={partnerAddress.enderecoBairroSocio || ""}
                onChange={changePartnerAddress}
                error={formErrors.enderecoBairroSocio}
                errorMessage={formErrors.enderecoBairroSocio}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-2">
              <Input
                label={"Complemento"}
                type={"text"}
                name={"enderecoComplementoSocio"}
                id={"enderecoComplementoSocio"}
                value={partnerAddress.enderecoComplementoSocio || ""}
                onChange={changePartnerAddress}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Select
                label={"Estado"}
                id={"enderecoEstadoSocio"}
                name={"enderecoEstadoSocio"}
                value={partnerAddress.enderecoEstadoSocio || ""}
                placeholder={"Selecione o Estado"}
                options={ufs}
                onChange={changePartnerAddress}
                error={formErrors.enderecoEstadoSocio}
                errorMessage={formErrors.enderecoEstadoSocio}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"Cidade"}
                type={"text"}
                name={"enderecoCidadeSocio"}
                id={"enderecoCidadeSocio"}
                value={partnerAddress.enderecoCidadeSocio || ""}
                onChange={changePartnerAddress}
                error={formErrors.enderecoCidadeSocio}
                errorMessage={formErrors.enderecoCidadeSocio}
                disabled={!isEditingMode}
              />
            </div>
          </div>
        </div>
        {checkRole(role, [USER_ADMIN, USER_OPERACOES, ADMIN_ECOSYSTEM]) && (
          <div className="bg-gray-50 px-4 py-3 space-x-3 text-right sm:px-6">
            {!isEditingMode && (
              <Button
                type={"button"}
                label={"Editar"}
                onClick={handleEdit}
                disabled={editingForm && editingForm !== "partner-address"}
              />
            )}
            {isEditingMode && (
              <>
                <Button
                  type={"submit"}
                  label={"Salvar"}
                  onClick={handleSubmit}
                  disabled={comparePartnerAddress()}
                />
                <Button
                  type={"button"}
                  secondary
                  label={"Cancelar"}
                  onClick={handleCancel}
                />
              </>
            )}
          </div>
        )}
      </div>
    </form>
  );
}
