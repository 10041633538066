import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

import Input from "components/input";
import Button from "components/button";
import Select from "components/select";
import ConfirmationModal from "components/modal-confirmation";
import CheckBox from "components/checkbox";
import TextArea from "components/textarea";
import { ufs } from "utils/ufs";
import { fetchZipcode } from "reducers/zipcode/zipcodeThunk";
import { postPfAccount } from "reducers/onboarding/onboardingThunk";
import { isResponseSuccess } from "utils/request";
import {
  cpfMask,
  phoneMask,
  numberMask,
  cepMask,
  dateMask,
  currencyMask,
  convertCurrencyBRLToFloat,
} from "utils/mask";

export default function NewAccountPf() {
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [formattedValue, setFormattedValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isPosting } = useSelector((store) => store.onboarding);
  const { id_Customer_Bank } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleChange(event) {
    const { name, value, type, checked } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  }

  async function handleBlur(event) {
    const { value } = event.target;
    const formZipcode = numberMask(value);

    if (formZipcode?.length === 8) {
      const response = await dispatch(fetchZipcode(formZipcode));

      if (isResponseSuccess(response)) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          endereco: response.payload.logradouro,
          enderecoBairro: response.payload.bairro,
          enderecoCidade: response.payload.localidade,
          enderecoEstado: response.payload.uf,
          enderecoCEP: response.payload.cep,
        }));
      }
    }
  }

  async function handleSubmit() {
    if (!isPosting) {
      const response = await dispatch(
        postPfAccount({
          ...formData,
          username: numberMask(formData.cpf),
          senha: uuidv4(),
          idCustomerBank: parseInt(id_Customer_Bank),
          cpf: numberMask(formData.cpf),
          codigoArea: numberMask(formData.numeroTelefone).substr(0, 2),
          numeroTelefone: numberMask(formData.numeroTelefone).substr(2),
          enderecoCEP: numberMask(formData.enderecoCEP),
          dataNascimento: moment(formData.dataNascimento, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          ),
          dataEmissaoRG: moment(formData.dataEmissaoRG, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          ),
          pep: !formData.pep || formData.pep === "1" ? false : true,
          dataInicioPEP: formData.dataInicioPEP
            ? moment(formData.dataInicioPEP, "DD/MM/YYYY").format("YYYY-MM-DD")
            : "1900-01-01",
          rendaMediaMensal: convertCurrencyBRLToFloat(formattedValue),
        })
      );

      if (isResponseSuccess(response)) {
        navigate("/contas");
      }
    }
  }

  function validateForm(values) {
    const errors = {};
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    if (!values.nome) {
      errors.nome = "Nome deve ser informado!";
    }

    const nomeArray = values.nome.split(" ");
    if (nomeArray.length < 2) {
      errors.nome = "Nome e sobrenome devem ser informados!";
    }

    if (!values.cpf) {
      errors.cpf = "CPF deve ser informado!";
    } else if (numberMask(values.cpf).length < 11) {
      errors.cpf = "CPF deve ter 11 dígitos!";
    }

    if (!values.email) {
      errors.email = "Email deve ser informado!";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "Email em formato inválido!";
    }

    if (!values.numeroTelefone) {
      errors.numeroTelefone = "Celular deve ser informado!";
    } else if (numberMask(values.numeroTelefone).length !== 11) {
      errors.numeroTelefone = "Celular deve ter 11 dígitos!";
    }

    if (!values.dataNascimento) {
      errors.dataNascimento = "Data de nascimento deve ser informada!";
    } else if (values.dataNascimento.length < 10) {
      errors.dataNascimento = "Data de nascimento inválida!";
    } else if (!moment(values.dataNascimento, "DD/MM/YYYY").isValid()) {
      errors.dataNascimento = "Data de nascimento inválida!";
    }

    if (!values.nomeMae) {
      errors.nomeMae = "Nome da mãe deve ser informado!";
    }

    if (!values.nomePai) {
      errors.nomePai = "Nome do pai deve ser informado!";
    }

    if (!values.rg) {
      errors.rg = "RG deve ser informado!";
    }

    if (!values.estadoRG) {
      errors.estadoRG = "Estado de emissão do RG deve ser informado!";
    }

    if (!values.emissorRG) {
      errors.emissorRG = "Orgão emissor do RG deve ser informado!";
    }

    if (!values.dataEmissaoRG) {
      errors.dataEmissaoRG = "Data de emissão do RG deve ser informada!";
    } else if (values.dataEmissaoRG.length < 10) {
      errors.dataEmissaoRG = "Data inválida!";
    } else if (!moment(values.dataEmissaoRG, "DD/MM/YYYY").isValid()) {
      errors.dataEmissaoRG = "Data inválida!";
    }

    if (!values.nacionalidade) {
      errors.nacionalidade = "Nacionalidade deve ser informada!";
    }

    if (!values.pep) {
      errors.pep = "Pessoa Exposta Politicamente deve ser informada!";
    }

    if (values.pep === "2") {
      if (!values.dataInicioPEP) {
        errors.dataInicioPEP = "Data de início deve ser informada!";
      } else if (values.dataInicioPEP.length < 10) {
        errors.dataInicioPEP = "Data de início inválida!";
      } else if (!moment(values.dataInicioPEP, "DD/MM/YYYY").isValid()) {
        errors.dataInicioPEP = "Data de início inválida!";
      }
    }

    if (!values.profissao) {
      errors.profissao = "Profissão deve ser informada!";
    }

    if (!values.enderecoCEP) {
      errors.enderecoCEP = "CEP deve ser informado!";
    } else if (numberMask(values.enderecoCEP).length !== 8) {
      errors.enderecoCEP = "CEP deve ter 8 dígitos!";
    }

    if (!values.endereco) {
      errors.endereco = "Rua deve ser informada!";
    }

    if (!values.enderecoNumero) {
      errors.enderecoNumero = "Número deve ser informado!";
    }

    if (!values.enderecoBairro) {
      errors.enderecoBairro = "Bairro deve ser informado!";
    }

    if (!values.enderecoEstado) {
      errors.enderecoEstado = "Estado deve ser informado!";
    }

    if (!values.enderecoCidade) {
      errors.enderecoCidade = "Cidade deve ser informada!";
    }

    if (!values.solicitanteRazaoOnboarding) {
      errors.solicitanteRazaoOnboarding =
        "Motivo de abertura manual deve ser informado!";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      <ConfirmationModal
        title={"Deseja prosseguir?"}
        description={"Caso confirme, sua ação não poderá ser desfeita."}
        confirmButtonLabel={"Continuar"}
        onConfirm={handleSubmit}
        cancelButtonLabel={"Cancelar"}
        onCancel={() => setIsModalOpen(false)}
        isModalOpen={isModalOpen}
      />
      <form
        className="space-y-6"
        onSubmit={(e) => {
          e.preventDefault();
          validateForm({ ...formData, rendaMediaMensal: formattedValue }) &&
            setIsModalOpen(true);
        }}
        action="#"
        method="POST"
      >
        <div className="shadow overflow-hidden sm:rounded-md">
          <div className="px-6 py-4 sm:py-5 bg-white border-b border-gray-200">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Dados pessoais
            </h3>
          </div>
          <div className="px-4 py-7 bg-white sm:p-7">
            <div className="grid grid-cols-6 gap-7">
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"Nome"}
                  type={"text"}
                  name={"nome"}
                  id={"nome"}
                  value={formData.nome || ""}
                  error={formErrors.nome}
                  errorMessage={formErrors.nome}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"CPF"}
                  type={"text"}
                  name={"cpf"}
                  id={"cpf"}
                  value={cpfMask(formData.cpf) || ""}
                  error={formErrors.cpf}
                  errorMessage={formErrors.cpf}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"Email"}
                  type={"text"}
                  name={"email"}
                  id={"email"}
                  value={formData.email || ""}
                  error={formErrors.email}
                  errorMessage={formErrors.email}
                  onChange={handleChange}
                  autoComplete={"off"}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"Celular"}
                  type={"text"}
                  name={"numeroTelefone"}
                  id={"numeroTelefone"}
                  maxLength={15}
                  value={phoneMask(formData.numeroTelefone) || ""}
                  error={formErrors.numeroTelefone}
                  errorMessage={formErrors.numeroTelefone}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"Data de nascimento"}
                  type={"text"}
                  name={"dataNascimento"}
                  id={"dataNascimento"}
                  value={dateMask(formData.dataNascimento) || ""}
                  error={formErrors.dataNascimento}
                  errorMessage={formErrors.dataNascimento}
                  onChange={handleChange}
                  placeholder={"dd/mm/aaaa"}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"Nacionalidade"}
                  type={"text"}
                  name={"nacionalidade"}
                  id={"nacionalidade"}
                  value={formData.nacionalidade || ""}
                  error={formErrors.nacionalidade}
                  errorMessage={formErrors.nacionalidade}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"RG"}
                  type={"text"}
                  name={"rg"}
                  id={"rg"}
                  value={formData.rg || ""}
                  error={formErrors.rg}
                  errorMessage={formErrors.rg}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Select
                  label={"Estado de emissão do RG"}
                  id={"estadoRG"}
                  name={"estadoRG"}
                  value={formData.estadoRG || ""}
                  onChange={handleChange}
                  placeholder={"Selecione o estado"}
                  options={ufs}
                  error={formErrors.estadoRG}
                  errorMessage={formErrors.estadoRG}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"Orgão emissor do RG"}
                  type={"text"}
                  name={"emissorRG"}
                  id={"emissorRG"}
                  value={formData.emissorRG || ""}
                  error={formErrors.emissorRG}
                  errorMessage={formErrors.emissorRG}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"Data de emissão do RG"}
                  type={"text"}
                  name={"dataEmissaoRG"}
                  id={"dataEmissaoRG"}
                  value={dateMask(formData.dataEmissaoRG) || ""}
                  error={formErrors.dataEmissaoRG}
                  errorMessage={formErrors.dataEmissaoRG}
                  onChange={handleChange}
                  placeholder={"dd/mm/aaaa"}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"Nome completo da mãe"}
                  type={"text"}
                  name={"nomeMae"}
                  id={"nomeMae"}
                  value={formData.nomeMae || ""}
                  error={formErrors.nomeMae}
                  errorMessage={formErrors.nomeMae}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"Nome completo do pai"}
                  type={"text"}
                  name={"nomePai"}
                  id={"nomePai"}
                  value={formData.nomePai || ""}
                  error={formErrors.nomePai}
                  errorMessage={formErrors.nomePai}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Select
                  label={"Pessoa Politicamente Exposta (PPE)"}
                  id={"pep"}
                  name={"pep"}
                  placeholder={"Selecione uma opção"}
                  error={formErrors.pep}
                  errorMessage={formErrors.pep}
                  value={formData.pep || ""}
                  onChange={handleChange}
                  options={[
                    { id: 1, name: "Não" },
                    { id: 2, name: "Sim" },
                  ]}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"Início PPE"}
                  type={"text"}
                  name={"dataInicioPEP"}
                  id={"dataInicioPEP"}
                  value={dateMask(formData.dataInicioPEP) || ""}
                  disabled={!formData.pep || formData.pep === "1"}
                  error={formErrors.dataInicioPEP}
                  errorMessage={formErrors.dataInicioPEP}
                  onChange={handleChange}
                  placeholder={"dd/mm/aaaa"}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"Profissão"}
                  type={"text"}
                  name={"profissao"}
                  id={"profissao"}
                  value={formData.profissao || ""}
                  error={formErrors.profissao}
                  errorMessage={formErrors.profissao}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"Renda mensal"}
                  type={"text"}
                  name={"rendaMediaMensal"}
                  id={"rendaMediaMensal"}
                  prefix={"R$"}
                  value={formattedValue || ""}
                  onChange={(e) =>
                    setFormattedValue(currencyMask(e.target.value))
                  }
                  placeholder={"0,00"}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="shadow overflow-hidden sm:rounded-md">
          <div className="px-6 py-4 sm:py-5 bg-white border-b border-gray-200">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Endereço
            </h3>
          </div>
          <div className="px-4 py-7 bg-white sm:p-7">
            <div className="grid grid-cols-6 gap-7">
              <div className="col-span-6 sm:col-span-2 lg:col-span-3">
                <Input
                  label={"CEP"}
                  type={"text"}
                  name={"enderecoCEP"}
                  id={"enderecoCEP"}
                  value={cepMask(formData.enderecoCEP) || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={formErrors.enderecoCEP}
                  errorMessage={formErrors.enderecoCEP}
                />
              </div>
              <div className="col-span-6">
                <Input
                  label={"Rua"}
                  type={"text"}
                  name={"endereco"}
                  id={"endereco"}
                  value={formData.endereco || ""}
                  onChange={handleChange}
                  error={formErrors.endereco}
                  errorMessage={formErrors.endereco}
                />
              </div>
              <div className="col-span-6 sm:col-span-2">
                <Input
                  label={"Número"}
                  type={"text"}
                  name={"enderecoNumero"}
                  id={"enderecoNumero"}
                  value={formData.enderecoNumero || ""}
                  onChange={handleChange}
                  error={formErrors.enderecoNumero}
                  errorMessage={formErrors.enderecoNumero}
                />
              </div>
              <div className="col-span-6 sm:col-span-2">
                <Input
                  label={"Bairro"}
                  type={"text"}
                  name={"enderecoBairro"}
                  id={"enderecoBairro"}
                  value={formData.enderecoBairro || ""}
                  onChange={handleChange}
                  error={formErrors.enderecoBairro}
                  errorMessage={formErrors.enderecoBairro}
                />
              </div>
              <div className="col-span-6 sm:col-span-2">
                <Input
                  label={"Complemento"}
                  type={"text"}
                  name={"enderecoComplemento"}
                  id={"enderecoComplemento"}
                  value={formData.enderecoComplemento || ""}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Select
                  label={"Estado"}
                  id={"enderecoEstado"}
                  name={"enderecoEstado"}
                  value={formData.enderecoEstado || ""}
                  onChange={handleChange}
                  placeholder={"Selecione o estado"}
                  options={ufs}
                  error={formErrors.enderecoEstado}
                  errorMessage={formErrors.enderecoEstado}
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Input
                  label={"Cidade"}
                  type={"text"}
                  name={"enderecoCidade"}
                  id={"enderecoCidade"}
                  value={formData.enderecoCidade || ""}
                  onChange={handleChange}
                  error={formErrors.enderecoCidade}
                  errorMessage={formErrors.enderecoCidade}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="shadow overflow-hidden sm:rounded-md">
          <div className="px-6 py-4 sm:py-5 bg-white border-b border-gray-200">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Justificativa
            </h3>
          </div>
          <div className="px-4 py-7 bg-white sm:p-7">
            <div className="grid grid-cols-6 gap-7">
              <div className="col-span-6">
                <CheckBox
                  id={"solicitanteBypassSolicitacao"}
                  name={"solicitanteBypassSolicitacao"}
                  label={"Ignorar validações da IdWall, PH3A e Clearsale"}
                  description={
                    "Ao marcar essa opção, todas as estapas de validação serão ignoradas."
                  }
                  checked={formData.solicitanteBypassSolicitacao || false}
                  onChange={handleChange}
                />
              </div>
              <div className="col-span-6">
                <TextArea
                  id={"solicitanteRazaoOnboarding"}
                  name={"solicitanteRazaoOnboarding"}
                  label={"Motivo"}
                  placeholder={
                    "Descreva o motivo da abertura manual dessa conta e, caso tenha marcado a opção acima, o motivo de ignorar as validações de segurança. (max 500 caracteres)"
                  }
                  maxLength={500}
                  onChange={handleChange}
                  error={formErrors.solicitanteRazaoOnboarding}
                  errorMessage={formErrors.solicitanteRazaoOnboarding}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex-col flex sm:flex-row items-end sm:items-center justify-end gap-3">
          {Object.keys(formErrors).length > 0 && (
            <div className="flex items-center gap-2">
              <p className="text-sm text-red-600">
                Revise os erros no formulário e tente novamente
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 fill-red-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          )}
          <div className="flex items-center justify-end gap-3">
            <Button type={"submit"} label={"Criar conta"} />
            <Button
              type={"button"}
              label={"Cancelar"}
              secondary
              onClick={() => navigate("/contas")}
            />
          </div>
        </div>
      </form>
    </>
  );
}
