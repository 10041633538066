import Datepicker from "react-tailwindcss-datepicker";

import classNames from "utils/classNames";

export default function DatePicker({
  dateFilter,
  handleChangeDate,
  className,
}) {
  return (
    <div
      className={classNames(
        "rounded-md w-full sm:w-auto sm:max-w-xs",
        className
      )}
    >
      <Datepicker
        configs={{
          shortcuts: {
            today: "Hoje",
            yesterday: "Ontem",
            past: (period) => `Últimos ${period} dias`,
            currentMonth: "Este mês",
            pastMonth: "Último mês",
          },
        }}
        i18n={"pt"}
        placeholder={"Selecione o intervalo"}
        separator={"-"}
        displayFormat={"DD/MM/YYYY"}
        toggleClassName="hidden"
        readOnly={true}
        showShortcuts={true}
        value={dateFilter}
        onChange={handleChangeDate}
        primaryColor={"indigo"}
        popoverDirection={"down"}
        inputClassName="py-2 block w-full outline-0 text-sm rounded-md border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
      />
    </div>
  );
}
