import { createAsyncThunk } from "@reduxjs/toolkit";

import { setIsLoading } from "reducers/loading/isLoadingSlice";
import { setNotification } from "reducers/notification/notificationSlice";
import {
  getPixSent,
  getPixReceivid,
  getPixReturned,
  getPayment,
  internalTransferSent,
  internalTransferReceivid,
  getVoucherPdf,
} from "services/voucher";

export const fetchPixSent = createAsyncThunk(
  "voucher/fetchPixSent",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getPixSent(
        payload.idClient,
        payload.idAccountTransaction
      );

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchPixReceivid = createAsyncThunk(
  "voucher/fetchPixReceivid",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getPixReceivid(
        payload.idClient,
        payload.idAccountTransaction
      );

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchPixReturned = createAsyncThunk(
  "voucher/fetchPixReturned",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getPixReturned(
        payload.idClient,
        payload.idAccountTransaction
      );

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchPayment = createAsyncThunk(
  "voucher/fetchPayment",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getPayment(
        payload.idClient,
        payload.idAccountTransaction
      );

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchInternalTransferSent = createAsyncThunk(
  "voucher/fetchInternalTransferSent",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await internalTransferSent(
        payload.idClient,
        payload.idAccountTransaction
      );

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchInternalTransferReceivid = createAsyncThunk(
  "voucher/fetchInternalTransferReceivid",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await internalTransferReceivid(
        payload.idClient,
        payload.idAccountTransaction
      );

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchVoucherPdf = createAsyncThunk(
  "voucher/fetchVoucherPdf",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getVoucherPdf(
        payload.idClient, 
        payload.idAccountTransaction
      );
      
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);
