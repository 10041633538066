import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEqual } from "lodash";

import Input from "components/input";
import Button from "components/button";
import Select from "components/select";
import { cepMask, numberMask } from "utils/mask";
import { ufs } from "utils/ufs";
import { setEditingForm } from "reducers/client/clientSlice";
import { updateClient } from "reducers/client/clientThunk";
import { fetchZipcode } from "reducers/zipcode/zipcodeThunk";
import { checkRole } from "utils/isRoleAllowed";
import { USER_ADMIN, USER_OPERACOES, ADMIN_ECOSYSTEM } from "utils/const";

export default function PersonalAddress() {
  const dispatch = useDispatch();
  const { client, isUpdating, editingForm } = useSelector(
    (store) => store.client
  );
  const { role } = useSelector((store) => store.user);
  const zipcode = useSelector((store) => store.zipcode);
  const [personalAddress, setPersonalAddress] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [canUpdateCep, setCanUpdateCep] = useState(false);
  const [isEditingMode, setIsEditingMode] = useState(false);

  useEffect(() => {
    !isUpdating && setIsEditingMode(false);
  }, [isUpdating]);

  useEffect(() => {
    if (canUpdateCep && zipcode.localidade) {
      setPersonalAddress((prevState) => ({
        ...prevState,
        endereco: zipcode.logradouro,
        enderecoBairro: zipcode.bairro,
        enderecoCidade: zipcode.localidade,
        enderecoEstado: zipcode.uf,
        enderecoCEP: zipcode.cep,
      }));
      setCanUpdateCep(false);
    }

    // eslint-disable-next-line
  }, [zipcode]);

  useEffect(() => {
    setPersonalAddress({
      enderecoCEP: client.enderecoCEP,
      endereco: client.endereco,
      enderecoNumero: client.enderecoNumero,
      enderecoBairro: client.enderecoBairro,
      enderecoComplemento: client.enderecoComplemento,
      enderecoEstado: client.enderecoEstado,
      enderecoCidade: client.enderecoCidade,
    });
  }, [client, isEditingMode]);

  const changePersonalAddress = (event) => {
    const { name, value } = event.target;

    setPersonalAddress((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const validateForm = (values) => {
    const errors = {};

    if (!values.enderecoCEP) {
      errors.enderecoCEP = "CEP deve ser informado!";
    } else if (numberMask(values.enderecoCEP).length !== 8) {
      errors.enderecoCEP = "CEP deve ter 8 dígitos!";
    }

    if (!values.endereco) {
      errors.endereco = "Rua deve ser informada!";
    }

    if (!values.enderecoNumero) {
      errors.enderecoNumero = "Número deve ser informado!";
    }

    if (!values.enderecoBairro) {
      errors.enderecoBairro = "Bairro deve ser informado!";
    }

    if (!values.enderecoEstado) {
      errors.enderecoEstado = "Estado deve ser informado!";
    }

    if (!values.enderecoCidade) {
      errors.enderecoCidade = "Cidade deve ser informada!";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const comparePersonalAddress = () => {
    const clientStore = {
      enderecoCEP: client.enderecoCEP,
      endereco: client.endereco,
      enderecoNumero: client.enderecoNumero,
      enderecoBairro: client.enderecoBairro,
      enderecoComplemento: client.enderecoComplemento,
      enderecoEstado: client.enderecoEstado,
      enderecoCidade: client.enderecoCidade,
    };

    return isEqual(clientStore, {
      ...personalAddress,
      enderecoCEP: numberMask(personalAddress.enderecoCEP),
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateForm({ ...personalAddress }) && !isUpdating) {
      dispatch(
        updateClient({
          ...client,
          ...personalAddress,
          accountType: 1,
          enderecoCEP: numberMask(personalAddress.enderecoCEP),
        })
      );
      dispatch(setEditingForm(""));
    }
  };

  const handleBlur = (event) => {
    const { value } = event.target;
    const formZipcode = numberMask(value);

    if (formZipcode && formZipcode.length === 8) {
      setCanUpdateCep(true);
      dispatch(fetchZipcode(formZipcode));
    }
  };

  const handleEdit = () => {
    dispatch(setEditingForm("personal-address"));
    setIsEditingMode(true);
  };

  const handleCancel = () => {
    dispatch(setEditingForm(""));
    setIsEditingMode(false);
    setFormErrors({});
  };

  return (
    <form action="#" method="POST" onSubmit={handleSubmit}>
      <div className="shadow sm:overflow-hidden sm:rounded-md">
        <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Endereço
            </h3>
          </div>

          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"CEP"}
                type={"text"}
                name={"enderecoCEP"}
                id={"enderecoCEP"}
                value={cepMask(personalAddress.enderecoCEP) || ""}
                onChange={changePersonalAddress}
                onBlur={handleBlur}
                error={formErrors.enderecoCEP}
                errorMessage={formErrors.enderecoCEP}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6">
              <Input
                label={"Rua"}
                type={"text"}
                name={"endereco"}
                id={"endereco"}
                value={personalAddress.endereco || ""}
                onChange={changePersonalAddress}
                error={formErrors.endereco}
                errorMessage={formErrors.endereco}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-2">
              <Input
                label={"Número"}
                type={"text"}
                name={"enderecoNumero"}
                id={"enderecoNumero"}
                value={personalAddress.enderecoNumero || ""}
                onChange={changePersonalAddress}
                error={formErrors.enderecoNumero}
                errorMessage={formErrors.enderecoNumero}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-2">
              <Input
                label={"Bairro"}
                type={"text"}
                name={"enderecoBairro"}
                id={"enderecoBairro"}
                value={personalAddress.enderecoBairro || ""}
                onChange={changePersonalAddress}
                error={formErrors.enderecoBairro}
                errorMessage={formErrors.enderecoBairro}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-2">
              <Input
                label={"Complemento"}
                type={"text"}
                name={"enderecoComplemento"}
                id={"enderecoComplemento"}
                value={personalAddress.enderecoComplemento || ""}
                onChange={changePersonalAddress}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Select
                label={"Estado de emissão RG"}
                id={"enderecoEstado"}
                name={"enderecoEstado"}
                value={personalAddress.enderecoEstado || ""}
                placeholder={"Selecione o estado"}
                options={ufs}
                onChange={changePersonalAddress}
                error={formErrors.enderecoEstado}
                errorMessage={formErrors.enderecoEstado}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"Cidade"}
                type={"text"}
                name={"enderecoCidade"}
                id={"enderecoCidade"}
                value={personalAddress.enderecoCidade || ""}
                onChange={changePersonalAddress}
                error={formErrors.enderecoCidade}
                errorMessage={formErrors.enderecoCidade}
                disabled={!isEditingMode}
              />
            </div>
          </div>
        </div>
        {checkRole(role, [USER_ADMIN, USER_OPERACOES, ADMIN_ECOSYSTEM]) && (
          <div className="bg-gray-50 px-4 space-x-3 py-3 text-right sm:px-6">
            {!isEditingMode && (
              <Button
                type={"button"}
                label={"Editar"}
                onClick={handleEdit}
                disabled={editingForm && editingForm !== "personal-address"}
              />
            )}
            {isEditingMode && (
              <>
                <Button
                  type={"submit"}
                  label={"Salvar"}
                  onClick={handleSubmit}
                  disabled={comparePersonalAddress()}
                />
                <Button
                  type={"button"}
                  secondary
                  label={"Cancelar"}
                  onClick={handleCancel}
                />
              </>
            )}
          </div>
        )}
      </div>
    </form>
  );
}
