import { useEffect, useState } from "react";

import Layout from "components/layout";
import Tab from "components/tab";
import navigationMenu from "./navigation";

const LimitsRequest = () => {
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    const tabItems = navigationMenu.filter((item) => item.active);

    setTabs(tabItems);
  }, []);

  const handleTabNavigation = (evt) => {
    const { value: id } = evt.target;
    setTabs(
      tabs.map((tab) =>
        tab.id === parseInt(id)
          ? { ...tab, current: true }
          : { ...tab, current: false }
      )
    );
  };

  const displayItem = tabs.find((item) => item.current && item.component);

  return (
    <Layout title="Solicitações para aumento de limite transacional">
      {tabs.length > 0 && (
        <>
          <Tab tabs={tabs} onChangeTab={handleTabNavigation} />
          <div className="space-y-6">
            {displayItem && <displayItem.component />}
          </div>
        </>
      )}
    </Layout>
  );
};

export default LimitsRequest;
