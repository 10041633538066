import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Pagination from "components/pagination";
import ConfirmationModal from "components/modal-confirmation";
import Modal from "components/modal";
import Input from "components/input";
import Select from "components/select";
import Button from "components/button";
import { fetchBanks } from "reducers/onboarding/onboardingThunk";
import { checkRole } from "utils/isRoleAllowed";
import { USER_ADMIN, USER_OPERACOES, ADMIN_ECOSYSTEM } from "utils/const";
import {
  fetchGroupsFees,
  excludeGroupFee,
  createGroupFee,
  editGroupFee,
} from "reducers/fees/feesThunk";

const GroupFees = () => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [idGroupFee, setIdGroupFee] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [payload, setPayload] = useState({});
  const [payloadError, setPayloadError] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const { groupsFeesList } = useSelector((store) => store.fees);
  const { banks } = useSelector((store) => store.onboarding);
  const { role, id_Customer_Bank } = useSelector((store) => store.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGroupsFees(currentPage));

    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    if (!banks.length) dispatch(fetchBanks());

    // eslint-disable-next-line
  }, [banks]);

  const handleOpenConfirmationModal = (id) => {
    setIsConfirmationModalOpen(true);
    setIdGroupFee(id);
  };

  const handleDeleteFee = () => {
    dispatch(excludeGroupFee(idGroupFee));
    setIsConfirmationModalOpen(false);
    setCurrentPage(1);
  };

  const renderBankName = (idBank) => {
    const bankArr = banks.filter((bank) => bank.id === idBank);
    if (bankArr.length) return bankArr[0].name;

    return idBank;
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const changeCurrentPage = (page = 1) => {
    setCurrentPage(page);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setPayload((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const validatePayload = (values) => {
    const errors = {};

    if (process.env.REACT_APP_MODE === "CORE" && !values.idCustomerBank) {
      errors.idCustomerBank = "Selecione o banco!";
    }

    if (!values.nomeGrupoTarifa) {
      errors.nomeGrupoTarifa = "Nome deve ser informado!";
    }

    setPayloadError(errors);

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleCreateGroup = () => {
    if (validatePayload(payload)) {
      if (!isUpdate) {
        dispatch(
          createGroupFee({
            ...payload,
            idCustomerBank:
              process.env.REACT_APP_MODE === "CORE"
                ? payload.idCustomerBank
                : id_Customer_Bank,
          })
        );
      } else {
        dispatch(
          editGroupFee({
            idGroupFee,
            data: {
              ...payload,
              idCustomerBank:
                process.env.REACT_APP_MODE === "CORE"
                  ? payload.idCustomerBank
                  : id_Customer_Bank,
            },
          })
        );
      }
      setIsModalOpen(false);
      setPayload({});
      setPayloadError({});
      setCurrentPage(1);
    }
  };

  const handleCancelModal = () => {
    setIsModalOpen(false);
    setPayload({});
    setPayloadError({});
  };

  const openModalHandler = (update, fee) => {
    setIsUpdate(update);
    if (fee) {
      setIdGroupFee(fee.idFeeGroup);
      setPayload(fee);
    }
    setIsModalOpen(true);
  };

  return (
    <div className="relative">
      <ConfirmationModal
        title={"Deseja prosseguir?"}
        description={"Caso confirme, sua ação não poderá ser desfeita."}
        confirmButtonLabel={"Continuar"}
        onConfirm={handleDeleteFee}
        cancelButtonLabel={"Cancelar"}
        onCancel={() => setIsConfirmationModalOpen(false)}
        isModalOpen={isConfirmationModalOpen}
      />
      <Modal
        title={isUpdate ? "Atualizar Grupo" : "Cadastrar Grupo"}
        confirmButtonLabel={isUpdate ? "Salvar" : "Cadastrar"}
        onConfirm={handleCreateGroup}
        cancelButtonLabel={"Cancelar"}
        onCancel={handleCancelModal}
        isModalOpen={isModalOpen}
      >
        <div className="mt-5 text-left flex flex-col gap-5">
          {process.env.REACT_APP_MODE === "CORE" && (
            <div>
              <Select
                label={"Banco"}
                id={"idCustomerBank"}
                name={"idCustomerBank"}
                value={payload.idCustomerBank || ""}
                placeholder={"Selecione o banco"}
                options={banks}
                onChange={handleChange}
                error={payloadError.idCustomerBank}
                errorMessage={payloadError.idCustomerBank}
                disabled={isUpdate}
              />
            </div>
          )}
          <div>
            <Input
              label={"Nome"}
              type={"text"}
              name={"nomeGrupoTarifa"}
              id={"nomeGrupoTarifa"}
              value={payload.nomeGrupoTarifa || ""}
              onChange={handleChange}
              error={payloadError.nomeGrupoTarifa}
              errorMessage={payloadError.nomeGrupoTarifa}
            />
          </div>
        </div>
      </Modal>
      <div className="shadow sm:overflow-hidden sm:rounded-md">
        <div className="bg-white py-6 px-4 sm:p-6">
          <div className="flex items-center justify-between w-full">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Grupo de Tarifas
            </h3>
            {checkRole(role, [USER_ADMIN, USER_OPERACOES, ADMIN_ECOSYSTEM]) && (
              <div className="flex items-center gap-3">
                <Button
                  type={"button"}
                  label={"Novo Grupo"}
                  onClick={() => openModalHandler(false)}
                />
              </div>
            )}
          </div>
          <div className="mt-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    ID
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    Nome
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                  >
                    Banco
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {groupsFeesList.tarifaGrupos.length > 0 ? (
                  groupsFeesList.tarifaGrupos.map((fee) => (
                    <tr key={fee.idFeeGroup}>
                      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                        {fee.idFeeGroup}
                      </td>
                      <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                        {fee.nomeGrupoTarifa}
                      </td>
                      <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                        {renderBankName(parseInt(fee.idCustomerBank))}
                      </td>
                      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex flex-col gap-1 items-start">
                        <button
                          className="text-indigo-600 hover:text-indigo-500"
                          onClick={() => openModalHandler(true, fee)}
                        >
                          Editar
                        </button>
                        <button
                          className="text-red-600 hover:text-red-500"
                          onClick={() =>
                            handleOpenConfirmationModal(fee.idFeeGroup)
                          }
                        >
                          Deletar
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={3}
                      className="text-sm sm:text-base text-gray-500 text-center py-5"
                    >
                      Não há dados para exibir
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Pagination
              total={groupsFeesList.total}
              pageItens={30}
              pageCount={Math.ceil(groupsFeesList.total / 30)}
              currentPage={currentPage}
              onNextPage={handleNextPage}
              onPreviousPage={handlePreviousPage}
              onClick={changeCurrentPage}
              itemsPerPage={30}
              showItemsPerPageOption={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupFees;
