import Limits from "./limits";
import EmailBase from "./email-base";
import Onboarding from "./onboarding";
import Fees from "./fees";

const navigationMenu = [
  {
    id: 1,
    name: "Limites",
    current: true,
    active: true,
    component: Limits,
  },
  {
    id: 2,
    name: "Envio de email",
    current: false,
    active: process.env.REACT_APP_MODE === "CORE" ? true : false,
    component: EmailBase,
  },
  {
    id: 3,
    name: "Onboarding",
    current: false,
    active: true,
    component: Onboarding,
  },
  {
    id: 4,
    name: "Tarifas",
    current: false,
    active: true,
    component: Fees,
  },
];

export default navigationMenu;
