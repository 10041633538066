import { Fragment, useState, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useSelector, useDispatch } from "react-redux";
import Datepicker from "react-tailwindcss-datepicker";
import moment from "moment";

import Button from "components/button";
import Select from "components/select";
import Input from "components/input";
import { fetchTransactionTypes } from "reducers/fees/feesThunk";
import { currencyMask, convertCurrencyBRLToFloat } from "utils/mask";
import { USER_ADMIN, PRE_TRANSACTION_APROVER } from "utils/const";
import { checkRole } from "utils/isRoleAllowed";
import {
  postPreTransaction,
  fetchPendingPreTransactions,
} from "reducers/accounts/accountsThunk";

moment.locale("pt-br");

export default function ModalTransaction({
  title,
  onCancel,
  isModalOpen,
  idAccount,
  idClient,
  idCustomerBank,
}) {
  const dialogRef = useRef(null);
  const dispatch = useDispatch();
  const { transactionTypes } = useSelector((store) => store.fees);
  const { preTransaction } = useSelector((store) => store.accounts);
  const { role } = useSelector((store) => store.user);

  const [releaseDate, setReleaseDate] = useState({
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [selectOptions, setSelectOptions] = useState([]);
  const [formattedValue, setFormattedValue] = useState("");

  useEffect(() => {
    if (preTransaction.success) {
      closeModalHandler();

      if (checkRole(role, [USER_ADMIN, PRE_TRANSACTION_APROVER])) {
        dispatch(fetchPendingPreTransactions());
      }
    }

    // eslint-disable-next-line
  }, [preTransaction.success]);

  useEffect(() => {
    dispatch(fetchTransactionTypes());
  }, [dispatch]);

  useEffect(() => {
    setSelectOptions(
      transactionTypes.map((transaction) => ({
        id: transaction.idTransaction,
        name: transaction.descricao,
      }))
    );
  }, [transactionTypes]);

  function changeFormHandler(event) {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  }

  function validateFormHandler() {
    const errors = {};

    if (!formData.idTransaction) {
      errors.idTransaction = "Tipo de transação deve ser informado!";
    }

    if (moment(releaseDate.startDate, "YYYY-MM-DD").isAfter(moment(), "day")) {
      errors.releaseDate = "Data não pode ser superior ao dia de hoje!";
    }

    if (!formData.TipoEntrada) {
      errors.TipoEntrada = "Obrigatório!";
    }

    if (!formattedValue.length) {
      errors.Valor = "Valor deve ser informado!";
    }

    if (!formData.Descricao) {
      errors.Descricao = "Descrição deve ser informada!";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  }

  const submitFormHandler = (e) => {
    e.preventDefault();

    if (!validateFormHandler()) {
      return;
    }

    let payload = {
      ...formData,
      idAccount,
      idClient,
      idCustomerBank,
      Valor: convertCurrencyBRLToFloat(formattedValue),
    };

    if (releaseDate.startDate !== null) {
      payload = {
        ...payload,
        Data: `${releaseDate.startDate}T12:00:00.000`,
      };
    }

    dispatch(postPreTransaction(payload));
  };

  const handleReleaseDateChange = (newValue) => {
    const startDate = moment(newValue.startDate, "YYYY-MM-DD");
    const endDate = moment(newValue.endDate, "YYYY-MM-DD");

    setReleaseDate({
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
    });
  };

  const closeModalHandler = () => {
    setFormData({});
    setFormErrors({});
    setFormattedValue("");
    setReleaseDate({
      startDate: null,
      endDate: null,
    });
    onCancel();
  };

  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative 3-10"
        onClose={closeModalHandler}
        initialFocus={dialogRef}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-30 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg sm:p-6 overflow-visible">
                <div className="text-center">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {title}
                  </Dialog.Title>
                  <form onSubmit={submitFormHandler}>
                    <div className="mt-5 text-left grid grid-cols-8 gap-5">
                      <div className="col-span-8">
                        <Select
                          label={"Transação"}
                          name={"idTransaction"}
                          id={"idTransaction"}
                          placeholder={"Selecione a transação"}
                          options={selectOptions}
                          value={formData.idTransaction || ""}
                          error={formErrors.idTransaction}
                          errorMessage={formErrors.idTransaction}
                          onChange={changeFormHandler}
                        />
                      </div>
                      <div className="col-span-8 w-full">
                        <label className="block text-sm font-medium text-gray-700">
                          Data de lançamento
                        </label>
                        <Datepicker
                          name="Data"
                          i18n="pt"
                          displayFormat={"DD/MM/YYYY"}
                          useRange={false}
                          asSingle={true}
                          onChange={handleReleaseDateChange}
                          value={releaseDate}
                          readOnly={true}
                          inputClassName="py-2 w-full mt-1 outline-0 text-sm rounded-md border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                        />
                        {formErrors?.releaseDate && (
                          <p className="mb-0.5 mt-2 text-sm text-red-600">
                            {formErrors.releaseDate}
                          </p>
                        )}
                      </div>
                      <div className="col-span-8 sm:col-span-2">
                        <Select
                          label={"Tipo entrada"}
                          name={"TipoEntrada"}
                          id={"TipoEntrada"}
                          placeholder={"Selecione a transação"}
                          options={[
                            { id: "D", name: "Débito" },
                            { id: "C", name: "Crédito" },
                          ]}
                          value={formData.TipoEntrada || ""}
                          error={formErrors.TipoEntrada}
                          errorMessage={formErrors.TipoEntrada}
                          onChange={changeFormHandler}
                        />
                      </div>
                      <div className="col-span-8 sm:col-span-6">
                        <Input
                          label={"Valor"}
                          name={"Valor"}
                          id={"Valor"}
                          type={"text"}
                          prefix={"R$"}
                          value={formattedValue || ""}
                          onChange={(e) =>
                            setFormattedValue(currencyMask(e.target.value))
                          }
                          placeholder={"0,00"}
                          error={formErrors.Valor}
                          errorMessage={formErrors.Valor}
                        />
                      </div>
                      <div className="col-span-8">
                        <Input
                          label={"Descrição"}
                          name={"Descricao"}
                          id={"Descricao"}
                          type={"text"}
                          value={formData.Descricao || ""}
                          error={formErrors.Descricao}
                          errorMessage={formErrors.Descricao}
                          onChange={changeFormHandler}
                        />
                      </div>
                    </div>
                    <div className="mt-5">
                      <p className="text-sm text-gray-500">
                        A transação não será adicionada automaticamente na conta
                        do cliente. É necessário a aprovação de um usuário admin
                        para que isso aconteça
                      </p>
                    </div>
                    <div className="mt-5 sm:mt-6 flex flex-col sm:grid sm:grid-flow-row-dense sm:grid-cols-2 gap-3">
                      <Button label={"Solicitar"} type={"submit"} />
                      <Button
                        label={"Cancelar"}
                        type={"button"}
                        secondary
                        onClick={closeModalHandler}
                        ref={dialogRef}
                      />
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
