import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";

import {
  newPfAccount,
  newPjAccount,
  getBanksList,
  addPermissiveList,
  removeFromPermissiveList,
  getAllPermissiveList,
  getAllRestrictiveList,
  getRestrictiveListType,
  addRestrictiveList,
  removeFromRestrictiveList,
  getOnboardingList,
  createCelcoinPf,
  createCelcoinPj,
} from "services/onboarding";
import { fetchClient } from "reducers/client/clientThunk";
import { setIsLoading } from "reducers/loading/isLoadingSlice";
import { setNotification } from "reducers/notification/notificationSlice";

export const postPfAccount = createAsyncThunk(
  "onboarding/postPfAccount",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const { user } = thunkAPI.getState();

      await newPfAccount({
        ...payload,
        solicitanteId: parseInt(user.id_Client),
      });

      thunkAPI.dispatch(
        setNotification({
          type: "success",
          title: "Conta Criada",
          message: "Conta criada com sucesso!",
        })
      );
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const postPjAccount = createAsyncThunk(
  "onboarding/postPjAccount",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const { user } = thunkAPI.getState();

      await newPjAccount({
        ...payload,
        solicitanteId: parseInt(user.id_Client),
      });

      thunkAPI.dispatch(
        setNotification({
          type: "success",
          title: "Conta Criada",
          message: "Conta criada com sucesso!",
        })
      );
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchBanks = createAsyncThunk(
  "onboarding/fetchBanks",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getBanksList();
      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(null);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const postPermissiveList = createAsyncThunk(
  "onboarding/postPermissiveList",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await addPermissiveList(payload);

      thunkAPI.dispatch(
        setNotification({
          type: "success",
          title: "Inclusão Realizada",
          message: "O documento foi incluído na lista!",
        })
      );

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(null);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const deletePermissiveList = createAsyncThunk(
  "onboarding/deletePermissiveList",
  async (idOnboardingPermissiveList, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await removeFromPermissiveList(
        idOnboardingPermissiveList
      );

      const operationResult = get(response, "data.success", "erro");

      if (operationResult) {
        thunkAPI.dispatch(
          setNotification({
            type: "success",
            title: "Documento Removido",
            message: "O documento foi removido da lista!",
          })
        );
      } else if (!operationResult) {
        thunkAPI.dispatch(
          setNotification({
            type: "error",
            title: "Onboarding Iniciado",
            message:
              "O onboarding já foi iniciado para este documento, não é possível excluir da lista!",
          })
        );
      } else {
        thunkAPI.dispatch(
          setNotification({
            type: "error",
            title: "Erro",
            message: "Houve um erro interno, entre em contato com o suporte!",
          })
        );

        return thunkAPI.rejectWithValue(null);
      }

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(null);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchAllPermissiveList = createAsyncThunk(
  "onboarding/fetchAllPermissiveList",
  async (
    { dataInicio, dataFim, document, paginaAtual, itemsPerPage },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getAllPermissiveList(
        dataInicio,
        dataFim,
        document,
        paginaAtual,
        itemsPerPage
      );

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue([]);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchAllRestrictiveList = createAsyncThunk(
  "onboarding/fetchAllRestrictiveList",
  async (
    { dataInicio, dataFim, document, paginaAtual, itemsPerPage },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getAllRestrictiveList(
        dataInicio,
        dataFim,
        document,
        paginaAtual,
        itemsPerPage
      );

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue([]);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchRestrictiveListTypes = createAsyncThunk(
  "onboarding/fetchRestrictiveListTypes",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getRestrictiveListType();

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue([]);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const postRestrictiveList = createAsyncThunk(
  "onboarding/postRestrictiveList",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      await addRestrictiveList(payload);

      thunkAPI.dispatch(
        setNotification({
          type: "success",
          title: "Inclusão Realizada",
          message: "O documento foi incluído na lista!",
        })
      );
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const deleteRestrictiveList = createAsyncThunk(
  "onboarding/deleteRestrictiveList",
  async ({ idRestrictiveListOnboarding, payload }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      await removeFromRestrictiveList(idRestrictiveListOnboarding, payload);

      thunkAPI.dispatch(
        setNotification({
          type: "success",
          title: "Documento Removido",
          message: "O documento foi removido da lista!",
        })
      );
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchOnboardingList = createAsyncThunk(
  "onboarding/fetchOnboardingList",
  async (
    {
      dataInicio,
      dataFim,
      document,
      paginaAtual,
      itemsPerPage,
      idCustomerBank,
    },
    thunkAPI
  ) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getOnboardingList(
        dataInicio,
        dataFim,
        document,
        paginaAtual,
        itemsPerPage,
        idCustomerBank
      );

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const createCelcoinAccount = createAsyncThunk(
  "onboarding/createCelcoinAccount",
  async ({ accountType, idClient }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      if (accountType === "PJ") {
        await createCelcoinPj(idClient);
      } else {
        await createCelcoinPf(idClient);
      }

      thunkAPI.dispatch(
        setNotification({
          type: "success",
          title: "Conta Criada",
          message: "A conta foi criada na celcoin!",
        })
      );

      fetchClient({ accountType, idClient });
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);
