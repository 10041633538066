import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TrashIcon } from "@heroicons/react/24/outline";

import Layout from "components/layout";
import Modal from "components/modal";
import ConfirmationModal from "components/modal-confirmation";
import TextArea from "components/textarea";
import {
  fetchCancelAccounts,
  accountCancel,
  deleteCancelRequest,
} from "reducers/accounts/accountsThunk";
import {
  formatWithTimezone,
  convertCurrencyBRLToString,
  cpfMask,
  cnpjMask,
} from "utils/mask";

const Cancellation = () => {
  const [idClient, setIdClient] = useState("");
  const [reason, setReason] = useState("");
  const [error, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const { cancelAccounts } = useSelector((store) => store.accounts);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCancelAccounts());

    // eslint-disable-next-line
  }, []);

  const renderDocument = (document) => {
    if (!document) return "";
    if (document.length === 14) {
      return cnpjMask(document);
    } else if (document.length === 11) {
      return cpfMask(document);
    }
  };

  const handleNavigation = (id, document) => {
    let type = 1;
    document.length === 14 ? (type = 2) : (type = 1);

    return `/contas/detalhes/${id}?type=${type}`;
  };

  const handleSubmit = () => {
    if (!reason) {
      setError("Motivo de cancelamento deve ser informado!");
      return;
    }

    dispatch(accountCancel({ idClient, RazaoCancelamento: reason }));
    handleCloseModal();
  };

  const handleOpenModal = (id) => {
    setIdClient(id);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setReason("");
    setError("");
    setIdClient("");
  };

  const handleOpenConfirmationModal = (id) => {
    setIdClient(id);
    setIsConfirmationModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
    setIdClient("");
  };

  const handleDeleteRequest = () => {
    dispatch(deleteCancelRequest(idClient));
    handleCloseConfirmationModal();
  };

  return (
    <>
      <ConfirmationModal
        title={"Deseja excluir a solicitação?"}
        description={
          "Sua ação irá excluir a solicitação de cancelamento feita pelo cliente e a conta permanecerá aberta. Deseja prosseguir?"
        }
        confirmButtonLabel={"Continuar"}
        onConfirm={handleDeleteRequest}
        cancelButtonLabel={"Cancelar"}
        onCancel={handleCloseConfirmationModal}
        isModalOpen={isConfirmationModalOpen}
      />
      <Modal
        title={""}
        description={""}
        confirmButtonLabel={"Cancelar conta"}
        onConfirm={handleSubmit}
        cancelButtonLabel={"Voltar"}
        onCancel={handleCloseModal}
        isModalOpen={isModalOpen}
      >
        <div className="text-left">
          <TextArea
            id={"reason"}
            name={"reason"}
            label={"Motivo de cancelamento"}
            placeholder={"Descreva o motivo do cancelamento da conta..."}
            value={reason || ""}
            onChange={(evt) => setReason(evt.target.value)}
            maxLength={500}
            error={error}
            errorMessage={error}
          />
        </div>
      </Modal>
      <Layout title={"Solicitação de cancelamento"}>
        <div className="mt-4 sm:mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:mx-0 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:table-cell whitespace-nowrap"
                >
                  ID Cliente
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Cliente
                </th>
                {process.env.REACT_APP_MODE === "CORE" && (
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    Banco
                  </th>
                )}
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell whitespace-nowrap"
                >
                  Data solicitação
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Saldo
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Detalhes</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {cancelAccounts && cancelAccounts.length > 0 ? (
                cancelAccounts.map((item) => (
                  <tr key={item.idClient}>
                    <td className="hidden pl-4 pr-3 py-4 sm:pl-6 text-sm text-gray-500 lg:table-cell">
                      <Link
                        className="text-indigo-600 hover:text-indigo-500 text-left font-medium"
                        to={handleNavigation(item.idClient, item.documento)}
                        target="_blank"
                      >
                        {item.idClient}
                      </Link>
                    </td>
                    <td className="w-full max-w-0 py-4 px-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none">
                      <span>{item.nome}</span>
                      <dl className="font-normal text-gray-500">
                        <dt className="sr-only">Documento</dt>
                        <dd className="mt-1 truncate">
                          {renderDocument(item.documento)}
                        </dd>
                        {process.env.REACT_APP_MODE === "CORE" && (
                          <>
                            <dt className="sr-only lg:hidden">Banco</dt>
                            <dd className="mt-1 truncate lg:hidden">
                              {item.nomeCustomerBank}
                            </dd>
                          </>
                        )}
                        <dt className="sr-only lg:hidden">Data Solicitação</dt>
                        <dd className="mt-1 truncate lg:hidden">
                          Solicitado em:{" "}
                          {formatWithTimezone(item.dataRequisicao)}
                        </dd>
                        <dt className="sr-only sm:hidden">Data Solicitação</dt>
                        <dd className="mt-1 truncate sm:hidden">
                          Saldo: R$ {convertCurrencyBRLToString(item.saldo)}
                        </dd>
                      </dl>
                    </td>
                    {process.env.REACT_APP_MODE === "CORE" && (
                      <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell whitespace-nowrap">
                        {item.nomeCustomerBank}
                      </td>
                    )}
                    <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell whitespace-nowrap">
                      {formatWithTimezone(item.dataRequisicao)}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell whitespace-nowrap">
                      R$ {convertCurrencyBRLToString(item.saldo)}
                    </td>
                    <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 whitespace-nowrap">
                      <div className="flex items-center gap-5 justify-around">
                        <div className="flex flex-col">
                          <Link
                            className="text-indigo-600 hover:text-indigo-500 text-left font-medium"
                            to={handleNavigation(item.idClient, item.documento)}
                            target="_blank"
                          >
                            Detalhes
                            <span className="sr-only">, {item.idClient}</span>
                          </Link>
                          <button
                            onClick={() =>
                              !item.saldo && handleOpenModal(item.idClient)
                            }
                            className={`${
                              !item.saldo
                                ? "text-red-600 hover:text-red-500"
                                : "cursor-not-allowed text-gray-400"
                            }`}
                          >
                            Cancelar conta
                            <span className="sr-only">, {item.idClient}</span>
                          </button>
                        </div>
                        <TrashIcon
                          className="h-5 w-5 text-red-600 cursor-pointer hover:scale-125"
                          onClick={() =>
                            handleOpenConfirmationModal(item.idClient)
                          }
                        />
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={process.env.REACT_APP_MODE === "CORE" ? 6 : 5}
                    className="text-sm sm:text-base text-gray-500 text-center py-5"
                  >
                    Não há dados para exibir
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Layout>
    </>
  );
};

export default Cancellation;
