import { convertCurrencyBRLToString } from "utils/mask";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload.length) {
    return (
      <div className="bg-white rounded flex flex-col py-2 px-4 border shadow-md">
        <span className="text-gray-900 font-medium">
          {label ? label : payload[0].name}
        </span>
        <span className="text-gray-500">
          R$ {convertCurrencyBRLToString(payload[0].value)}
        </span>
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
