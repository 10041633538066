import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { menuClasses } from "react-pro-sidebar";
import { useMsal } from "@azure/msal-react";
import {
  Cog8ToothIcon,
  ArrowUpTrayIcon,
  BellIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import {
  Sidebar as SidebarRoot,
  Menu as MenuRoot,
  MenuItem,
  SubMenu,
} from "react-pro-sidebar";
import { Bars3Icon } from "@heroicons/react/24/solid";

import navigationMenu from "./navigation";
import classNames from "utils/classNames";
import { ADMIN_ECOSYSTEM } from "utils/const";
import { fetchUser } from "reducers/user/userThunk";
import { checkRole } from "utils/isRoleAllowed";
import { logos } from "utils/logos";
import { banks } from "utils/banks";
import useWindowResize from "hooks/useWindowResize";

const menuRootStyles = {
  [`.${menuClasses.icon}`]: {
    color: "#3949ab",
  },
  [`.${menuClasses.label}`]: {
    color: "#111827",
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 500,
  },
  [`.${menuClasses.SubMenuExpandIcon}`]: {
    color: "#4b5563",
  },
  [`.${menuClasses.active}`]: {
    background: "#F3F3F3",
  },
  [`.${menuClasses.subMenuContent}`]: {
    background: "#FFFFFF",
  },
};
const DISPLAY_COLLAPSED = "collapsed";
const DISPLAY_EXPAND = "expand";
const BREAK_POINT_WIDTH = 768;

const Sidebar = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((store) => store.user);
  const { instance } = useMsal();
  const { notification } = useSelector((store) => store.azureAccount);
  const [toggled, setToggled] = useState(false);
  const [currentNavigationId, setCurrentNavigationId] = useState(6);
  const { width, collapsed, setCollapsed } = useWindowResize(BREAK_POINT_WIDTH);

  useEffect(() => {
    dispatch(fetchUser());

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const showSidebarMenu = localStorage.getItem("showSidebarMenu");

    showSidebarMenu === DISPLAY_COLLAPSED
      ? setCollapsed(true)
      : setCollapsed(false);

    // eslint-disable-next-line
  }, []);

  const handleCurrentNavigationId = (id) => {
    setCurrentNavigationId(id);
    setToggled((prevToggled) => !prevToggled);
  };

  function handleCollapsedSidebar() {
    setCollapsed((prevState) => {
      const value = !prevState;

      value
        ? localStorage.setItem("showSidebarMenu", DISPLAY_COLLAPSED)
        : localStorage.setItem("showSidebarMenu", DISPLAY_EXPAND);

      return value;
    });
  }

  function handleClickLogo() {
    if (checkRole(user.role, [ADMIN_ECOSYSTEM])) {
      navigate("/");
    } else {
      navigate("/contas");
    }
  }

  function getMenuName() {
    const fullName = user.name.split(" ");
    const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
    return initials.toUpperCase();
  }

  const handleLogout = () => {
    instance.logoutRedirect({
      onRedirectNavigate: () => {
        navigate("/");
        return false;
      },
    });
  };

  const renderExpandIcon = (open) => {
    return (
      <ChevronRightIcon
        className={classNames(
          "h-3 w-3 text-gray-800 transition-transform duration-300",
          open && "rotate-90"
        )}
      />
    );
  };

  const getHeaderLogo = () => {
    const idCustomerBank = user.id_Customer_Bank;

    const logoSmall = logos.filter(
      (logo) => logo.idCustomerBank === parseInt(idCustomerBank)
    );

    const logoBig = banks.filter(
      (logo) => logo.idCustomerBank === parseInt(idCustomerBank)
    );

    return (
      <>
        {collapsed && width > BREAK_POINT_WIDTH ? (
          <div className="h-12 w-12 flex justify-center">{logoSmall[0].svg}</div>
        ) : (
          <div className="h-8 w-full flex justify-center">{logoBig[0].svg}</div>
        )}
      </>
    );
  };

  return (
    <div className="flex flex-row">
      {width < BREAK_POINT_WIDTH ? (
        <div className="flex justify-center items-center fixed z-10 w-full h-auto bg-white border-b">
          <button
            onClick={() => setToggled(!toggled)}
            className="fixed top-0 left-0 px-4 py-2"
          >
            <Bars3Icon className="h-8 w-8 text-gray-800" />
          </button>
          <div className="mx-auto px-4 py-2">
            {user?.id_Customer_Bank && getHeaderLogo()}
          </div>
        </div>
      ) : (
        <button
          onClick={handleCollapsedSidebar}
          className={classNames(
            "fixed top-1/2 z-20 p-1 border border-gray-300 bg-white rounded-full",
            collapsed
              ? "left-[65px] transition-width-right-left duration-300"
              : "left-[235px] transition-width-left-right duration-300"
          )}
        >
          <ChevronRightIcon
            className={classNames(
              "h-4 w-4 text-gray-800 transition-transform duration-300",
              !collapsed && "rotate-180"
            )}
          />
        </button>
      )}

      <SidebarRoot
        backgroundColor="white"
        style={{
          position: "fixed",
          height: "100%",
          zIndex: 10,
        }}
        collapsed={collapsed}
        onBackdropClick={() => setToggled(false)}
        toggled={toggled}
        breakPoint="md"
      >
        <div className="h-full flex flex-col justify-between relative w-auto">
          <div>
            {width > BREAK_POINT_WIDTH && (
              <div
                className="flex-shrink-0 flex items-center cursor-pointer h-16 px-5"
                onClick={handleClickLogo}
              >
                {user?.id_Customer_Bank && getHeaderLogo()}
              </div>
            )}
            <MenuRoot
              closeOnClick={collapsed}
              rootStyles={menuRootStyles}
              renderExpandIcon={({ open }) => renderExpandIcon(open)}
            >
              {user?.role?.length > 0 &&
                navigationMenu?.map((item) => {
                  if (
                    item.available &&
                    (!item.protected || checkRole(user.role, item.roles))
                  ) {
                    return item.subMenu?.length ? (
                      <SubMenu
                        key={item.id}
                        label={item.name}
                        icon={item.icon}
                        active={item.id === currentNavigationId}
                      >
                        {item?.subMenu?.map((option) => (
                          <MenuItem
                            key={option.id}
                            component={<Link to={option.href} />}
                            onClick={() => handleCurrentNavigationId(item.id)}
                          >
                            {option.name}
                          </MenuItem>
                        ))}
                      </SubMenu>
                    ) : (
                      <MenuItem
                        key={item.id}
                        icon={item.icon}
                        component={<Link to={item.href} />}
                        onClick={() => handleCurrentNavigationId(item.id)}
                        active={item.id === currentNavigationId}
                      >
                        {item.name}
                      </MenuItem>
                    );
                  } else {
                    return null;
                  }
                })}
            </MenuRoot>
          </div>
          <MenuRoot
            closeOnClick={collapsed}
            rootStyles={menuRootStyles}
            renderExpandIcon={({ open }) => renderExpandIcon(open)}
          >
            {notification && notification.length > 0 ? (
              <SubMenu
                label={"Notificações"}
                icon={
                  <div className="relative">
                    <div className="absolute text-[8px] text-white bg-danger rounded-full w-3 h-3 text-center cursor-pointer" />
                    <BellIcon className="h-5 w-5" />
                  </div>
                }
              >
                {notification?.map((item) => (
                  <MenuItem key={item.id} onClick={() => item.action()}>
                    {item.description}
                  </MenuItem>
                ))}
              </SubMenu>
            ) : (
              <MenuItem
                onClick={handleLogout}
                icon={<BellIcon className="h-5 w-5 text-gray-500" />}
                disabled
              >
                Notificações
              </MenuItem>
            )}
            <MenuItem
              component={<Link to="/admin" />}
              icon={<Cog8ToothIcon className="h-5 w-5" />}
              onClick={() => handleCurrentNavigationId(0)}
              active={currentNavigationId === 0}
            >
              Admin
            </MenuItem>
            <MenuItem
              onClick={handleLogout}
              icon={<ArrowUpTrayIcon className="h-5 w-5 text-danger" />}
            >
              Logout
            </MenuItem>
            <MenuItem disabled>
              <div className="max-w-xs mx-auto bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <span className="sr-only">Open user menu</span>
                <span className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-500">
                  <span className="text-sm font-medium leading-none text-white">
                    {user.name ? getMenuName() : ""}
                  </span>
                </span>
              </div>
            </MenuItem>
          </MenuRoot>
        </div>
      </SidebarRoot>
      <div
        className={classNames(
          "grow",
          width < BREAK_POINT_WIDTH
            ? "pt-6 transition-width-right-left duration-300"
            : collapsed
            ? "pl-20 transition-width-right-left duration-300"
            : "pl-[250px] transition-width-left-right duration-300"
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default Sidebar;
