import { useEffect, useReducer, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TrashIcon } from "@heroicons/react/24/solid";
import Layout from "components/layout";
import Button from "components/button";
import Modal from "components/modal";
import Input from "components/input";
import Select from "components/select";
import TextArea from "components/textarea";
import ConfirmationModal from "components/modal-confirmation";
import DatePicker from "components/date-picker";
import Pagination from "components/pagination";
import timezone from "moment-timezone";
import { cnpjMask, cpfMask, formatWithTimezone, numberMask } from "utils/mask";
import { checkRole } from "utils/isRoleAllowed";
import { USER_ADMIN, ADMIN_ECOSYSTEM } from "utils/const";
import {
  setAddToPermissiveList,
  setDeleteFromPermissiveList,
} from "reducers/onboarding/onboardingSlice";
import {
  fetchAllPermissiveList,
  fetchBanks,
  postPermissiveList,
  deletePermissiveList,
} from "reducers/onboarding/onboardingThunk";
import Tooltip from "components/tooltip";

const initialValues = {
  currentPage: 1,
  dateFilter: {
    startDate: timezone().subtract(30, "d").format("YYYY-MM-DD"),
    endDate: timezone().format("YYYY-MM-DD"),
  },
  itemsPerPage: 30
};

const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
const SET_DATE_FILTER = "SET_DATE_FILTER";
const SET_ITEMS_PER_PAGE = "SET_ITEMS_PER_PAGE";

const reducer = (state, action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE: 
      return {...state, currentPage: action.payload};
    case SET_DATE_FILTER: 
      return {...state, dateFilter: action.payload, currentPage: 1};
    case SET_ITEMS_PER_PAGE:
      return {...state, itemsPerPage: action.payload, currentPage: 1};
    default:
      return state;
  }
};

const PermissiveList = () => {
  const [payload, setPayload] = useState({});
  const [payloadErrors, setPayloadErrors] = useState({});
  const [document, setDocument] = useState("");
  const [error, setError] = useState("");
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idPermissiveList, setIdPermissiveList] = useState();
  const { permissiveList } = useSelector((store) => store.onboarding);
  const { role: userRole } = useSelector((store) => store.user);
  const { banks, isPosting, addToPermissiveList, deleteFromPermissiveList } = useSelector(
    (store) => store.onboarding
  );
  const [ state, localDispatch ] = useReducer(reducer, initialValues);
  const dispatch = useDispatch();

  const fetchWhiteListHandler = useCallback(() => {
    dispatch(
      fetchAllPermissiveList({
        dataInicio: state.dateFilter.startDate,
        dataFim: state.dateFilter.endDate,
        paginaAtual: state.currentPage,
        document: numberMask(document),
        itemsPerPage: state.itemsPerPage,
      })
    );

    // eslint-disable-next-line
  }, [dispatch, state]);

  useEffect(() => {
    fetchWhiteListHandler();

    // eslint-disable-next-line
  }, [fetchWhiteListHandler]);

  useEffect(() => {
    if (addToPermissiveList) {
      setIsModalOpen(false);
      setPayload({});
      fetchWhiteListHandler();
    }

    return () => {
      dispatch(setAddToPermissiveList(false));
    };

    // eslint-disable-next-line
  }, [addToPermissiveList]);

  useEffect(() => {
    if (deleteFromPermissiveList) {
      setIsConfirmationModalOpen(false);
      fetchWhiteListHandler();
    }

    return () => {
      dispatch(setDeleteFromPermissiveList(false));
    };

    // eslint-disable-next-line
  }, [deleteFromPermissiveList]);

  useEffect(() => {
    dispatch(fetchBanks());

    // eslint-disable-next-line
  }, []);

  const renderDocument = (document) => {
    if (!document) return "";
    const newDocument = numberMask(document);
    if (newDocument.length === 14) {
      return cnpjMask(newDocument);
    } else if (newDocument.length === 11) {
      return cpfMask(newDocument);
    }
  };

  const handleChangeDate = (newValue) => {
    const startDate = timezone(newValue.startDate, "YYYY-MM-DD");
    const endDate = timezone(newValue.endDate, "YYYY-MM-DD");

    localDispatch({
      type: SET_DATE_FILTER,
      payload: {
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
      },
    });
  };

  const handleChangeDocument = (evt) => {
    const { value } = evt.target;

    if (!value.length) {
      setDocument("");
    } else if (numberMask(value).length <= 11) {
      setDocument(cpfMask(value));
    } else {
      setDocument(cnpjMask(value));
    }
  };

  const handleFilter = () => {
    if (document.length) {
      if (
        numberMask(document).length !== 11 &&
        numberMask(document).length !== 14
      ) {
        setError("Tamanho inválido");
        return;
      }
    }

    setError("");

    changeCurrentPage();
  };

  const handleNextPage = () => {
    localDispatch({ type: SET_CURRENT_PAGE, payload: state.currentPage + 1 });
  };

  const handlePreviousPage = () => {
    localDispatch({ type: SET_CURRENT_PAGE, payload: state.currentPage - 1 });
  };

  const changeCurrentPage = (page = 1) => {
    localDispatch({ type: SET_CURRENT_PAGE, payload: page });
  };

  const changeItemsPerPageHandler = (e) => {
    localDispatch({
      type: SET_ITEMS_PER_PAGE,
      payload: parseInt(e.target.value),
    });
  };

  const handleChange = (evt) => {
    const { name, value } = evt.target;
    let newValue = value;

    if (name === "documento") {
      if (numberMask(value) && numberMask(value).length <= 11) {
        newValue = cpfMask(value);
      } else {
        newValue = cnpjMask(value);
      }
    }

    setPayload((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const handleSubmit = () => {
    if (!isPosting && validateRequest(payload)) {
      dispatch(
        postPermissiveList({ ...payload, documento: numberMask(payload.documento) })
      );
    }
  };

  const validateRequest = (values) => {
    const errors = {};

    if (!values.documento) {
      errors.documento = "Documento deve ser informado!";
    } else if (
      numberMask(values.documento).length !== 11 &&
      numberMask(values.documento).length !== 14
    ) {
      errors.documento = "Informe um CPF ou CNPJ válido!";
    }

    if (process.env.REACT_APP_MODE === "CORE" && !values.idCustomerBank) {
      errors.idCustomerBank = "Selecione o banco para inclusão!";
    }

    if (!values.razaoAprovacao) {
      errors.razaoAprovacao = "Motivo de inclusão deve ser informado!";
    }

    setPayloadErrors(errors);

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const renderBankName = (idBank) => {
    const bankArr = banks.filter((bank) => bank.id === idBank);
    if (bankArr.length) return bankArr[0].name;

    return idBank;
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setPayloadErrors({});
  };

  const handleDeleteWhiteList = () => {
    dispatch(deletePermissiveList(idPermissiveList));
  };

  return (
    <>
      <ConfirmationModal
        title={"Remover documento"}
        description={
          "Tem certeza que deseja remover este documento da lista permissiva? Após essa ação, o cliente passará por todas as validações no onboarding."
        }
        confirmButtonLabel={"Remover"}
        onConfirm={handleDeleteWhiteList}
        cancelButtonLabel={"Cancelar"}
        onCancel={() => setIsConfirmationModalOpen(false)}
        type={"danger"}
        isModalOpen={isConfirmationModalOpen}
      />
      <Modal
        title={""}
        description={
          "O cliente poderá realizar a abertura de conta mesmo que o onboarding esteja indisponível. Durante esse processo, ele passará por todas as validações do fluxo padrão."
        }
        confirmButtonLabel={"Cadastrar"}
        onConfirm={handleSubmit}
        cancelButtonLabel={"Cancelar"}
        onCancel={handleCancel}
        isModalOpen={isModalOpen}
      >
        <div className="mt-5 text-left flex flex-col gap-5">
          <div>
            <Input
              label={"Documento"}
              type={"text"}
              name={"documento"}
              id={"documento"}
              value={payload.documento || ""}
              onChange={handleChange}
              error={payloadErrors.documento}
              errorMessage={payloadErrors.documento}
            />
          </div>
          {process.env.REACT_APP_MODE === "CORE" && (
            <div>
              <Select
                label={"Banco"}
                id={"idCustomerBank"}
                name={"idCustomerBank"}
                value={payload.idCustomerBank || ""}
                onChange={handleChange}
                options={banks}
                placeholder={"Selecione o banco"}
                error={payloadErrors.idCustomerBank}
                errorMessage={payloadErrors.idCustomerBank}
              />
            </div>
          )}
          <div>
            <TextArea
              id={"razaoAprovacao"}
              name={"razaoAprovacao"}
              label={"Motivo"}
              placeholder={
                "Descreva o motivo da inclusão do documento na lista permissiva"
              }
              value={payload.razaoAprovacao || ""}
              maxLength={500}
              onChange={handleChange}
              error={payloadErrors.razaoAprovacao}
              errorMessage={payloadErrors.razaoAprovacao}
            />
          </div>
        </div>
      </Modal>
      <Layout title={"Lista Permissiva"}>
        <div className="relative">
          <div className="flex flex-col md:flex-row gap-3 items-start justify-between">
            <div className="flex flex-col w-full sm:flex-row gap-3 items-start">
              <div className="sm:max-w-[184px] w-full">
                <DatePicker 
                  dateFilter={state.dateFilter}
                  handleChangeDate={handleChangeDate}
                />
              </div>
              <div className="w-full md:max-w-xs">
                <Input
                  type={"text"}
                  name={"document"}
                  id={"document"}
                  placeholder={"Informe o CPF ou CNPJ"}
                  onChange={handleChangeDocument}
                  value={document}
                  error={error}
                  errorMessage={error}
                />
              </div>
              <Button
                type={"submit"}
                label={"Filtrar"}
                onClick={handleFilter}
                className={"w-full sm:w-auto"}
              />
            </div>
            {checkRole(userRole, [USER_ADMIN, ADMIN_ECOSYSTEM]) && (
              <Button
                type={"button"}
                label={"Adicionar Documento"}
                onClick={() => setIsModalOpen(true)}
                className={"w-full sm:w-auto"}
              />
            )} 
          </div>
          <div className="mt-4 sm:mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:mx-0 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    ID
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden lg:table-cell"
                  >
                    Documento
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden lg:table-cell"
                  >
                    Aprovado por
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell"
                  >
                    Motivo
                  </th>
                  {process.env.REACT_APP_MODE === "CORE" && (
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden lg:table-cell"
                    >
                      Banco
                    </th>
                  )}
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden md:table-cell"
                  >
                    Data aprovação
                  </th>
                  {checkRole(userRole, [USER_ADMIN, ADMIN_ECOSYSTEM]) && (
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Remover</span>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {permissiveList.itens.length > 0 ? (
                  permissiveList.itens.map((item) => (
                      <tr key={item.dataAprovacao}>
                        <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                          {item.id}
                          <dl className="font-normal">
                            <dt className="sr-only lg:hidden">Documento</dt>
                            <dd className="mt-1 font-medium text-gray-700 lg:hidden">
                              {renderDocument(item.documento)}
                            </dd>
                            <dt className="sr-only md:hidden">Aprovado por</dt>
                            <dd className="mt-1 text-gray-500 lg:hidden">
                              Aprovado por: {item.aprovadoPorNome}
                            </dd>
                            {process.env.REACT_APP_MODE === "CORE" && (
                              <>
                                <dt className="sr-only md:hidden">Banco</dt>
                                <dd className="mt-1 text-gray-500 lg:hidden">
                                  Banco:{" "}
                                  {renderBankName(
                                    parseInt(item.idCustomerBank)
                                  )}
                                </dd>
                              </>
                            )}
                            <dt className="sr-only md:hidden">
                              Data aprovação
                            </dt>
                            <dd className="mt-1 text-gray-500 md:hidden">
                              Data aprovação:{" "}
                              {formatWithTimezone(item.dataAprovacao)}
                            </dd>
                            <dt className="sr-only md:hidden">Motivo</dt>
                            <dd className="mt-1 text-gray-500 sm:hidden">
                              <span className="truncate break-words whitespace-normal">
                                {item.razaoAprovacao}
                              </span>
                            </dd>
                          </dl>
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 hidden lg:table-cell whitespace-nowrap">
                          {renderDocument(item.documento)}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 hidden lg:table-cell whitespace-nowrap">
                          {item.aprovadoPorNome}
                        </td>
                        <td className="whitespace-normal px-3 py-4 text-sm text-gray-500 truncate hidden sm:table-cell">
                          {item.razaoAprovacao}
                        </td>
                        {process.env.REACT_APP_MODE === "CORE" && (
                          <td className="px-3 py-4 text-sm text-gray-500 hidden lg:table-cell whitespace-nowrap">
                            {renderBankName(parseInt(item.idCustomerBank))}
                          </td>
                        )}
                        <td className="px-3 py-4 text-sm text-gray-500 hidden md:table-cell whitespace-nowrap">
                          {formatWithTimezone(item.dataAprovacao)}
                        </td>
                        {checkRole(userRole, [USER_ADMIN, ADMIN_ECOSYSTEM]) && (
                          <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <Tooltip text={"Excluir"}>
                              <TrashIcon
                                className="h-5 w-5 text-red-600 cursor-pointer hover:scale-125"
                                onClick={() => {
                                    setIdPermissiveList(item.id);
                                    setIsConfirmationModalOpen(true);
                                  }
                                }
                              >
                                <span className="sr-only">, {item.documento} </span>
                              </TrashIcon>
                            </Tooltip>
                          </td>
                        )}
                      </tr>
                    ))
                    .reverse()
                ) : (
                  <tr>
                    <td
                      colSpan={
                        process.env.REACT_APP_MODE === "CORE" &&
                        checkRole(userRole, [USER_ADMIN, ADMIN_ECOSYSTEM])
                          ? 7
                          : 6
                      }
                      className="text-sm sm:text-base text-gray-500 text-center py-5"
                    >
                      Não há dados para exibir
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Pagination
              total={permissiveList.total}
              pageItens={permissiveList?.itens?.length}
              pageCount={Math.ceil(permissiveList.total / state.itemsPerPage)}
              currentPage={state.currentPage}
              onNextPage={handleNextPage}
              onPreviousPage={handlePreviousPage}
              onClick={changeCurrentPage}
              itemsPerPage={state.itemsPerPage}
              onChangeItemsPerPage={changeItemsPerPageHandler}
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default PermissiveList;
