import { createSlice } from "@reduxjs/toolkit";

import {
  fetchMetrics,
  fetchTransactionalProducts,
  fetchRentability,
} from "./metricsThunk";

const initialState = {
  metrics: [],
  transactionalProduct: [],
  rentability: [],
};

const metricsSlice = createSlice({
  name: "metrics",
  initialState,
  extraReducers: {
    [fetchMetrics.fulfilled]: (state, action) => {
      return { ...state, metrics: action.payload };
    },
    [fetchTransactionalProducts.fulfilled]: (state, action) => {
      return { ...state, transactionalProduct: action.payload };
    },
    [fetchRentability.fulfilled]: (state, action) => {
      return { ...state, rentability: action.payload.reverse() };
    },
  },
});

export default metricsSlice.reducer;
