import axiosClient from "./apiClient";

export function getLogs(dataInicio, dataFinal, pagina, itensPorPagina) {
  const params = new URLSearchParams();

  dataInicio && params.append("dataInicio", dataInicio);
  dataFinal && params.append("dataFinal", dataFinal);
  pagina && params.append("pagina", pagina);
  itensPorPagina && params.append("itensPorPagina", itensPorPagina);
  params.append("removerGet", true);

  return axiosClient.get(`${process.env.REACT_APP_API_DASH}/user/logs`, {
    params,
  });
}
