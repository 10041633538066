import axios from "axios";

import { msalInstance } from "index";
import { loginRequest } from "services/authConfig"

async function getToken() {
  const account = msalInstance.getAllAccounts()[0];
  const request = { ...loginRequest, account: account };
  const token = account && (await msalInstance.acquireTokenSilent(request));
  return `Bearer ${token.accessToken}`
}

const axiosClient = axios.create({
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
});

axiosClient.interceptors.request.use(
  async (config) => {
    const item = await getToken();
    config.headers.Authorization = item;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  });

axiosClient.interceptors.response.use(
  res => {
    if (res.data.error) {
      return Promise.reject(res.data.error);
    }
    return res
  },
  error => {
    let res = error.response;

    if (res.status === 401) {
      window.location.href = window.location.origin;
      return Promise.reject("Sessão expirada, por favor realize o login novamente!");
    }

    if (res.status === 403) {
      return Promise.reject("Você não tem permissão para executar essa ação!");
    }

    if (res.status === (500 || 502 || 503 || 504)) {
      return Promise.reject("Houve um erro interno, entre em contato com o suporte!");
    }

    return Promise.reject("Ops! Tente novamente mais tarde!");
  }
);

export default axiosClient;