import { useEffect } from "react";
import { useDispatch } from "react-redux";

import TransactionalFees from "./transactional";
import PeriodicFees from "./periodic";
import GroupFees from "./group";
import {
  fetchTransactionTypes,
  fetchPeriodTypes,
} from "reducers/fees/feesThunk";

const Fees = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTransactionTypes());
    dispatch(fetchPeriodTypes());

    // eslint-disable-next-line
  }, []);

  return (
    <>
      {process.env.REACT_APP_MODE === "CORE" && (
        <>
          <TransactionalFees />
          <PeriodicFees />
        </>
      )}
      <GroupFees />
    </>
  );
};

export default Fees;
