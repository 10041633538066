import React from "react";

import Select from "components/select";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Tab({ tabs, onChangeTab, breakpoint = "sm" }) {
  return (
    <div className="mb-6 w-full">
      <div className={`${breakpoint}:hidden`}>
        <Select
          label={"Selecione um Menu"}
          labelSrOnly={true}
          id={"tabs"}
          name={"tabs"}
          value={tabs.find((tab) => tab.current).id}
          options={tabs}
          onChange={onChangeTab}
        />
      </div>
      <div className={`hidden ${breakpoint}:block`}>
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                value={tab.id}
                onClick={onChangeTab}
                className={classNames(
                  tab.current
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={tab.current ? "page" : undefined}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
