import Modal from "components/modal";
import classNames from "utils/classNames";
import Input from "components/input";
import {
  renderDocument,
  formatDate,
  convertCurrencyBRLToString,
} from "utils/mask";

const TransactionModal = ({ open, setOpen, item, setItem }) => {
  const closeModalHandler = () => {
    setItem(null);
    setOpen(false);
  };

  return (
    <Modal
      title={"Solicitação de aumento de limite"}
      showFooter={false}
      onCancel={closeModalHandler}
      isModalOpen={open}
    >
      <div className="mt-6 space-y-6 text-left">
        <div className="flex flex-col items-start text-sm text-gray-500">
          <span className="font-medium text-gray-900">{item?.nomeCliente}</span>
          <span className="text-gray-500">
            {renderDocument(item?.documento)}
          </span>
          <span>
            Status:{" "}
            <span
              className={classNames(
                "font-medium",
                item?.status === "Rejeitado" && "text-danger",
                item?.status === "Aprovado" && "text-success",
                item?.status === "Solicitado" && "text-yellow-500"
              )}
            >
              {item?.status === "Solicitado" ? "Pendente" : item?.status}
            </span>
          </span>
        </div>
        <div>
          <Input
            label={"Data e hora da solicitação"}
            value={formatDate(item?.dataSolicitacao)}
            type={"text"}
            disabled
          />
        </div>
        <div>
          <Input
            label={"Data e hora da resposta"}
            value={formatDate(item?.dataAtualizacao)}
            type={"text"}
            disabled
          />
        </div>
        <div>
          <Input
            label={"Tipo de limite"}
            value={item?.limiteNoturno ? "Noturno" : "Diário"}
            type={"text"}
            disabled
          />
        </div>
        <div>
          <Input
            label={"Tipo de transação"}
            value={item?.transacao}
            type={"text"}
            disabled
          />
        </div>
        <div>
          <Input
            label={"Valor aprovado"}
            value={`R$ ${convertCurrencyBRLToString(item?.valorSolicitado)}`}
            type={"text"}
            disabled
          />
        </div>
        <div>
          <Input
            label={"Usuário responsável"}
            value={item?.nomeUsuario}
            type={"text"}
            disabled
          />
        </div>
        <div>
          <Input
            label={"Justificativa"}
            value={item?.motivo}
            type={"text"}
            disabled
          />
        </div>
      </div>
    </Modal>
  );
};

export default TransactionModal;
