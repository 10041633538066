import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";

import renderTransactionIcon from "utils/extractIcons";
import { cn } from "utils/classNames";
import { convertCurrencyBRLToString } from "utils/mask";
import { WEEK, MONTH, QUARTER } from "../account-data";

const MostUsedProducts = () => {
  const { transactionalProduct } = useSelector((store) => store.metrics);
  const [period, setPeriod] = useState(WEEK);
  const [mostUsedValue, setMostUsedValue] = useState(null);
  const [mostQtdeValue, setMostQtdeValue] = useState(null);

  const buttons = [{ text: WEEK }, { text: MONTH }, { text: QUARTER }];

  useEffect(() => {
    const valueFieldName =
      period === WEEK
        ? "valorSemana"
        : period === MONTH
        ? "valorMes"
        : "valorTrimestre";

    const qtdeFieldName =
      period === WEEK
        ? "quantidadeSemana"
        : period === MONTH
        ? "qauntidadeMes"
        : "quantidadeTrimestre";

    if (transactionalProduct.length > 0) {
      const valueObj = transactionalProduct.reduce((maior, item) => {
        return item?.[valueFieldName] > maior?.[valueFieldName] ? item : maior;
      }, transactionalProduct[0]);

      const qtdeObj = transactionalProduct.reduce((maior, item) => {
        return item?.[qtdeFieldName] > maior?.[qtdeFieldName] ? item : maior;
      }, transactionalProduct[0]);

      setMostUsedValue({
        value: valueObj?.[valueFieldName],
        idTransaction: valueObj?.idTransacao,
        descricao: valueObj?.descricao,
      });

      setMostQtdeValue({
        value: qtdeObj?.[qtdeFieldName],
        idTransaction: qtdeObj?.idTransacao,
        descricao: qtdeObj?.descricao,
      });
    }
  }, [transactionalProduct, period]);

  return (
    <div className="col-span-4 lg:col-span-2 bg-white border rounded-lg p-6 flex flex-col">
      <div className="flex flex-row flex-wrap items-center justify-between gap-6">
        <div className="flex flex-row items-center gap-1">
          <h2 className="text-xl font-semibold">Produtos em destaque</h2>
          <QuestionMarkCircleIcon
            className="h-5 w-5 cursor-help shrink-0"
            data-tooltip-id="most-used-explanation"
          />
          <Tooltip id="most-used-explanation">
            <div className="text-center text-sm normal-case">
              Saiba quais são os produtos com mais transações e com o maior
              valor transacionado no mês.
              <br />A análise é realizada com base nos dias já corridos do mês
              atual em comparação ao mesmo recorte do mês anterior.
            </div>
          </Tooltip>
        </div>
        <div className="flex flex-row items-center gap-3">
          {buttons.map((button) => (
            <button
              key={button.text}
              onClick={() => setPeriod(button.text)}
              className={cn(
                "bg-gray-200 px-5 py-1 text-gray-800 rounded-full text-sm w-28 text-center uppercase font-medium",
                period === button.text
                  ? "bg-indigo-600 text-white"
                  : "hover:bg-indigo-400 hover:text-white"
              )}
            >
              {button.text}
            </button>
          ))}
        </div>
      </div>
      {transactionalProduct.length > 0 && (
        <div className="flex flex-col md:flex-row divide-y md:divide-y-0 md:divide-x pt-6 md:py-10 grow">
          <div className={styles.cardContainer}>
            <div className={styles.iconContainer}>
              {renderTransactionIcon(parseInt(mostUsedValue?.idTransaction), {
                className: "h-10 w-10",
              })}
              <span className={styles.transaction}>
                {mostUsedValue?.descricao}
              </span>
            </div>
            <div className={styles.valueContainer}>
              <span className={styles.value}>
                R$ {convertCurrencyBRLToString(mostUsedValue?.value)}
              </span>
              <span className={styles.label}>Maior valor transacionado</span>
            </div>
          </div>
          <div className={styles.cardContainer}>
            <div className={styles.iconContainer}>
              {renderTransactionIcon(parseInt(mostQtdeValue?.idTransaction), {
                className: "h-10 w-10",
              })}
              <span className={styles.transaction}>
                {mostQtdeValue?.descricao}
              </span>
            </div>
            <div className={styles.valueContainer}>
              <span className={styles.value}>{mostQtdeValue?.value}</span>
              <span className={styles.label}>
                Maior quantidade de transações
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MostUsedProducts;

const styles = {
  cardContainer: "flex-1 flex flex-col justify-center items-center gap-10 py-4",
  iconContainer: "flex flex-col items-center",
  transaction: "text-lg text-gray-500",
  valueContainer: "flex flex-col text-center",
  value: "text-xl font-medium text-gray-900",
  label: "text-sm text-gray-500 italic",
};
