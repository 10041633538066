import { createSlice } from "@reduxjs/toolkit";

import { fetchZipcode } from "./zipcodeThunk";

const initialState = {
  cep: "",
  logradouro: "",
  bairro: "",
  localidade: "",
  uf: "",
  ibge: "",
  ddd: "",
};

export const userSlice = createSlice({
  name: "zipcode",
  initialState,
  extraReducers: {
    [fetchZipcode.fulfilled]: (state, action) => {
      return action.payload;
    },
    [fetchZipcode.rejected]: (state, action) => {
      return {
        ...state,
        cep: action.payload,
      };
    },
  },
});

export default userSlice.reducer;
