import PendingList from "./pending-list";
import LimitHistoryList from "./history-list";

const navigationMenu = [
  {
    id: 1,
    name: "Solicitações pendentes",
    current: true,
    active: true,
    component: PendingList,
  },
  {
    id: 2,
    name: "Solicitações respondidas",
    current: false,
    active: true,
    component: LimitHistoryList,
  },
];

export default navigationMenu;
