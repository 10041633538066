import axiosClient from "./apiClient";

export function newPos(payload) {
  return axiosClient.post(
    `${process.env.REACT_APP_API_DASH}/Account/pj/cadastrar-terminal`,
    payload,
    {
      headers: { 'Content-Type': 'application/json;charset=utf-8' }
    }
  );
}

export function getPos(idClient) {
  return axiosClient.get(`${process.env.REACT_APP_API_DASH}/Account/pj/listar-terminal/${idClient}`);
}