import React, { useCallback, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { XMarkIcon } from "@heroicons/react/24/solid";
import timezone from "moment-timezone";

import Layout from "components/layout";
import Button from "components/button";
import Pagination from "components/pagination";
import Radio from "components/radio";
import { fetchAccountStatus, fetchAccounts } from "reducers/accounts/accountsThunk";
import { fetchBanks } from "reducers/onboarding/onboardingThunk";
import { cnpjMask, cpfMask, numberMask, phoneMask } from "utils/mask";
import {
  RESET_FILTER_TYPE,
  SET_ACCOUNT_TYPE,
  SET_API_FILTER,
  SET_CAN_SEND_REQUEST,
  SET_CURRENT_PAGE,
  SET_FILTER_TYPE,
  SET_ID_BANK,
  SET_ITEMS_PER_PAGE,
  SET_STATUS_FILTER,
  initialState,
  reducer,
} from "./reducer";

export default function Accounts() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accounts = useSelector((store) => store.accounts);
  const accountStatus = useSelector((store) => store.accounts.accountStatus);
  const { banks } = useSelector((store) => store.onboarding);
  const [state, localDispatch] = useReducer(reducer, initialState);

  const getAccountsHandler = useCallback(() => {
    dispatch(
      fetchAccounts({
        ...state,
        ...state.apiFilter
      })
    );
  }, [state, dispatch]);

  useEffect(() => {
    getAccountsHandler();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(fetchAccountStatus());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchBanks());
  }, [dispatch]);

  useEffect(() => {
    getAccountsHandler();

    // eslint-disable-next-line
  }, [state.page, state.itemsPerPage]);

  useEffect(() => {
    state.canSendRequest && getAccountsHandler();

    return () => localDispatch({ type: SET_CAN_SEND_REQUEST, payload: false });

    // eslint-disable-next-line
  }, [state.canSendRequest]);

  const changeItemsPerPageHandler = (e) => {
    localDispatch({
      type: SET_ITEMS_PER_PAGE,
      payload: parseInt(e.target.value),
    });
  };

  const handleChangeFilterType = (event) => {
    localDispatch({ type: SET_FILTER_TYPE, payload: event.target.value });
  };

  const handleClearInput = () => {
    localDispatch({ type: RESET_FILTER_TYPE });
  };

  const handleDetails = (idAccount, document) => {
    let type = 1;
    numberMask(document).length === 14 ? (type = 2) : (type = 1);
    navigate(`/contas/detalhes/${idAccount}?type=${type}`);
  };

  const handleChangeFilter = (key, value) => {
    localDispatch({
      type: SET_API_FILTER,
      payload: { ...state.apiFilter, [key]: handleMask(value) },
    });
  };

  const handleAccountType = (event) => {
    localDispatch({ type: SET_ACCOUNT_TYPE, payload: event.target.value });
  };

  const handleBank = (event) => {
    localDispatch({ type: SET_ID_BANK, payload: event.target.value });
  };

  const handleMask = (value) => {
    if (state.filterType === "document" && value) {
      if (numberMask(value).length <= 11) {
        return cpfMask(value);
      } else {
        return cnpjMask(value);
      }
    }
    if (
      (state.filterType === "idAccount" || state.filterType === "idClient") &&
      value
    ) {
      return numberMask(value);
    } else if (state.filterType === "phoneNumber" && value) {
      return phoneMask(value);
    } else {
      return value;
    }
  };

  function handleOnEnter(event) {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      getAccountsHandler();
    }
  }

  const handleNextPage = () => {
    localDispatch({ type: SET_CURRENT_PAGE, payload: state.page + 1 });
  };

  const handlePreviousPage = () => {
    localDispatch({ type: SET_CURRENT_PAGE, payload: state.page - 1 });
  };

  const changeCurrentPage = (page = 1) => {
    localDispatch({ type: SET_CURRENT_PAGE, payload: page });
  };

  const handleAccountStatusFilter = (event) => {
    localDispatch({ type: SET_STATUS_FILTER, payload: event.target.value })
  }

  function getPlaceHolder() {
    switch (state.filterType) {
      case "name":
        return "o nome";
      case "email":
        return "o email";
      case "document":
        return "o documento";
      case "idAccount":
        return "a conta";
      case "idClient":
        return "o ID Cliente";
      case "phoneNumber":
        return "o telefone";
      default:
        return state.filterType;
    }
  }

  return (
    <Layout title={"Contas abertas"}>
      <div>
        <div className="mt-1 flex flex-col gap-1 xl:flex-row rounded-md w-full">
          <div className="flex flex-col md:flex-row w-full gap-1">
            <div className="inset-y-0 left-0 flex items-center xl:mb-0 w-full xl:w-auto">
              <label htmlFor="accountType" className="sr-only">
                Tipo de conta
              </label>
              <div className="inline-flex p-1.5 px-3 items-center rounded-md border border-gray-300 bg-gray-50 text-gray-500 text-sm focus:ring-indigo-500 focus:border-indigo-500  w-full xl:w-auto">
                <Radio
                  id="accountType"
                  name="accountType"
                  label="Todas"
                  value={""}
                  checked={!state.accountType}
                  onChange={handleAccountType}
                />
                <Radio
                  id="accountType-1"
                  name="accountType"
                  label="PF"
                  value={1}
                  checked={state.accountType === "1"}
                  onChange={handleAccountType}
                />
                <Radio
                  id="accountType-2"
                  name="accountType"
                  label="PJ"
                  value={2}
                  checked={state.accountType === "2"}
                  onChange={handleAccountType}
                />
              </div>
            </div>
            <div className="inset-y-0 left-0 flex items-center xl:mb-0 w-full xl:w-auto">
              <label htmlFor="bank" className="sr-only">
                Selecione o status
              </label>
              <select
                id="idStatus"
                name="idStatus"
                autoComplete="idStatus"
                value={state.idStatus}
                onChange={handleAccountStatusFilter}
                className="inline-flex items-center rounded-md border border-gray-300 bg-gray-50 text-gray-500 text-sm focus:ring-indigo-500 focus:border-indigo-500 cursor-pointer  w-full xl:w-auto"
              >
                <option value="">Selecione o status</option>
                {accountStatus.map((status) => {
                  return (
                    <option key={status.iD_STATUS} value={status.iD_STATUS}>
                      {status.description}
                    </option>
                  );
                })}
              </select>
            </div>
            {process.env.REACT_APP_MODE === "CORE" && (
              <div className="inset-y-0 left-0 flex items-center xl:mb-0 w-full xl:w-auto">
                <label htmlFor="bank" className="sr-only">
                  Selecione o banco
                </label>
                <select
                  id="idBank"
                  name="idBank"
                  autoComplete="idBank"
                  value={state.idBank}
                  onChange={handleBank}
                  className="inline-flex items-center rounded-md border border-gray-300 bg-gray-50 text-gray-500 text-sm focus:ring-indigo-500 focus:border-indigo-500 cursor-pointer  w-full xl:w-auto"
                >
                  <option value="">Selecione o banco</option>
                  {banks.map((bank) => {
                    return (
                      <option key={bank.id} value={bank.id}>
                        {bank.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
          </div>
          <div className=" flex w-full">
            <div className="flex w-full">
              <div className="inset-y-0 left-0 flex items-center">
                <label htmlFor="type" className="sr-only">
                  Selectione o Filtro
                </label>
                <select
                  id="type"
                  name="type"
                  autoComplete="type"
                  value={state.filterType}
                  onChange={handleChangeFilterType}
                  className={
                    "inline-flex items-center w-auto rounded-l-md border border-gray-300 bg-gray-50 text-gray-500 text-sm focus:z-10 focus:ring-indigo-500 focus:border-indigo-500 cursor-pointer"
                  }
                >
                  <option value="name">Nome</option>
                  <option value="document">Documento</option>
                  <option value="email">Email</option>
                  <option value="idAccount">Conta</option>
                  <option value="idClient">ID Cliente</option>
                  <option value="phoneNumber">Telefone</option>
                </select>
              </div>
              <div className="w-full relative">
                <input
                  type="text"
                  name="filter"
                  id="filter"
                  maxLength={state.filterType === "phoneNumber" ? "15" : "100"}
                  value={
                    state.apiFilter.name ||
                    state.apiFilter.document ||
                    state.apiFilter.email ||
                    state.apiFilter.idAccount ||
                    state.apiFilter.idClient ||
                    state.apiFilter.phoneNumber
                  }
                  onChange={(event) =>
                    handleChangeFilter(state.filterType, event.target.value)
                  }
                  onKeyUp={handleOnEnter}
                  disabled={!state.filterType}
                  className={`${
                    !state.filterType ? "cursor-not-allowed bg-gray-50" : ""
                  }
                  border-l-0 pl-[12px] focus:border-l focus:pl-[11px] focus:ring-indigo-500 focus:border-indigo-500 block text-sm border-gray-300 w-full`}
                  placeholder={
                    state.filterType
                      ? `Digite ${getPlaceHolder()}`
                      : "Selecione o filtro ao lado"
                  }
                />
                {(state.apiFilter.name ||
                  state.apiFilter.document ||
                  state.apiFilter.email ||
                  state.apiFilter.idAccount ||
                  state.apiFilter.idClient ||
                  state.apiFilter.phoneNumber) && (
                  <div className="cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3">
                    <XMarkIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                      onClick={handleClearInput}
                    />
                  </div>
                )}
              </div>
              <Button
                type={"submit"}
                label={"Filtrar"}
                className={"rounded-l-none m-l-0 z-10"}
                onClick={getAccountsHandler}
              />
            </div>
          </div>
        </div>
        <div className="mt-4">
          {accounts.accounts.total > 0 && (
            <span className="mb-1 text-xs flex justify-end text-gray-500">
              Total de contas: {accounts.accounts.total}
            </span>
          )}
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:mx-0 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="hidden py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:table-cell whitespace-nowrap"
                  >
                    ID Cliente
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Nome
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    Documento
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    Criado em
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                  >
                    Conta
                  </th>
                  {process.env.REACT_APP_MODE === "CORE" && (
                    <th
                      scope="col"
                      className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                    >
                      Banco
                    </th>
                  )}
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Ativo
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {accounts.accounts.accounts.length ? (
                  accounts.accounts.accounts.map((person) => (
                    <tr key={person.id_Account}>
                      <td className="hidden pl-4 pr-3 py-4 sm:pl-6 text-sm text-gray-500 lg:table-cell">
                        {person.id_Client}
                      </td>
                      <td className="w-full max-w-0 py-4 px-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none">
                        <span className="lg:hidden">
                          ID Cliente: {person.id_Client}
                        </span>
                        <span className="hidden lg:block">{person.name}</span>
                        <dl className="font-normal">
                          <dt className="sr-only lg:hidden">Nome</dt>
                          <dd className="mt-1 font-medium text-gray-900 lg:hidden">
                            {person.name}
                          </dd>
                          <dt className="sr-only lg:hidden">Documento</dt>
                          <dd className="mt-1 truncate text-gray-700 lg:hidden">
                            {person.document.length === 14
                              ? `CNPJ: ${cnpjMask(person.document)}`
                              : `CPF: ${cpfMask(person.document)}`}
                          </dd>
                          <dt className="sr-only">Telefone</dt>
                          <dd className="mt-1 truncate text-gray-500">
                            {phoneMask(
                              `${person.area_Code}${person.phone_Number}`
                            )}
                          </dd>
                          <dt className="sr-only">Email</dt>
                          <dd className="mt-1 truncate text-gray-500">
                            {person.email}
                          </dd>
                          <dt className="sr-only lg:hidden">Data de Criação</dt>
                          <dd className="lg:hidden mt-1 truncate text-gray-500">
                            Criado em:{" "}
                            {timezone
                              .utc(person.created)
                              .tz("America/Sao_Paulo")
                              .format("DD/MM/YYYY HH:mm:ss")}
                          </dd>
                          <dt className="sr-only md:hidden">Conta</dt>
                          <dd className="md:hidden mt-1 truncate text-gray-500">
                            Conta: {person.id_Account} /{" "}
                            {process.env.REACT_APP_MODE === "CORE" &&
                              person.name_bank}
                          </dd>
                          <dt className="sr-only sm:hidden">Bloqueado</dt>
                          <dd className="sm:hidden mt-1 truncate text-gray-500">
                            Bloqueado: {person.isBlocked ? "Sim" : "Não"}
                          </dd>
                        </dl>
                      </td>
                      <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell whitespace-nowrap">
                        {person.document.length === 14
                          ? cnpjMask(person.document)
                          : cpfMask(person.document)}
                      </td>
                      <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell whitespace-nowrap">
                        {timezone
                          .utc(person.created)
                          .tz("America/Sao_Paulo")
                          .format("DD/MM/YYYY HH:mm:ss")}
                      </td>
                      <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                        {person.id_Account}
                      </td>
                      {process.env.REACT_APP_MODE === "CORE" && (
                        <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell whitespace-nowrap">
                          {person.name_bank}
                        </td>
                      )}
                      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                        {person.isBlocked ? "Não" : "Sim"}
                      </td>
                      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <button
                          onClick={() =>
                            handleDetails(person.id_Client, person.document)
                          }
                          className="text-indigo-600 hover:text-indigo-500"
                        >
                          Detalhes
                          <span className="sr-only">, {person.name}</span>
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={8}
                      className="text-sm sm:text-base text-gray-500 text-center py-5"
                    >
                      Não há dados para exibir
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Pagination
              total={accounts.accounts.total}
              pageItens={accounts.accounts.accounts.length}
              pageCount={Math.ceil(
                accounts.accounts.total / state.itemsPerPage
              )}
              currentPage={state.page}
              onNextPage={handleNextPage}
              onPreviousPage={handlePreviousPage}
              onClick={changeCurrentPage}
              itemsPerPage={state.itemsPerPage}
              onChangeItemsPerPage={changeItemsPerPageHandler}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}
