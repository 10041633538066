import { useSelector } from "react-redux";

import CompanyData from "./company-data";
import CompanyAddress from "./company-address";
import PartnerData from "./partner-data";
import PartnerAddres from "./partner-address";
import CreationDetails from "../common/creation-details";

export default function ClientePjForm() {
  const { client } = useSelector((store) => store.client);

  return (
    <>
      <CompanyData />
      <CompanyAddress />
      <PartnerData />
      <PartnerAddres />
      {client.onboardingManualBy !== 0 && <CreationDetails />}
    </>
  );
}
