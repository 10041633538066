import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export default function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const cn = (...inputs) => {
  return twMerge(clsx(inputs));
};
