import { HomeIcon } from "@heroicons/react/24/solid";
import { NavLink } from "react-router-dom";

export default function Breadcrumb({ pages }) {
  if (!pages) return <></>;
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li>
          <div>
            <NavLink to="/" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Início</span>
            </NavLink>
          </div>
        </li>
        {pages.map((page) => (
          <li key={page.name}>
            <div className="flex items-center">
              <svg
                className="flex-shrink-0 h-5 w-5 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              {page?.href ? (
                <NavLink
                  to={page.href}
                  className="ml-4 text-sm font-medium text-gray-500 cursor-pointer"
                >
                  {page.name}
                </NavLink>
              ) : (
                <p className="ml-4 text-sm font-medium text-gray-500 cursor-default">
                  {page.name}
                </p>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}
