import { createAsyncThunk } from "@reduxjs/toolkit";

import { getLogs } from "services/admin";
import { setNotification } from "reducers/notification/notificationSlice";
import { setIsLoading } from "reducers/loading/isLoadingSlice";

export const fetchLogs = createAsyncThunk(
  "admin/fetchLogs",
  async ({ dataInicio, dataFinal, pagina, itensPorPagina }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getLogs(
        dataInicio,
        dataFinal,
        pagina,
        itensPorPagina
      );

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);
