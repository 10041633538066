export default function CheckBox({
  label,
  description,
  id,
  name,
  checked,
  onChange
}) {
  return (
    <div className="relative flex items-start">
      <div className="flex h-5 items-center">
        <input
          id={id}
          name={name}
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
          aria-describedby={`${id}-description`}
          checked={checked}
          onChange={onChange}
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor={id} className="font-medium text-gray-700">
          {label}
        </label>
        <p id={`${id}-description`} className="text-gray-500">
          {description}
        </p>
      </div>
    </div>
  )
}