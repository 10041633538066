import { useState, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";

import SlideOver from "components/slide-over";
import Input from "components/input";
import Select from "components/select";
import Button from "components/button";
import { editGroupItems } from "reducers/fees/feesThunk";
import {
  convertCurrencyBRLToString,
  convertCurrencyBRLToFloat,
  formatCurrency,
} from "utils/mask";

const FeeDetails = ({ isDetailsOpen, onClose, feeDetail, idCustomerBank }) => {
  const dispatch = useDispatch();
  const [isUpdate, setIsUpdate] = useState(false);
  const [feeChargeType, setFeeChargeType] = useState("");
  const [valueFormatted, setValueFormatted] = useState("");
  const [percentualMaxFormatted, setPercentualMaxFormatted] = useState("");
  const [minValueFormatted, setMinValueFormatted] = useState("");
  const [qtdMax, setQtdMax] = useState("");
  const [qtdMin, setQtdMin] = useState("");
  const [daysOfDelay, setDaysOfDelay] = useState("");
  const [payloadError, setPayloadError] = useState({});

  const buildPayload = (fee) => {
    if (fee.percentual) {
      setFeeChargeType("percentual");
      setValueFormatted(convertCurrencyBRLToString(fee.percentual));
      fee.valorMaximoParaCobrancaPorPercentual
        ? setPercentualMaxFormatted(
            convertCurrencyBRLToString(fee.valorMaximoParaCobrancaPorPercentual)
          )
        : setPercentualMaxFormatted("");
    } else {
      setFeeChargeType("currency");
      setValueFormatted(convertCurrencyBRLToString(fee.valor));
    }

    fee.valorMinimoParaCobranca
      ? setMinValueFormatted(
          convertCurrencyBRLToString(fee.valorMinimoParaCobranca)
        )
      : setMinValueFormatted("");

    fee.quantidadeMaximaMensalParaCobranca
      ? setQtdMax(fee.quantidadeMaximaMensalParaCobranca)
      : setQtdMax("");

    fee.quantidadeMinimaMensalParaCobranca
      ? setQtdMin(fee.quantidadeMinimaMensalParaCobranca)
      : setQtdMin("");

    fee.quantidadeDiasAtrasoTarifaPeriodica
      ? setDaysOfDelay(fee.quantidadeDiasAtrasoTarifaPeriodica)
      : setDaysOfDelay("");
  };

  useLayoutEffect(() => {
    buildPayload(feeDetail);
  }, [feeDetail]);

  const updateFeeHandler = () => {
    if (checkPayload()) {
      dispatch(
        editGroupItems({
          idFeeGroupFee: feeDetail.idFeeGroupFee,
          idCustomerBank,
          data: {
            ...feeDetail,
            idCustomerBank: parseInt(idCustomerBank),
            quantidadeMinimaMensalParaCobranca: parseInt(qtdMin) || 0,
            quantidadeMaximaMensalParaCobranca: parseInt(qtdMax) || 0,
            quantidadeDiasAtrasoTarifaPeriodica: feeDetail.isPeriodica
              ? parseInt(daysOfDelay) || 0
              : 0,
            valorMinimoParaCobranca:
              convertCurrencyBRLToFloat(minValueFormatted),
            valor:
              feeChargeType === "currency"
                ? convertCurrencyBRLToFloat(valueFormatted)
                : 0,
            percentual:
              feeChargeType === "percentual"
                ? convertCurrencyBRLToFloat(valueFormatted)
                : 0,
            valorMaximoParaCobrancaPorPercentual:
              feeChargeType === "percentual"
                ? convertCurrencyBRLToFloat(percentualMaxFormatted)
                : 0,
          },
        })
      );
      setIsUpdate(false);
      onClose();
    }
  };

  const cancelHandler = () => {
    setIsUpdate(false);
    onClose();
    buildPayload(feeDetail);
    setPayloadError({});
  };

  function checkPayload(values) {
    const errors = {};

    if (!valueFormatted || valueFormatted === "0,00") {
      errors.valor = "Valor da tarifa deve ser informado!";
    }

    if (feeChargeType === "percentual" && !percentualMaxFormatted) {
      errors.valorMaximoParaCobrancaPorPercentual =
        "Valor máximo deve ser informado!";
    }

    setPayloadError(errors);

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <SlideOver
      isOpen={isDetailsOpen}
      onClose={cancelHandler}
      title={feeDetail && feeDetail.nome}
      subTitle={
        feeDetail.isPeriodica ? "Tarifa periódica" : "Tarifa transacional"
      }
    >
      <dl className="space-y-8 sm:space-y-6">
        <div>
          <Select
            label={"Tipo de cobrança"}
            id={"feeChargeType"}
            name={"feeChargeType"}
            value={feeChargeType}
            onChange={(e) => {
              setFeeChargeType(e.target.value);
              setValueFormatted("");
            }}
            options={[
              { id: "percentual", name: "Percentual" },
              { id: "currency", name: "Valor fixo" },
            ]}
            disabled={!isUpdate}
          />
        </div>
        <div>
          <Input
            label={"Valor cobrado"}
            type={"text"}
            name={"valor"}
            id={"valor"}
            prefix={feeChargeType === "percentual" ? "%" : "R$"}
            value={valueFormatted || ""}
            onChange={(e) => setValueFormatted(formatCurrency(e.target.value))}
            error={payloadError.valor}
            errorMessage={payloadError.valor}
            disabled={!isUpdate}
          />
        </div>
        {feeChargeType === "percentual" && (
          <div>
            <Input
              label={"Valor máximo para cobrança"}
              type={"text"}
              name={"valorMaximoParaCobrancaPorPercentual"}
              id={"valorMaximoParaCobrancaPorPercentual"}
              prefix={"R$"}
              placeholder={"0,00"}
              value={percentualMaxFormatted}
              onChange={(e) =>
                setPercentualMaxFormatted(formatCurrency(e.target.value))
              }
              error={payloadError.valorMaximoParaCobrancaPorPercentual}
              errorMessage={payloadError.valorMaximoParaCobrancaPorPercentual}
              disabled={!isUpdate}
            />
          </div>
        )}
        <div>
          <Input
            label={"Valor mínimo para cobrança"}
            type={"text"}
            name={"valorMinimoParaCobranca"}
            id={"valorMinimoParaCobranca"}
            prefix={"R$"}
            placeholder={"0,00"}
            value={minValueFormatted}
            onChange={(e) =>
              setMinValueFormatted(formatCurrency(e.target.value))
            }
            disabled={!isUpdate}
          />
        </div>
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="bg-white px-2 text-sm text-gray-500">
              Dados Adicionais
            </span>
          </div>
        </div>
        <div>
          <Input
            label={"Quantidade mínima"}
            type={"number"}
            value={qtdMin}
            onChange={(e) => setQtdMin(e.target.value)}
            hint={"Quantidade mínima mensal para a cobrança"}
            placeholder={"0"}
            disabled={!isUpdate}
          />
        </div>
        <div>
          <Input
            label={"Quantidade máxima"}
            type={"number"}
            value={qtdMax}
            onChange={(e) => setQtdMax(e.target.value)}
            hint={"Quantidade máxima mensal para a cobrança"}
            placeholder={"0"}
            disabled={!isUpdate}
          />
        </div>
        {feeDetail.isPeriodica && (
          <div>
            <Input
              label={"Dias de atraso para tarifas periódicas"}
              type={"number"}
              value={daysOfDelay}
              onChange={(e) => setDaysOfDelay(e.target.value)}
              placeholder={"0"}
              disabled={!isUpdate}
            />
          </div>
        )}
      </dl>
      <hr className="border-gray-200 mt-6" />
      {!isUpdate && (
        <div className="flex justify-end px-4 py-4">
          <Button label={"Editar"} onClick={() => setIsUpdate(true)} />
        </div>
      )}
      {isUpdate && (
        <div className="flex justify-end px-4 py-4 gap-3">
          <Button label={"Salvar"} onClick={updateFeeHandler} />
          <Button label={"Cancelar"} secondary onClick={cancelHandler} />
        </div>
      )}
    </SlideOver>
  );
};

export default FeeDetails;
