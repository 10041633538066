import { createSlice } from "@reduxjs/toolkit";

import {
  fetchBaasBalance,
  fetchBanksBalance,
  fetchBaasBalanceByMonth,
  fetchBanksBalanceByMonth,
  fetchBaasBalanceByDay,
  fetchBanksBalanceByDay,
  fetchBaasExtract,
  fetchBanksExtract,
  fetchClientExtract,
  fetchBaasCsv,
  fetchBanksCsv,
  fetchClientCsv,
} from "./financialThunk";

const initialState = {
  baas: {},
  banks: [],
  baasBalance: { balance: [], total: 0 },
  baasBalanceDay: { balance: [], total: 0 },
  bankBalance: { balance: [], total: 0 },
  bankBalanceDay: { balance: [], total: 0 },
  baasExtract: { transacoes: [], total: 0 },
  banksExtract: { transacoes: [], total: 0 },
  clientExtract: [],
  baasCsv: "",
  banksCsv: "",
  clientCsv: "",
};

export const financialSlice = createSlice({
  name: "financial",
  initialState,
  reducers: {
    setBaasCsv: (state, action) => {
      return { ...state, baasCsv: action.payload };
    },
    setBanksCsv: (state, action) => {
      return { ...state, banksCsv: action.payload };
    },
    setClientCsv: (state, action) => {
      return { ...state, clientCsv: action.payload };
    },
  },
  extraReducers: {
    [fetchBaasBalance.fulfilled]: (state, action) => {
      return { ...state, baas: action.payload };
    },
    [fetchBanksBalance.fulfilled]: (state, action) => {
      return { ...state, banks: action.payload };
    },
    [fetchBaasBalanceByMonth.pending]: (state, _) => {
      return { ...state, baasBalance: { balance: [], total: 0 } };
    },
    [fetchBaasBalanceByMonth.fulfilled]: (state, action) => {
      return { ...state, baasBalance: action.payload };
    },
    [fetchBanksBalanceByMonth.pending]: (state, _) => {
      return { ...state, bankBalance: { balance: [], total: 0 } };
    },
    [fetchBanksBalanceByMonth.fulfilled]: (state, action) => {
      return { ...state, bankBalance: action.payload };
    },
    [fetchBaasBalanceByDay.pending]: (state, _) => {
      return { ...state, baasBalanceDay: { balance: [], total: 0 } };
    },
    [fetchBaasBalanceByDay.fulfilled]: (state, action) => {
      return { ...state, baasBalanceDay: action.payload };
    },
    [fetchBanksBalanceByDay.pending]: (state, _) => {
      return { ...state, bankBalanceDay: { balance: [], total: 0 } };
    },
    [fetchBanksBalanceByDay.fulfilled]: (state, action) => {
      return { ...state, bankBalanceDay: action.payload };
    },
    [fetchBaasExtract.fulfilled]: (state, action) => {
      return { ...state, baasExtract: action.payload };
    },
    [fetchBaasExtract.pending]: (state, _) => {
      return { ...state, baasExtract: { transacoes: [], total: 0 } };
    },
    [fetchBanksExtract.fulfilled]: (state, action) => {
      return { ...state, banksExtract: action.payload };
    },
    [fetchBanksExtract.pending]: (state, _) => {
      return { ...state, banksExtract: { transacoes: [], total: 0 } };
    },
    [fetchClientExtract.fulfilled]: (state, action) => {
      return { ...state, clientExtract: action.payload };
    },
    [fetchClientExtract.pending]: (state, _) => {
      return { ...state, clientExtract: [] };
    },
    [fetchBaasCsv.fulfilled]: (state, action) => {
      return { ...state, baasCsv: action.payload };
    },
    [fetchBaasCsv.pending]: (state, _) => {
      return { ...state, baasCsv: "" };
    },
    [fetchBanksCsv.fulfilled]: (state, action) => {
      return { ...state, banksCsv: action.payload };
    },
    [fetchBanksCsv.pending]: (state, _) => {
      return { ...state, banksCsv: "" };
    },
    [fetchClientCsv.fulfilled]: (state, action) => {
      return { ...state, clientCsv: action.payload };
    },
    [fetchClientCsv.pending]: (state, _) => {
      return { ...state, clientCsv: "" };
    },
  },
});

export const { setBaasCsv, setBanksCsv, setClientCsv } = financialSlice.actions;

export default financialSlice.reducer;
