import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";

import { cn } from "utils/classNames";
import { convertCurrencyBRLToString } from "utils/mask";

export const DAY = "dia";
export const WEEK = "semana";
export const MONTH = "mes";
export const QUARTER = "trimestre";

const AccountData = () => {
  const { metrics } = useSelector((store) => store.metrics);
  const [period, setPeriod] = useState(DAY);
  const [accountNumber, setAccountNumber] = useState(null);
  const [accountValues, setAccountValues] = useState(null);

  const buttons = [{ text: DAY }, { text: WEEK }, { text: MONTH }];

  useEffect(() => {
    if (metrics.length > 0) {
      const accountNumberObj = {
        contasAtivas: metrics[0][period].contasAtivas,
        contasAbertas: metrics[0][period].contasAbertas,
        contasFechadas: metrics[0][period].contasFechadas,
        contasBloqueadas: metrics[0][period].contasBloqueadas,
      };

      setAccountNumber(accountNumberObj);

      const accountValuesObj = {
        valorTransacaoBanco: metrics[0][period].valorTransacaoBanco,
        valorTransacaoBancoPF: metrics[0][period].valorTransacaoBancoPF,
        valorTransacaoBancoPJ: metrics[0][period].valorTransacaoBancoPJ,
        quantidadeTransacoes: metrics[0][period].quantidadeTransacoes,
      };

      setAccountValues(accountValuesObj);
    }
  }, [metrics, period]);

  return (
    <div className="col-span-4 lg:col-span-2 bg-white border rounded-lg p-6 flex flex-col">
      <div className="flex flex-row flex-wrap items-center justify-between gap-6">
        <div className="flex flex-row items-center gap-1">
          <h2 className="text-xl font-semibold">Panorama do período</h2>
          <QuestionMarkCircleIcon
            className="h-5 w-5 cursor-help shrink-0"
            data-tooltip-id="account-data-explanation"
          />
          <Tooltip id="account-data-explanation">
            <div className="text-center text-sm normal-case">
              Tenha um panorama dos dados de contas do ecossistema
              <br />e filtre as informações por período desejado.
            </div>
          </Tooltip>
        </div>
        <div className="flex flex-row items-center gap-3">
          {buttons.map((button) => (
            <button
              key={button.text}
              onClick={() => setPeriod(button.text)}
              className={cn(
                "bg-gray-200 px-5 py-1 text-gray-800 rounded-full text-sm w-24 text-center uppercase font-medium",
                period === button.text
                  ? "bg-indigo-600 text-white"
                  : "hover:bg-indigo-400 hover:text-white"
              )}
            >
              {button.text}
            </button>
          ))}
        </div>
      </div>
      {metrics.length > 0 && (
        <div className="flex flex-col md:flex-row divide-y md:divide-y-0 md:divide-x pt-6 md:py-10 grow">
          <div className={styles.cardContainer}>
            <div className={styles.headerContainer}>
              <span className={styles.headerValue}>
                {accountNumber?.contasAtivas}
              </span>
              <div className="flex flex-row items-center gap-1">
                <span className={styles.label}>Total de contas ativas</span>
                <QuestionMarkCircleIcon
                  className="h-4 w-4 cursor-help shrink-0"
                  data-tooltip-id="active-accounts-explanation"
                />
                <Tooltip id="active-accounts-explanation">
                  <div className="text-center text-sm normal-case">
                    Contas que realizaram ou receberam transações no período
                    selecionado.
                  </div>
                </Tooltip>
              </div>
            </div>
            <div>
              <div className={styles.valueContainer}>
                <span className={styles.value}>
                  {accountNumber?.contasAbertas}
                </span>{" "}
                contas abertas
              </div>
              <div className={styles.valueContainer}>
                <span className={styles.value}>
                  {accountNumber?.contasFechadas}
                </span>{" "}
                contas encerradas
              </div>
              <div className={styles.valueContainer}>
                <span className={styles.value}>
                  {accountNumber?.contasBloqueadas}
                </span>{" "}
                contas bloqueadas
              </div>
            </div>
          </div>

          <div className={styles.cardContainer}>
            <div className={styles.headerContainer}>
              <span className={styles.headerValue}>
                R${" "}
                {convertCurrencyBRLToString(accountValues?.valorTransacaoBanco)}
              </span>
              <span className={styles.label}>Valor total transacionado</span>
            </div>
            <div>
              <div className={styles.valueContainer}>
                <span className={styles.value}>
                  R${" "}
                  {convertCurrencyBRLToString(
                    accountValues?.valorTransacaoBancoPF
                  )}
                </span>{" "}
                por PF
              </div>
              <div className={styles.valueContainer}>
                <span className={styles.value}>
                  R${" "}
                  {convertCurrencyBRLToString(
                    accountValues?.valorTransacaoBancoPJ
                  )}
                </span>{" "}
                por PJ
              </div>
              <div className={styles.valueContainer}>
                <span className={styles.value}>
                  {accountValues?.quantidadeTransacoes}
                </span>{" "}
                transações
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountData;

const styles = {
  cardContainer: "flex-1 flex flex-col justify-center items-center gap-10 py-4",
  headerContainer: "flex flex-col items-center",
  headerValue: "text-xl font-semibold",
  label: "text-sm text-gray-500",
  valueContainer: "text-center text-sm text-gray-500",
  value: "text-sm font-medium text-gray-900",
};
