import { createSlice } from "@reduxjs/toolkit";

import {
  fetchBankList,
  fetchRendeMais,
  updateTime,
  fetchBankLimits,
  newBankLimit,
  updateTimeOnboarding,
} from "./bankThunk";

const initialState = {
  banks: [],
  bankLimits: [],
  rendeMaisAccounts: [],
  error: false,
  success: false,
};

export const bankSlice = createSlice({
  name: "bank",
  initialState,
  extraReducers: {
    [fetchBankList.fulfilled]: (state, action) => {
      return { ...state, banks:  action.payload };
    },
    [fetchBankList.rejected]: (state, action) => {
      return { ...state, banks: action.payload };
    },
    [updateTime.pending]: (state) => {
      return { ...state, error: false, success: false };
    },
    [updateTime.fulfilled]: (state) => {
      return { ...state, error: false, success: true };
    },
    [updateTime.rejected]: (state) => {
      return { ...state, error: true, success: false };
    },
    [fetchBankLimits.pending]: (state) => {
      return { ...state, bankLimits: [] };
    },
    [fetchBankLimits.fulfilled]: (state, action) => {
      return { ...state, bankLimits: action.payload };
    },
    [fetchBankLimits.rejected]: (state, action) => {
      return { ...state, bankLimits: action.payload };
    },
    [fetchRendeMais.pending]: (state) => {
      return { ...state, rendeMaisAccounts: [] };
    },
    [fetchRendeMais.fulfilled]: (state, action) => {
      return { ...state, rendeMaisAccounts: action.payload };
    },
    [fetchRendeMais.rejected]: (state, action) => {
      return { ...state, rendeMaisAccounts: action.payload };
    },
    [newBankLimit.pending]: (state) => {
      return { ...state, error: false, success: false };
    },
    [newBankLimit.fulfilled]: (state) => {
      return { ...state, error: false, success: true };
    },
    [newBankLimit.rejected]: (state) => {
      return { ...state, error: true, success: false };
    },
    [updateTimeOnboarding.pending]: (state) => {
      return { ...state, error: false, success: false };
    },
    [updateTimeOnboarding.fulfilled]: (state) => {
      return { ...state, error: false, success: true };
    },
    [updateTimeOnboarding.rejected]: (state) => {
      return { ...state, error: true, success: false };
    },
  },
});

export default bankSlice.reducer;
