import { useState, useEffect, useReducer, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Datepicker from "react-tailwindcss-datepicker";
import timezone from "moment-timezone";

import Layout from "components/layout";
import Button from "components/button";
import Pagination from "components/pagination";
import Input from "components/input";
import Select from "components/select";
import { cnpjMask, cpfMask, numberMask } from "utils/mask";
import { fetchOnboardingList } from "reducers/onboarding/onboardingThunk";
import { fetchBankList } from "reducers/bank/bankThunk";
import { renderDocument } from "utils/mask";

const initialState = {
  currentPage: 1,
  dateFilter: {
    startDate: timezone().subtract(30, "d").format("YYYY-MM-DD"),
    endDate: timezone().format("YYYY-MM-DD"),
  },
  itemsPerPage: 30,
  idCustomerBank: null,
};

const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
const SET_DATE_FILTER = "SET_DATE_FILTER";
const SET_ITEMS_PER_PAGE = "SET_ITEMS_PER_PAGE";
const SET_ID_CUSTOMER_BANK = "SET_ID_CUSTOMER_BANK";

const reducer = (state, action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return { ...state, currentPage: action.payload };
    case SET_DATE_FILTER:
      return { ...state, dateFilter: action.payload, currentPage: 1 };
    case SET_ITEMS_PER_PAGE:
      return { ...state, itemsPerPage: action.payload, currentPage: 1 };
    case SET_ID_CUSTOMER_BANK:
      return { ...state, idCustomerBank: action.payload, currentPage: 1 };
    default:
      return state;
  }
};

const OpeningList = () => {
  const dispatch = useDispatch();

  const { onboardingList } = useSelector((store) => store.onboarding);
  const { banks } = useSelector((store) => store.bank);
  const [document, setDocument] = useState("");
  const [error, setError] = useState("");
  const [state, localDispatch] = useReducer(reducer, initialState);
  const [bankList, setBankList] = useState([]);

  const fetchOnboardingListHandler = useCallback(() => {
    dispatch(
      fetchOnboardingList({
        dataInicio: state.dateFilter.startDate,
        dataFim: state.dateFilter.endDate,
        paginaAtual: state.currentPage,
        document: numberMask(document),
        itemsPerPage: state.itemsPerPage,
        idCustomerBank:
          state.idCustomerBank === "0" ? null : state.idCustomerBank,
      })
    );

    // eslint-disable-next-line
  }, [dispatch, state, document]);

  useEffect(() => {
    fetchOnboardingListHandler();

    // eslint-disable-next-line
  }, [state.dateFilter, state.currentPage, state.itemsPerPage]);

  useEffect(() => {
    if (!banks.length) dispatch(fetchBankList());

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const bankListArray = banks.map((bank) => ({
      id: bank.idBanco,
      name: bank.descricao,
    }));

    bankListArray.unshift({ id: 0, name: "Todos" });

    setBankList(bankListArray);
  }, [banks]);

  const handleChangeDate = (newValue) => {
    const startDate = timezone(newValue.startDate, "YYYY-MM-DD");
    const endDate = timezone(newValue.endDate, "YYYY-MM-DD");

    localDispatch({
      type: SET_DATE_FILTER,
      payload: {
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
      },
    });
  };

  const handleNextPage = () => {
    localDispatch({ type: SET_CURRENT_PAGE, payload: state.currentPage + 1 });
  };

  const handlePreviousPage = () => {
    localDispatch({ type: SET_CURRENT_PAGE, payload: state.currentPage - 1 });
  };

  const changeCurrentPage = (page = 1) => {
    localDispatch({ type: SET_CURRENT_PAGE, payload: page });
  };

  const changeItemsPerPageHandler = (e) => {
    localDispatch({
      type: SET_ITEMS_PER_PAGE,
      payload: parseInt(e.target.value),
    });
  };

  const handleChange = (evt) => {
    const { value } = evt.target;

    if (!value.length) {
      setDocument("");
    } else if (numberMask(value).length <= 11) {
      setDocument(cpfMask(value));
    } else {
      setDocument(cnpjMask(value));
    }
  };

  const isValidDocument = () => {
    if (document.length) {
      if (
        numberMask(document).length !== 11 &&
        numberMask(document).length !== 14
      ) {
        setError("Tamanho inválido");
        return false;
      }
    }

    return true;
  };

  const handleFilter = () => {
    if (!isValidDocument()) return;

    setError("");

    if (state.currentPage !== 1) {
      changeCurrentPage();
    } else {
      fetchOnboardingListHandler();
    }
  };

  const changeBankHandler = (event) => {
    const { value } = event.target;

    localDispatch({
      type: SET_ID_CUSTOMER_BANK,
      payload: value,
    });
  };

  const handleOnEnter = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      handleFilter();
    }
  };

  return (
    <Layout title={"Onboarding"}>
      <div className="flex flex-col sm:flex-row gap-3 items-start">
        <div className="rounded-md w-full sm:w-auto sm:max-w-xs">
          <Datepicker
            configs={{
              shortcuts: {
                today: "Hoje",
                yesterday: "Ontem",
                past: (period) => `Últimos ${period} dias`,
                currentMonth: "Este mês",
                pastMonth: "Último mês",
              },
            }}
            i18n={"pt"}
            placeholder={"Selecione o intervalo"}
            separator={"-"}
            displayFormat={"DD/MM/YYYY"}
            toggleClassName="hidden"
            readOnly={true}
            showShortcuts={true}
            value={state.dateFilter}
            onChange={handleChangeDate}
            primaryColor={"indigo"}
            popoverDirection={"down"}
            inputClassName="py-2 block w-full outline-0 text-sm rounded-md border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        {process.env.REACT_APP_MODE === "CORE" && (
          <Select
            className={"mt-0 w-auto"}
            id={"idCustomerBank"}
            name={"idCustomerBank"}
            value={state.idCustomerBank || ""}
            placeholder={"Selecione o banco desejado"}
            options={bankList}
            onChange={changeBankHandler}
          />
        )}
        <div className="w-full sm:max-w-xs">
          <Input
            type={"text"}
            name={"document"}
            id={"document"}
            placeholder={"Informe o CPF ou CNPJ"}
            onChange={handleChange}
            value={document}
            error={error}
            errorMessage={error}
            onKeyUp={handleOnEnter}
          />
        </div>
        <Button
          type={"submit"}
          label={"Filtrar"}
          onClick={handleFilter}
          className={"w-full sm:w-auto"}
        />
      </div>
      <div className="mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:mx-0 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Documento
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                ID Guid
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Iniciado em
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
              >
                Completo
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
              >
                Aprovado
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Mensagem
              </th>
              {process.env.REACT_APP_MODE === "CORE" && (
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  Banco
                </th>
              )}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {onboardingList?.onboarding?.length ? (
              onboardingList.onboarding.map((item) => (
                <tr key={item.idOnb}>
                  <td className="hidden pl-4 pr-3 py-4 sm:pl-6 text-sm text-gray-500 lg:table-cell whitespace-nowrap">
                    {renderDocument(item.documento)}
                  </td>
                  <td className="pl-4 pr-3 py-4 sm:pl-6 text-sm text-gray-500 lg:hidden">
                    <span className="lg:hidden font-medium text-gray-900 whitespace-nowrap">
                      {renderDocument(item.documento)}
                    </span>
                    <dl className="font-normal text-gray-700 lg:hidden">
                      <dt className="sr-only">ID Guid</dt>
                      <dd className="mt-1 truncate">ID Guid: {item.idGuid}</dd>
                    </dl>
                    <dl className="font-normal lg:hidden">
                      <dt className="sr-only">Iniciado em</dt>
                      <dd className="mt-1 truncate">
                        {timezone
                          .utc(item.data)
                          .tz("America/Sao_Paulo")
                          .format("DD/MM/YYYY HH:mm:ss")}
                      </dd>
                    </dl>
                    {process.env.REACT_APP_MODE === "CORE" && (
                      <dl className="font-normal lg:hidden">
                        <dt className="sr-only">Banco</dt>
                        <dd className="mt-1 truncate">{item.nome}</dd>
                      </dl>
                    )}
                    <dl className="font-normal md:hidden">
                      <dt className="sr-only">Completo</dt>
                      <dd className="mt-1 truncate">
                        Completo: {item.completo ? "Sim" : "Não"}
                      </dd>
                    </dl>
                    <dl className="font-normal md:hidden">
                      <dt className="sr-only">Aprovado</dt>
                      <dd className="mt-1 truncate">
                        Aprovado: {item.aprovado ? "Sim" : "Não"}
                      </dd>
                    </dl>
                    <dl className="font-normal sm:hidden">
                      <dt className="sr-only">Mensagem</dt>
                      <dd className="mt-1">Mensagem: {item.mensagem}</dd>
                    </dl>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                    {item.idGuid}
                  </td>
                  <td className="whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                    {timezone
                      .utc(item.data)
                      .tz("America/Sao_Paulo")
                      .format("DD/MM/YYYY HH:mm:ss")}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                    {item.completo ? "Sim" : "Não"}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                    {item.aprovado ? "Sim" : "Não"}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {item.mensagem}
                  </td>
                  {process.env.REACT_APP_MODE === "CORE" && (
                    <td className="whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                      {item.nome}
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={process.env.REACT_APP_MODE === "CORE" ? 7 : 6}
                  className="text-sm sm:text-base text-gray-500 text-center py-5"
                >
                  Não há dados para exibir.
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination
          total={onboardingList.total}
          pageItens={onboardingList?.onboarding?.length}
          pageCount={Math.ceil(onboardingList.total / state.itemsPerPage)}
          currentPage={state.currentPage}
          onNextPage={handleNextPage}
          onPreviousPage={handlePreviousPage}
          onClick={changeCurrentPage}
          itemsPerPage={state.itemsPerPage}
          onChangeItemsPerPage={changeItemsPerPageHandler}
        />
      </div>
    </Layout>
  );
};

export default OpeningList;
