import OnboardingTime from "./onboarding-time";
import OnboardingAvailability from "./onboarding-availability";

const Onboarding = () => {
  return (
    <>
      <OnboardingTime />
      <OnboardingAvailability />
    </>
  );
};

export default Onboarding;
