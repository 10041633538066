import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BuildingLibraryIcon } from "@heroicons/react/24/outline";

import ModalSetTime from "./components/modal-set-time";
import Toggle from "components/toggle";
import { updateTimeOnboarding } from "reducers/bank/bankThunk";
import { getUTCTime } from "utils/mask";
import { logos } from "utils/logos";
import { cn } from "utils/classNames";

const getBankLogo = (idBank) => {
  const logo = logos.filter((logo) => logo.idCustomerBank === idBank);

  if (logo.length) {
    return (
      <div className="rounded-md">
        <div className="h-9 w-9 flex items-center">{logo[0].svg}</div>
      </div>
    );
  } else {
    return (
      <div className="rounded-md bg-indigo-500 p-3">
        <BuildingLibraryIcon
          className="h-4 w-4 text-white"
          aria-hidden="true"
        />
      </div>
    );
  }
};

const OnboardingTime = () => {
  const dispatch = useDispatch();

  const { banks } = useSelector((store) => store.bank);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [item, setItem] = useState({});

  const openModalHandler = (changedItem) => {
    setItem(changedItem);
    setIsModalOpen(true);
  };

  const changeAvailabilityHandler = (changedItem, checked) => {
    dispatch(
      updateTimeOnboarding({
        ...changedItem,
        onboarding24horas: checked,
        idCustomerBank: changedItem.idBanco,
      })
    );
  };

  return (
    <div className="relative">
      <ModalSetTime open={isModalOpen} setOpen={setIsModalOpen} item={item} />
      <div className="shadow sm:overflow-hidden sm:rounded-md">
        <div className="bg-white ">
          <div className="py-6 px-4 sm:p-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Horário Onboarding
            </h3>
          </div>
          <div>
            <ul className="divide-y divide-gray-200">
              {banks.map((bank) => (
                <li key={bank.idBanco}>
                  <span
                    className="block hover:bg-gray-50 cursor-pointer"
                    onClick={() =>
                      !bank.onboarding24horas && openModalHandler(bank)
                    }
                  >
                    <div className="flex items-center px-4 py-4 sm:px-6">
                      <div className="flex min-w-0 flex-1 items-center">
                        {getBankLogo(bank.idBanco)}
                        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-4 md:gap-4">
                          <div className="flex  flex-col justify-center">
                            <p className="truncate text-sm font-medium text-indigo-600">
                              {bank.descricao}
                            </p>
                            <div className="md:hidden space-y-0.5 mt-2">
                              <p
                                className={cn(
                                  "text-sm text-gray-900",
                                  bank.onboarding24horas && "text-gray-400"
                                )}
                              >
                                Horário inicial{" "}
                                <span
                                  className={cn(
                                    "text-gray-500",
                                    bank.onboarding24horas && "text-gray-400"
                                  )}
                                >
                                  {getUTCTime(bank.horarioInicioOnboarding)}
                                </span>
                              </p>
                              <p
                                className={cn(
                                  "text-sm text-gray-900",
                                  bank.onboarding24horas && "text-gray-400"
                                )}
                              >
                                Horário final{" "}
                                <span
                                  className={cn(
                                    "text-gray-500",
                                    bank.onboarding24horas && "text-gray-400"
                                  )}
                                >
                                  {getUTCTime(bank.horarioFinalOnboarding)}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="hidden md:block">
                            <div>
                              <p
                                className={cn(
                                  "text-sm text-gray-900",
                                  bank.onboarding24horas && "text-gray-400"
                                )}
                              >
                                Horário inicial
                              </p>
                              <p
                                className={cn(
                                  "mt-2 flex items-center text-sm text-gray-500",
                                  bank.onboarding24horas && "text-gray-400"
                                )}
                              >
                                {getUTCTime(bank.horarioInicioOnboarding)}
                              </p>
                            </div>
                          </div>
                          <div className="hidden md:block">
                            <div>
                              <p
                                className={cn(
                                  "text-sm text-gray-900",
                                  bank.onboarding24horas && "text-gray-400"
                                )}
                              >
                                Horário final
                              </p>
                              <p
                                className={cn(
                                  "mt-2 flex items-center text-sm text-gray-500",
                                  bank.onboarding24horas && "text-gray-400"
                                )}
                              >
                                {getUTCTime(bank.horarioFinalOnboarding)}
                              </p>
                            </div>
                          </div>
                          <div
                            className="flex flex-col justify-center mt-2 md:mt-0"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Toggle
                              enabled={bank.onboarding24horas}
                              label={"Aberto 24 horas"}
                              onChange={(checked) =>
                                changeAvailabilityHandler(bank, checked)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingTime;
