import { cn } from "utils/classNames";

const SvgIcon = ({ icon, alt, className }) => {
  return (
    <div>
      <img className={cn("h-4 w-auto", className)} src={icon} alt={alt} />
    </div>
  );
};

export default SvgIcon;
