import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BuildingLibraryIcon } from "@heroicons/react/24/outline";

import Layout from "components/layout";
import Baas2uLogo from "assets/baas2u.svg";
import { logos } from "utils/logos";
import { convertCurrencyBRLToString } from "utils/mask";
import {
  fetchBaasBalance,
  fetchBanksBalance,
} from "reducers/financial/financialThunk";

export default function Conciliation() {
  const { baas, banks } = useSelector((store) => store.financial);
  const { id_Customer_Bank } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const breadcrumbPages = [{ name: "Conciliação" }];

  useEffect(() => {
    if (process.env.REACT_APP_MODE === "CLIENT")
      navigate(`/financeiro/conciliacao/${id_Customer_Bank}`);
  }, [id_Customer_Bank, navigate]);

  useEffect(() => {
    if (process.env.REACT_APP_MODE === "CORE") {
      dispatch(fetchBaasBalance());
      dispatch(fetchBanksBalance());
    }

    // eslint-disable-next-line
  }, []);

  const getBankLogo = (idBank) => {
    const logo = logos.filter((logo) => logo.idCustomerBank === idBank);

    if (logo.length) {
      return (
        <div className="absolute rounded-md">
          <div className="h-10 w-10 flex items-center">{logo[0].svg}</div>
        </div>
      );
    } else {
      return (
        <div className="absolute rounded-md bg-indigo-500 p-3">
          <BuildingLibraryIcon
            className="h-6 w-6 text-white"
            aria-hidden="true"
          />
        </div>
      );
    }
  };

  if (process.env.REACT_APP_MODE === "CLIENT") return;

  return (
    <Layout title={"BaaS2u"} breadcrumbPages={breadcrumbPages}>
      <div>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {Object.keys(baas).length > 0 && (
            <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6">
              <dt>
                <div className="absolute rounded-md">
                  <img className="h-10 w-10" alt="Baas2u" src={Baas2uLogo} />
                </div>
                <p className="ml-16 truncate text-sm font-medium text-gray-500">
                  BaaS2u
                </p>
              </dt>
              <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                <p className="text-2xl font-semibold text-gray-900">
                  R$ {convertCurrencyBRLToString(baas.saldo)}
                </p>
                <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                  <div className="text-sm">
                    <span
                      onClick={() => navigate(`/financeiro/conciliacao/baas`)}
                      className="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
                    >
                      {" "}
                      Detalhes<span className="sr-only"> Baas2u stats</span>
                    </span>
                  </div>
                </div>
              </dd>
            </div>
          )}
        </dl>
        <h2 className="text-xl font-bold leading-tight text-gray-900 mt-6">
          Ecossistemas
        </h2>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {banks.map((bank) => (
            <div
              key={bank.idCustomerBank}
              className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6"
            >
              <dt>
                {getBankLogo(bank.idCustomerBank)}
                <p className="ml-16 truncate text-sm font-medium text-gray-500">
                  {bank.nome}
                </p>
              </dt>
              <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                <p className="text-2xl font-semibold text-gray-900">
                  R$ {convertCurrencyBRLToString(bank.saldo)}
                </p>
                <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                  <div className="text-sm">
                    <span
                      onClick={() =>
                        navigate(
                          `/financeiro/conciliacao/${bank.idCustomerBank}`
                        )
                      }
                      className="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
                    >
                      {" "}
                      Detalhes
                      <span className="sr-only"> {bank.nome} stats</span>
                    </span>
                  </div>
                </div>
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </Layout>
  );
}
