import { useEffect, useReducer, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { EyeIcon } from "@heroicons/react/24/solid";
import Datepicker from "react-tailwindcss-datepicker";
import moment from "moment";

import Pagination from "components/pagination";
import JsonModal from "components/modal-json";
import { fetchClientErrors } from "reducers/client/clientThunk";
import { formatDate } from "utils/mask";

const initialState = {
  currentPage: 1,
  dateFilter: {
    startDate: moment().subtract(30, "d").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  },
  itemsPerPage: 30,
};

const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
const SET_DATE_FILTER = "SET_DATE_FILTER";
const SET_ITEMS_PER_PAGE = "SET_ITEMS_PER_PAGE";

const reducer = (state, action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return { ...state, currentPage: action.payload };
    case SET_DATE_FILTER:
      return { ...state, dateFilter: action.payload, currentPage: 1 };
    case SET_ITEMS_PER_PAGE:
      return { ...state, itemsPerPage: action.payload, currentPage: 1 };
    default:
      return state;
  }
};

export default function ClientErrors() {
  const dispatch = useDispatch();
  const { idClient } = useParams();
  const { errors } = useSelector((store) => store.client);

  const [state, reducerDispatch] = useReducer(reducer, initialState);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [jsonRequest, setJsonRequest] = useState(null);

  const getClientErrors = useCallback(() => {
    dispatch(
      fetchClientErrors({
        idClient,
        dataInicio: state.dateFilter.startDate,
        dataFim: state.dateFilter.endDate,
        currentPage: state.currentPage,
        itemsPerPage: state.itemsPerPage,
      })
    );
  }, [dispatch, state, idClient]);

  useEffect(() => {
    getClientErrors();
  }, [getClientErrors]);

  const handleChangeDate = (newValue) => {
    const startDate = moment(newValue.startDate, "YYYY-MM-DD");
    const endDate = moment(newValue.endDate, "YYYY-MM-DD");

    reducerDispatch({
      type: SET_DATE_FILTER,
      payload: {
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
      },
    });
  };

  const handleNextPage = () => {
    reducerDispatch({ type: SET_CURRENT_PAGE, payload: state.currentPage + 1 });
  };

  const handlePreviousPage = () => {
    reducerDispatch({ type: SET_CURRENT_PAGE, payload: state.currentPage - 1 });
  };

  const changeCurrentPage = (page = 1) => {
    reducerDispatch({ type: SET_CURRENT_PAGE, payload: page });
  };

  const changeItemsPerPageHandler = (e) => {
    reducerDispatch({
      type: SET_ITEMS_PER_PAGE,
      payload: parseInt(e.target.value),
    });
  };

  const openModalHandler = (request) => {
    setIsModalOpen(true);
    request.param1.value = JSON.parse(request?.param1?.value);
    setJsonRequest(request);
  };

  const closeModalHandler = () => {
    setIsModalOpen(false);
    setTimeout(() => setJsonRequest(null), 500);
  };

  return (
    <div className="relative">
      <JsonModal
        isModalOpen={isModalOpen}
        onCloseModal={closeModalHandler}
        json={jsonRequest}
      />
      <Datepicker
        configs={{
          shortcuts: {
            today: "Hoje",
            yesterday: "Ontem",
            past: (period) => `Últimos ${period} dias`,
            currentMonth: "Este mês",
            pastMonth: "Último mês",
          },
        }}
        i18n={"pt"}
        placeholder={"Selecione o intervalo"}
        separator={"-"}
        displayFormat={"DD/MM/YYYY"}
        toggleClassName="hidden"
        readOnly={true}
        showShortcuts={true}
        value={state.dateFilter}
        onChange={handleChangeDate}
        primaryColor={"indigo"}
        popoverDirection={"down"}
        inputClassName="w-full sm:w-48 text-center relative transition-all duration-300 py-2.5 px-2 border-gray-300 rounded-lg tracking-wide font-light text-sm placeholder-gray-400 bg-white focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-indigo-500 focus:ring-indigo-500/20"
      />
      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:mx-0 md:rounded-lg mt-6">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Data / Hora
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
              >
                IP
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Endpoint
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Erro
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Requisição
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {errors?.errors?.length > 0 ? (
              errors.errors.map((error) => (
                <tr key={error?.idAccountAPILog}>
                  <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm text-gray-500 sm:w-auto sm:max-w-none sm:pl-6 whitespace-nowrap">
                    {formatDate(error?.dateStart)}
                    <dl className="font-normal lg:hidden">
                      <dt className="sr-only md:hidden">IP</dt>
                      <dd className="mt-1 truncate text-gray-500 md:hidden">
                        {error?.ip}
                      </dd>
                      <dt className="sr-only lg:hidden">Endpoint</dt>
                      <dd className="mt-1 truncate text-gray-500 lg:hidden flex items-center">
                        <button
                          className="mr-3"
                          onClick={() =>
                            openModalHandler(JSON.parse(error?.request))
                          }
                        >
                          {<EyeIcon className="h-5 w-5" />}
                        </button>
                        {error?.endpoint}
                      </dd>
                      <dt className="sr-only sm:hidden">Mensagem de erro</dt>
                      <dd className="mt-1 text-gray-500 sm:hidden whitespace-normal">
                        {error?.response}
                      </dd>
                    </dl>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell whitespace-nowrap">
                    {error?.ip}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell whitespace-nowrap">
                    {error?.endpoint}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {error?.response}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                    <button
                      onClick={() =>
                        openModalHandler(JSON.parse(error?.request))
                      }
                    >
                      {<EyeIcon className="h-5 w-5" />}
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={5}
                  className="text-sm sm:text-base text-gray-500 text-center py-5"
                >
                  Não há dados para exibir
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination
          total={errors.total}
          pageItens={state.itemsPerPage}
          pageCount={Math.ceil(errors.total / state.itemsPerPage)}
          currentPage={state.currentPage}
          onNextPage={handleNextPage}
          onPreviousPage={handlePreviousPage}
          onClick={changeCurrentPage}
          itemsPerPage={state.itemsPerPage}
          onChangeItemsPerPage={changeItemsPerPageHandler}
        />
      </div>
    </div>
  );
}
