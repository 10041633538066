import { createSlice } from "@reduxjs/toolkit";

import {
  fetchAccounts,
  chargeBackTransaction,
  fetchChargeBackId,
  fetchCancelAccounts,
  postPreTransaction,
  fetchPendingPreTransactions,
  fetchAccountStatus
} from "./accountsThunk";

const initialState = {
  accounts: { accounts: [], total: 0 },
  accountStatus: [],
  cancelAccounts: [],
  chargeBack: { success: false, error: false },
  preTransactionList: [],
  preTransaction: { success: false, error: false },
  chargeBackIds: [],
};

export const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    setChargeBack: (state, action) => {
      return { ...state, chargeBack: action.payload };
    },
  },
  extraReducers: {
    [fetchAccounts.fulfilled]: (state, action) => {
      return { ...state, accounts: { ...action.payload } };
    },
    [fetchAccountStatus.fulfilled]: (state, action) => {
      return { ...state, accountStatus: action.payload };
    },
    [chargeBackTransaction.pending]: (state) => {
      return { ...state, chargeBack: { success: false, error: false } };
    },
    [chargeBackTransaction.fulfilled]: (state) => {
      return { ...state, chargeBack: { success: true, error: false } };
    },
    [chargeBackTransaction.rejected]: (state) => {
      return { ...state, chargeBack: { success: false, error: true } };
    },
    [fetchChargeBackId.fulfilled]: (state, action) => {
      return { ...state, chargeBackIds: action.payload };
    },
    [fetchChargeBackId.rejected]: (state) => {
      return { ...state, chargeBackIds: [] };
    },
    [fetchCancelAccounts.pending]: (state) => {
      return { ...state, cancelAccounts: [] };
    },
    [fetchCancelAccounts.fulfilled]: (state, action) => {
      return { ...state, cancelAccounts: action.payload };
    },
    [fetchCancelAccounts.rejected]: (state) => {
      return { ...state, cancelAccounts: [] };
    },
    [postPreTransaction.pending]: (state) => {
      return { ...state, preTransaction: { success: false, error: false } };
    },
    [postPreTransaction.fulfilled]: (state) => {
      return { ...state, preTransaction: { success: true, error: false } };
    },
    [postPreTransaction.rejected]: (state) => {
      return { ...state, preTransaction: { success: false, error: true } };
    },
    [fetchPendingPreTransactions.pending]: (state) => {
      return { ...state, preTransactionList: [] };
    },
    [fetchPendingPreTransactions.fulfilled]: (state, action) => {
      return { ...state, preTransactionList: action.payload };
    },
    [fetchPendingPreTransactions.rejected]: (state) => {
      return { ...state, preTransactionList: [] };
    },
  },
});

export const { setChargeBack } = accountsSlice.actions;

export default accountsSlice.reducer;
