export const limitsToDisplay = [1, 3, 7, 23, 24];
export const availableReceipts = [1, 7, 8, 12, 23, 24, 98];

export const USER_ADMIN = "admin_dashboard";
export const USER_FINANCEIRO = "user_financeiro";
export const USER_OPERACOES = "user_operacoes";

export const PRE_TRANSACTION_CREATOR = "pre_transaction_creator";
export const PRE_TRANSACTION_APROVER = "pre_transaction_approver";

export const LIMIT_REQUEST_APPROVER = "limit_request_approver";

export const ADMIN_ECOSYSTEM = "admin_ecosystem";
