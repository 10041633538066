import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./user/userSlice";
import isLoadingReducer from "./loading/isLoadingSlice";
import accountsReducer from "./accounts/accountsSlice";
import clientReducer from "./client/clientSlice";
import azureAccountReducer from "./azure-account/azureAccountSlice";
import notificationReducer from "./notification/notificationSlice";
import zipCodeReducer from "./zipcode/zipcodeSlice";
import onboardingReducer from "./onboarding/onboardingSlice";
import posReducer from "./pos/posSlice";
import finalcialReducer from "./financial/financialSlice";
import limitsReducer from "./limits/limitsSlice";
import bankReducer from "./bank/bankSlice";
import emailBaseReducer from "./email-base/emailBaseSlice";
import feesReducer from "./fees/feesSlice";
import voucherReducer from "./voucher/voucherSlice";
import adminReducer from "./admin/adminSlice";
import metricsReducer from "./metrics/metricsSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    isLoading: isLoadingReducer,
    accounts: accountsReducer,
    client: clientReducer,
    azureAccount: azureAccountReducer,
    notification: notificationReducer,
    zipcode: zipCodeReducer,
    onboarding: onboardingReducer,
    pos: posReducer,
    financial: finalcialReducer,
    limits: limitsReducer,
    bank: bankReducer,
    emailer: emailBaseReducer,
    fees: feesReducer,
    voucher: voucherReducer,
    admin: adminReducer,
    metrics: metricsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
