import { createAsyncThunk } from "@reduxjs/toolkit";

import { setIsLoading } from "reducers/loading/isLoadingSlice";
import { setNotification } from "reducers/notification/notificationSlice";
import { emailBase } from "services/emailBase";

export const postEmailBase = createAsyncThunk(
  "emailer/postEmailBase",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      await emailBase(
        payload.idCustomerBank,
        payload.idTemplate,
        payload.receiver
      );

      thunkAPI.dispatch(
        setNotification({
          type: "success",
          title: "Email enviado",
          message: "Email enviado com sucesso!",
        })
      );

      return true;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );
      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);
