import { createAsyncThunk } from "@reduxjs/toolkit";

import { newPos, getPos } from "services/pos";
import { setNotification } from "reducers/notification/notificationSlice";
import { setIsLoading } from "reducers/loading/isLoadingSlice";

export const fethPosList = createAsyncThunk(
  "pos/fethPosList",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getPos(payload);

      return response.data.success;
    } catch (error) {
      console.error("error getPos ==> ", error);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const registerPos = createAsyncThunk(
  "pos/registerPos",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      await newPos(payload);

      thunkAPI.dispatch(
        setNotification({
          type: "success",
          title: "POS cadastrada",
          message: "POS cadastrada com sucesso!",
        })
      );

      thunkAPI.dispatch(fethPosList(payload.id_client));
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);
