import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { isEqual } from "lodash";

import Button from "components/button";
import Select from "components/select";
import Input from "components/input";
import BadgeCelcoin from "../../common/badge-celcoin";
import { banks } from "utils/banks";
import { ufs } from "utils/ufs";
import { setEditingForm } from "reducers/client/clientSlice";
import { updateClient } from "reducers/client/clientThunk";
import { checkRole } from "utils/isRoleAllowed";
import { USER_ADMIN, USER_OPERACOES, ADMIN_ECOSYSTEM } from "utils/const";
import {
  cpfMask,
  phoneMask,
  dateMask,
  numberMask,
  currencyMask,
  convertCurrencyBRLToFloat,
} from "utils/mask";

export default function PersonalData() {
  const { client, isUpdating, editingForm } = useSelector(
    (store) => store.client
  );
  const { role } = useSelector((store) => store.user);
  const [personalData, setPersonalData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [formattedValue, setFormattedValue] = useState("");
  const dispatch = useDispatch();
  const [logo, setLogo] = useState();
  const [isEditingMode, setIsEditingMode] = useState(false);

  useEffect(() => {
    !isUpdating && setIsEditingMode(false);
  }, [isUpdating]);

  useEffect(() => {
    banks.forEach((bank) => {
      if (bank.idCustomerBank === client.idCustomerBank) {
        setLogo(bank.svg);
      }
    });
  }, [client]);

  useEffect(() => {
    setPersonalData({
      nome: client.nome,
      email: client.email,
      numeroTelefone: `${client.codigoArea}${client.numeroTelefone}`,
      dataNascimento: moment(client.dataNascimento).format("DD/MM/YYYY"),
      nacionalidade: client.nacionalidade,
      rg: client.rg,
      estadoRG: client.estadoRG && client.estadoRG.trim(),
      emissorRG: client.emissorRG,
      dataEmissaoRG: moment(client.dataEmissaoRG).format("DD/MM/YYYY"),
      nomeMae: client.nomeMae,
      nomePai: client.nomePai,
      pep: client.pep ? "2" : "1",
      dataInicioPEP: moment(client.dataInicioPEP).format("DD/MM/YYYY"),
      profissao: client.profissao,
      rendaMediaMensal: client.rendaMediaMensal,
    });

    if (client.rendaMediaMensal) {
      const currencyFractionDigits = new Intl.NumberFormat("pt-br", {
        style: "currency",
        currency: "BRL",
      }).resolvedOptions().maximumFractionDigits;

      const value = client.rendaMediaMensal.toLocaleString("pt-br", {
        maximumFractionDigits: currencyFractionDigits,
        minimumFractionDigits: 2,
      });

      setFormattedValue(value);
    }
  }, [client, isEditingMode]);

  const changePersonalData = (event) => {
    const { name, value } = event.target;

    setPersonalData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const validateForm = (values) => {
    const errors = {};
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    if (!values.nome) {
      errors.nome = "Nome deve ser informado!";
    }

    if (!values.email) {
      errors.email = "Email deve ser informado!";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "Email em formato inválido!";
    }

    if (!values.numeroTelefone) {
      errors.numeroTelefone = "Celular deve ser informado!";
    } else if (numberMask(values.numeroTelefone).length !== 11) {
      errors.numeroTelefone = "Celular deve ter 11 dígitos!";
    }

    if (!values.dataNascimento) {
      errors.dataNascimento = "Data de nascimento deve ser informada!";
    } else if (values.dataNascimento.length < 10) {
      errors.dataNascimento = "Data de nascimento inválida!";
    } else if (!moment(values.dataNascimento, "DD/MM/YYYY").isValid()) {
      errors.dataNascimento = "Data de nascimento inválida!";
    }

    if (!values.nomeMae) {
      errors.nomeMae = "Nome da mãe deve ser informado!";
    }

    if (!values.nomePai) {
      errors.nomePai = "Nome do pai deve ser informado!";
    }

    if (!values.rg) {
      errors.rg = "RG deve ser informado!";
    }

    if (!values.estadoRG) {
      errors.estadoRG = "Estado de emissão do RG deve ser informado!";
    }

    if (!values.emissorRG) {
      errors.emissorRG = "Orgão emissor do RG deve ser informado!";
    }

    if (!values.dataEmissaoRG) {
      errors.dataEmissaoRG = "Data de emissão do RG deve ser informada!";
    } else if (values.dataEmissaoRG.length < 10) {
      errors.dataEmissaoRG = "Data inválida!";
    } else if (!moment(values.dataEmissaoRG, "DD/MM/YYYY").isValid()) {
      errors.dataEmissaoRG = "Data inválida!";
    }

    if (!values.nacionalidade) {
      errors.nacionalidade = "Nacionalidade deve ser informada!";
    }

    if (!values.pep) {
      errors.pep = "Pessoa Exposta Politicamente deve ser informada!";
    }

    if (values.pep === "2") {
      if (!values.dataInicioPEP) {
        errors.dataInicioPEP = "Data de início deve ser informada!";
      } else if (values.dataInicioPEP.length < 10) {
        errors.dataInicioPEP = "Data de início inválida!";
      } else if (!moment(values.dataInicioPEP, "DD/MM/YYYY").isValid()) {
        errors.dataInicioPEP = "Data de início inválida!";
      }
    }

    if (!values.profissao) {
      errors.profissao = "Profissão deve ser informada!";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const comparePersonalData = () => {
    const clientStore = {
      nome: client.nome,
      email: client.email,
      numeroTelefone: `${client.codigoArea}${client.numeroTelefone}`,
      dataNascimento: moment(client.dataNascimento).format("DD/MM/YYYY"),
      nacionalidade: client.nacionalidade,
      rg: client.rg,
      estadoRG: client.estadoRG,
      emissorRG: client.emissorRG,
      dataEmissaoRG: moment(client.dataEmissaoRG).format("DD/MM/YYYY"),
      nomeMae: client.nomeMae,
      nomePai: client.nomePai,
      pep: client.pep ? "2" : "1",
      dataInicioPEP: moment(client.dataInicioPEP).format("DD/MM/YYYY"),
      profissao: client.profissao,
      rendaMediaMensal: client.rendaMediaMensal,
    };

    return isEqual(clientStore, {
      ...personalData,
      numeroTelefone: numberMask(personalData.numeroTelefone),
      rendaMediaMensal: convertCurrencyBRLToFloat(formattedValue),
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (
      validateForm({ ...personalData, rendaMediaMensal: formattedValue }) &&
      !isUpdating
    ) {
      dispatch(
        updateClient({
          ...client,
          ...personalData,
          accountType: 1,
          codigoArea: numberMask(personalData.numeroTelefone).substr(0, 2),
          numeroTelefone: numberMask(personalData.numeroTelefone).substr(2),
          dataNascimento: personalData.dataNascimento,
          dataEmissaoRG: personalData.dataEmissaoRG,
          pep: !personalData.pep || personalData.pep === "1" ? false : true,
          dataInicioPEP: personalData.dataInicioPEP
            ? personalData.dataInicioPEP
            : "1900-01-01",
          rendaMediaMensal: convertCurrencyBRLToFloat(formattedValue),
        })
      );
      dispatch(setEditingForm(""));
    }
  };

  const handleEdit = () => {
    dispatch(setEditingForm("personal-data"));
    setIsEditingMode(true);
  };

  const handleCancel = () => {
    dispatch(setEditingForm(""));
    setIsEditingMode(false);
    setFormErrors({});
  };

  return (
    <form action="#" method="POST" onSubmit={handleSubmit}>
      <div className="shadow sm:overflow-hidden sm:rounded-md">
        <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
          <div className="flex items-center justify-between">
            <div className="flex flex-col justify-start">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Dados pessoais
              </h3>
              <BadgeCelcoin celcoinStatus={client.celcoinStatus} />
            </div>
            {logo ? (
              <div className="flex justify-end max-w-[120px] sm:max-w-[130px] h-8">
                {logo}
              </div>
            ) : (
              <p className="text-lg leading-6 font-medium text-gray-900">
                {client.nomeBanco}
              </p>
            )}
          </div>
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"Nome"}
                type={"text"}
                name={"nome"}
                id={"nome"}
                value={personalData.nome || ""}
                onChange={changePersonalData}
                error={formErrors.nome}
                errorMessage={formErrors.nome}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"CPF"}
                type={"text"}
                name={"cpf"}
                id={"cpf"}
                value={cpfMask(client.cpf) || ""}
                onChange={() => {}}
                disabled
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"Email"}
                type={"text"}
                name={"email"}
                id={"email"}
                value={personalData.email || ""}
                onChange={changePersonalData}
                error={formErrors.email}
                errorMessage={formErrors.email}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"Celular"}
                type={"text"}
                name={"numeroTelefone"}
                id={"numeroTelefone"}
                maxLength={15}
                value={phoneMask(personalData.numeroTelefone) || ""}
                onChange={changePersonalData}
                error={formErrors.numeroTelefone}
                errorMessage={formErrors.numeroTelefone}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"Data de nascimento"}
                type={"text"}
                name={"dataNascimento"}
                id={"dataNascimento"}
                value={dateMask(personalData.dataNascimento) || ""}
                onChange={changePersonalData}
                error={formErrors.dataNascimento}
                errorMessage={formErrors.dataNascimento}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"Nacionalidade"}
                type={"text"}
                name={"nacionalidade"}
                id={"nacionalidade"}
                value={personalData.nacionalidade || ""}
                onChange={changePersonalData}
                error={formErrors.nacionalidade}
                errorMessage={formErrors.nacionalidade}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"RG"}
                type={"text"}
                name={"rg"}
                id={"rg"}
                value={personalData.rg || ""}
                onChange={changePersonalData}
                error={formErrors.rg}
                errorMessage={formErrors.rg}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Select
                label={"Estado de emissão RG"}
                id={"estadoRG"}
                name={"estadoRG"}
                value={personalData.estadoRG || ""}
                placeholder={"Selecione o estado"}
                options={ufs}
                onChange={changePersonalData}
                error={formErrors.estadoRG}
                errorMessage={formErrors.estadoRG}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"Orgão emissor RG"}
                type={"text"}
                name={"emissorRG"}
                id={"emissorRG"}
                value={personalData.emissorRG || ""}
                onChange={changePersonalData}
                error={formErrors.emissorRG}
                errorMessage={formErrors.emissorRG}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"Data de emissão RG"}
                type={"text"}
                name={"dataEmissaoRG"}
                id={"dataEmissaoRG"}
                value={dateMask(personalData.dataEmissaoRG) || ""}
                onChange={changePersonalData}
                error={formErrors.dataEmissaoRG}
                errorMessage={formErrors.dataEmissaoRG}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"Nome completo da mãe"}
                type={"text"}
                name={"nomeMae"}
                id={"nomeMae"}
                value={personalData.nomeMae || ""}
                onChange={changePersonalData}
                error={formErrors.nomeMae}
                errorMessage={formErrors.nomeMae}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"Nome completo do pai"}
                type={"text"}
                name={"nomePai"}
                id={"nomePai"}
                value={personalData.nomePai || ""}
                onChange={changePersonalData}
                error={formErrors.nomePai}
                errorMessage={formErrors.nomePai}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Select
                label={"Pessoa Politicamente Exposta (PPE)"}
                id={"pep"}
                name={"pep"}
                placeholder={"Selecione uma opção"}
                value={personalData.pep}
                error={formErrors.pep}
                errorMessage={formErrors.pep}
                options={[
                  { id: 1, name: "Não" },
                  { id: 2, name: "Sim" },
                ]}
                onChange={changePersonalData}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"Início PPE"}
                type={"text"}
                name={"dataInicioPEP"}
                id={"dataInicioPEP"}
                value={
                  (personalData.pep === "2" &&
                    dateMask(personalData.dataInicioPEP)) ||
                  ""
                }
                onChange={changePersonalData}
                disabled={personalData.pep === "1" || !isEditingMode}
                error={formErrors.dataInicioPEP}
                errorMessage={formErrors.dataInicioPEP}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"Profissão"}
                type={"text"}
                name={"profissao"}
                id={"profissao"}
                value={personalData.profissao || ""}
                onChange={changePersonalData}
                error={formErrors.profissao}
                errorMessage={formErrors.profissao}
                disabled={!isEditingMode}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                label={"Renda mensal"}
                type={"text"}
                name={"rendaMediaMensal"}
                id={"rendaMediaMensal"}
                prefix={"R$"}
                value={formattedValue || ""}
                placeholder={"0,00"}
                onChange={(e) =>
                  setFormattedValue(currencyMask(e.target.value))
                }
                disabled={!isEditingMode}
              />
            </div>
          </div>
        </div>
        {checkRole(role, [USER_ADMIN, USER_OPERACOES, ADMIN_ECOSYSTEM]) && (
          <div className="bg-gray-50 px-4 py-3 space-x-3 text-right sm:px-6">
            {!isEditingMode && (
              <Button
                type={"button"}
                label={"Editar"}
                onClick={handleEdit}
                disabled={editingForm && editingForm !== "personal-data"}
              />
            )}
            {isEditingMode && (
              <>
                <Button
                  type={"submit"}
                  label={"Salvar"}
                  onClick={handleSubmit}
                  disabled={comparePersonalData()}
                />
                <Button
                  type={"button"}
                  secondary
                  label={"Cancelar"}
                  onClick={handleCancel}
                />
              </>
            )}
          </div>
        )}
      </div>
    </form>
  );
}
