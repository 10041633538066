import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Select from "components/select";
import Input from "components/input";
import Button from "components/button";
import CheckBox from "components/checkbox";
import ConfirmationModal from "components/modal-confirmation";
import { postEmailBase } from "reducers/email-base/emailBaseThunk";

export default function EmailBase() {
  const [formData, setFormData] = useState({ idBank: 1 });
  const [formErrors, setFormErrors] = useState({});
  const [bankList, setBankList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [segment, setSegment] = useState(false);
  const { success, error } = useSelector((store) => store.emailer);
  const { banks } = useSelector((store) => store.bank);
  const dispatch = useDispatch();

  useEffect(() => {
    if (success || error) {
      handleCloseModal();
    }

    if (success) {
      setFormData({ idBank: 1 });
      setSegment(false);
    }
  }, [success, error]);

  useEffect(() => {
    setBankList(
      banks.map((bank) => ({ id: bank.idBanco, name: bank.descricao }))
    );
  }, [banks]);

  const handleSubmit = () => {
    if (validateForm(formData)) {
      dispatch(
        postEmailBase({
          idCustomerBank: formData.idBank,
          idTemplate: formData.idTemplate,
          receiver: segment ? formData.receiver : null,
        })
      );
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  function validateForm(values) {
    const errors = {};
    if (!values.idBank) {
      errors.idBank = "Banco deve ser informado!";
    }

    if (!values.idTemplate) {
      errors.idTemplate = "Template ID deve ser informado!";
    }

    if (segment && !values.receiver) {
      errors.receiver = "Tipo de conta deve ser informado!";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  }

  function handleChange(event) {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  }

  return (
    <div className="relative">
      <ConfirmationModal
        title={"Enviar email para base"}
        type={"danger"}
        description={"Tem certeza que deseja enviar este email para esta base?"}
        confirmButtonLabel={"Enviar"}
        onConfirm={handleSubmit}
        cancelButtonLabel={"Cancelar"}
        onCancel={handleCloseModal}
        isModalOpen={isModalOpen}
      />
      <div>
        <div className="shadow sm:overflow-hidden sm:rounded-md">
          <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Configurações de envio
              </h3>
            </div>
            <div className="mt-6 flex flex-col gap-y-6 gap-x-4 sm:grid-cols-6">
              <div>
                <Select
                  id={"idBank"}
                  name={"idBank"}
                  label={"Selecione o banco:"}
                  value={formData.idBank || ""}
                  onChange={handleChange}
                  options={bankList}
                  error={formErrors.idBank}
                  errorMessage={formErrors.idBank}
                />
              </div>
              <div>
                <Input
                  placeholder={"Exemplo: 'd-c05abcde34bf47777db40777d7ab33'"}
                  label={"Insira o ID do template:"}
                  type={"text"}
                  name={"idTemplate"}
                  id={"idTemplate"}
                  onChange={handleChange}
                  value={formData.idTemplate || ""}
                  error={formErrors.idTemplate}
                  errorMessage={formErrors.idTemplate}
                />
              </div>
              <div>
                <div className="mb-1">
                  <CheckBox
                    id={"segment"}
                    name={"segment"}
                    label={"Segmentar envio"}
                    checked={segment}
                    onChange={() => setSegment((prevState) => !prevState)}
                  />
                </div>
                {segment && (
                  <Select
                    id={"receiver"}
                    name={"receiver"}
                    placeholder={"Selecione o tipo de conta"}
                    value={formData.receiver || ""}
                    onChange={handleChange}
                    options={[
                      { id: "1", name: "PF" },
                      { id: "2", name: "PJ" },
                    ]}
                    error={formErrors.receiver}
                    errorMessage={formErrors.receiver}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 text-right w-full">
            <Button
              label={"Enviar"}
              onClick={() => {
                validateForm({
                  ...formData,
                }) && setIsModalOpen(true);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
