import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ClipboardDocumentCheckIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import ReactJson from "react-json-view";

import Button from "components/button";

export default function JsonModal({ onCloseModal, isModalOpen, json }) {
  const [showIcon, setShowIcon] = useState(false);

  const copyToClipboardHandler = () => {
    setShowIcon(true);
    navigator.clipboard.writeText(JSON.stringify(json));
    setTimeout(() => {
      setShowIcon(false);
    }, 1000);
  };

  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={onCloseModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-30 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="p-6">
                  <div className="pb-6 relative">
                    {showIcon ? (
                      <CheckCircleIcon className="h-5 w-5 absolute right-0 z-10 text-green-500" />
                    ) : (
                      <button
                        onClick={copyToClipboardHandler}
                        className="absolute right-0 z-10"
                      >
                        <ClipboardDocumentCheckIcon className="h-5 w-5" />
                      </button>
                    )}
                    <ReactJson
                      displayObjectSize={false}
                      displayDataTypes={false}
                      enableClipboard={false}
                      src={json}
                    />
                  </div>
                  <div className="flex justify-end border-t">
                    <Button
                      type={"button"}
                      label={"Cancelar"}
                      secondary
                      onClick={onCloseModal}
                      className={"mt-6"}
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
