import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Layout from "components/layout";
import ValuesData from "./components/values-data";
import AccountData from "./components/account-data";
import Rentability from "./components/rentability";
import TransactionalProduct from "./components/transactional-product";
import MostUsedProducts from "./components/most-used-products";
import {
  fetchMetrics,
  fetchTransactionalProducts,
  fetchRentability,
} from "reducers/metrics/metricsThunk";

const Home = () => {
  const dispatch = useDispatch();

  const { name } = useSelector((store) => store.user);

  useEffect(() => {
    dispatch(fetchMetrics());
    dispatch(fetchTransactionalProducts());
    dispatch(fetchRentability());

    // eslint-disable-next-line
  }, []);

  return (
    <Layout title={`Bem vindo, ${name}!`}>
      <div className="space-y-6">
        <ValuesData />
        <div className="grid grid-cols-4 gap-6">
          <AccountData />
          <Rentability />
        </div>
        <div className="grid grid-cols-4 gap-6">
          <TransactionalProduct />
          <MostUsedProducts />
        </div>
      </div>
    </Layout>
  );
};

export default Home;
