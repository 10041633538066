import { createAsyncThunk } from "@reduxjs/toolkit";

import { setNotification } from "reducers/notification/notificationSlice";
import { setIsLoading } from "reducers/loading/isLoadingSlice";
import {
  getBankList,
  updateNightTime,
  getBankLimits,
  updateBankLimit,
  updateOnboardingTime,
  getRendeMais,
} from "services/bank";

export const fetchBankList = createAsyncThunk(
  "bank/fetchBankList",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getBankList();
      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue([]);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const updateTime = createAsyncThunk(
  "bank/updateTime",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      await updateNightTime(payload.idCustomerBank, payload);

      thunkAPI.dispatch(
        setNotification({
          type: "success",
          title: "Horário Atualizado",
          message:
            "O horário de funcionamento noturno foi atualizado com sucesso!",
        })
      );

      thunkAPI.dispatch(fetchBankList());
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchBankLimits = createAsyncThunk(
  "bank/fetchBankLimits",
  async (idCustomerBank, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getBankLimits(idCustomerBank);

      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue([]);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const newBankLimit = createAsyncThunk(
  "bank/newBankLimit",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      await updateBankLimit(payload.idCustomerBank, payload);

      thunkAPI.dispatch(
        setNotification({
          type: "success",
          title: "Limite Cadastrado",
          message: "O novo limite foi cadastrado com sucesso!",
        })
      );

      thunkAPI.dispatch(fetchBankLimits(payload.idCustomerBank));
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const fetchRendeMais = createAsyncThunk(
  "rendemais/fetchRendeMais",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      const response = await getRendeMais(payload.year, payload.month);
      return response.data.success;
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue([]);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);

export const updateTimeOnboarding = createAsyncThunk(
  "bank/updateTimeOnboarding",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));

      await updateOnboardingTime(payload.idCustomerBank, payload);

      thunkAPI.dispatch(
        setNotification({
          type: "success",
          title: "Horário Atualizado",
          message: "Configurações de onboarding atualizadas com sucesso!",
        })
      );

      thunkAPI.dispatch(fetchBankList());
    } catch (error) {
      thunkAPI.dispatch(
        setNotification({
          type: "error",
          title: "Erro",
          message: error,
        })
      );

      return thunkAPI.rejectWithValue(false);
    } finally {
      thunkAPI.dispatch(setIsLoading(false));
    }
  }
);
