import React, { useEffect, useState, useCallback } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Layout from "components/layout";
import Tab from "components/tab";
import PageHeader from "components/pages/account-details/page-header";
import Dropdown from "components/dropdown";
import ConfirmationModal from "components/modal-confirmation";
import ModalTransaction from "components/modal-transaction";
import getNavigationMenu from "./navigation";
import {
  fetchClient,
  fetchClientStatusFull,
  blockAccount,
} from "reducers/client/clientThunk";
import { createCelcoinAccount } from "reducers/onboarding/onboardingThunk";
import { addCancel } from "reducers/accounts/accountsThunk";

import { checkRole } from "utils/isRoleAllowed";
import {
  USER_OPERACOES,
  USER_ADMIN,
  PRE_TRANSACTION_CREATOR,
  ADMIN_ECOSYSTEM,
} from "utils/const";

export default function AccountDetails() {
  const [accountType, setAccountType] = useState();
  const [tabs, setTabs] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isTransactionModalOpen, setIsTransactionModalOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const { role } = useSelector((store) => store.user);
  const { client, status } = useSelector((store) => store.client);
  const { idClient } = useParams();
  const dispatch = useDispatch();
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();

  const breadcrumbPages = [{ name: "Conta" }];
  const accountIsActive = status.idStatus === (1 || 2 || 3);

  const accountTypeQuery = parseInt(query.get("type"));

  const celcoinAccountHandler = useCallback(() => {
    dispatch(
      createCelcoinAccount({
        accountType: accountTypeQuery,
        idClient: idClient,
      })
    );
  }, [accountTypeQuery, dispatch, idClient]);

  useEffect(() => {
    const actionsArray = [
      {
        label: accountIsActive ? "Bloquear conta" : "Ativar conta",
        action: () => setIsModalOpen(true),
      },
      {
        label: "Solicitar cancelamento",
        action: () => setIsCancelModalOpen(true),
      },
    ];

    if (
      process.env.REACT_APP_MODE === "CORE" &&
      checkRole(role, [USER_ADMIN, USER_OPERACOES])
    ) {
      actionsArray.push({
        label: "Criar conta na Celcoin",
        action: () => celcoinAccountHandler(),
      });
    }

    if (
      process.env.REACT_APP_MODE === "CORE" &&
      checkRole(role, [PRE_TRANSACTION_CREATOR, USER_ADMIN])
    ) {
      actionsArray.push({
        label: "Adicionar transação manual",
        action: () => setIsTransactionModalOpen(true),
      });
    }

    setOptions(actionsArray);
  }, [role, accountIsActive, celcoinAccountHandler]);

  useEffect(() => {
    setAccountType(accountTypeQuery);

    dispatch(
      fetchClient({ accountType: accountTypeQuery, idClient: idClient })
    );

    dispatch(fetchClientStatusFull(idClient));

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const navigationItems = getNavigationMenu(accountType);

    const tabItems = navigationItems.filter(
      (item) => item.active && (!item.protected || checkRole(role, item.roles))
    );
    setTabs(tabItems);
  }, [role, accountType]);

  const blockUnblockHandler = () => {
    dispatch(blockAccount({ idStatus: status.idStatus, idClient }));
    setIsModalOpen(false);
  };

  const addToCancelHandler = () => {
    dispatch(
      addCancel({ idClient: client.idClient, idBank: client.idCustomerBank })
    );
    setIsCancelModalOpen(false);
  };

  const handleTabNavigation = (evt) => {
    const { value: id } = evt.target;
    setTabs(
      tabs.map((tab) =>
        tab.id === parseInt(id)
          ? { ...tab, current: true }
          : { ...tab, current: false }
      )
    );
  };

  const displayItem = tabs.find((item) => item.current && item.component);

  return (
    <Layout subTitle={<PageHeader />} breadcrumbPages={breadcrumbPages}>
      <ConfirmationModal
        title={`${accountIsActive ? "Bloquear" : "Ativar"} conta`}
        description={`${
          accountIsActive
            ? "Tem certeza que deseja bloquear a conta? Após essa ação, o cliente perderá o acesso a todos os produtos."
            : "Tem certeza que deseja ativar a conta? Após esta ação, o cliente poderá utilizar todos os produtos novamente."
        }`}
        confirmButtonLabel={accountIsActive ? "Bloquear" : "Ativar"}
        onConfirm={blockUnblockHandler}
        cancelButtonLabel={"Cancelar"}
        onCancel={() => setIsModalOpen(false)}
        type={"danger"}
        isModalOpen={isModalOpen}
      />
      <ConfirmationModal
        title={"Adicionar ao cancelamento?"}
        description={
          "A conta não será cancelada automaticamente! É necessário acessar a página de solicitações de cancelamento para concluir a ação."
        }
        confirmButtonLabel={"Confirmar"}
        onConfirm={addToCancelHandler}
        cancelButtonLabel={"Cancelar"}
        onCancel={() => setIsCancelModalOpen(false)}
        type={"danger"}
        isModalOpen={isCancelModalOpen}
      />
      <ModalTransaction
        title={"Adicionar transação manual"}
        onCancel={() => setIsTransactionModalOpen(false)}
        type={"danger"}
        isModalOpen={isTransactionModalOpen}
        idAccount={client.idAccount}
        idClient={client.idClient}
        idCustomerBank={client.idCustomerBank}
      />
      {tabs.length > 0 && (
        <>
          <div className="flex flex-row justify-between items-center w-full gap-3">
            <Tab
              tabs={tabs}
              onChangeTab={handleTabNavigation}
              breakpoint={"lg"}
            />
            {checkRole(role, [USER_ADMIN, USER_OPERACOES, ADMIN_ECOSYSTEM]) && (
              <div className="mb-6 mt-1 text-right">
                <Dropdown options={options} />
              </div>
            )}
          </div>
          <div className="space-y-5">
            {displayItem && <displayItem.component />}
          </div>
        </>
      )}
    </Layout>
  );
}
