import React, { useState, useEffect } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import ReactPaginate from "react-paginate";

export default function Pagination({
  pageCount,
  currentPage,
  onNextPage,
  onPreviousPage,
  onClick,
  total,
  pageItens,
  itemsPerPage,
  onChangeItemsPerPage,
  showItemsPerPageOption = true,
}) {
  const [firstItem, setFirstItem] = useState(1);
  const [lastItem, setLastItem] = useState(pageItens);

  const options = [
    { value: "10" },
    { value: "20" },
    { value: "30" },
    { value: "40" },
    { value: "50" },
  ];

  useEffect(() => {
    setFirstItem((currentPage - 1) * itemsPerPage || 1);
    setLastItem((currentPage - 1) * itemsPerPage + pageItens);
  }, [currentPage, pageItens, itemsPerPage]);

  const handlePageClick = (data) => {
    onClick(data.selected + 1);
  };

  const ItemsPerPage = (
    <div className="hidden md:flex flex-row items-center gap-2">
      <label
        htmlFor="itens"
        className="text-xs text-gray-500 whitespace-nowrap"
      >
        Itens por página
      </label>
      <select
        id="itens"
        name="itens"
        defaultValue={itemsPerPage}
        onChange={onChangeItemsPerPage}
        className="border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 relative block w-full outline-0 text-sm rounded-md disabled:cursor-not-allowed disabled:bg-gray-100 shadow-sm focus:outline-none bg-white py-2 pl-3 pr-7 border"
      >
        {options.map((option) => {
          return (
            <option value={option.value} key={option.value}>
              {option.value}
            </option>
          );
        })}
      </select>
    </div>
  );

  return (
    <div className="bg-white px-4 py-3 border-t border-gray-200 md:px-6">
      {pageCount > 0 && (
        <div className="flex items-center justify-between">
          {pageCount > 1 && (
            <span className="hidden md:block text-xs text-gray-500">
              Mostrando {firstItem} - {lastItem} de {total}
            </span>
          )}
          {total > itemsPerPage ? (
            <>
              <div className="flex-1 flex justify-between md:hidden">
                <button
                  onClick={onPreviousPage}
                  disabled={currentPage === 1}
                  className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:cursor-not-allowed disabled:bg-white"
                >
                  Anterior
                </button>
                <button
                  onClick={onNextPage}
                  disabled={currentPage === pageCount}
                  className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:cursor-not-allowed disabled:bg-white"
                >
                  Próxima
                </button>
              </div>
              <div className="flex items-end gap-3">
                {showItemsPerPageOption && ItemsPerPage}
                <ReactPaginate
                  containerClassName={
                    "hidden md:flex-1 md:flex md:items-center md:justify-end z-0 -space-x-px text-gray-500"
                  }
                  previousLabel={
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  }
                  previousClassName={
                    "relative inline-flex items-center px-2 py-2 rounded-l-md border bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  }
                  nextLabel={
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  }
                  nextClassName={
                    "relative inline-flex items-center px-2 py-2 rounded-r-md border bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  }
                  disabledClassName={"cursor-not-allowed hover:bg-white"}
                  disabledLinkClassName={"cursor-not-allowed hover:bg-white"}
                  breakLabel={"..."}
                  breakClassName={
                    "relative inline-flex items-center border text-sm font-medium"
                  }
                  breakLinkClassName={"px-4 py-2 cursor-pointer"}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={pageCount > 10 ? 1 : 10}
                  marginPagesDisplayed={2}
                  pageClassName={
                    "relative inline-flex items-center border text-sm font-medium hover:bg-gray-50"
                  }
                  pageLinkClassName={"px-4 py-2 cursor-pointer"}
                  activeClassName={
                    "z-20 bg-gray-100 border-indigo-500 text-indigo-600 hover:bg-gray-100"
                  }
                  forcePage={currentPage - 1}
                />
              </div>
            </>
          ) : showItemsPerPageOption ? (
            <div className="flex justify-end w-full">{ItemsPerPage}</div>
          ) : null}
        </div>
      )}
    </div>
  );
}
