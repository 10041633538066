import axiosClient from "./apiClient";

export function getAccountLimits(idClient) {
  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/account/${idClient}/limits`
  );
}

export function updateAccountLimit(idClient, payload) {
  return axiosClient.post(
    `${process.env.REACT_APP_API_DASH}/account/${idClient}/limit`,
    payload,
    {
      headers: { "Content-Type": "application/json;charset=utf-8" },
    }
  );
}

export function getBankPixLimit(idCustomerBank) {
  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/bank/${idCustomerBank}/pix/limit`
  );
}

export function getPendingRequests() {
  return axiosClient.get(`${process.env.REACT_APP_API_DASH}/limites/pendentes`);
}

export function approveLimitRequest(payload) {
  return axiosClient.post(
    `${process.env.REACT_APP_API_DASH}/limites/aprovar`,
    payload,
    {
      headers: { "Content-Type": "application/json;charset=utf-8" },
    }
  );
}

export function rejectLimitRequest(payload) {
  return axiosClient.post(
    `${process.env.REACT_APP_API_DASH}/limites/rejeitar`,
    payload,
    {
      headers: { "Content-Type": "application/json;charset=utf-8" },
    }
  );
}

export function getHistoryRequests(
  dataInicio,
  dataFim,
  documento,
  paginaAtual,
  itemsPerPage,
  idCustomerBank
) {
  const params = new URLSearchParams();

  dataInicio && params.append("dataInicio", dataInicio);
  dataFim && params.append("dataFinal", dataFim);
  documento && params.append("documento", documento);
  paginaAtual && params.append("pagina", paginaAtual);
  itemsPerPage && params.append("itensPorPagina", itemsPerPage);
  idCustomerBank && params.append("idCustomerBank", idCustomerBank);

  return axiosClient.get(
    `${process.env.REACT_APP_API_DASH}/limites/historico`,
    {
      headers: { "Content-Type": "application/json;charset=utf-8" },
      params: params,
    }
  );
}
