export default function Radio({ label, id, name, value, checked, onChange }) {
  return (
    <div className="flex items-center cursor-pointer">
      <input
        id={id}
        name={name}
        type="radio"
        value={value}
        checked={checked}
        onChange={onChange}
        className="h-4 w-4 ml-2 border-gray-300 cursor-pointer text-indigo-600 focus:ring-indigo-500"
      />
      <label
        htmlFor={id}
        className="ml-2 mr-2 block text-sm leading-6 cursor-pointer text-gray-500"
      >
        {label}
      </label>
    </div>
  );
}
