import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Button from "components/button";
import Modal from "components/modal";
import Input from "components/input";
import { setPostPos } from "reducers/pos/posSlice";
import { registerPos, fethPosList } from "reducers/pos/posThunk";

export default function PosList() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const { posList, postPos } = useSelector((store) => store.pos);
  const { client } = useSelector((store) => store.client);
  const { idClient } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fethPosList(idClient));

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (postPos.success) {
      handleCloseModal();
      dispatch(setPostPos({ success: false, error: false }));
    }

    // eslint-disable-next-line
  }, [postPos]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const validateForm = (values) => {
    const errors = {};

    if (!values.mid) {
      errors.mid = "MID deve ser informado!";
    }

    if (!values.tid) {
      errors.tid = "TID deve ser informado!";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = () => {
    if (validateForm({ ...formData })) {
      dispatch(
        registerPos({
          ...formData,
          tids: [{ tid: formData.tid }],
          isEcommerce: false,
          id_client: client.idClient,
          id_customer_bank: client.idCustomerBank,
        })
      );
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormData({});
    setFormErrors({});
  };

  return (
    <>
      <div className="relative">
        <Modal
          title={"Cadastrar POS"}
          confirmButtonLabel={"Cadastrar"}
          onConfirm={handleSubmit}
          cancelButtonLabel={"Cancelar"}
          onCancel={handleCloseModal}
          isModalOpen={isModalOpen}
        >
          <div className="mt-5 text-left flex flex-col gap-5">
            <div>
              <Input
                label={"MID"}
                type={"text"}
                name={"mid"}
                id={"mid"}
                value={formData.mid || ""}
                onChange={handleChange}
                error={formErrors.mid}
                errorMessage={formErrors.mid}
              />
            </div>
            <div>
              <Input
                label={"TID"}
                type={"text"}
                name={"tid"}
                id={"tid"}
                value={formData.tid || ""}
                onChange={handleChange}
                error={formErrors.tid}
                errorMessage={formErrors.tid}
              />
            </div>
          </div>
        </Modal>
      </div>
      <div className="flex justify-end">
        <Button
          type={"button"}
          label={"Novo Cadastro"}
          onClick={() => setIsModalOpen(true)}
        />
      </div>
      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:mx-0 md:rounded-lg mt-8">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                MID
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                TID
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {posList.length > 0 ? (
              posList.map((pos) => (
                <tr key={pos.idTerminal}>
                  <td className="py-4 pl-4 pr-3 text-sm text-gray-500 sm:w-auto sm:max-w-none sm:pl-6">
                    {pos.mid}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">{pos.tid}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={2}
                  className="text-sm sm:text-base text-gray-500 text-center py-5"
                >
                  Não há dados para exibir
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
