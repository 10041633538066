import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import { store } from "reducers/store";
import { msalConfig } from "services/authConfig";
import App from "./App";
import "./index.css";

const container = document.getElementById("root");
const root = createRoot(container);

export const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
