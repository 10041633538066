import { createSlice } from "@reduxjs/toolkit";

import { fetchUser } from "./userThunk";

const initialState = {
  sub: "",
  name: "",
  id_Customer: "",
  id_Customer_Bank: "",
  id_Client: "",
  phone_Number: "",
  account_Type: "",
  document: "",
  email: "",
  status: "",
  clientId: "",
  role: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: {
    [fetchUser.fulfilled]: (_, action) => {
      return action.payload;
    },
  },
});

export default userSlice.reducer;
