import {
  Fragment,
  useState,
  useEffect,
  useRef,
  useReducer,
  useCallback,
} from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  ArrowPathIcon,
  PrinterIcon,
  XMarkIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/solid";
import { CSVLink } from "react-csv";
import Datepicker from "react-tailwindcss-datepicker";
import moment from "moment";
import "moment/locale/pt";
import { get } from "lodash";

import Entrada from "assets/entrada.svg";
import Saida from "assets/saida.svg";
import Hold from "assets/hold.svg";
import Pagination from "components/pagination";
import Tooltip from "components/tooltip";
import classNames from "utils/classNames";
import renderTransactionIcon from "utils/extractIcons";
import Select from "components/select";
import { checkAvailableReceipt } from "utils/availableReceipts";
import {
  cpfMask,
  cnpjMask,
  formatDate,
  convertCurrencyBRLToString,
} from "utils/mask";
import { getDescription } from "utils/transactionType";
import {
  fetchBaasCsv,
  fetchBanksCsv,
  fetchBaasExtract,
  fetchBanksExtract,
} from "reducers/financial/financialThunk";
import {
  fetchPixSent,
  fetchPixReceivid,
  fetchPixReturned,
  fetchPayment,
  fetchInternalTransferSent,
  fetchInternalTransferReceivid
} from "reducers/voucher/voucherThunk";
import { setVoucher } from "reducers/voucher/voucherSlice";
import { setBaasCsv, setBanksCsv } from "reducers/financial/financialSlice";
import { fetchTransactionTypes } from "reducers/fees/feesThunk";
import ModalVoucher from "components/modal-voucher";

const initialState = {
  currentPage: 1,
  dateFilter: {
    startDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  },
  itemsPerPage: 30,
};

const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
const SET_DATE_FILTER = "SET_DATE_FILTER";
const SET_ITEMS_PER_PAGE = "SET_ITEMS_PER_PAGE";
const SET_CREDIT_DEBIT = "SET_CREDIT_DEBIT";
const SET_TIPO = "SET_TIPO";
const SET_RESET_FILTER = "SET_RESET_FILTER";

const reducer = (state, action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return { ...state, currentPage: action.payload };
    case SET_DATE_FILTER:
      return { ...state, dateFilter: action.payload, currentPage: 1 };
    case SET_ITEMS_PER_PAGE:
      return { ...state, itemsPerPage: action.payload, currentPage: 1 };
    case SET_CREDIT_DEBIT:
      return { ...state, creditoDebito: action.payload, currentPage: 1 };
    case SET_TIPO:
      return { ...state, tipo: action.payload, currentPage: 1 };
    case SET_RESET_FILTER:
      return { ...state, creditoDebito: "", tipo: "", currentPage: 1 };
    default:
      return state;
  }
};

const ExtractTable = () => {
  const csvLink = useRef();
  const [extractData, setExtractData] = useState([]);
  const [csvData, setCsvData] = useState("");
  const { idBank } = useParams();
  const { transactionTypes } = useSelector((store) => store.fees);
  const { baasExtract, banksExtract, baasCsv, banksCsv } = useSelector(
    (store) => store.financial
  );
  const dispatch = useDispatch();
  const isLoading = useSelector((store) => store.isLoading);
  const [state, reducerDispatch] = useReducer(reducer, initialState);
  const [selectOptions, setSelectOptions] = useState([]);
  const [transactional, setTransactional] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchExtractHandler = useCallback(() => {
    if (isNaN(idBank)) {
      dispatch(
        fetchBaasExtract({
          dataInicio: state.dateFilter.startDate,
          dataFim: state.dateFilter.endDate,
          currentPage: state.currentPage,
          itemsPerPage: state.itemsPerPage,
          creditoDebito: state.creditoDebito,
          tipo: state.tipo,
        })
      );
    } else {
      dispatch(
        fetchBanksExtract({
          dataInicio: state.dateFilter.startDate,
          dataFim: state.dateFilter.endDate,
          idCustomerBank: idBank,
          currentPage: state.currentPage,
          itemsPerPage: state.itemsPerPage,
          creditoDebito: state.creditoDebito,
          tipo: state.tipo,
        })
      );
    }
  }, [dispatch, state, idBank]);

  useEffect(() => {
    dispatch(fetchTransactionTypes());
  }, [dispatch]);

  useEffect(() => {
    fetchExtractHandler();
  }, [fetchExtractHandler]);

  useEffect(() => {
    isNaN(idBank) ? setExtractData(baasExtract) : setExtractData(banksExtract);
  }, [idBank, baasExtract, banksExtract]);

  useEffect(() => {
    if (isNaN(idBank)) {
      baasCsv && setCsvData(baasCsv);
    } else {
      banksCsv && setCsvData(banksCsv);
    }
  }, [baasCsv, banksCsv, idBank]);

  useEffect(() => {
    csvData && csvLink.current.link.click();

    return () => {
      isNaN(idBank) ? setBaasCsv("") : setBanksCsv("")
    };
  }, [idBank, csvData, dispatch]);

  useEffect(() => {
    setSelectOptions(
      transactionTypes.map((transaction) => ({
        id: transaction.idTransaction,
        name: transaction.descricao,
      }))
    );
  }, [transactionTypes]);

  const renderDocument = (document) => {
    if (!document) return "";
    if (document.length === 14) {
      return cnpjMask(document);
    } else if (document.length === 11) {
      return cpfMask(document);
    }
  };

  const handleChangeDate = (newValue) => {
    const startDate = moment(newValue.startDate, "YYYY-MM-DD");
    const endDate = moment(newValue.endDate, "YYYY-MM-DD");

    reducerDispatch({
      type: SET_DATE_FILTER,
      payload: {
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
      },
    });
  };

  const renderTransactionDay = (day, month, year) => {
    return `${day.toString().padStart(2, "0")}/${month
      .toString()
      .padStart(2, "0")}/${year}`;
  };

  const navigationHandler = (id, document) => {
    let type = 1;
    document.length === 14 ? (type = 2) : (type = 1);

    return `/contas/detalhes/${id}?type=${type}`;   
  };

  const handleDownloadCsv = () => {
    if (isNaN(idBank)) {
      dispatch(
        fetchBaasCsv({
          dataInicio: state.dateFilter.startDate,
          dataFim: state.dateFilter.endDate,
          creditoDebito: state.creditoDebito,
          tipo: state.tipo,
        })
      );
    } else {
      dispatch(
        fetchBanksCsv({
          dataInicio: state.dateFilter.startDate,
          dataFim: state.dateFilter.endDate,
          idCustomerBank: idBank,
          creditoDebito: state.creditoDebito,
          tipo: state.tipo,
        })
      );
    }
  };

  const handleNextPage = () => {
    reducerDispatch({ type: SET_CURRENT_PAGE, payload: state.currentPage + 1 });
  };

  const handlePreviousPage = () => {
    reducerDispatch({ type: SET_CURRENT_PAGE, payload: state.currentPage - 1 });
  };

  const changeCurrentPage = (page = 1) => {
    reducerDispatch({ type: SET_CURRENT_PAGE, payload: page });
  };

  const changeItemsPerPageHandler = (e) => {
    reducerDispatch({
      type: SET_ITEMS_PER_PAGE,
      payload: parseInt(e.target.value),
    });
  };

  const showVoucherHandler = async (singleTransaction) => {
    const {idClient, idTransaction, idAccountTransaction, creditoDebito } = singleTransaction;

    if (idClient && idTransaction && idAccountTransaction) {
      setTransactional(singleTransaction);

      let response;

      if (idTransaction === 7) {
        response = await dispatch(fetchPixSent({ idClient, idAccountTransaction }));
      } else if (idTransaction === 8) {
        response = await dispatch(fetchPixReceivid({ idClient, idAccountTransaction }));
      } else if (idTransaction === 12) {
        response = await dispatch(fetchPixReturned({ idClient, idAccountTransaction }));
      } else if (idTransaction === 23) {
        response = await dispatch(fetchPayment({ idClient, idAccountTransaction }));
      } else if (idTransaction === 24 && creditoDebito === "D") {
        response = await dispatch(fetchInternalTransferSent({ idClient, idAccountTransaction }));
      } else if (idTransaction === 24 && creditoDebito === "C") {
        response = await dispatch(fetchInternalTransferReceivid({ idClient, idAccountTransaction }));
      }

      const reqStatus = get(response, "meta.requestStatus", "");
      if (reqStatus === "fulfilled") {
        setVoucher(response.payload)
        setIsModalOpen(true);
      }
    }
  }

  const creditoDebitoHandler = (value) => {
    let creditoDebito;
    if (value === state.creditoDebito) {
      creditoDebito = "";
    } else {
      creditoDebito = value;
    }

    reducerDispatch({ type: SET_CREDIT_DEBIT, payload: creditoDebito });
  };

  const tipoTransactionHandler = (e) => {
    reducerDispatch({ type: SET_TIPO, payload: e.target.value });
  };

  return (
    <>
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-2">
        <div className="flex flex-row items-center w-full sm:w-auto gap-3">
          <Datepicker
            configs={{
              shortcuts: {
                today: "Hoje",
                yesterday: "Ontem",
                past: (period) => `Últimos ${period} dias`,
                currentMonth: "Este mês",
                pastMonth: "Último mês",
              },
            }}
            i18n={"pt"}
            placeholder={"Selecione o intervalo"}
            separator={"-"}
            displayFormat={"DD/MM/YYYY"}
            toggleClassName="hidden"
            readOnly={true}
            showShortcuts={true}
            value={state.dateFilter}
            onChange={handleChangeDate}
            primaryColor={"indigo"}
            popoverDirection={"down"}
            inputClassName="w-full sm:w-48 text-center relative transition-all duration-300 py-2.5 px-2 border-gray-300 rounded-lg tracking-wide font-light text-sm placeholder-gray-400 bg-white focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-indigo-500 focus:ring-indigo-500/20"
          />
          <Select
            placeholder={"Tipo de transação"}
            value={state.tipo || ""}
            options={selectOptions}
            className={"mt-0 h-[42px] w-48"}
            onChange={tipoTransactionHandler}
          />
          <Tooltip text={"Sincronizar extrato"}>
            <ArrowPathIcon
              className={`h-5 w-5 cursor-pointer ${
                isLoading && "animate-spin"
              }`}
              onClick={fetchExtractHandler}
              aria-hidden="true"
            />
          </Tooltip>
          {(state.creditoDebito || state.tipo) && (
            <Tooltip text={"Resetar filtro"}>
              <XMarkIcon
                className={`h-5 w-5 cursor-pointer ${
                  isLoading && "animate-spin"
                }`}
                onClick={() => reducerDispatch({ type: SET_RESET_FILTER })}
                aria-hidden="true"
              />
            </Tooltip>
          )}
        </div>
        <div className="w-full flex flex-row-reverse gap-6">
          <div className="flex items-center">
            <button
              onClick={() => creditoDebitoHandler("C")}
              className={classNames(
                "text-gray-500 flex flex-col items-center text-xs p-2 hover:bg-gray-100",
                state.creditoDebito === "C" && "bg-gray-200 hover:bg-gray-200"
              )}
            >
              <img className="h-5 w-auto" src={Entrada} alt="Multiplicar" />
            </button>
            <hr className="border border-r-gray-200 h-[70%] w-[0.5px] mx-2" />
            <button
              onClick={() => creditoDebitoHandler("D")}
              className={classNames(
                "text-gray-500 flex flex-col items-center text-xs p-2 hover:bg-gray-100",
                state.creditoDebito === "D" && "bg-gray-200 hover:bg-gray-200"
              )}
            >
              <img className="h-5 w-auto" src={Saida} alt="Multiplicar" />
            </button>
            <hr className="border border-r-gray-200 h-[70%] w-[0.5px] mx-2" />
            <Tooltip text={"Download CSV"}>
              <button className="p-2" onClick={handleDownloadCsv}>
                <ArrowDownTrayIcon className={"h-5 w-5"} aria-hidden="true" />
              </button>
              <CSVLink
                data={csvData}
                filename={`${state.dateFilter.startDate}_${state.dateFilter.endDate}_transactions.csv`}
                className="hidden"
                ref={csvLink}
                target="_blank"
              />
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:mx-0 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Tipo
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 xl:table-cell"
              >
                ID
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
              >
                Descrição
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Cliente
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Documento
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
              >
                Valor
              </th>
              {isNaN(idBank) && (
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 xl:table-cell"
                >
                  Banco
                </th>
              )}
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 table-cell"
              ></th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {extractData?.transacoes?.length > 0 ? (
              extractData.transacoes.map((transactionDay) => (
                <Fragment
                  key={renderTransactionDay(
                    transactionDay.dia,
                    transactionDay.mes,
                    transactionDay.ano
                  )}
                >
                  <tr className="border-t border-gray-200">
                    <th
                      colSpan={7}
                      scope="colgroup"
                      className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                    >
                      <div className="flex items-center justify-between">
                        <div>
                          {`${renderTransactionDay(
                            transactionDay.dia,
                            transactionDay.mes,
                            transactionDay.ano
                          )} - ${moment(
                            renderTransactionDay(
                              transactionDay.dia,
                              transactionDay.mes,
                              transactionDay.ano
                            ),
                            "DD/MM/YYYY"
                          ).format("dddd")}`}
                        </div>
                        <div>
                          Saldo: R${" "}
                          {convertCurrencyBRLToString(
                            transactionDay.saldoFinal
                          )}
                        </div>
                      </div>
                    </th>
                  </tr>
                  {transactionDay.transacoes.map(
                    (transaction, transactionIdx) => (
                      <tr
                        key={transaction.idAccountTransaction}
                        className={classNames(
                          transactionIdx === 0
                            ? "border-gray-300"
                            : "border-gray-200",
                          "border-t align-middle xl:align-top"
                        )}
                      >
                        <td className="w-80 whitespace-normal py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          <div className="flex items-center flex-row-reverse justify-end xl:flex-row xl:justify-start gap-2">
                            <div className="flex flex-row shrink-0 items-center gap-1">
                              <img
                                className="h-4 w-auto"
                                src={
                                  transaction.creditoDebito === "C"
                                    ? Entrada
                                    : transaction.creditoDebito === "D"
                                    ? Saida
                                    : Hold
                                }
                                alt="Entrada/Saida"
                              />
                              {renderTransactionIcon(transaction.idTransaction)}
                            </div>
                            {getDescription(
                              transaction.idTransaction,
                              transaction.tipo
                            )}
                          </div>
                          <span className="hidden font-normal ml-11 xl:flex text-gray-500">
                            {formatDate(transaction.data, "HH:mm:ss")}
                          </span>
                          <dl className="font-normal">
                            <dt className="sr-only xl:hidden">
                              ID Transaction
                            </dt>
                            <dt className="sr-only xl:hidden">Horário</dt>
                            <dd className="mt-1 text-gray-500 xl:hidden">
                              Hora: {formatDate(transaction.data, "HH:mm:ss")}
                            </dd>
                            <dd className="mt-1 text-gray-500 xl:hidden">
                              ID Transaction: {transaction.idAccountTransaction}
                            </dd>
                            <dt className="sr-only lg:hidden">Cliente</dt>
                            <dd className="mt-1 text-gray-500 lg:hidden">
                              Cliente: {transaction.nome}
                            </dd>
                            <dt className="sr-only lg:hidden">Documento</dt>
                            <dd className="mt-1 text-gray-500 lg:hidden">
                              Documento: {renderDocument(transaction.documento)}
                            </dd>
                            <dt className="sr-only md:hidden">Valor</dt>
                            <dd className="mt-1 font-medium text-gray-900 md:hidden">
                              Valor:{" "}
                              {transaction.valor.toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </dd>
                            <dt className="sr-only md:hidden">Descrição</dt>
                            <dd className="mt-1 text-gray-500 md:hidden">
                              <span className="truncate break-words whitespace-normal">
                                {transaction.descricao}
                              </span>
                            </dd>
                            {isNaN(idBank) && (
                              <>
                                <dt className="sr-only xl:hidden">Bano</dt>
                                <dd className="mt-1 text-gray-500 xl:hidden">
                                  {transaction.nomeBanco}
                                </dd>
                              </>
                            )}
                          </dl>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden xl:table-cell">
                          {transaction.idAccountTransaction}
                        </td>
                        <td className="whitespace-normal px-3 py-4 text-sm text-gray-500 truncate hidden md:table-cell">
                          {transaction.descricao}
                        </td>
                        <td className="whitespace-normal px-3 py-4 text-sm text-gray-500 hidden lg:table-cell">
                          {transaction.nome}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden lg:table-cell">
                          <Link
                            className="text-indigo-600 hover:text-indigo-500 text-left font-medium"
                            to={navigationHandler(transaction.idClient, transaction.documento)}
                            target="_blank"
                          >
                            {renderDocument(transaction.documento)}
                          </Link>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden md:table-cell">
                          {transaction.valor.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </td>
                        {isNaN(idBank) && (
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden xl:table-cell">
                            {transaction.nomeBanco}
                          </td>
                        )}
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 table-cell">
                          {checkAvailableReceipt(transaction.idTransaction) && (
                            <button onClick={() => showVoucherHandler(transaction)}>
                              <PrinterIcon className="h-4 w-4" />
                            </button>
                          )}
                        </td>
                      </tr>
                    )
                  )}
                </Fragment>
              ))
            ) : (
              <tr>
                <td
                  colSpan={isNaN(idBank) ? 8 : 7}
                  className="text-sm sm:text-base text-gray-500 text-center py-5"
                >
                  Não há dados para exibir
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination
          total={extractData.total}
          pageItens={state.itemsPerPage}
          pageCount={Math.ceil(extractData.total / state.itemsPerPage)}
          currentPage={state.currentPage}
          onNextPage={handleNextPage}
          onPreviousPage={handlePreviousPage}
          onClick={changeCurrentPage}
          itemsPerPage={state.itemsPerPage}
          onChangeItemsPerPage={changeItemsPerPageHandler}
        />
      </div>
      <ModalVoucher
        isModalOpen={isModalOpen}
        onOpenChange={setIsModalOpen}
        transactional={transactional}
      />
    </>
  );
};

export default ExtractTable;
