import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Layout from "components/layout";
import navigationMenu from "./navigation";
import Tab from "components/tab";
import { fetchBankList, fetchBankLimits } from "reducers/bank/bankThunk";

const Settings = () => {
  const [tabs, setTabs] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const tabItems = navigationMenu.filter((item) => item.active);

    setTabs(tabItems);
  }, []);

  useEffect(() => {
    dispatch(fetchBankList());
    dispatch(fetchBankLimits(1));

    // eslint-disable-next-line
  }, []);

  const handleTabNavigation = (evt) => {
    const { value: id } = evt.target;
    setTabs(
      tabs.map((tab) =>
        tab.id === parseInt(id)
          ? { ...tab, current: true }
          : { ...tab, current: false }
      )
    );
  };

  const displayItem = tabs.find((item) => item.current && item.component);

  return (
    <Layout title={"Admin"}>
      {tabs.length > 0 && (
        <>
          <Tab tabs={tabs} onChangeTab={handleTabNavigation} />
          <div className="space-y-6">
            {displayItem && <displayItem.component />}
          </div>
        </>
      )}
    </Layout>
  );
};

export default Settings;
