import React from "react";

import { cn } from "utils/classNames";

export default function Select({
  id,
  name,
  value,
  label,
  labelSrOnly = false,
  onChange,
  placeholder,
  options,
  error,
  errorMessage,
  disabled = false,
  className,
}) {
  return (
    <>
      {label && (
        <label
          htmlFor={id}
          className={`${
            labelSrOnly ? "sr-only" : ""
          } block text-sm font-medium text-gray-700`}
        >
          {label}
        </label>
      )}
      <select
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        className={cn(
          error
            ? "border-red-300 focus:ring-red-500 focus:border-red-500 text-red-900 pr-10"
            : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 pr-2",
          "relative block w-full mt-1 outline-0 text-sm rounded-md disabled:cursor-not-allowed disabled:bg-gray-100 shadow-sm focus:outline-none bg-white py-2 pl-3 pr-6 border",
          className
        )}
        disabled={disabled}
      >
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {options.map((item) => {
          return (
            <option value={item.id} key={`${name}-${item.id}`}>
              {item.name}
            </option>
          );
        })}
      </select>
      {error && (
        <p className="mb-0.5 mt-2 text-sm text-red-600" id={`${name}-error`}>
          {errorMessage}
        </p>
      )}
    </>
  );
}
