import { createSlice } from "@reduxjs/toolkit";

import { fetchLogs } from "./adminThunk";

const initialState = {
  logs: { itens: [], total: 0 },
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  extraReducers: {
    [fetchLogs.fulfilled]: (state, action) => {
      return { ...state, logs: { ...action.payload } };
    },
  },
});

export default adminSlice.reducer;
